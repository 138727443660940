import React from "react";
import { Container, Menu, Segment } from "semantic-ui-react";
import CashReceivedSegment from "../../components/store/CashReceivedSegment";
import POReceivedSegment from "../../components/store/POReceivedSegment";

let currentPage = "cash"
export default function ReceivingGoodsView() {
	const [activeItem, setActiveItem] = React.useState(currentPage);

	const handleItemClick = (e, {name}) => { 
    currentPage = name;
    setActiveItem(name);
  }

	return (
		<Container fluid style={{marginTop: 24}}>
			<Menu attached={"top"} tabular>
				<Menu.Item
					name={"cash"}
					active={activeItem === "cash"}
					onClick={handleItemClick}>
						รายการเงินสด
				</Menu.Item>
				<Menu.Item
					name={"po_control"}
					active={activeItem === "po_control"}
					onClick={handleItemClick}>
						รายการ PO (ควบคุม)
				</Menu.Item>
				<Menu.Item
					name={"po_not_control"}
					active={activeItem === "po_not_control"}
					onClick={handleItemClick}>
						รายการ PO (ไม่ควบคุม)
				</Menu.Item>
			</Menu>

			<Segment attached={"bottom"}>
				{(activeItem === "cash") && (
					<CashReceivedSegment style={{marginTop: 16}}/>
				)}

				{(activeItem === "po_control") && (
					<POReceivedSegment 
						style={{marginTop: 16}}
						queryParams={{
							state: "WAIT_PRODUCT,RECEIVED,RECEIVED_REJECT,WAIT_PRODUCT_APPROVE_REQUESTED,WAIT_PRODUCT_APPROVE_REJECTED",
							is_control: true,
							order_by: "created"
						}}/>
				)}

				{(activeItem === "po_not_control") && (
					<POReceivedSegment 
						style={{marginTop: 16}}
						queryParams={{
							state: "WAIT_PRODUCT,RECEIVED,RECEIVED_REJECT,WAIT_PRODUCT_APPROVE_REQUESTED,WAIT_PRODUCT_APPROVE_REJECTED",
							is_control: false,
							order_by: "created"
						}}/>
				)}
      </Segment>
		</Container>
	)
}