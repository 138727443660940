import React from "react";
import propTypes from "prop-types";
import { Form, Message, Input, TextArea, Checkbox, Button, Dropdown } from "semantic-ui-react";
import ConfirmModal from "../../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { URL_PURCHASE } from "../../../constances";
import { GET, POST } from "../../../frameworks/HttpClient";
import { DateInput } from "semantic-ui-calendar-react";

const CheckingInput = React.forwardRef((props, ref) => {
	const [lab, setLab] = React.useState(null);
	const [result, setResult] = React.useState(props.data.result);

	React.useImperativeHandle(ref, () => ({ 
		getValue: () => {
			if (lab && result) {
				return { template: lab ? lab.id : "", result: result }
			}
			return null;
		}
	}));

	React.useEffect(() => {
		if (props.data) {
			setLab(props.data.lab)
			setResult(props.data.result)
		}
	}, [props.data])

	return (
		<Form.Group style={{marginTop: 0, marginBottom: 0}}>
			<Form.Field width={8} required
				label={"คุณสมบัติ"}
				control={Dropdown}
				selection
				options={props.options ? props.options : []}
				placeholder={"เลือก..."}
				value={lab ? lab.id : ""}
				selectOnBlur={false}
				onChange={(_, data) => {
					const target = data.options.find(item => item.id === data.value);
					setLab(target);
				}}/>
			<Form.Field width={4}
				label={"ค่ามาตรฐาน"}
				control={Input}
				readOnly
				value={lab ? lab.std_value : ""}/>
			<Form.Field width={4} required
				label={"ผลตรวจ"}
				control={Input}
				value={result}
				onChange={(_, {value}) => setResult(value)}/>
			{/* Button */}
			<Form.Field style={{marginTop: "auto"}}>
				{(!props.canDelete) && (
					<Button icon={"add"}  color={"green"} onClick={() => props.onAdd()}/>
				)}
				{(props.canDelete) && (
					<Button icon={"minus"} color={"red"} onClick={() => props.onDelete(props.data)}/>
				)}
			</Form.Field>
		</Form.Group>
	)
});

const defaultLabResult = {
	id: 1,
	lab: null,
	result: "",
}
let targetId = 0;
export default function DOChemicalCheckModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [labOptions, setLabOptions] = React.useState([]);
	const [data, setData] = React.useState([]);
	const [trigger, setTrigger] = React.useState(false);
	const dataRef = React.useRef([]);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			delivery_order: props.deliveryOrder ? props.deliveryOrder.id : "",
			certificate_no: "",
			certificate_date: null,
			is_valid: null,
			note: "",
		},
		validationSchema: Yup.object({
			delivery_order: Yup.string().required("ข้อมูลใบรับสินค้าไม่ถูกต้อง"),
			certificate_no: Yup.string().notRequired(),
			certificate_date: Yup.string().notRequired().nullable(true),
			is_valid: Yup.bool().required(true).nullable(true),
			note: Yup.string()
				.notRequired().nullable(true)
				.when("is_valid", {
					is: (is_valid) => !is_valid,
					then: Yup.string().required("กรุณากรอกข้อมูล").nullable(true),
				}),
		}),
		onSubmit: (values) => handleSubmit(values),
	});

	const handleAdd = () => {
		targetId = targetId + 1;
		setData([
			...data,
			{...defaultLabResult, id: targetId},
		])
	}

	const handleDelete = async (target) => {
		let myList = data;
		let index = 0;
		for (let i = 0; i < myList.length; i++) {
			if (myList[i].id === target.id) {
				index = i;
				break;
			}
		}
		if (index > 0) {
			myList.splice(index, 1);
			setData(myList);
			setTrigger(!trigger);
		}
	}

	const handleSubmit = async (values) => {
		if (!props.deliveryOrder) {
			setErrorMessages("Delivery Order Not Found!");
			return;
		}

		setLoading(true);
		setErrorMessages(null);
		let items = []
		for (let i in data) {
			let myData = dataRef.current[i].getValue();
			// Don't create and update object that empty field
			if (myData) {
				items.push(myData)
			} 
		}
		
		values = {
			...values,
			items: items
		}
		let action = "approve";
		let params = {}
		if (!values.is_valid) {
			action = "not_approve";
			params.reject_note = values.note
		}
		try {
			await POST(URL_PURCHASE.DELIVERY_INSPECT, values);
			await POST(`${URL_PURCHASE.DELIVERY_ORDER}${props.deliveryOrder.id}/${action}/`, params);
			props.onClose();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const fetchLabOptions = async () => {
		try {
			const response = await GET(URL_PURCHASE.DELIVERY_INSPECT_TEMPLATE);
			setLabOptions(
        response.data.map(item => {
          return {
						...item,
            value: item.id, 
            text: item.name
          }
        })
      );
		} catch (error) {
			setErrorMessages(error.errorMessages);
			setLabOptions([]);
		}
	}

	const fetchMasterData = async (product) => {
		if (product) {
			try {
				const response = await GET(`${URL_PURCHASE.MASTER_PRODUCT_DELIVERY_INSPECT}?product=${product}`);
				let item = []
				response.data.map(x => {
					for (let temp of x.templates) {
						item.push(temp)
					}
				})

				if (item.length > 0) {
					setData(
						item.map(i => {
							targetId = targetId + 1
							console.log(i)
							return {
								...defaultLabResult, 
								id: targetId,
								lab: i
							}
						})
					)
				}
			} catch (error) {}
		}

		if (targetId == 0) {
			// Initial lab result
			setData([defaultLabResult]);
			targetId = 1;
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
			
			fetchLabOptions();
			let product = (props.deliveryOrder && props.deliveryOrder.purchase_request) ? props.deliveryOrder.purchase_request.product.id : "";
			fetchMasterData(product)
		} else {
			targetId = 0
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={`ผลการตรวจรับสินค้า/สารเคมี`}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form>
						<Form.Field
							label={"ชื่อสินค้า"}
							control={Input}
							readOnly
							error={formik.errors.delivery_order}
							value={(props.deliveryOrder && props.deliveryOrder.purchase_request) 
								? props.deliveryOrder.purchase_request.product.name : ""}/>

						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"หมายเลขใบ Cer"}
								control={Input}
								name={"certificate_no"}
								error={formik.errors.certificate_no}
								value={formik.values.certificate_no}
								onChange={formik.handleChange}/>
							<Form.Field 
								label={"วันที่ออกใบ Cer"}
								control={DateInput}
								closable={true}
								dateFormat="YYYY-MM-DD"
								placeholder={"เลือกวันที่..."}
								name={"certificate_date"}
								error={formik.errors.certificate_date}
								value={formik.values.certificate_date}
								onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
						</Form.Group>

						<Form.Group grouped>
							<label>ผลการตรวจสอบ/ทดสอบ</label>
							{data.map((item, index) => {
								return (
									<CheckingInput 
										ref={(el) => (dataRef.current[index] = el)}
										key={item.id}
										data={item} 
										options={labOptions}
										onAdd={handleAdd} 
										onDelete={handleDelete}
										canDelete={index !== 0}/>
								)
							})}
						</Form.Group>

						<Form.Group grouped>
							<label>สรุปผลการประเมินผลการตรวจสอบ/ทดสอบ</label>
							<Form.Field 
								label={"ผ่าน"}
								control={Checkbox}
								checked={(formik.values.is_valid !== null) && formik.values.is_valid}
								onClick={() => formik.setFieldValue("is_valid", true)}
								error={formik.errors.is_valid}/>
							<Form.Field 
								label={"ไม่ผ่าน"}
								control={Checkbox}
								checked={(formik.values.is_valid !== null) && !formik.values.is_valid}
								onClick={() => formik.setFieldValue("is_valid", false)}
								error={formik.errors.is_valid}/>
						</Form.Group>

						<Form.Field
							label={"รายละเอียดเพิ่มเติม"}
							control={TextArea}
							name={"note"}
							placeholder={"รายละเอียด..."}
							value={formik.values.note}
							error={formik.errors.note}
							onChange={formik.handleChange}/>
					</Form>
				</div>
			}/>
	)
}

DOChemicalCheckModal.defaultProps = {
	deliveryOrder: null,
	open: false,
	onClose: () => {},
}

DOChemicalCheckModal.propTypes = {
	deliveryOrder: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}