import React from 'react';
import propTypes from "prop-types";
import { Form, Input, Message } from 'semantic-ui-react';
import ConfirmModal from "../../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { URL_SALE } from '../../../constances';
import { PATCH, POST } from '../../../frameworks/HttpClient';

export default function OrderDCModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	
	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { 
			type: "dc",
			weight: props.data ? parseFloat(props.data.weight) : "" 
		},
    validationSchema: Yup.object().shape({
			weight: Yup.number().required("กรุณากรอกข้อมูล")
		}),
    onSubmit: (values) => handleSubmit(values),
  });

	const handleSubmit = async (values) => {
		if (!props.tankTransaction) {
			setErrorMessages("ไม่พบข้อมูล TANK Transaction");
			return;
		}
		
		// Prepare data
		setLoading(true);
		const saleTransaction = props.data ? props.data.id : null;
		let response = null;
		try {
			if (saleTransaction) // Update
				response = await PATCH(`${URL_SALE.CONCENTRATED_LATEX_SELL_TRANSACTION}${saleTransaction}/`, values);
			else // Create
				response = await POST(
					URL_SALE.CONCENTRATED_LATEX_SELL_TRANSACTION, 
					{...values, tank_transaction: props.tankTransaction});
				
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (!props.open) {
			setErrorMessages(null);
			formik.resetForm();
		}
	}, [props.open]);

	return (
		<ConfirmModal
			open={props.open}
			title={props.data ? "แก้ไขคำสั่ง DC" : "คำสั่ง DC"}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={props.data ? "แก้ไข" : "ยืนยัน"}
			positiveColor={props.data ? "yellow" : "green"}
			onClose={props.onClose}
			onConfirm={formik.handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}

					<Form style={{textAlign: "right" ,width: "80%"}}>
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"น้ำหนัก (kg)"}
							control={Input}
							placeholder={"น้ำหนัก (kg)"}
							name={"weight"}
							error={formik.errors.weight}
							value={formik.values.weight}
							onChange={formik.handleChange} />
					</Form>
				</div>
			}
		/>
	)
}

OrderDCModal.defaultProps = {
	open: false,
	tankTransaction: null,
	data: null,
	onClose: () => {},
}

OrderDCModal.propTypes = {
	open: propTypes.bool,
	tankTransaction: propTypes.string,
	data: propTypes.object,
	onClose: propTypes.func,
}