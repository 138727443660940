import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import { dateTimeToString } from "../../frameworks/Util";

export default function BurnSegment(props) {
	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>เผ่าฆ่าเชื้อ</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"เริ่มเผ่าฆ่าเชื้อ"}
					value={(props.data && props.data.burn_date) 
						? dateTimeToString(props.data.burn_date)
						: "-"} />
			</Form>

			<div style={{marginTop: 16}}></div>
		</Segment>
	)
}

BurnSegment.defaultProps = {
	style: null,
	data: null,
}

BurnSegment.propTypes = {
	data: propTypes.object,
}