import moment from 'moment';
import React from 'react';
import { URL_REPORT } from '../../constances/urls';
import { GET, POST } from '../HttpClient';


const initialData = {
  id: null,
  created: null,
  report_type: '',
  report_date: '',
  originator: '',
}

export default function useReportVerify({reportType=null, reportDate=null}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [data, setData] = React.useState(initialData)

  const verifyCheck = async () => {
    let isSuccess = true;
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_REPORT.REPORT_VERIFY_LOG, {report_type: reportType, report_date: reportDate})
      if(response.data.length) {
        setData(response.data[0]);
      } else {
        setData(initialData)
      }
    } catch (error) {
      isSuccess = false;
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
    return isSuccess;    
  }

  const handleVerify = async () => {
    let isSuccess = true;
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await POST(URL_REPORT.REPORT_VERIFY_LOG, {report_type: reportType, report_date: reportDate})
    } catch (error) {
      isSuccess = false;
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
    return isSuccess;
  }

  React.useEffect(() => {
    if (reportType && moment(reportDate, 'YYYY-MM-DD').isValid()) {
      verifyCheck();
    }
  }, [reportType, reportDate]);

  return { data, isLoading, errorMessages, handleVerify, verifyCheck };
}