import React, { useMemo } from 'react';
import moment from "moment";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Dropdown } from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import TSReactTable from "../../frameworks/TSReactTable";
import { 
	TABLE_HEADER_STYLE,
	getColumnsList,
	dateToString,
} from "../../utils/Util";
import { URL_REPORT } from '../../constances/urls';
import { GET } from '../../frameworks/HttpClient';
import { formatComma } from "../../frameworks/Util";
import { LATEX_GRADE_OPTIONS } from '../../constances/options';
import { PATH } from '../../routes/Routes';
import { REPORT_PATH } from '../ReportView';

export default function SummaryFieldLatexReportView() {
	// Filter
	const [dateRange, setDateRange] = React.useState([]);
	const [grade, setGrade] = React.useState("ALL");
	// Summary
	const [total, setTotal] = React.useState(0);
	const [nh3Average, setNH3Average] = React.useState(0);
	const [vfaAverage, setVFAAverage] = React.useState(0);
	// Table
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columns = useMemo(() => [
		...getColumnsList([
			{ 
				Header: "เวลา", 
				accessor: "date",
				Footer: "TOTAL",
				Cell: ({value}) => (
					<a href={`${PATH.REPORT}${REPORT_PATH.REPORT_TEST_FIELD_LATEX}?date=${moment(value).format('YYYY-MM-DD')}`} 
						rel="noreferrer" target="_blank">
						{dateToString(value)}
					</a>
				),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "น้ำหนักรวม", 
				accessor: "net_weight",
				Cell: ({value}) => (<p>{formatComma(value)}</p>),
				Footer: formatComma(total),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: " ค่าเฉลี่ย NH3", 
				accessor: "nh3",
				Footer: parseFloat(nh3Average).toFixed(2),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "ค่าเฉลี่ย VFA", 
				accessor: "vfa",
				Cell: ({value}) => (<p>{parseFloat(value).toFixed(3)}</p>),
				Footer: parseFloat(vfaAverage).toFixed(3),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
		]),
	])

	const fetchData = async () => {
		setLoading(true)
		setErrorMessages(null)
		try {
			const response = await GET(
				URL_REPORT.RAW_LATEX_SUMMARY_REPORT, 
				{
					grade: grade,
					date_after: moment(dateRange[0]).format('YYYY-MM-DD'),
					date_before: moment(dateRange[1]).format('YYYY-MM-DD')
				}
			)
			const totalWeight = response.data.reduce((c, p) => c + Number(p.net_weight), Number(0));
			const totalNH3 = response.data.reduce((c, p) => c + (Number(p.nh3) * Number(p.net_weight)), Number(0));
			const totalVFA = response.data.reduce((c, p) => c + (Number(p.vfa) * Number(p.net_weight)), Number(0));
			setTotal(totalWeight);
			setNH3Average((totalWeight > 0) ? (totalNH3 / totalWeight).toFixed(2) : 0)
			setVFAAverage((totalWeight > 0) ? (totalVFA / totalWeight).toFixed(3) : 0)
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<Form.Field
						style={{width: "500px"}}
						inline
						label={"วันที่"}
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						onChange={(_, data) => setDateRange(data.value)}/>
						
					<Form.Field 
            label={"เกรดผลิต"}
            control={Dropdown}
						selectOnBlur={false}
            selection
            value={grade}
            onChange={(_, data) => setGrade(data.value)}
            options={LATEX_GRADE_OPTIONS}/>

					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
					
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => window.open(
						`${URL_REPORT.RAW_LATEX_SUMMARY_REPORT_EXPORT}
						?grade=${grade}
						&date_after=${moment(dateRange[0]).format('YYYY-MM-DD')}
						&date_before=${moment(dateRange[1]).format('YYYY-MM-DD')}`
					)}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}