import React from "react";
import propTypes from "prop-types";
import { Segment, Form, Input, Message, Dimmer, Loader } from "semantic-ui-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PATCH, POST } from "../../frameworks/HttpClient";
import { URL_PURCHASE } from "../../constances";

let validationSchema = {
	unit_price: Yup.number().required("กรุณากรอกข้อมูล"),
	total_price: Yup.number().required("กรุณากรอกข้อมูล"),
}

const PricingSegment = React.forwardRef((props, ref) => {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: (props.initialModel.selected_quotation && props.initialModel.selected_quotation.type === "set_price") ? props.initialModel.selected_quotation : {
			unit_price: "",
			total_price: "",
			selected: true,
			type: "set_price",
			purchase_request: props.initialModel.id,
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => handleSaveQuotation(values),
	});

	const handleSaveQuotation = async () => {
		// manually validate
		const validation = await formik.validateForm();
		const isValid = Object.keys(validation).length === 0;
		if (!isValid) return;

		let isSuccess = false;
		setErrorMessages(null);
		setLoading(true);
		try {
			if (formik.values.id) {
				await PATCH(`${URL_PURCHASE.QUOTATION}${formik.values.id}/`, formik.values);
			} else {
				await POST(URL_PURCHASE.QUOTATION, formik.values);
			}
			isSuccess = true;
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		return isSuccess;
	}

	React.useImperativeHandle(ref, () => ({
		handleSaveQuotation: () => handleSaveQuotation(),
	}));

	return (
		<Segment basic style={{padding: 0, ...props.style}}>
			<Dimmer inverted active={loading}>
        <Loader inverted>โหลดข้อมูล...</Loader>
      </Dimmer>
      {errorMessages && (
        <Message negative content={errorMessages}/>
			)}

			<Form style={{marginTop: 16}}>
				<Form.Group inline>
					<Form.Field
						inline
						required
						label={"ราคาซื้อต่อหน่วย"}
						control={Input}
						placeholder={"0"}
						type={"number"}
						name={"unit_price"}
						readOnly={!props.canEdit}
						error={formik.errors.unit_price}
						value={formik.values.unit_price}
						onChange={(_, data) => {
							let totalPrice = Number(data.value) * Number(props.initialModel ? props.initialModel.amount : 0)
							totalPrice = totalPrice.toFixed(2);
							formik.setFieldValue('unit_price', data.value)
							formik.setFieldValue('total_price', totalPrice)
						}}/>					
					<Form.Field
						inline
						required
						label={"ราคารวม"}
						control={Input}
						placeholder={"0"}
						type={"number"}
						name={"total_price"}
						readOnly
						error={formik.errors.total_price}
						value={formik.values.total_price}/>
					
					</Form.Group>
			</Form>
		</Segment>
	)
})

export default PricingSegment;

PricingSegment.defaultProps = {
	style: {},
	canEdit: true,
}

PricingSegment.propTypes = {
	style: propTypes.object,
	canEdit: propTypes.bool,
}