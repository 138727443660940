import Cookies from 'js-cookie';
import React from 'react';


const defaultUserData = {
  display_name: '',
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  signature: null,
  department: null,
  is_active: '',
  groups: [],
}

export default function useUserData() {
  const [user, setUser] = React.useState({
    ...defaultUserData, 
    ...JSON.parse(Cookies.get('user') ? Cookies.get('user') : null)
  });

  const setUserData = (userData) => {
    Cookies.set('user', userData, { expires: 30 })
    // store user department in localStorage
    if (userData && userData.department) {
      localStorage.setItem('department', userData.department.id)
      localStorage.setItem('department_name', userData.department.name)
    }
    setUser({...defaultUserData, ...userData})
  }

  const removeUserData = () => {
    Cookies.remove('user')
    localStorage.removeItem('department')
    localStorage.removeItem('department_name')

    setUser(defaultUserData)
  }

  return { user, setUserData, removeUserData }
}