import React from 'react';
import propTypes from "prop-types";
import { Divider, Header } from 'semantic-ui-react';
import ReadModal from "../common/ReadModal";
import TSReactTable from "../../frameworks/TSReactTable";
import useListItem from "../../frameworks/hooks/useItemList";
import { URL_POND } from "../../constances";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { dateTimeToString, formatComma } from "../../frameworks/Util";

export default function TruckTransactionModal(props) {
	const list = useListItem({url: `${URL_POND.POND_TRUCK_TRANSACTION}?pond_transaction=${props.pondTransaction}`});
	const splitPondList = useListItem({ url: `${URL_POND.POND_SPLIT}?pond_transaction=${props.pondTransaction}` });
	const [total, setTotal] = React.useState(0);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลา", 
				accessor: "date", 
				width: 160, 
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>),
				Footer: "รวม",
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "ทะเบียนรถ", accessor: "license_plate" },
			{ Header: "จุดรับซื้อ", accessor: "customer_name" },
			{ 
				Header: "%NH3", 
				accessor: "lab_results", 
				width: 120,
				Cell: ({value}) => columnItem(value, "nh3")
			},
			{ 
				Header: "VFA", 
				accessor: "lab_results", 
				width: 120, 
				Cell: ({value}) => columnItem(value, "vfa")
			},
			{ 
				Header: "น้ำหนัก (kg)",
				accessor: "weight",
				Cell: ({value}) => (<p>{formatComma(value)}</p>),
				width: 140,
				Footer: formatComma(total),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
		]),
	]);

	const splitPondColumns = React.useMemo(() => [
		...getColumnsList([
			{
				Header: "วันที่และเวลา",
				accessor: "created",
				width: 160,
				Cell: ({ value }) => (<p>{dateTimeToString(value)}</p>),
			},
			{ Header: "บ่อพักต้นทาง", accessor: "from_pond_name" },
			{ Header: "บ่อพักปลายทาง", accessor: "to_pond_name" },
			{
				Header: "น้ำหนัก (kg)",
				accessor: "weight",
				Cell: ({ value }) => (<p>{formatComma(value)}</p>),
				width: 140,
			},
		])
	])

	const columnItem = (list, keyName) => {
		let item = null;
		for (let it of list) {
			if (it.chemical === keyName) {
				item = it;
				break;
			}
		}
		return (<p>{item ? parseFloat(item.amount).toLocaleString("en-US") : "-"}</p>)
	}

	React.useEffect(() => {
		/** Calculate total */
		let sum = list.response.reduce((prev, current) => prev + current.weight, 0);
		setTotal(sum);
	}, [list.response])

	const fetchData = async () => {
		list.fetchData();
		splitPondList.fetchData();
	}

	React.useEffect(() => {
		if (props.open && props.pondTransaction) {
			/** load list */
			fetchData();
		}
	}, [props.open]);

	return (
		<ReadModal 
			open={props.open}
			title={`รายการรถ บ่อพัก ${props.pondName}`}
			size={"large"}
			detail={
				<div>
					<TSReactTable 
						style={{marginTop: 16}}
						columns={columns}
						data={list.response}
						pageSize={(list.response.length > 0) ? list.response.length : 0}
						showPagination={false}/>
					
					{splitPondList.response.length > 0 && 
						<>
							<Divider/>
							<Header as='h3'>การแบ่งบ่อ</Header>
							<TSReactTable
								style={{ marginTop: 16 }}
								columns={splitPondColumns}
								data={splitPondList.response}
								pageSize={(splitPondList.response.length > 0) ? splitPondList.response.length : 0}
								showPagination={false} />
						</>
					}

				</div>
			}
			onClose={props.onClose}/>
	)
}

TruckTransactionModal.defaultProps = {
	pondTransaction: "",
	pondName: "",
	open: false, 
	onClose: () => {},
}

TruckTransactionModal.propTypes = {
	pondTransaction: propTypes.string,
	pondName: propTypes.string,
	open: propTypes.bool,
	onClose: propTypes.func,
}