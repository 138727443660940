import React from 'react';
import { Container, Icon, Message } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import sale from "../assets/img_sale.png";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import { URL_SALE } from '../constances';
import useListItem from '../frameworks/hooks/useItemList';
import { dateTimeToString, formatComma } from '../frameworks/Util';
import { LATEX_ORDER_TYPE } from '../constances/string';

export default function StoreOfTankView() {
	const history = useHistory();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const list = useListItem({url: `${URL_SALE.CONCENTRATED_LATEX_SELL_TRANSACTION}?is_finished=false`});
	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ 
				Header: "วันที่และเวลาสร้าง", 
				accessor: "created", 
				width: 140,
				Cell: ({value}) => (<p>{value ? dateTimeToString(value) : "-"}</p>)
			},
			{ Header: "เลขที่แท็งก์", accessor: "tank_name" },
			{ Header: "Lot No.", accessor: "lot_no", width: 120 },
			{ 
				Header: "คำสั่ง", 
				accessor: "type", 
				width: 80,
				Cell: ({value}) => (<p>{value ? LATEX_ORDER_TYPE[value] : "-"}</p>)
			},
			{ Header: "Contact", accessor: "contact", width: 140, Cell: ({value}) => (<p>{value ? value : "-"}</p>) },
			{ Header: "Advice", accessor: "advice", Cell: ({value}) => (<p>{value ? value : "-"}</p>) },
			{ 
				Header: "Buyer", 
				accessor: "buyer", 
				width: 180, 
				Cell: ({value}) => (<p>{value ? value : "-"}</p>) 
			},
			{ Header: "Packing", accessor: "packing", Cell: ({value}) => (<p>{value ? value : "-"}</p>) },
			{ 
				Header: "นน.สั่งขาย (kg)", 
				accessor: "weight", 
				width: 120, 
				Cell: ({value}) => (<p>{value ? formatComma(value) : "0"}</p>)
			},
			{ 
				Header: "นน.โหลด (Kg)", 
				accessor: "total_load_weight", 
				width: 120,
				Cell: ({value}) => (<p>{value ? formatComma(value) : "0"}</p>)
			},
			{
				Header: "สถานะ",
				width: 70,
				Cell: ({original}) => {
					const isFinished = original.is_finished;
					return (
						<div>
							<Icon size={"large"}
								name={isFinished ? "check circle" : "minus circle"} 
								color={isFinished ? "green" : "grey"} />
						</div>
					)
				}
			}
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: ({original}) => {
				return (
					<div>
						<ImageButton 
							title={"ขายน้ำยางข้น"}
							src={sale}
							onClick={(e, data) => handleSale(original)}/>
					</div>
				)
			}
		},
	]);

	const handleSale = (data) => {
		// Go to sale
		history.push({pathname: `${PATH.SALE_CON_LATEX}${data.id}`})
	}

	React.useEffect(() => {
		list.fetchData();
	}, [])

	return (
		<Container fluid style={{marginTop: 24}}>
			{/* Error */}
			{errorMessages && (
				<Message 
					negative
					header={"เกิดข้อผิดพลาด"}
					content={errorMessages}/>
			)}

			{/* Table */}
			<TSReactTable
				columns={columns}
				data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>

		</Container>
	);
}