import React from "react";
import propTypes from "prop-types";
import { Header, Form, Input } from "semantic-ui-react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GET } from "../../frameworks/HttpClient";
import { URL_COMMON } from "../../constances";
import ChemicalInputForm from "./ChemicalInputForm";

let targetId = 1;
const LabTankInput = React.forwardRef((props, ref) => {
	const [others, setOthers] = React.useState([]);
	const [trigger, setTrigger] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const chemicalRef = React.useRef([]);

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
			tz: {amount: "", percent: "", batch: ""},
			la: {amount: "", percent: "", batch: ""}
		},
    validationSchema: Yup.object().shape({
			tz: Yup.object().shape({
				amount: Yup.number().notRequired(),
				percent: Yup.number().notRequired(),
				batch: Yup.string().notRequired()
			}),
			la: Yup.object().shape({
				amount: Yup.number().notRequired(),
				percent: Yup.number().notRequired(),
				batch: Yup.string().notRequired()
			}),
		}),
    onSubmit: props.onSubmit,
  });

	const fetchOptions = async () => {
		try {
			const response = await GET(`${URL_COMMON.CHEMICAL}`);
			setOptions(
        response.data.map(item => {
          return {
            key: item.id, 
            value: item.name, 
            text: item.name
          }
        })
      );
		} catch (error) {}
	}
	
	const handleAddChemical = () => {
		targetId = targetId + 1;
		setOthers([
			...others,
			{id: targetId, chemical: "", amount: ""}
		])
	}

	const handleDeleteChemical = (data) => {
		let myList = others;
		let index = 0;
		for (let i = 0; i < myList.length; i++) {
			if (myList[i].id === data.id) {
				index = i;
				break;
			}
		}
		
		if (index > 0) {
			myList.splice(index, 1);
			setOthers(myList);
			setTrigger(!trigger);
		}
	}

	React.useImperativeHandle(ref, () => ({ 
		formik,
		getOthers: () => {
			let list = []
			for (let index in others) {
				let value = chemicalRef.current[index].getValue()
				if (value.chemical && (value.amount > 0)) {
					list.push(value);
				}
			}
			return list;
		}
	 }));

	React.useEffect(() => {
		if (props.initialOthersModal 
			&& (props.initialOthersModal.length > 0)) {
				targetId = props.initialOthersModal.length;
				setOthers(props.initialOthersModal);
		} else {
			targetId = 1;
			setOthers([{id: 1, chemical: "", amount: ""}]);
		}

		formik.resetForm();
		fetchOptions();
		if (props.initialModel) {
			formik.setValues(props.initialModel);
		}
	}, [])

	return (
		<div style={{...props.style}}>
			<Header as={"h3"} style={{marginLeft: 24}}>สารเคมีที่ต้องการเติมเพิ่ม</Header>
			<Form style={{textAlign: "right"}}>
				<Form.Group>
					<Form.Field
						width={8} 
						style={{width: "55%"}}
						inline
						label={"TZ"}
						control={Input}
						placeholder={"0"}
						name={"tz.amount"}
						error={formik.errors.tz ? formik.errors.tz.amount : false}
						value={formik.values.tz ? formik.values.tz.amount : ""}
						onChange={formik.handleChange}
					/>
					<Form.Field 
						width={4}
						control={Input}
						placeholder={"%ความเข้มข้น"} 
						name={"tz.percent"}
						error={formik.errors.tz ? formik.errors.tz.percent : false}
						value={formik.values.tz ? formik.values.tz.percent : ""}
						onChange={formik.handleChange}
					/>
					<Form.Field 
						width={4}
						control={Input}
						placeholder={"Batch"} 
						name={"tz.batch"}
						error={formik.errors.tz ? formik.errors.tz.batch : false}
						value={formik.values.tz ? formik.values.tz.batch : ""}
						onChange={formik.handleChange}
					/>
				</Form.Group>
				
				<Form.Group>
					<Form.Field
						width={8} 
						style={{width: "55%"}}
						inline
						label={"La"}
						control={Input}
						placeholder={"0"} 
						name={"la.amount"}
						error={formik.errors.la ? formik.errors.la.amount : false}
						value={formik.values.la ? formik.values.la.amount : ""}
						onChange={formik.handleChange}
					/>
					<Form.Field 
						width={4}
						control={Input}
						placeholder={"%ความเข้มข้น"} 
						name={"la.percent"}
						error={formik.errors.la ? formik.errors.la.percent : false}
						value={formik.values.la ? formik.values.la.percent : ""}
						onChange={formik.handleChange}
					/>
					<Form.Field 
						width={4}
						control={Input}
						placeholder={"Batch"} 
						name={"la.batch"}
						error={formik.errors.la ? formik.errors.la.batch : false}
						value={formik.values.la ? formik.values.la.batch : ""}
						onChange={formik.handleChange}
					/>
				</Form.Group>

				{/* <ChemicalInput /> */}
				{others.map((data, index) => {
					return (
						<ChemicalInputForm 
							ref={(el) => (chemicalRef.current[index] = el)}
							key={data.id} 
							options={options} 
							data={data} 
							canDelete={(index === 0) ? false : true}
							onAdd={handleAddChemical} 
							onDelete={handleDeleteChemical}
						/>
					)
				})}
			</Form>
		</div>
	)
});

LabTankInput.defaultProps = {
	style: null,
	initialModel: null,
	initialOthersModal: [],
}

LabTankInput.propTypes = {
	style: propTypes.object,
	initialModel: propTypes.object,
	initialOthersModal: propTypes.array,
}

export default LabTankInput;