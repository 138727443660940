import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import { dateTimeToString } from "../../frameworks/Util";

export default function ProduceSegment(props) {
	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>เปิดบ่อ</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"เปิดบ่อเมื่อ"}
					value={(props.data && props.data.produce_date) 
						? dateTimeToString(props.data.produce_date)
						: "-"} />
			</Form>

			<div style={{marginTop: 16}}></div>
		</Segment>
	)
}

ProduceSegment.defaultProps = {
	style: null,
	data: null,
}

ProduceSegment.propTypes = {
	data: propTypes.object,
}