import React from 'react';
import { GET } from '../HttpClient';


// usage ==> const choices = useDropdownItem({url: '/api/items/choice1'});
// choices.response
export default function useDropdownItem ({url, text_renderer=(item) => item.name}) {
  const [response, setResponse] = React.useState([]);

  const fetchData = async () => {
    try {
      const response = await GET(url);
      setResponse(
        response.data.map(item => {
          return {
            ...item, 
            key: item.id, 
            value: item.id, 
            text: text_renderer(item)
          }
        })
      );
    } catch (error) {
      console.log(error.errorMessages)
    }
  };

  const initialResponse = async (dataList) => {
    setResponse(
      dataList.map(item => {
        return {
          ...item, 
          key: item.id, 
          value: item.id, 
          text: item.name
        }
      })
    );
    // setResponse(dataList);
  }

  return { response, fetchData, initialResponse };
}