import React from 'react';
import { Container, Form, Search } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import edit from "../assets/img_edit.png";
import historyIMG from "../assets/img_history.png";
import summary from "../assets/img_summary.png";
import sale from "../assets/img_sale.png";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import { TANK_STATUS, URL_TANK } from '../constances';
import useListItem from '../frameworks/hooks/useItemList';
import { dateTimeToString, formatComma } from '../frameworks/Util';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import _ from 'lodash';
import { GET } from '../frameworks/HttpClient';

export default function LabOfControlView() {
	const history = useHistory();
	const [dateFilter, setDateFilter] = React.useState(moment().format('YYYY-MM-DD'));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [transactionOptions, setTransactionOptions] = React.useState([]);
	const [selectedLotNo, setSelectedLotNo] = React.useState(null);
	// Table
	const list = useListItem({url: `${URL_TANK.TANK_LAB_CLOSED}?date=${dateFilter}&&lot_no=${selectedLotNo ? selectedLotNo.title : ""}`});
	const columns = React.useMemo(() => [
		{
			Header: "",
			headerStyle: TABLE_HEADER_STYLE,
			accessor: "state",
			width: 100,
			Cell: ({original, value}) => {
				const STATUS = TANK_STATUS[value];
				return (
					<div>
						<ImageButton 
							title={(STATUS === TANK_STATUS.END) ? "ประวัติ" : "แก้ไข"}
							color={(STATUS === TANK_STATUS.END) ? "teal" : "blue"}
							src={(STATUS === TANK_STATUS.END) ? historyIMG : edit}
							onClick={() => handleDetail(original.uid)}/>
						
						{(STATUS === TANK_STATUS.SELL) && (
							<ImageButton 
								title={"คำสั่งขาย"}
								src={sale}
								onClick={() => handleOrdering(original.uid)}/>
						)}

						{(STATUS === TANK_STATUS.CLOSE) && (
							<ImageButton 
								title={"สรุป"}
								src={summary}
								onClick={() => handleSummary(original.uid)}/>
						)}
					</div>
					
				)
			}
		},
		...getColumnsList([
			{ 
				Header: "วันที่และเวลาล่าสุด", 
				accessor: "last_date_event", 
				width: 140,
				Cell: ({value}) => (<p>{value ? dateTimeToString(value) : "-"}</p>)
			},
			{ Header: "เลขที่แท็งก์", accessor: "tank_name", width: 90 },
			{ Header: "Lot No.", accessor: "lot_no", width: 120 },
			{ Header: "เกรด", accessor: "grade", width: 90 },
			{ 
				Header: "น้ำหนัก (Kg)", 
				accessor: "current_weight", 
				minWidth: 100, 
				Cell: ({value}) => (<p>{value ? formatComma(value, true) : "0"}</p>)
			},
			{ 
				Header: "สถานะ", 
				accessor: "state", 
				width: 120,
				Cell: ({value}) => (<p>{value ? TANK_STATUS[value] : "-"}</p>)
			}
		]),
		...getColumnsList([
			{ Header: "%TSC", accessor: "closed_lab", Cell: ({value}) => columnItem(value, "tsc") },
			{ Header: "%DRC", accessor: "closed_lab", Cell: ({value}) => columnItem(value, "drc") },
			{ 
				Header: "NON Rubber", 
				accessor: "closed_lab", 
				headerStyle: { fontSize: 10 },
				Cell: ({value}) => columnItem(value, "non_rubber")
			},
			{ Header: "%NH3", accessor: "closed_lab", Cell: ({value}) => columnItem(value, "nh3") },
			{ Header: "VFA", accessor: "closed_lab", Cell: ({value}) => columnItem(value, "vfa") },
			{ Header: "KOH", accessor: "closed_lab", Cell: ({value}) => columnItem(value, "koh") },
			{ Header: "MST", accessor: "closed_lab", Cell: ({value}) => columnItem(value, "mst") },
			{ Header: "Mg", accessor: "closed_lab", Cell: ({value}) => columnItem(value, "mg") },
			{ 
				Header: "%Coagulum", 
				accessor: "closed_lab", 
				headerStyle: { fontSize: 10 },
				Cell: ({value}) => columnItem(value, "coagulum")
			},
			{ 
				Header: "Viscosity", 
				accessor: "closed_lab", 
				headerStyle: { fontSize: 10 },
				Cell: ({value}) => columnItem(value, "viscosity")
			},
			{ 
				Header: "Sludge", 
				accessor: "closed_lab", 
				Cell: ({value}) => columnItem(value, "sludge") 
			},
			{ 
				Header: "Filterability", 
				accessor: "closed_lab", 
				headerStyle: { fontSize: 10 },
				Cell: ({value}) => columnItem(value, "filterability")
			},
			{ 
				Header: "Micro Coagulum", 
				accessor: "closed_lab", 
				headerStyle: { fontSize: 10 },
				Cell: ({value}) => columnItem(value, "micro_coagulum")
			},
		], 65),
	])

	const columnItem = (list, keyName) => {
		let item = null;
		for (let it of list) {
			if (it.chemical === keyName) {
				item = it;
				break;
			}
		}
		return (<p>{item ? parseFloat(item.amount) : "-"}</p>)
	}

	const handleDetail = (id) => history.push({pathname: `${PATH.LAB_CONTROL}${id}`});
	const handleSummary = (id) => history.push({pathname: `${PATH.TANK_SUMMARY}${id}`});
	const handleOrdering = (id) => history.push({pathname: `${PATH.SALE_ORDER_LATEX}${id}`})

	/** START: Delay for search */
	const debounceFunc = React.useCallback(_.debounce(e => fetchTankTransactionOptions(e), 500), []);
	const handleSearchChange = e => debounceFunc(e);
	/** END: Delay for search */
	const fetchTankTransactionOptions = async (search) => {
		if (!search)
			return;
		
		setSearchLoading(true)
		setTransactionOptions([]);
		try {
			const response = await GET(URL_TANK.TANK_TRANSACTION, {lot_no: search});
			setTransactionOptions(
				response.data.map(item => {
					return {
						id: item.id,
						key: item.id,
						value: item.id,
						title: item.lot_no,
					}
				})
			);
		} catch (error) {
			// setErrorMessages(null);
		} finally {
			setSearchLoading(false)
		}
	}

	React.useEffect(() => {
		if (dateFilter) {
			if (!moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
				if (!dateFilterError)
					setDateFilterError(true);
				return;
			}
			
			if (dateFilterError)
				setDateFilterError(false);
			list.fetchData();
		}
	}, [dateFilter, selectedLotNo]);

	React.useEffect(() => {
		list.fetchData();
	}, []);

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<label>{"วันที่"}</label>
					<Form.Field
						control={DateInput}
						closable={true}
						dateFormat="YYYY-MM-DD"
						maxDate={moment()}
						placeholder={"เลือก..."}
						name={"date"}
						error={dateFilterError}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>

					<label style={{marginLeft: 18}}>{"Lot No."}</label>
					<Form.Field
						control={Search}
						placeholder={"ค้นหา..."}
						minCharacters={3}
						results={transactionOptions}
						showNoResults={true}
						onSearchChange={(_, data) => {
							handleSearchChange(data.value);
							setSelectedLotNo(null);
						}}
						onResultSelect={(_, data) => {
							setSelectedLotNo(data.result);
						}}
						loading={searchLoading}
					/>
				</Form.Group>
			</Form>

			<TSReactTable 
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>
		</Container>
	)
}