import React, { useState, useMemo } from 'react';
import { Container, Form, Dropdown, Button } from 'semantic-ui-react';
import { TANK_STATUS, TANK_STATUS_OPTIONS } from "../constances";
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import TransferLatexModal from "../components/tank/TransferLatexModal";
import CreateLotNoModal from "../components/tank/CreateLotNoModal";
import ConfirmModal from "../components/common/ConfirmModal";
import cleaning from "../assets/img_cleaning.png";
import create from "../assets/img_create.png";
import edit from "../assets/img_edit.png";
import lab from "../assets/img_lab.png";
import lab_noti from "../assets/img_lab_noti.png";
import fire from "../assets/img_fire.png";
import done from "../assets/img_done.png";
import summary from "../assets/img_summary.png";
import { TABLE_HEADER_STYLE, TABLE_BODY_STYLE, getColumnsList } from "../utils/Util";
import useListItem from "../frameworks/hooks/useItemList";
import { URL_TANK } from "../constances";
import { formatComma } from '../frameworks/Util';
import { POST } from '../frameworks/HttpClient';
import TankWeightLogModal from '../components/tank/TankWeightLogModal';

export default function TankView() {
	const history = useHistory();
	const [filterStatus, setFilterStatus] = useState("ALL");
	const list = useListItem({url: `${URL_TANK.TANK}?state=${filterStatus}`});
	// Change state with modal
	const [targetCreate, setTargetCreate] = React.useState(null);
	const [changeToClean, setChangeToClean] = React.useState(false);
	const [targetClean, setTargetClean] = React.useState(null);
	const [changeToBurn, setChangeToBurn] = React.useState(false);
	const [targetBurn, setTargetBurn] = React.useState(null);
	const [changeToReady, setChangeToReady] = React.useState(false);
	const [targetReady, setTargetReady] = React.useState(null);
	const [openTransfer, setOpenTransfer] = React.useState(false);
	// Weight Detail
	const [targetWeight, setTargetWeight] = React.useState(null);

	const columns = useMemo(() => [
		...getColumnsList([ 
			{ Header: "เลขที่แท็งก์", accessor: "name" },
			{ 
				Header: "Lot No.", 
				accessor: "lot_no", 
				width: 160,
				Cell: ({value}) => (<p>{value ? value : "-"}</p>)
			},
			{ 
				Header: "เกรด", 
				accessor: "grade", 
				width: 120,
				Cell: ({value}) => (<p>{value ? value : "-"}</p>) 
			},
			{ 
				Header: "นำ้หนัก (Kg)", 
				accessor: "weight", 
				Cell: ({original, value}) => {
					return (
						<p 
							onClick={() => {
								if (original.transaction)
									setTargetWeight(original)
							}}>
							{value ? formatComma(value, true) : "0"}
						</p>
					)
				} 
			},
		]),
		{
			Header: "สถานะ",
			headerStyle: TABLE_HEADER_STYLE,
			Cell: (row) => {
				const STATUS = TANK_STATUS[row.original.state];
				return (<p style={{...TABLE_BODY_STYLE, height: "100%",}}>{STATUS}</p>)
			}
		},
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			accessor: "state",
			width: 150,
			Cell: ({original, value}) => {
				const STATUS = TANK_STATUS[value];
				const transaction = original.transaction;
				const wRefilledLab = original.warning_refilled_lab_input;
				const wCloseLab = original.warning_closed_lab_input;
				return (
					<div>
						{(STATUS === TANK_STATUS.EMPTY) && (
							<ImageButton 
								title={"สร้าง Lot No."}
								src={create}
								onClick={(e, data) => setTargetCreate(original)}/>
						)}
						{(STATUS === TANK_STATUS.REFILL) && (
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={(e, data) => handleDetail(transaction)}/>
						)}
						{(STATUS === TANK_STATUS.REFILL) && (
							<ImageButton 
								title={"แลป (ผลิต)"}
								src={wRefilledLab ? lab_noti : lab}
								onClick={(e, data) => handleLabAdjust(transaction)}/>
						)}
						{((STATUS === TANK_STATUS.SELL) || ((STATUS === TANK_STATUS.CLOSE) && (original.weight <= 0))) && (
							<ImageButton 
								title={"ทำความสะอาด"}
								src={cleaning}
								onClick={(e, data) => handleClean(original)}/>
						)}
						{((STATUS === TANK_STATUS.CLOSE) || (STATUS === TANK_STATUS.SELL)) && (
							<ImageButton 
								title={"แลป (เก็บ)"}
								src={wCloseLab ? lab_noti : lab}
								onClick={(e, data) => handleLabControl(transaction)}/>
						)}
						{((STATUS === TANK_STATUS.CLEAN) || (STATUS === TANK_STATUS.BURN)) && (
							<ImageButton 
								title={"เผาฆ่าเชื้อ"}
								src={fire}
								onClick={(e, data) => handleBurn(original)}/>
						)}
						{(STATUS === TANK_STATUS.BURN) && (
							<ImageButton 
								title={"พร้อมใช้งาน"}
								src={done}
								onClick={(e, data) => handleReady(original)}/>
						)}
						{!(STATUS === TANK_STATUS.EMPTY) && (
							<ImageButton 
								title={"สรุป"}
								src={summary}
								onClick={(e, data) => handleSummary(transaction)}/>
						)}
					</div>
				)
			}
		},
	]);

	// Function
	const handleStatusChange = (e, {value}) => { setFilterStatus(value) }
	const handleDetail = (transaction) => history.push({pathname: `${PATH.TANK_DETAIL}${transaction}`});
	const handleSummary = (transaction) => history.push({pathname: `${PATH.TANK_SUMMARY}${transaction}`});
	const handleLabAdjust = (transaction) => history.push({pathname: `${PATH.LAB_ADJUST}${transaction}`});
	const handleLabControl = (transaction) => history.push({pathname: `${PATH.LAB_CONTROL}${transaction}`});
	const handleClean = (data) => setTargetClean(data); 
	const handleBurn = (data) => setTargetBurn(data); 
	const handleReady = (data) => setTargetReady(data);

	// Change STATE [clean_up, burn, re_burn, end]
	const handleChangeState = async (transaction, action) => {
		let response = null;
		let error = null;
		try {
			response = await POST(`${URL_TANK.TANK_TRANSACTION}${transaction}/${action}/`)
		} catch (e) {
			error = e.errorMessages
		}
		
		return [response, error]
	}

	React.useEffect(() => {
		setChangeToClean(targetClean ? true : false);

	}, [targetClean]);

	React.useEffect(() => {
		setChangeToBurn(targetBurn ? true : false);
		
	}, [targetBurn]);

	React.useEffect(() => {
		setChangeToReady(targetReady ? true : false);
		
	}, [targetReady]);

	React.useEffect(() => {
		if (!targetCreate && !targetClean && !targetBurn && !targetReady && !openTransfer) {
			// Load list
			list.fetchData();
		}
	}, [filterStatus, targetCreate, targetClean, targetBurn, targetReady, openTransfer]);

	return (
		<Container fluid style={{marginTop: 24}}>
			{/* Filter */}
			<Form>
				<Form.Group widths={"equal"}>
					<Form.Field inline>
						<label>สถานะแท็งก์</label>
						<Dropdown 
							selection
							options={TANK_STATUS_OPTIONS}
							placeholder={"ทั้งหมด"}
							onChange={handleStatusChange}
							value={filterStatus}
						/>
					</Form.Field>
					<Form.Field style={{textAlign: "right"}}>
						<Button 
							color={"green"}
							onClick={() => setOpenTransfer(true)}>
								โอนย้ายแท็งก์
						</Button>
					</Form.Field>
				</Form.Group>
			</Form>

			{/* Table */}
			<TSReactTable
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}
				getTrProps={(state, rowInfo, column, instance) => {
					if (rowInfo) {
						const wRefilledLab = rowInfo.original.warning_refilled_lab_input;
						const wCloseLab = rowInfo.original.warning_closed_lab_input;
						if (wRefilledLab || wCloseLab) {
							return {
								style: { background: "#F5B7B1"},
							}
						}
						else 
							return {}
					} 
					else 
						return {}
				}}
			/>
			
			{/* Create lot no */}
			<CreateLotNoModal 
				open={targetCreate ? true : false}
				data={targetCreate}
				onClose={() => setTargetCreate(null)} />
			
			{/* Change to clean */}
			<ConfirmModal 
				open={changeToClean}
				title={`ทำความสะอาด [${targetClean ? targetClean.name : ""}]`}
				detail={"คุณต้องการทำความสะอาดแท็งก์ใช่หรือไม่ ?"}
				onClose={() => setTargetClean(null)}
				onCancel={() => setTargetClean(null)}
				onConfirm={async () => {
					await handleChangeState(targetClean.transaction, "clean_up");
					setTargetClean(null);
				}}/>
			
			{/* Change to burn */}
			<ConfirmModal 
				open={changeToBurn}
				title={`เผ่าฆ่าเชื้อ [${targetBurn ? targetBurn.name : ""}]`}
				detail={"คุณต้องการเผ่าฆ่าเชื้อแท็งก์ใช่หรือไม่ ?"}
				onClose={() => setTargetBurn(null)}
				onCancel={() => setTargetBurn(null)}
				onConfirm={async () => {
					let action = "burn";
					if (TANK_STATUS.BURN === TANK_STATUS[targetBurn.state])
						action = "re_burn";
					await handleChangeState(targetBurn.transaction, action);
					setTargetBurn(null);
				}}/>

			{/* Change to Ready */}
			<ConfirmModal 
				open={changeToReady}
				title={`เตรียมพร้อมใช้งาน [${targetReady ? targetReady.name : ""}]`}
				detail={"แท็งก์พร้อมใช้งานแล้วใช่หรือไม่ ?"}
				onClose={() => setTargetReady(null)}
				onCancel={() => setTargetReady(null)}
				onConfirm={async () => {
					await handleChangeState(targetReady.transaction, "end");
					setTargetReady(null);
				}}/>

			{/* Transfer */}
			<TransferLatexModal 
				open={openTransfer}
				options={list.response}
				onClose={() => setOpenTransfer(false)}/>

			{/* Weight Log */}
			<TankWeightLogModal 
				open={targetWeight ? true : false}
				data={targetWeight}
				onClose={() => setTargetWeight(null)}/>

		</Container>
	);
}