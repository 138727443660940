import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form, Button, Message } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import ConfirmModal from "../common/ConfirmModal";
import { POST } from "../../frameworks/HttpClient";
import { URL_POND } from "../../constances";
import { dateTimeToString, formatComma } from "../../frameworks/Util";
export default function CloseSegment(props) {
	const [openModal, setOpenModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	/** Goto PRODUCE state */
	const handleChangeState = async (args) => {
		let error = null;
		setLoading(true);
		try {
			await POST(`${URL_POND.POND_TRANSACTION}${props.data ? props.data.id : ""}/produce/`)
			setOpenModal(false);
			props.onReload();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (openModal && errorMessages)
			setErrorMessages(null)
	}, [openModal])

	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>ปิดบ่อ</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"วันที่ปิดบ่อ"}
					value={(props.data && props.data.closing_date) 
						? dateTimeToString(props.data.closing_date) 
						: "-"} />

				<SummaryField 
					title={"เกรดผลิต"}
					value={(props.data && props.data.closing_grade) 
						? props.data.closing_grade
						: "-"} />
				
				<SummaryField 
					title={"น้ำหนักล่าสุดหลังปิดบ่อ"}
					value={props.isFinished 
						? formatComma(parseFloat(props.data.close_weight)) 
						: "-"}
					unit={"กิโลกรัม"}/>
			</Form>

			<div style={{textAlign: "right", marginTop: 16}}>
				{!props.isFinished && (
					<Button primary disabled={props.disableAction} onClick={() => setOpenModal(true)}>เปิดบ่อ</Button>
				)}
			</div>

			{props.disableAction && (
				<Message 
					error
					style={{textAlign: "center"}}
					content={"ยังมีรายการสารเคมีที่รอการเติมลงบ่อพัก กรุณาเติมสารเคมีให้เสร็จสิ้นก่อนเปิดบ่อ"}/>
			)}

			<ConfirmModal 
				open={openModal}
				title={"เปิดบ่อ"}
				detail={
					<div>
						{errorMessages && (
							<Message 
								negative
								header={"เกิดข้อผิดพลาด"}
								content={errorMessages}/>
						)}
						<p>{"คุณต้องการเปิดบ่อพักน้ำยางใช่หรือไม่ ?"}</p>
					</div>
				}
				loading={loading}
				onClose={() => setOpenModal(false)}
				onCancel={() => setOpenModal(false)}
				onConfirm={handleChangeState}/>
			
		</Segment>
	)
}

CloseSegment.defaultProps = {
	style: null,
	data: null,
	disableAction: false,
	isFinished: false,
	onReload: () => {},
}

CloseSegment.propTypes = {
	data: propTypes.object,
	disableAction: propTypes.bool,
	isFinished: propTypes.bool,
	onReload: propTypes.func,
}