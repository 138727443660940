import React from 'react';
import propTypes from "prop-types";
import { Form, Dropdown, Input, Checkbox, Segment, Message, Divider } from 'semantic-ui-react';
import ConfirmModal from "../common/ConfirmModal";
import { TANK_STATUS, URL_COMMON, URL_TANK } from '../../constances';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useDropdownItem from '../../frameworks/hooks/useDropdownItem';
import { POST } from '../../frameworks/HttpClient';

const TRANSFER_TYPE = {
	TRANSFER_WEIGHT: "TRANSFER_WEIGHT", //โอนย้ายนํ้าหนัก
	TRANSFER_WEIGHT_SAME_LOT_NO: "TRANSFER_WEIGHT_SAME_LOT_NO", // โอนย้ายนํ้าหนักโดยใช้ lot no เดิม
	TRANSFER_WEIGHT_NEW_LOT_NO: "TRANSFER_WEIGHT_NEW_LOT_NO", // โอนย้ายนํ้าหนักโดยใช้ lot no ใหม่
}
export default function TransferLatexModal(props) {
	const gradeOptions = useDropdownItem({url: URL_COMMON.GRADE});
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [sourceOptions, setSourceOptions] = React.useState([]);
	const [destOptions, setDestOptions] = React.useState([]);
	const [sourceSelected, setSourceSelected] = React.useState(null);
	const [enableWeightWarning, setEnableWeightWarning] = React.useState(false);
	const [isEmptyTank, setEmptyTank] = React.useState(false);
	const [isSameTank, setSameTank] = React.useState(false);

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
			tank_transaction_origin: "",
			tank_transaction_destination: "",
			weight: "",
			status: TRANSFER_TYPE.TRANSFER_WEIGHT,
			grade: "",
			nh3_solution: "",
		},
    validationSchema: Yup.object().shape({
			tank_transaction_origin: Yup.string().required("กรุณากรอกข้อมูล"),
			tank_transaction_destination: Yup.string().required("กรุณากรอกข้อมูล"),
			weight: Yup.number().required("กรุณากรอกข้อมูล"),
			status: Yup.string().required(true),
			grade: Yup.string()
				.notRequired().nullable(true)
				.when("status", {
					is: (status) => (status !==TRANSFER_TYPE.TRANSFER_WEIGHT),
					then: Yup.string().required("กรุณากรอกข้อมูล").nullable(true),
				}),
			nh3_solution: Yup.number()
				.notRequired().nullable(true)
				.when("status", {
					is: (status) => (status !==TRANSFER_TYPE.TRANSFER_WEIGHT),
					then: Yup.number().required("กรุณากรอกข้อมูล").nullable(true),
				}),
		}),
    onSubmit: (values) => handleSubmit(values),
  });

	const handleSubmit = async (values) => {
		setLoading(true);
		setErrorMessages(null);
		// Prepare data
		if (values.status == TRANSFER_TYPE.TRANSFER_WEIGHT) {
			delete values['grade']
			delete values['nh3_solution']
		}
		let response = null;
		try {
			response = await POST(URL_TANK.TANK_TRANSACTION_TRANSFER, values);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (isSameTank) 
			formik.setFieldValue("status", TRANSFER_TYPE.TRANSFER_WEIGHT_NEW_LOT_NO)

	}, [isSameTank])

	React.useEffect(() => {
		if (formik.values.tank_transaction_origin && formik.values.tank_transaction_destination) {
			setSameTank(formik.values.tank_transaction_origin == formik.values.tank_transaction_destination)
		}

	}, [formik.values.tank_transaction_origin, formik.values.tank_transaction_destination])

	React.useEffect(() => {
		if (sourceSelected) {
			let weight = formik.values.weight
			if (weight && !isNaN(parseFloat(weight))) {
				setEnableWeightWarning((parseFloat(weight) > sourceSelected.weight))
			} else {
				if (enableWeightWarning)
					setEnableWeightWarning(false);
			}
		}
	}, [sourceSelected, formik.values.weight])

	React.useEffect(() => {
		formik.setFieldValue("status", isEmptyTank ? "" : TRANSFER_TYPE.TRANSFER_WEIGHT);
		if (!isEmptyTank)
			formik.setFieldValue("grade", "");

	}, [isEmptyTank]);

	React.useEffect(() => {
		if (props.open) {
			// Load option
			gradeOptions.fetchData();
			formik.resetForm();
			setEnableWeightWarning(false);
			setEmptyTank(false);
			setSameTank(false);
			setSourceSelected(null);
			setErrorMessages(null);
			// Options
			let sources = props.options.filter((el) => {
				const state = TANK_STATUS[el.state]
				return (el.weight > 0) && ((state === TANK_STATUS.REFILL) 
					|| (state === TANK_STATUS.CLOSE) || (state === TANK_STATUS.SELL))
			})
			setSourceOptions(
        sources.map(item => {
          return {
            ...item,
						key: item.id,
            value: item.id, 
            text: `[${item.name}] : ${item.lot_no ? item.lot_no : "-"}`
          }
        })
      );
			let dest = props.options.filter((el) => {
				const state = TANK_STATUS[el.state]
				return (state === TANK_STATUS.EMPTY) || (state === TANK_STATUS.REFILL) 
					|| (state === TANK_STATUS.CLOSE) || (state === TANK_STATUS.SELL)
			})
			setDestOptions(
        dest.map(item => {
          return {
            ...item,
						key: item.id,
            value: item.id, 
            text: `[${item.name}] : ${item.lot_no ? item.lot_no : "-"}`
          }
        })
      );
		}
	}, [props.open]);

	return (
		<ConfirmModal
			open={props.open}
			title={"โอนย้ายแท็งก์"}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={"ยืนยัน"}
			onClose={props.onClose}
			onConfirm={formik.handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}
					<Form>
						<Form.Group widths={"equal"}>
							<Form.Field 
								required
								label={"แท็งก์ต้นทาง"}
								control={Dropdown}
								selection
								placeholder={"เลือก..."}
								options={sourceOptions}
								name={"tank_transaction_origin"}
								value={formik.values.tank_transaction_origin}
								error={formik.errors.tank_transaction_origin}
								selectOnBlur={false}
								onChange={(_, data) => {
									formik.setFieldValue(data.name, data.value);
									const target = sourceOptions.find(item => item.id === data.value);
									setSourceSelected(target);
									if (formik.values.status === TRANSFER_TYPE.TRANSFER_WEIGHT_SAME_LOT_NO)
										formik.setFieldValue("grade", target ? target.grade : "-");
								}}/>
							
							<Form.Field 
								required
								label={"แท็งก์ปลายทาง"}
								control={Dropdown}
								selection
								placeholder={"เลือก..."}
								options={destOptions}
								name={"tank_transaction_destination"}
								value={formik.values.tank_transaction_destination}
								error={formik.errors.tank_transaction_destination}
								selectOnBlur={false}
								onChange={(_, data) => {
									formik.setFieldValue(data.name, data.value);
									const target = destOptions.find(item => item.id === data.value);
									if (target) {
										setEmptyTank((target.state === "EMPTY") ? true : false);
									}
								}}/>
						</Form.Group>

						{isSameTank && (
							<Message color={"yellow"}>{"แท็งก์ต้นทาง และแท็งก์ปลายทาง เป็นแท็งก์เดียวกัน!"}</Message>
						)}

						{formik.values.tank_transaction_destination && (
							<Segment>
								{!isEmptyTank && !isSameTank && (
									<Form.Field 
										control={Checkbox}
										label={"ถ่ายโอนน้ำหนักไม่เปลี่ยน Lot No."}
										radio
										name={TRANSFER_TYPE.TRANSFER_WEIGHT}
										checked={formik.values.status === TRANSFER_TYPE.TRANSFER_WEIGHT}
										error={formik.errors.status}
										onClick={(_, data) => {
											formik.setFieldValue("status", data.name);
											if (sourceSelected)
												formik.setFieldValue("grade", sourceSelected.grade);
										}}/>
								)}
								{!isEmptyTank && !isSameTank && (<Divider horizontal>{"หรือ"}</Divider>)}
								
								<Form.Group widths={"equal"}>
									{!isSameTank && (
										<Form.Field 
											control={Checkbox}
											label={"ใช้ Lot No. เดิม"}
											radio
											name={TRANSFER_TYPE.TRANSFER_WEIGHT_SAME_LOT_NO}
											checked={formik.values.status === TRANSFER_TYPE.TRANSFER_WEIGHT_SAME_LOT_NO}
											error={formik.errors.status}
											onClick={(_, data) => {
												formik.setFieldValue("status", data.name);
												if (sourceSelected)
													formik.setFieldValue("grade", sourceSelected.grade);
											}}/>
									)}
									
									<Form.Field 
										control={Checkbox}
										label={"สร้าง Lot No. ใหม่"}
										radio
										name={TRANSFER_TYPE.TRANSFER_WEIGHT_NEW_LOT_NO}
										checked={formik.values.status === TRANSFER_TYPE.TRANSFER_WEIGHT_NEW_LOT_NO}
										error={formik.errors.status}
										onClick={(_, data) => formik.setFieldValue("status", data.name)}/>
								</Form.Group>

								<Form.Group>
									<Form.Field 
										width={10}
										label={"เกรด"}
										control={Dropdown}
										selection 
										name={"grade"}
										placeholder={"เลือก..."}
										selectOnBlur={false}
										options={gradeOptions.response}
										onChange={(_, data) => {
											formik.setFieldValue(data.name, data.value);
										}}
										error={formik.errors.grade}
										value={formik.values.grade}
									/>

									<Form.Field 
										width={6}
										label={"NH3 Solution"}
										control={Input}
										name={"nh3_solution"}
										placeholder={"0"}
										onChange={(_, data) => formik.setFieldValue(data.name, data.value)}
										error={formik.errors.nh3_solution}
										value={formik.values.nh3_solution}/>
								</Form.Group>
							</Segment>
						)}
						
						<Form.Field 
							required
							label={"น้ำหนักโอน (kg)"}
							control={Input}
							placeholder={sourceSelected ? sourceSelected.weight : "0"}
							name={"weight"}
							value={formik.values.weight}
							error={formik.errors.weight}
							onChange={formik.handleChange}/>

						{enableWeightWarning && (
							<Message color={"yellow"}>{"น้ำหนักโอน มีปริมาณมากกว่าน้ำหนักคงเหลือในแท็งก์!"}</Message>
						)}
					</Form>
				</div>
			}
		/>
	)
}

TransferLatexModal.defaultProps = {
	open: false,
	options: [],
	onClose: () => {},
	
}

TransferLatexModal.propTypes = {
	open: propTypes.bool,
	options: propTypes.array,
	onClose: propTypes.func,
}