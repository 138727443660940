import React from 'react';
import propTypes from "prop-types";
import { Input, Message, Form } from 'semantic-ui-react';
import ConfirmModal from "./ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { POST } from "../../frameworks/HttpClient";
import { URL_AUTH } from "../../constances/urls";

export default function ChangePasswordModal(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	let validationSchema = {
		new_password: Yup.string().required("กรุณากรอกข้อมูล"),
		confirm_password: Yup.string().required("กรุณากรอกข้อมูล"),
	}

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {new_password: "", confirm_password: ""},
    validationSchema: Yup.object(validationSchema),
    onSubmit: props.onSubmit,
  });

	const handleConfirm = async () => {
		const validation = await formik.validateForm();
		const isValid = Object.keys(validation).length === 0;
		if (!isValid) return;

		if (formik.values.new_password !== formik.values.confirm_password) {
			setErrorMessages("รหัสผ่านไม่ตรงกัน กรุณากรอกรหัสใหม่อีกครั้ง!");
			return;
		}

		// Update password
		setLoading(true);
		setErrorMessages(null);
		try {
			await POST(URL_AUTH.CHANGE_PASSWORD, formik.values);
		} catch (error) {
			setErrorMessages(error.errorMessages);
			return;
		} finally {
			setLoading(false);
		}

		props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			formik.resetForm();
			setErrorMessages(null);
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={"เปลี่ยนรหัสผ่าน"}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={handleConfirm}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							content={errorMessages}/>
					)}

					<Form>
						<Form.Field 
							required
							label={"รหัสผ่านใหม่"}
							control={Input}
							placeholder={"รหัสผ่านใหม่..."}
							type={"password"}
							name={"new_password"}
							error={formik.errors.new_password}
							value={formik.values.new_password}
							onChange={formik.handleChange}/>

						<Form.Field 
							required
							label={"ยืนยันรหัสผ่านใหม่"}
							control={Input}
							placeholder={"รหัสผ่านใหม่..."}
							type={"password"}
							name={"confirm_password"}
							error={formik.errors.confirm_password}
							value={formik.values.confirm_password}
							onChange={formik.handleChange}/>
					</Form>
				</div>
			}/>
	)
}

ChangePasswordModal.defaultProps = {
	open: false,
	onClose: () => {},
}

ChangePasswordModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
}