import React from 'react';
import propTypes from "prop-types";
import { Form, Input, Label, TextArea, Message } from 'semantic-ui-react';
import ConfirmModal from "../common/ConfirmModal";
import { formatComma } from "../../frameworks/Util";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = {
	weight: Yup.number().required("กรุณากรอกข้อมูล"),
	note: Yup.string().notRequired(),
}

const validationSchemaAllRequired = {
	weight: Yup.number().required("กรุณากรอกข้อมูล"),
	note: Yup.string().required("กรุณากรอกข้อมูล"),
}
export default function WeightInputModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Detail
	const [diff, setDiff] = React.useState("0");

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
			weight: (props.initialWeight) ? props.initialWeight : "",
			note: "",
		},
    validationSchema: Yup.object(props.remarkRequired 
			? validationSchemaAllRequired 
			: validationSchema),
    onSubmit: (values) => handleSubmit(values),
  });

	const handleSubmit = async (values) => {
		// Goto Update
		setErrorMessages(null);
		setLoading(true)
		const [response, error] = await props.onSubmit(values);
		setLoading(false);

		if(error) {
			setErrorMessages(error)
			return;
		}
		props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			setDiff(0);
			setErrorMessages(null);
			formik.resetForm();
		}
	}, [props.open])

	React.useEffect(() => {})
	return (
		<ConfirmModal
			open={props.open}
			title={props.title}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={"ยืนยัน"}
			onClose={props.onClose}
			onConfirm={formik.handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}

					<Form>
						<Form.Field inline>
							<label>{props.message1}</label>
							<Label color={"blue"}>{formatComma(props.targetWeight)}</Label>
						</Form.Field>
						<Form.Field 
							fluid
							label={
								<div>
									<label 
										style={{fontWeight: "bold", fontSize: 13, marginRight: 10}}>
											{props.message2}
									</label>
									<Label color={"red"}>{diff}</Label>
								</div>
							}
							control={Input}
							placeholder={"0"}
							name={"weight"}
							value={formik.values.weight}
							error={formik.errors.weight}
							onChange={(_, data) => {
								formik.setFieldValue(data.name, data.value);
								let diff = props.targetWeight - parseInt(data.value ? data.value : 0);
								setDiff(diff);
							}}
							/>
						{(props.remarkEnable) && (
							<Form.Field 
								label={"หมายเหตุ"}
								control={TextArea}
								name={"note"}
								value={formik.values.note}
								error={formik.errors.note}
								onChange={formik.handleChange}/>
						)}
					</Form>
				</div>
			}
		/>
	)
}

WeightInputModal.defaultProps = {
	open: false,
	title: "น้ำหนัก",
	message1: "น้ำหนักล่าสุด",
	message2: "กรุณากรอกน้ำหนัก",
	targetWeight: 0,
	remarkEnable: false,
	remarkRequired: false,
	onClose: () => {},
	onSubmit: (data) => {},
	
}

WeightInputModal.propTypes = {
	open: propTypes.bool,
	title: propTypes.string,
	message1: propTypes.string,
	message2: propTypes.string,
	targetWeight: propTypes.number,
	remarkEnable: propTypes.bool,
	remarkRequired: propTypes.bool,
	onClose: propTypes.func,
	onSubmit: propTypes.func,
}