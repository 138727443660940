import React from "react";
import { useParams } from "react-router-dom";
import { Container, Header, Grid, Dimmer, Loader, Message, Button, Icon } from "semantic-ui-react";
import TitleBar from "../../components/common/TitleBar";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import accept from "../../assets/img_accept.png";
import summary from "../../assets/img_summary.png";
import edit from "../../assets/img_edit.png";
import { GET } from "../../frameworks/HttpClient";
import { formatComma } from "../../frameworks/Util";
import { URL_PURCHASE, URL_REPORT } from "../../constances/urls";
import DOListModal from "../../components/store/DOListModal";
import PREditorModal from "../../components/store/purchase/PREditorModal";
import InfoModal from "../../components/common/InfoModal";
import GoodsReceivedModal from "../../components/store/GoodsReceivedModal";

const defaultPODetail = {
	id: null,
	quotations: [],
	supplier: "",
	code: "",
}

export default function PODetailScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [targetHistory, setTargetHistory] = React.useState(null);
	const [targetReceived, setTargetReceived] = React.useState(null);
	const [targetEdit, setTargetEdit] = React.useState(null);
	const [targetMemo, setTargetMemo] = React.useState(null);
	const [data, setData] = React.useState(defaultPODetail);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "รายการสินค้า", accessor: "product.name" },
			{ Header: "จำนวน", accessor: "amount", width: 80, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคาต่อหน่วย", accessor: "selected_quotation.unit_price", width: 100, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคารวม", accessor: "selected_quotation.total_price", width: 100, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคาจริง", accessor: "selected_quotation.real_price", width: 100, Cell: ({ value }) => formatComma(value) },
			{ Header: "จำนวนที่รับมาแล้ว", accessor: "total_received_amount", width: 80, Cell: ({ value }) => formatComma(value) },
			{ 
				Header: "สถานะ", 
				accessor: "state_name", 
				width: 140,
				Cell: ({original, value}) => {
					const memo = (original.state === "WAIT_PRODUCT_APPROVE_REJECTED") 
						? original.memo_wait_product_approve_rejected : null
					return (
						<div>
							<label>{value}</label>
							{memo && (
								<Icon 
									name="warning circle" 
									color={"yellow"}
									onClick={() => setTargetMemo(original)} 
									/>
							)}
						</div>
					)
				} 
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 140,
			Cell: ({original}) => (
				<div>
					<ImageButton 
						title={"แก้ไขใบขอซื้อ"}
						src={edit}
						color={"yellow"}
						disabled={(original.state === 'WAIT_PRODUCT_APPROVE_REQUESTED')}
						onClick={() => setTargetEdit(original)}/>
					<ImageButton 
						title={"ดูใบรับสินค้า"}
						src={summary}
						onClick={() => setTargetHistory(original)}/>
					{(original.state !== 'END') && (
						<ImageButton 
							title={"สร้างใบรับสินค้า"}
							src={accept}
							onClick={() => setTargetReceived(original)}/>
					)}
				</div>
			)
		},
	]);

	const fetchData = async () => {
		if (!params.id) {
			setErrorMessages("ID Not found!");
			return;
		}

		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_ORDER}${params.id}/`);
			setData({...defaultPODetail, ...response.data});
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const gotoPRExport = (item) => {
		window.open(`${URL_REPORT.CR_STO_03_REPORT_EXPORT}?id=${item.id}`)
	}

	React.useEffect(() => {
		if (!targetEdit)
			fetchData();

	}, [targetEdit]);

	return (
		<Container fluid>
			<TitleBar title={`รายละเอียด PO [${data.code}]`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Header as={"h2"} textAlign={"center"}>รายละเอียด</Header>
				<Grid style={{marginTop: 16}}>
					<Grid.Column width={6} textAlign={"left"}>
						<p style={{fontSize: 15}}>{`รหัส PO: ${data.code}`}</p>
					</Grid.Column>
					<Grid.Column width={10} textAlign={"left"}>
						<p style={{fontSize: 15}}>{`ผู้ผลิต: ${data ? data.supplier.name : "-"}`}</p>
					</Grid.Column>
				</Grid>

				<TSReactTable
					style={{marginTop: 16}}
					columns={columns}
					data={data.list_purchase_request}
					pageSize={(data.list_purchase_request && (data.list_purchase_request.length > 0)) ? data.list_purchase_request.length : 5}
					showPagination={false}/>

				<div style={{marginTop: 24, textAlign: "center"}}>
					<Button 
						primary 
						disabled={data.printable ? false : true}
						onClick={() => 
							window.open(`${URL_REPORT.CR_STO_04_REPORT_EXPORT}pdf/?id=${data.id}`)}>
						พิมพ์ใบ PO
					</Button>
				</div>
			</Container>

			<DOListModal
				purchaseRequest={targetHistory}
				open={targetHistory ? true : false}
				onClose={() => setTargetHistory(null)}/>
			
			<GoodsReceivedModal
				open={targetReceived ? true : false}
				data={targetReceived}
				onClose={() => {
					setTargetReceived(null)
					fetchData();
				}}/>
			
			<PREditorModal 
				open={targetEdit ? true : false}
				pr={targetEdit}
				onClose={() => setTargetEdit(null)}/>
			
			{/* Detail memo */}
			<InfoModal
				open={targetMemo ? true : false}
				size={"tiny"}
				title={targetMemo ? targetMemo.product.name : ""}
				detail={
					<div>
						<p>{`สถานะ : ${targetMemo ? targetMemo.state_name : ""}`}</p>
						<p>{`หมายเหตุ : ${targetMemo ? targetMemo.memo_wait_product_approve_rejected : ""}`}</p>
					</div>
				}
				onClose={() => setTargetMemo(null)}/>

		</Container>
	)
}