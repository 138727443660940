import React from "react";
import propTypes from "prop-types";
import { Form, Message, Dropdown } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { URL_ACCOUNTING } from "../../constances";
import { GET, PATCH, POST } from "../../frameworks/HttpClient";

let validationSchema = {
	purchase_request: Yup.string().required("กรุณากรอกข้อมูล"),
	account_code: Yup.string().required("กรุณากรอกข้อมูล"),
}

export default function AccountingCodeModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [codeOptions, setCodeOptions] = React.useState([]);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			purchase_request: props.data ? props.data.id : null,
			account_code: (props.data && props.data.purchase_record) 
				? props.data.purchase_record.account_code : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => handleSubmit(values),
	});

	const handleSubmit = async (values) => {
		setLoading(true);
		setErrorMessages(null);
		const recordId = (props.data && props.data.purchase_record) 
			? props.data.purchase_record.id : null
		try {
			if (recordId)
				await PATCH(`${URL_ACCOUNTING.PURCHASE_RECORD}${recordId}/`, values);
			else
				await POST(URL_ACCOUNTING.PURCHASE_RECORD, values)
			props.onSuccess();
			props.onClose();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}		
	}

	const fetchCodeOptions = async () => {
    try {
      const response = await GET(URL_ACCOUNTING.ACCOUNT_CHART);
      setCodeOptions(
        response.data.map(item => {
          return {
            ...item, 
            key: item.id, 
            value: item.id, 
            text: `[${item.code}] ${item.name}`
          }
        })
      );
    } catch (error) {
      console.log(error.errorMessages)
    }
  };

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
			fetchCodeOptions();
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={props.title}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form>
						<Form.Field 
							inline
							required
							style={{width: "75%"}}
							label={"รหัสทางบัญชี"}
							control={Dropdown}
							selectOnBlur={false}
							search
							selection
							placeholder={"เลือก..."}
							name={"account_code"}
							options={codeOptions}
							error={formik.errors.account_code}
							value={formik.values.account_code}
							onChange={(_, data) => {
								formik.setFieldValue(data.name, data.value)
							}}/>
					</Form>
				</div>
			}/>
	)
}

AccountingCodeModal.defaultProps = {
	data: null,
	title: "",
	baseURL: "",
	open: false,
	onClose: () => {},
}

AccountingCodeModal.propTypes = {
	data: propTypes.object,
	title: propTypes.string,
	baseURL: propTypes.string,
	open: propTypes.bool,
	onClose: propTypes.func,
}