import React from 'react';
import propTypes from "prop-types";
import { Message, Button } from 'semantic-ui-react';
import { STR_CHEMICAL } from "../../constances";
import { formatComma } from "../../frameworks/Util";

export default function LabNotify(props) {
	const [myItems, setMyItems] = React.useState([]);

	React.useEffect(() => {
		let all = [];
		for (let index = (props.items.length - 1); index >= 0; index--) {
			const it = props.items[index];
			const chemical = STR_CHEMICAL[it.chemical];
			all.push(`${chemical ? chemical : it.chemical} : ${it.amount ? formatComma(parseFloat(it.amount)) : "-"}`)
		}
		setMyItems(all);
	}, [props.items])
	return (
		<Message color={props.color} style={{overflow: "auto"}}>
			<Message.Header>{props.title}</Message.Header>
			<Message.List items={myItems}/>
			<Message.Content>
				<Button positive
					floated={"right"}  
					onClick={props.onConfirm}>
						{props.buttonText}
				</Button>
			</Message.Content>
		</Message>
	)
}

LabNotify.defaultProps = {
	title: "",
	color: "yellow",
	buttonText: "ยืนยัน",
	items: [],
	onConfirm: () => {},
}

LabNotify.propTypes = {
	title: propTypes.string,
	color: propTypes.string,
	buttonText: propTypes.string,
	items: propTypes.array,
	onConfirm: propTypes.func,
}