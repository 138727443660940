import moment from 'moment';
import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Checkbox, Search } from 'semantic-ui-react';
import { GET } from '../../../frameworks/HttpClient';
import TSReactTable from '../../../frameworks/TSReactTable';
import { getColumnsList } from '../../../utils/Util';
import _ from 'lodash';
import { URL_REPORT, URL_TANK } from '../../../constances/urls';


export default function STWeeklyStatisticReportView () {
	// Table
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Filter
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [transactionOptions, setTransactionOptions] = React.useState([]);
	const [selectedLotNo, setSelectedLotNo] = React.useState(null);
	const [dateRange, setDateRange] = React.useState(null);
	const [dateRangeError, setDateRangeError] = React.useState(false);
	// Checkbox
	const [chemicalCheck, setChemicalCheck] = React.useState([
		"tsc", "drc", "non_rubber", "nh3", "vfa", "koh", "mst", "mg", "coagulum", 
		"micro_coagulum", "viscosity", "sludge", "filterability", "la", "water_dilution"]);
	const [trigger, setTrigger] = React.useState(false);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ Header: " วันที่", accessor: "datetime", width: 120 },
			{ Header: "TSC", accessor: "tsc", show: chemicalCheck.includes("tsc") },
			{ Header: "DRC", accessor: "drc", show: chemicalCheck.includes("drc") },
			{ Header: "NON", accessor: "non_rubber", show: chemicalCheck.includes("non_rubber") },
			{ Header: "NH3", accessor: "nh3", show: chemicalCheck.includes("nh3") },
			{ 
				Header: "VFA", 
				accessor: "vfa", 
				show: chemicalCheck.includes("vfa"),
				Cell: ({value}) => (<p>{parseFloat(value).toFixed(3)}</p>),
			},
			{ Header: "KOH", accessor: "koh", show: chemicalCheck.includes("koh") },
			{ Header: "MST", accessor: "mst", show: chemicalCheck.includes("mst") },
			{ Header: "MG", accessor: "mg", show: chemicalCheck.includes("mg") },
			{ Header: "%Coagulum", accessor: "coagulum", show: chemicalCheck.includes("coagulum") },
			{ Header: "Micro Coagulum", accessor: "micro_coagulum", show: chemicalCheck.includes("micro_coagulum") },
			{ Header: "Viscosity", accessor: "viscosity", show: chemicalCheck.includes("viscosity") },
			{ Header: "Sludge", accessor: "sludge", show: chemicalCheck.includes("sludge") },
			{ Header: "Filterability", accessor: "filterability", show: chemicalCheck.includes("filterability") },
			{ Header: "%Lauric", accessor: "la", show: chemicalCheck.includes("la") },
			{ Header: "%WATER DILUTION", accessor: "water_dilution", show: chemicalCheck.includes("water_dilution") },
		]),
	])

	const handleChemicalCheck = (_, data) => {
		let myData = chemicalCheck
		if (data.checked)
			myData.push(data.name)
		else
			myData = myData.filter(item => item !== data.name)
		
		setChemicalCheck(myData);
		setTrigger(!trigger);
	}

	/** START: Delay for search */
	const debounceFunc = React.useCallback(_.debounce(e => fetchTankTransactionOptions(e), 500), []);
	const handleSearchChange = e => debounceFunc(e);
	/** END: Delay for search */
	const fetchTankTransactionOptions = async (search) => {
		if (!search)
			return;
		
		setSearchLoading(true)
		setTransactionOptions([]);
		try {
			const response = await GET(URL_TANK.TANK_TRANSACTION, {lot_no: search});
			setTransactionOptions(
				response.data.map(item => {
					return {
						id: item.id,
						key: item.id,
						value: item.id,
						title: item.lot_no,
					}
				})
			);
		} catch (error) {
			// setErrorMessages(null);
		} finally {
			setSearchLoading(false)
		}
	}

	const fetchData = async () => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);
		
		setLoading(true)
		setErrorMessages(null)
		try {
			const response = await GET(
				URL_REPORT.ST_WEEKLY_STATISTIC_REPORT, 
				{
					date_before: moment(dateRange[0]).format('YYYY-MM-DD'),
					date_after: moment(dateRange[1]).format('YYYY-MM-DD'),
					lot_no: selectedLotNo.title,
				}
			)
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const gotoExport = (exportType) => {
		const date_before = moment(dateRange[0]).format('YYYY-MM-DD')
		const date_after = moment(dateRange[1]).format('YYYY-MM-DD')
		window.open(`${URL_REPORT.ST_WEEKLY_STATISTIC_REPORT_EXPORT}${exportType}/?date_before=${date_before}&date_after=${date_after}&lot_no=${selectedLotNo.title}`)
	}

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<label>{"Lot No."}</label>
					<Form.Field 
						control={Search}
						placeholder={"ค้นหา..."}
						minCharacters={3}
						results={transactionOptions}
						showNoResults={true}
						onSearchChange={(_, data) => {
							handleSearchChange(data.value);
							setSelectedLotNo(null);
						}}
						onResultSelect={(_, data) => {
							setSelectedLotNo(data.result);
						}}
						loading={searchLoading}
					/>
					
					<Form.Field
						style={{width: "500px"}}
						inline
						label={"วันที่"}
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						error={dateRangeError}
						onChange={(_, data) => setDateRange(data.value)}/>
					
					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>

				<label>ผลตรวจ</label>
				<Form.Group inline widths={"equal"}>
						<Form.Field 
							label={"TSC"}
							control={Checkbox}
							name={"tsc"}
							checked={chemicalCheck.includes("tsc")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"DRC"}
							control={Checkbox}
							name={"drc"}
							checked={chemicalCheck.includes("drc")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"NON"}
							control={Checkbox}
							name={"non_rubber"}
							checked={chemicalCheck.includes("non_rubber")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"NH3"}
							control={Checkbox}
							name={"nh3"}
							checked={chemicalCheck.includes("nh3")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"VFA"}
							control={Checkbox}
							name={"vfa"}
							checked={chemicalCheck.includes("vfa")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"KOH"}
							control={Checkbox}
							name={"koh"}
							checked={chemicalCheck.includes("koh")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"MST"}
							control={Checkbox}
							name={"mst"}
							checked={chemicalCheck.includes("mst")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"MG"}
							control={Checkbox}
							name={"mg"}
							checked={chemicalCheck.includes("mg")}
							onChange={handleChemicalCheck}/>
				</Form.Group>
				<Form.Group inline>
						<Form.Field 
							label={"%Coagulum"}
							control={Checkbox}
							name={"coagulum"}
							checked={chemicalCheck.includes("coagulum")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"Micro Coagulum"}
							control={Checkbox}
							name={"micro_coagulum"}
							checked={chemicalCheck.includes("micro_coagulum")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"Viscosity"}
							control={Checkbox}
							name={"viscosity"}
							checked={chemicalCheck.includes("viscosity")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"Sludge"}
							control={Checkbox}
							name={"sludge"}
							checked={chemicalCheck.includes("sludge")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"Filterability"}
							control={Checkbox}
							name={"filterability"}
							checked={chemicalCheck.includes("filterability")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"%Lauric"}
							control={Checkbox}
							name={"la"}
							checked={chemicalCheck.includes("la")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"%WATER DILUTION"}
							control={Checkbox}
							name={"water_dilution"}
							checked={chemicalCheck.includes("water_dilution")}
							onChange={handleChemicalCheck}/>
				</Form.Group>
			</Form>

			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>

				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					disabled={(!selectedLotNo) || (!dateRange)}
					onClick={() => gotoExport("pdf")}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}