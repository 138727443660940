import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import { Form, Input, Message, TextArea, Dropdown } from "semantic-ui-react";
import { DateInput } from 'semantic-ui-calendar-react'
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { INVENTORY_TYPE_OPTIONS, USER_DEPARTMENT } from "../../constances";
import { URL_COMMON, URL_STOCK, URL_ACCOUNTING } from "../../constances/urls";
import { GET, POST } from "../../frameworks/HttpClient";

let validationSchema = {
	type: Yup.string().required("กรุณากรอกข้อมูล"),
	product: Yup.string().required("กรุณากรอกข้อมูล"),
	unit: Yup.string().notRequired(),
	require_amount: Yup.number().required("กรุณากรอกข้อมูล"),
	due_date: Yup.string().required("กรุณากรอกข้อมูล"),
	department: Yup.string().required("ผู้ใช้งานไม่มีข้อมูลแผนก"),
	create_note: Yup.string().required("กรุณากรอกข้อมูล"),
}
export default function RequestModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [productOptions, setProductOptions] = React.useState([]);
	const [departmentOptions, setDepartmentOptions] = React.useState([]);
	const [productAmount, setProductAmount] = React.useState(0);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			type: "", 
			product: "",
			unit: "",
			require_amount: "",
			due_date: moment().format("YYYY-MM-DD"),
			department: localStorage.getItem('department') || "",
			create_note: "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: props.onSubmit,
	});

	const handleSubmit = async () => {
		// Verify
		const validation = await formik.validateForm();
		const isValid = Object.keys(validation).length === 0;
		if (!isValid) return;

		if (formik.values.require_amount > parseFloat(productAmount)) {
			setErrorMessages("สินค้าคงเหลือมีไม่เพียงพอ!")
			return;
		}

		setLoading(true);
		setErrorMessages(null);
		let response = null;
		try {
			response = await POST(URL_STOCK.WITHDRAW_TRANSACTION, formik.values)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	const handleProductSearch = async (_, data) => {
		const search = data.searchQuery;
		if (search)
			getProductOptions(search);
		else 
			setProductOptions([]);
	}

	const getProductOptions = async (search) => {
		const params = {
			type: formik.values.type,
			name: search,
			control_stock: true,
		}
		try {
			const response = await GET(URL_STOCK.PRODUCT, params);
			setProductOptions(
				response.data.map(item => {
					return {
						...item, 
						key: item.id, 
						value: item.id, 
						text: item.name
					}
				})
			);
		} catch (error) {
			setProductOptions([]);
			console.log(error.errorMessages);
		}
	}

	const getDepartmentOptions = async () => {
		try {
			const response = await GET(URL_COMMON.DEPARTMENT);
			setDepartmentOptions(
				response.data.map(item => {
					return {
						key: item.id,
						value: item.id,
						text: item.name
					}
				})
			);
		} catch (error) {
			setDepartmentOptions([]);
			console.log(error.errorMessages)
		}
	};

	const renderWithdrawDepartment = () => {
		if (localStorage.getItem('department_name') === USER_DEPARTMENT.STORE) {
			return(
				<Form.Field
					width={5}
					label={"แผนก"}
					control={Dropdown}
					name={'department'}
					selection
					placeholder={"เลือก..."}
					options={departmentOptions}
					value={formik.values.department}
					selectOnBlur={false}
					onChange={(_, data) => {
						formik.setFieldValue(data.name, data.value);
					}} />
			)
		}
		else {
			return(
				<Form.Input
					width={5}
					label={"แผนกขอเบิก"}
					placeholder={"กรุณาติดต่อเพื่อเพิ่มแผนกให้ผู้ใช้งาน"}
					readOnly={true}
					name={"department"}
					error={formik.errors.department}
					value={localStorage.getItem('department_name')} />
			)
		}
	}

	React.useEffect(() => {
		if (props.open) {
			formik.resetForm();
			setProductOptions([]);
			setErrorMessages(false);
			setProductAmount(0)
		}
	}, [props.open])

	React.useEffect(() => {
		getDepartmentOptions();
	}, [])

	return (
		<ConfirmModal 
			open={props.open}
			title={"คำขอเบิก"}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form textAlign={"center"}>
						<Form.Dropdown
							label={"ประเภทสินค้า"}
							style={{width: "100%"}}
							selection
							placeholder={"เลือก..."}
							options={INVENTORY_TYPE_OPTIONS}
							name={"type"}
							error={formik.errors.type}
							value={formik.values.type}
							selectOnBlur={false}
							onChange={(_, data) => {
								formik.setFieldValue(data.name, data.value)
								formik.setFieldValue("product", "")
								formik.setFieldValue("unit", "")
								setProductOptions([]);
								setProductAmount(0)
							}}/>
							
						<Form.Field 
							label={"ชื่อสินค้า"}
							style={{width: "100%"}}
							control={Dropdown}
							selection
							search
							placeholder={"ค้นหา..."}
							options={productOptions}
							icon={"search"}
							name={"product"}
							error={formik.errors.product}
							value={formik.values.product}
							onSearchChange={handleProductSearch}
							selectOnBlur={false}
							onChange={(_, data) => {
								formik.setFieldValue(data.name, data.value)
								const target = productOptions.find(item => item.id === data.value);
								if (target) {
									formik.setFieldValue("type", target.type);
									formik.setFieldValue("unit", target.unit);
									setProductAmount(target.amount)
								}
							}}/>
								
						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"จำนวนคงเหลือ"}
								control={Input}
								readOnly
								value={productAmount}/>
							<Form.Field 
								label={"หน่วย"}
								readOnly
								control={Input}
								name={"unit"}
								error={formik.errors.unit}
								value={formik.values.unit}/>
						</Form.Group>

						<Form.Group widths={"equal"}>
							<Form.Field 
								width={2}
								label={"จำนวนที่ต้องการ"}
								control={Input}
								placeholder={"0"}
								type={"text"}
								name={"require_amount"}
								error={formik.errors.require_amount}
								value={formik.values.require_amount}
								onChange={formik.handleChange}/>
							<Form.Field
								width={4}
								label={"Due Date"}
								control={DateInput}
								closable
								dateFormat="YYYY-MM-DD"
								minDate={moment()}
								placeholder={"เลือก..."}
								name={"due_date"}
								error={formik.errors.due_date}
								value={formik.values.due_date}
								onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
							
							{renderWithdrawDepartment()}
						</Form.Group>
						<Form.Field 
							required
							label={"เหตุผล"}
							control={TextArea}
							placeholder={"เลือก..."}
							name={"create_note"}
							error={formik.errors.create_note}
							value={formik.values.create_note}
							onChange={formik.handleChange}/>
					</Form>
				</div>
			}/>
	)
}

RequestModal.defaultProps = {
	initialModel: null,
	open: false,
	onClose: () => {},
}

RequestModal.propTypes = {
	initialModel: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}