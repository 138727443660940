import React from 'react';
import propTypes from "prop-types";
import { Segment, Form, Input, Dropdown, Dimmer, Loader, Popup, List, Header } from 'semantic-ui-react';
import TSReactTable from '../../frameworks/TSReactTable';
import ImageButton from '../common/ImageButton';
import InventoryModal from "./InventoryModal";
import PurchaseModal from "./PurchaseModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import { INVENTORY_TYPE, INVENTORY_TYPE_OPTIONS } from "../../constances";
import edit from "../../assets/img_edit.png";
import shopping from "../../assets/img_shopping.png";
import { GET } from '../../frameworks/HttpClient';
import { formatComma } from '../../frameworks/Util';
import PaginationTable from '../common/PaginationTable';

const typeOptions = [
	{key: "ALL", value: "ALL", text: "ทั้งหมด"},
	...INVENTORY_TYPE_OPTIONS
]
export default function InventorySegment(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);		
	// Filter
	const [filterName, setFilterName] = React.useState("");
	const [filterType, setFilterType] = React.useState("ALL");
	const [targetEdit, setTargetEdit] = React.useState(null);
	const [targetPurchase, setTargetPurchase] = React.useState(null);
	const [data, setData] = React.useState({
    links: {
      next: null,
      previous: null,
    },
    total: 0,
    pages: 0,
    results: [],
  });

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "ชื่อสินค้า", accessor: "name"},
			{ 
				Header: "ประเภทสินค้า", 
				accessor: "type", 
				width: 120,
				Cell: ({value}) => (<p>{INVENTORY_TYPE[value]}</p>)
			},
			{ Header: "คงเหลือ", accessor: "amount", width: 100, Cell: ({ original, value }) => (
				<Popup
					content={
						<div>
							<Header as="h5">{original.name}</Header>
							<List bulleted>
								{ original.deposit_transactions && original.deposit_transactions.map(deposit_transaction => (
									<List.Item>{deposit_transaction.lot_no}: {formatComma(deposit_transaction.amount)} {original.unit}</List.Item>
								)) }
								{ !original.deposit_transactions.length && (
									<div>ไม่มีสินค้า</div>
								)}
							</List>
						</div>
					}
					trigger={<label>{formatComma(value)}</label>}
					position="right center"/>
			) },
			{ Header: "หน่วย", accessor: "unit", width: 120 },
			{ Header: "สถานะ", accessor: "status", width: 140 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: (row) => {
				return (
					<div>
						<ImageButton 
							title={"แก้ไข"}
							src={edit}
							onClick={(e, data) => setTargetEdit(row.original)}/>

						<ImageButton 
							title={"สั่งซื้อสินค้า"}
							src={shopping}
							onClick={(e, data) => setTargetPurchase(row.original)}/>
					</div>
				)
			}
		},
	]);

	const handleSearch = (e) => {
		if (e.key === "Enter") {
			fetchData();
		}
	}

	const fetchData = async (params) => {
		const url = props.baseURL;
		if (!url) {
			setErrorMessages("URL not found!")
			return;
		}

		setLoading(true);
		setErrorMessages(null)
		try {
			const response = await GET(`${url}`, 
				{
					control_stock: true,
					name: filterName,
					type: filterType,
					...props.params,
					...params,
				});
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	React.useEffect(() => {
		if (!targetEdit && !targetPurchase) {
			fetchData()
		}
	}, [filterType, targetEdit, targetPurchase])

	return (
		<Segment basic>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			<Form>
				<Form.Group inline>
					<Form.Field 
						width={7}
						label={"สินค้า"}
						control={Input}
						icon={"search"}
						placeholder={"ค้นหาสินค้าโดย รหัสสินค้า หรือชื่อสินค้า..."}
						value={filterName}
						onChange={(_, {value}) => setFilterName(value)}
						onKeyDown={handleSearch}/>
					
					<Form.Field 
						label={"ประเภทสินค้า"}
						control={Dropdown}
						selection
						placeholder={"เลือก..."}
						options={typeOptions}
						value={filterType}
						selectOnBlur={false}
						onChange={(_, {value}) => setFilterType(value)}/>
				</Form.Group>
			</Form>

			{/* Table */}
			<PaginationTable
				columns={columns}
				data={data}
				onPageChange={(page) => fetchData({page})}/>
			{/* <TSReactTable
				columns={columns}
				data={data}
				defaultPageSize={10}/> */}

			<InventoryModal 
				open={targetEdit ? true : false}
				initialModel={targetEdit}
				onClose={() => setTargetEdit(null)}/>

			<PurchaseModal 
				open={targetPurchase}
				initialModel={targetPurchase}
				onClose={() => setTargetPurchase(false)}/>
		</Segment>
	)
}

InventorySegment.defaultProps = {
	baseURL: "",
	params: null,
}

InventorySegment.propTypes = {
	baseURL: propTypes.string,
	params: propTypes.object,
}