import React from 'react';
import propTypes from 'prop-types';
import { Button, Form, Input, Message, Modal, Search } from 'semantic-ui-react';
import { URL_STOCK } from '../../constances/urls';
import useListItem from '../../frameworks/hooks/useItemList';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { POST, PATCH } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';

const defaultProduct = {
  name: "",
  unit: "",
}

export default function StockCountProductModal(props) {
  const {
    open,
    onClose,
    target,
    recordId,
  } = props

  const productList = useListItem({url: `${URL_STOCK.PRODUCT}`});
  const [isLoading, setIsLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [productSearch, setProductSearch] = React.useState(defaultProduct);

  const handleSave = async (values) => {
		setIsLoading(true);
		setErrorMessages(null);
		try {
      if (target && target.id) {
        const response = await PATCH(`${URL_STOCK.STOCK_COUNT_PRODUCT}${target.id}/`, values);
      } else {
        const response = await POST(URL_STOCK.STOCK_COUNT_PRODUCT, values);
      }
			Toast.success('สำเร็จ');
      onClose();
		} catch (error){
			setErrorMessages(error.errorMessages);
		} finally {
			setIsLoading(false);
		}
  }

  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			product: "",
			stock_record: recordId ? recordId : null,
      stock_amount: "",
      counting_amount: "",
      note: ""
		},
		validationSchema: Yup.object().shape({
      product: Yup.string().required('กรุณากรอกข้อมูล'),
      stock_record: Yup.string().required('กรุณาลองใหม่อีกครั้ง'),
      stock_amount: Yup.number().required('กรุณากรอกข้อมูล'),
      counting_amount: Yup.number().required('กรุณากรอกข้อมูล'),
      note: Yup.string().notRequired(),
    }),
		onSubmit: handleSave,
  })

  React.useEffect(() => {
    if(open) {
      formik.resetForm();
      if (target) {
        formik.setValues(target)
        setProductSearch({
          name: target.product_name,
          unit: target.product_unit,
        })
      } else {
        setProductSearch(defaultProduct);
      }
      
    }
  }, [open]);

  return (
    <Modal
      open={open}
      centered={false}
      onClose={onClose}
      closeIcon
      size='small'
    >
      <Modal.Header>รายละเอียดการตรวจนับ Stock</Modal.Header>
      <Modal.Content>
        { formik.errors.stock_record && 
          <Message error>ไม่พบการนับ Stock ดังกล่าว กรุณาลองใหม่อีกครั้ง</Message>
        }
        { errorMessages && 
          <Message error>{errorMessages}</Message>
        }
        <Form>
          <Form.Field
            style={{width: '100%'}}    
            label={"สินค้า"}        
            placeholder='ค้นหา...'
            name='product'
            minCharacters='3'
            control={Search}
            results={productList.response.map(e => ({ ...e, key: e.id, value: e.id, title: e.name }))}
            showNoResults={true}
            onSearchChange={(_, data) => {
              if (formik.values.product) {
                formik.setFieldValue('product', null);
                formik.setFieldValue('stock_amount', '')
                setProductSearch({ name: '', unit: '' })  
              }
              productList.fetchData({control_stock: true, name: data.value})
              setProductSearch({ ...productSearch, name: data.value })
            }}
            onResultSelect={(_, data) => {
              formik.setFieldValue('product', data.result.id)
              setProductSearch(data.result)
              formik.setFieldValue('stock_amount', data.result.amount)
            }}
            value={productSearch.name}
            error={formik.errors.product}
            loading={productList.isLoading}
            />

          <Form.Group widths='equal'>
            <Form.Field
              label={"จำนวนคงคลัง"}
              control={Input}
              action={productSearch.unit ? productSearch.unit : 'unit'}
              placeholder='0'
              type='number'
              name='stock_amount'
              value={formik.values.stock_amount}/>
            
            <Form.Field
              label={"จำนวนที่นับได้"}
              control={Input}
              action={productSearch.unit ? productSearch.unit : 'unit'}
              placeholder='0'
              type='number'
              name='counting_amount'
              error={formik.errors.counting_amount}
              value={formik.values.counting_amount}
              onChange={formik.handleChange}/>
          </Form.Group>
          
          <Form.TextArea
            label='หมายเหตุ'
            name='note'
            value={formik.values.note}
            onChange={formik.handleChange}/>
        </Form>

      </Modal.Content>
      <Modal.Actions>
        <Button content='ยกเลิก' color='grey' onClick={onClose}/>
        <Button 
          loading={isLoading} 
          content='ยืนยัน' 
          color='green' 
          icon='check' 
          type={"submit"}
          onClick={formik.handleSubmit}/>
      </Modal.Actions>
    </Modal>
  );
}


StockCountProductModal.defaultProps = {
	open: false,
	onClose: () => null,
  recordId: null,
  target: {},
}

StockCountProductModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
  recordId: propTypes.string,
  target: propTypes.object,
}