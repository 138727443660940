import React, { useMemo } from 'react';
import moment from "moment";
import { Container, Header, Form, Button, Icon, Message, Segment, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react'
import TSReactTable from "../../frameworks/TSReactTable";
import { 
	TABLE_HEADER_STYLE,
	getColumnsList,
} from "../../utils/Util";
import { URL_REPORT, URL_TRUCK } from '../../constances/urls';
import { GET } from '../../frameworks/HttpClient';


export default function TestFieldLatexReportView() {
	const [dateFilter, setDateFilter] = React.useState(moment());
	const [productOptions, setProductOptions] = React.useState([]);
	const [productFilter, setProductFilter] = React.useState("ALL");
	const [total, setTotal] = React.useState(0);
	const [nh3Average, setNH3Average] = React.useState(0);
	const [vfaAverage, setVFAAverage] = React.useState(0);
	// Table
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columns = useMemo(() => [
		...getColumnsList([
			{ Header: "เวลา", accessor: "time", width: 70 },
			{ 
				Header: "จุดรับซื้อ", 
				accessor: "customer_name",
				style: {textAlign: "left"},
				Footer: "TOTAL", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "ทะเบียน", accessor: "license_plate", width: 90 },
			{ Header: "สินค้า", accessor: "product_name", width: 130 },
			{ 
				Header: "น้ำหนัก", 
				accessor: "net_weight", 
				width: 80,
				Cell: ({value}) => (<p>{parseFloat(value).toLocaleString("en-US")}</p>),
				Footer: parseFloat(total).toLocaleString("en-US"),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "NH3", 
				accessor: "nh3", 
				width: 60,
				Footer: parseFloat(nh3Average).toFixed(2),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "VFA", 
				accessor: "vfa", 
				width: 60,
				Footer: parseFloat(vfaAverage).toFixed(3),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "BULKING TANK NO.", accessor: "bulking_tank_no", width: 90 },
			{ Header: "REMARK", accessor: "is_approved", width: 80 },
			{ Header: "CHECK BY", accessor: "inspector", width: 120 }
		]),
	])

	const fetchData = async (date=null) => {
		setLoading(true)
		setErrorMessages(null)
		const params = {
			date: moment(date || dateFilter, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			product: productFilter,
		}
		try {
			const response = await GET(URL_REPORT.TRUCK_TRANSACTION_QUALITY_CHECK_REPORT, params)
			let totalWeight = response.data.reduce((c, p) => c + Number(p.net_weight), Number(0));
			let totalNH3 = response.data.reduce((c, p) => c + (Number(p.nh3) * Number(p.net_weight)), Number(0));
			let totalVFA = response.data.reduce((c, p) => c + (Number(p.vfa) * Number(p.net_weight)), Number(0));
			setTotal(totalWeight)
			setNH3Average((totalWeight > 0) ? (totalNH3 / totalWeight).toFixed(2) : 0)
			setVFAAverage((totalWeight > 0) ? (totalVFA / totalWeight).toFixed(3) : 0)
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const fetchProductOptions = async () => {
		try {
			const response = await GET(URL_TRUCK.TRUCK_PRODUCT, {allowed_weight_in: true});
			const options = response.data.map(item => {
				return {key: item.id, value: item.id, text: item.name}
			})
			setProductOptions([
				{key: "ALL", value: "ALL", text: "ทั้งหมด"},
				...options,
			]);
		} catch (error) {
			setErrorMessages(error.errorMessages)
		}
	}

	const gotoExport = () => {
		const date = moment(dateFilter, 'DD/MM/YYYY').format('YYYY-MM-DD');
		const params = `date=${date}&product=${productFilter}`
		window.open(`${URL_REPORT.TRUCK_TRANSACTION_QUALITY_CHECK_REPORT_EXPORT}?${params}`);
	}

	const nextDay = () => {
		const date = moment(dateFilter, 'DD/MM/YYYY')
		setDateFilter(date.add(1, "days"))
	}

	const previousDay = () => {
		const date = moment(dateFilter, 'DD/MM/YYYY')
		setDateFilter(date.add(-1, "days"))
	}

	React.useEffect(() => {
		if(window.location.search) {
			const sp = new URLSearchParams(window.location.search);
			if (sp.has("date")) {
				const initDate = moment(sp.get("date")).format('DD/MM/YYYY')
				setDateFilter(initDate)
				fetchData(initDate)
			}
		}

		fetchProductOptions();
	}, [])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<p 
						style={{
							marginTop: "auto", 
							marginBottom: "auto", 
							marginRight: 16, 
							fontWeight: "bold"}}>
								{"วันที่"}
					</p>
					<Form.Field
						control={DateInput}
						closable={true}
						dateFormat="DD/MM/YYYY"
						maxDate={moment()}
						placeholder={"เลือก..."}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>
					<Form.Field style={{}}>
						<Button icon={"angle left"} primary onClick={() => previousDay()} />
						<Button icon={"angle right"} primary onClick={() => nextDay()} />
					</Form.Field>
						<Form.Field 
							label={"สินค้า"}
							control={Dropdown}
							selectOnBlur={false}
							selection
							options={productOptions}
							value={productFilter}
							onChange={(_, data) => setProductFilter(data.value)}/>
						
						<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>

				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport()}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}