import React from 'react';
import propTypes from "prop-types";
import { Form, Input, Dropdown, Message } from 'semantic-ui-react';
import ConfirmModal from "../../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { URL_SALE } from '../../../constances';
import { PATCH, POST } from '../../../frameworks/HttpClient';

export default function SaleConcentratedLatexModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { 
			container_number: props.data ? props.data.container_number : "",
			seal_number: props.data ? props.data.seal_number : "",
			lump_number: props.data ? props.data.lump_number : "",
			filter_number: props.data ? props.data.filter_number : "",
			car_registration: props.data ? props.data.car_registration : "",
			do_no: props.data ? props.data.do_no : "",
			weight: props.data ? parseFloat(props.data.weight) : "" 
		},
    validationSchema: Yup.object().shape({
			container_number: Yup.string().notRequired().nullable(true),
			seal_number: Yup.string().required("กรุณากรอกข้อมูล"),
			lump_number: Yup.string().required("กรุณากรอกข้อมูล"),
			filter_number: Yup.string().required("กรุณากรอกข้อมูล"),
			car_registration: Yup.string().required("กรุณากรอกข้อมูล"),
			do_no: Yup.string().notRequired().nullable(true),
			weight: Yup.number().required("กรุณากรอกข้อมูล")
		}),
    onSubmit: (values) => handleSubmit(values),
  });

	const handleSubmit = async (values) => {
		if (!props.sellTransaction) {
			setErrorMessages("ไม่พบข้อมูล SELL Transaction");
			return;
		}
		
		// Prepare data
		setLoading(true);
		const loadTransaction = props.data ? props.data.id : null;
		let response = null;
		try {
			if (loadTransaction) // Update
				response = await PATCH(`${URL_SALE.LOAD_CONCENTRATED_LATEX_SELL_TRANSACTION}${loadTransaction}/`, values);
			else // Create
				response = await POST(
					URL_SALE.LOAD_CONCENTRATED_LATEX_SELL_TRANSACTION, 
					{...values, concentrated_latex_sell_transaction: props.sellTransaction});
				
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}
	
	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
		}
	}, [props.open]);

	return (
		<ConfirmModal
			open={props.open}
			title={props.data ? "แก้ไขการโหลดน้ำยางข้น" : "โหลดน้ำยางข้น"}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={props.data ? "แก้ไข" : "ยืนยัน"}
			positiveColor={props.data ? "yellow" : "green"}
			onClose={props.onClose}
			onConfirm={formik.handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}

					<Form style={{textAlign: "right" ,width: "80%"}}>
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"เบอร์ตู้"}
							control={Input}
							placeholder={"เบอร์ตู้"}
							name={"container_number"}
							error={formik.errors.container_number}
							value={formik.values.container_number}
							onChange={formik.handleChange}/>
						
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"หมายเลขซีล"}
							control={Input}
							placeholder={"หมายเลขซีล"}
							name={"seal_number"}
							error={formik.errors.seal_number}
							value={formik.values.seal_number}
							onChange={formik.handleChange}/>
						
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"Lump"}
							control={Input}
							placeholder={"Lump"}
							name={"lump_number"}
							error={formik.errors.lump_number}
							value={formik.values.lump_number}
							onChange={formik.handleChange}/>
						
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"หมายเลข Filter"}
							control={Input}
							placeholder={"หมายเลข Filter"}
							name={"filter_number"}
							error={formik.errors.filter_number}
							value={formik.values.filter_number}
							onChange={formik.handleChange}/>
						
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"ทะเบียนรถ"}
							control={Input}
							placeholder={"ทะเบียนรถ"}
							name={"car_registration"}
							error={formik.errors.car_registration}
							value={formik.values.car_registration}
							onChange={formik.handleChange}/>
						
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"เลขที่ใบ DO"}
							control={Input}
							placeholder={"เลขที่ใบ DO"}
							name={"do_no"}
							error={formik.errors.do_no}
							value={formik.values.do_no}
							onChange={formik.handleChange}/>
						
						<Form.Field 
							inline
							style={{width: "65%"}}
							label={"น้ำหนักโหลด (Kg)"}
							control={Input}
							placeholder={"0"}
							name={"weight"}
							error={formik.errors.weight}
							value={formik.values.weight}
							onChange={formik.handleChange}/>
					</Form>
				</div>
			}
		/>
	)
}

SaleConcentratedLatexModal.defaultProps = {
	open: false,
	sellTransaction: null,
	data: null,
	onClose: () => {},
	
}

SaleConcentratedLatexModal.propTypes = {
	open: propTypes.bool,
	sellTransaction: propTypes.string,
	data: propTypes.object,
	onClose: propTypes.func,
}