import React, { useMemo } from 'react';
import moment from "moment";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Label, Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react'
import TSReactTable from "../../../frameworks/TSReactTable";
import { 
	TABLE_HEADER_STYLE,
	getColumnsList,
	dateToString,
} from "../../../utils/Util";
import { URL_REPORT } from "../../../constances/urls";
import { GET } from '../../../frameworks/HttpClient';
import { formatComma } from "../../../frameworks/Util";

export default function SummaryConcentLatexReportView() {
	const [dateFilter, setDateFilter] = React.useState(moment());
	const [grade, setGrade] = React.useState("OTHERS");
	// Table
	const [data, setData] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columnsSummary = useMemo(() => [
		...getColumnsList([
			{ 
				Header: "Distribution", 
				accessor: "grade",
				Footer: "รวม", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "B/F ยอดยกมา (KGS.)", 
				accessor: "bring_forward",
				Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
				Footer: data ? formatComma(data.total_bring_forward) : "0", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
			{ 
				Header: "Production ยอดผลิต (KGS.)", 
				accessor: "production",
				Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
				Footer: data ? formatComma(data.total_production) : "0", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
			{ 
				Header: "Sale ยอดขาย (KGS.)", 
				accessor: "sale",
				Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
				Footer: data ? formatComma(data.total_sale) : "0", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
			{ 
				Header: "C/F ยอดคงเหลือ (KGS.)", 
				accessor: "balance",
				Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
				Footer: data ? formatComma(data.total_balance) : "0", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
		]),
	])

	const columnsDetail = useMemo(() => [
		{
			Header: "Con.Volume ปริมาตรน้ำยางข้น / เกรด",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ 
					Header: "Lot No.", 
					accessor: "lot_no",
					Footer: "รวม", 
					width: 120,
					Cell: ({original, value}) => {
						let msg = value
						if (original.is_pro)
							msg += " (PRO)"
						return (<p>{`${msg}`}</p>)
					},
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
				},
				{ 
					Header: "HA", 
					accessor: "HA", 
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_HA) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
				},
				{ 
					Header: "LA", 
					accessor: "LA", 
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_LA) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
				},
				{ 
					Header: "DC", 
					accessor: "DC", 
					show: grade == "OTHERS",
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_DC) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}), 
				},
				{ 
					Header: "VYTEX HA", 
					accessor: "VYTEX_HA", 
					show: grade == "OTHERS",
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_VYTEX_HA) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}), 
				},
				{ 
					Header: "VYTEX LA", 
					accessor: "VYTEX_LA", 
					show: grade == "OTHERS",
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_VYTEX_LA) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}), 
				},
				{ 
					Header: "MEDIUM", 
					accessor: "MEDIUM", 
					show: grade == "OTHERS",
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_MEDIUM) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),  
				},
				{ 
					Header: "FMA", 
					accessor: "FMA", 
					show: grade == "OTHERS",
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_FMA) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),  
				},
				{ 
					Header: "อื่นๆ", 
					accessor: "OTHERS",
					show: grade == "OTHERS", 
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{value ? formatComma(value) : ""}</p>),
					Footer: data ? formatComma(data.SUM_OTHERS) : "0", 
					getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),  
				},
			]),
		},
		...getColumnsList([
			{ Header: "ประเภท CON", accessor: "con_type", Cell: ({value, original}) => (<div></div>) },
		]),
		{
			Header: "Adjust ST",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ 
					Header: "H2O", 
					accessor: "water", 
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{(value && value != 0) ? formatComma(value) : ""}</p>)
				},
				{ 
					Header: "NH3", 
					accessor: "nh3", 
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{(value && value != 0) ? formatComma(value) : ""}</p>) 
				},
				{ 
					Header: "LA", 
					accessor: "lauric", 
					Cell: ({value}) => (<p style={{textAlign: "right"}}>{(value && value != 0) ? formatComma(value) : ""}</p>)
				},
			]),
		},
		...getColumnsList([
			{ 
				Header: "Diff", 
				accessor: "diff", 
				Cell: ({value}) => (<p style={{textAlign: "right"}}>{(value && value != 0) ? formatComma(value) : ""}</p>) 
			},
		]),
])

	const fetchData = async () => {
		setLoading(true)
		setErrorMessages(null)
		const params = {
			date: moment(dateFilter, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			grade: grade
		}
		try {
			const response = await GET(URL_REPORT.CONCENTRATED_LATEX_SUMMARY_REPORT, params);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const gotoExport = () => {
		const date = moment(dateFilter, 'DD/MM/YYYY').format('YYYY-MM-DD');
		const params = `date=${date}&grade${grade}`
		window.open(`${URL_REPORT.CONCENTRATED_LATEX_SUMMARY_REPORT_EXPORT}?${params}`);
	}

	return (
		<Container fluid style={{marginTop: 24}}>
			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group inline>
					<p 
						style={{
							marginTop: "auto", 
							marginBottom: "auto", 
							marginRight: 16, 
							fontWeight: "bold"}}>
								{"วันที่"}
					</p>
					<Form.Field
						control={DateInput}
						closable={true}
						dateFormat="DD/MM/YYYY"
						maxDate={moment()}
						placeholder={"เลือก..."}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>

					<Form.Field 
            label={"เกรดผลิต"}
            control={Dropdown}
            selection
						selectOnBlur={false}
            value={grade}
            onChange={(_, data) => setGrade(data.value)}
            options={[
              {key: "OTHERS", value: "OTHERS", text: "ทั่วไป"},
              {key: "FSC", value: "FSC", text: "FSC"},
            ]}/>
						
					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columnsSummary}
					data={data ? data.summary_grade : []}
					pageSize={(data && (data.summary_grade.length > 0)) ? data.summary_grade.length : 5}
					showPagination={false}/>
				
				<TSReactTable
					style={{marginTop: 24}}
					columns={columnsDetail}
					data={data ? data.summary_lot_no : []}
					pageSize={(data && (data.summary_lot_no.length > 0))? data.summary_lot_no.length : 5}
					showPagination={false}/>

				<Form style={{marginTop: 24}}>
					<Form.Field inline>
						<label style={{width: 150}}>TOTAL</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.total) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>DRUMS HA + IBC</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.drums_ha) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>DRUMS LA + IBC</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.drums_la) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>DRUMS VYTEX HA + IBC</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.drums_vytex_ha) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>DRUMS VYTEX LA + IBC</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.drums_vytex_la) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>DRUMS MEDIUM + IBC</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.drums_medium) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>DRUMS DC + IBC</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.drums_dc) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>GOOD PACK</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.good_pack) : "0"}</Label>
					</Form.Field>
					<Form.Field inline>
						<label style={{width: 150}}>SUBTOTAL</label>
						<Label style={{width: 86, textAlign: "right"}} color={"blue"}>{data ? formatComma(data.subtotal) : "0"}</Label>
					</Form.Field>
				</Form>
					
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					disabled
					onClick={() => () => gotoExport()}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}