import React from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router";
import { Container, Dimmer, Loader, Message } from "semantic-ui-react";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { INVENTORY_TYPE } from "../../constances";
import { PATH } from "../../routes/Routes";
import edit from "../../assets/img_edit.png";
import { GET } from "../../frameworks/HttpClient";
import { dateToString } from "../../frameworks/Util";
import { URL_STOCK } from "../../constances/urls";

export default function WithDrawManager() {
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ 
				Header: "วันที่", 
				accessor: "created", 
				width: 100,
				Cell: ({value}) => (<p>{dateToString(value)}</p>)
			},
			{ Header: "สินค้า", accessor: "product.name", minWidth: 240 },
			{ 
				Header: "ประเภท", 
				accessor: "product.type", 
				width: 120,
				Cell: ({value}) => (<p>{INVENTORY_TYPE[value]}</p>) 
			},
			{ Header: "จำนวน", accessor: "require_amount", width: 100 },
			{ Header: "แผนก", accessor: "department", width: 100 },
			{ Header: "ผู้เบิก", accessor: "created_name" },
			{ 
				Header: "Due Date", 
				accessor: "due_date", 
				width: 100, 
				Cell: ({value}) => (<p>{dateToString(value)}</p>)
			},
			{ Header: "สถานะ", accessor: "state_name" },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: (row) => {
				return (
					<div>
						<ImageButton 
							title={"แก้ไข"}
							src={edit}
							onClick={() => handleEdit(row.original)}/>
					</div>
				)
			}
		},
	]);

	const handleEdit = async (item) => {
		// Goto edit screen
		history.push(`${PATH.PR_DETAIL}${item.id}/`)
	}

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_STOCK.WITHDRAW_TRANSACTION}`,
				{
					state: "WAIT_PROGRESS",
					check_store_group: true,
				});
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
			fetchData();
	}, [])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/>

		</Container>
	)
}

WithDrawManager.defaultProps = {}

WithDrawManager.propTypes = {}