import React from "react";
import { Container, Menu, Segment } from "semantic-ui-react";
import PurchaseApprovalSegment from "../../components/store/purchase/PurchaseApprovalSegment";

let currentPage = "waiting"
export default function PurchaseApprovalTabView() {
	const [activeItem, setActiveItem] = React.useState(currentPage);

	const handleItemClick = (e, {name}) => { 
    currentPage = name;
    setActiveItem(name);
  }

	return (
		<Container fluid style={{marginTop: 24}}>
			<Menu attached={"top"} tabular>
				<Menu.Item
					name={"waiting"}
					active={activeItem === "waiting"}
					onClick={handleItemClick}>
						รายการรออนุมัติ
				</Menu.Item>
				<Menu.Item
					name={"approved"}
					active={activeItem === "approved"}
					onClick={handleItemClick}>
						รายการอนุมัติแล้ว
				</Menu.Item>
			</Menu>

			<Segment attached={"bottom"}>
				{(activeItem === "waiting") && (
					<PurchaseApprovalSegment 
						style={{marginTop: 16}}
						queryParams={{state: "WAIT_APPROVED,WAIT_PRODUCT_APPROVE_REQUESTED"}}/>
				)}

				{(activeItem === "approved") && (
					<PurchaseApprovalSegment 
						style={{marginTop: 16}}
						canEdit={false}
						showFilterDate={true}
						showApproval={true}
						queryParams={{state: "APPROVED,NOT_APPROVED,WAIT_MEMO,WAIT_PAY,WAIT_PRODUCT,RECEIVED,WAIT_PRODUCT_APPROVE_REJECTED,RECEIVED_REJECT,END"}}/>
				)}
      </Segment>
		</Container>
	)
}