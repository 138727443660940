import React from 'react';
import { Menu, Segment, Label, Container } from 'semantic-ui-react';
import InventorySegment from '../components/store/InventorySegment';
import { URL_STOCK } from '../constances/urls';

export default function InventoryView() {
	const [activeItem, setActiveItem] = React.useState("balance");
	const [notify, setNotify] = React.useState(null);

	const handleMenuChange = (_, {name}) => setActiveItem(name);

	return (
		<Container fluid style={{marginTop: 24}}>
			<Menu attached={"top"} tabular>
				<Menu.Item 
					name={"balance"}
					active={activeItem === "balance"}
					onClick={handleMenuChange}>
						รายการสินค้า
				</Menu.Item>

				<Menu.Item 
					name={"out_of_stock"}
					active={activeItem === "out_of_stock"}
					onClick={handleMenuChange}>
						รายการสินค้าใกล้หมด
						{notify && (<Label color={"red"}>{notify}</Label>)}
				</Menu.Item>
			</Menu>

			<Segment attached={"bottom"}>
				{(activeItem === "balance") && (
					<InventorySegment 
						baseURL={URL_STOCK.PRODUCT_PAGINATION}
						params={{check_store_group: true}}/>
				)}

				{(activeItem === "out_of_stock") && (
					<InventorySegment 
						baseURL={URL_STOCK.PRODUCT_PAGINATION}
						params={{
							nearly_out_of_stock: "True",  
							check_store_group: true
						}}/>
				)}
			</Segment>
		</Container>
	)
}