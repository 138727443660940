import React from "react";
import propTypes from "prop-types";
import { Form, Input, Message, TextArea } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { POST } from "../../frameworks/HttpClient";
import { URL_STOCK } from "../../constances/urls";
import CustomFileField from "../common/CustomFileField";
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import { formatComma } from "../../frameworks/Util";

const validationSchema = {
	product: Yup.string().required("กรุณากรอกข้อมูล"),
	deposit_transaction: Yup.string().required("กรุณากรอกข้อมูล"),
	new_amount: Yup.number().required("กรุณากรอกข้อมูล"),
	price: Yup.number().required("กรุณากรอกข้อมูล"),
	edit_note: Yup.string().required("กรุณากรอกข้อมูล"),
}

const defaultProductUpdateLogModel = {
	product: "",
	deposit_transaction: "",
	new_amount: null,
	price: 0,
	edit_note: "",
	attachment: null,
	is_active: true,
}

export default function InventoryModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [selectedLotNo, setSelectedLotNo] = React.useState(null)
	const [enablePrice, setEnablePrice] = React.useState(false);
	const depositTransaction = useDropdownItem({
		url: `${URL_STOCK.DEPOSIT_TRANSACTION_EMPTY_AMOUNT}?product=${props.initialModel ? props.initialModel.id : ''}`,
		text_renderer: (item) => `[${item.lot_no}] - ${formatComma(item.amount)} ${props.initialModel.unit}`
	})

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: defaultProductUpdateLogModel,
		validationSchema: Yup.object(validationSchema),
		onSubmit: props.onSubmit,
	});
	
	const handleSubmit = async () => {
		const formValidation = await formik.validateForm();
		const isFormValid = Object.keys(formValidation).length === 0;
		if (!isFormValid) {
			return;
		}

		if (!props.initialModel || !props.initialModel.id) {
			props.onClose();
			return;
		}

		setLoading(true);
		let response = null;
		let params = formik.values
		if (!params['attachment']) {
			// if attachment is null remove attachment key to avoid parsing error on backend
			delete params['attachment']
		}
		try {
			response = await POST(URL_STOCK.PRODUCT_UPDATE_LOG, params, true)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response) {
			props.onClose();
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			if (props.initialModel.id) {
				formik.setFieldValue("product", props.initialModel.id)
				depositTransaction.fetchData();
			}
		} else {
			formik.resetForm();
			setEnablePrice(false)
			setSelectedLotNo(null)
		}
	}, [props.open, props.initialModel])

	React.useEffect(() => {
		if (selectedLotNo) {
			let amount = formik.values.new_amount
			if (amount && !isNaN(parseFloat(amount))) {
				setEnablePrice((parseFloat(amount) >= parseFloat(selectedLotNo.amount)))
			} else {
				if (enablePrice)
					setEnablePrice(false);
			}
		}

	}, [selectedLotNo, formik.values.new_amount])

	return (
		<ConfirmModal 
			open={props.open}
			title={props.initialModel ? `แก้ไขจำนวนคงเหลือ` : "สินค้า"}
			loading={loading}
			size={"tiny"}
			positiveText={props.initialModel ? "แก้ไข" : "ตกลง"}
			positiveColor={props.initialModel ? "yellow" : "green"}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form>
						<Form.Group inline>
							<label style={{width: "18%"}}>ชื่อสินค้า</label>
							<Form.Field 
								readOnly
								width={13}
								control={Input}
								placeholder={"ชื่อสินค้า..."}
								name={"name"}
								error={formik.errors.product}
								value={props.initialModel ? props.initialModel.name : ''}/>
						</Form.Group>

						<Form.Group inline>
							<label style={{width: "18%"}}>จำนวนคงเหลือ</label>
							<Form.Field 
								width={13}
								control={Input}
								placeholder={"จำนวน..."}
								type={"text"}
								name={"new_amount"}
								error={formik.errors.new_amount}
								value={formik.values.new_amount}
								onChange={formik.handleChange}/>
						</Form.Group>

						<Form.Group inline>
							<Form.Field width={4} label={"ล็อตสินค้า"}/>
							<Form.Dropdown
								width={16}
								style={{width: "100%"}}
								selection
								options={depositTransaction.response}
								name={"deposit_transaction"}
								error={formik.errors.deposit_transaction}
								value={formik.values.deposit_transaction}
								selectOnBlur={false}
								onChange={(_, data) => {
									formik.setFieldValue(data.name, data.value)
									let selected = depositTransaction.response.find(item => item.id == data.value)
									if (selected)
										setSelectedLotNo(selected);
								}}/>
						</Form.Group>

						{enablePrice && (
							<Form.Group inline>
								<label style={{width: "18%"}}>จำนวนเงิน</label>
								<Form.Field 
									width={13}
									control={Input}
									placeholder={"จำนวน..."}
									type={"text"}
									name={"price"}
									error={formik.errors.price}
									value={formik.values.price}
									onChange={formik.handleChange}/>
							</Form.Group>
						)}

						<Form.Field 
							width={16}
							label={"เหตุผล"}
							control={TextArea}
							placeholder={"เหตุผล..."}
							name={"edit_note"}
							error={formik.errors.edit_note}
							value={formik.values.edit_note}
							onChange={formik.handleChange}/>

						<CustomFileField
							label={"ไฟล์แนบ"}
							error={formik.errors.attachment}
							value={formik.values.attachment ? formik.values.attachment.name : ''}
							onChange={(value) => formik.setFieldValue('attachment', value)}/>
					</Form>
				</div>
			}
		/>
	)
}

InventoryModal.defaultProps = {
	initialModel: null,
	open: false,
	onClose: () => {},
}

InventoryModal.propTypes = {
	initialModel: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}