import React from 'react';
import { Button, Container, Dimmer, Form, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { URL_REPORT, URL_STOCK } from '../../constances/urls';
import { MonthInput, YearInput } from 'semantic-ui-calendar-react';
import { MONTH_STRING_TH } from '../../constances';
import moment from "moment";
import TSReactTable from '../../frameworks/TSReactTable';
import { getColumnsList } from '../../utils/Util';
import { GET } from '../../frameworks/HttpClient';
import { formatComma } from '../../frameworks/Util';


export default function SparePartReportView() {

  const [monthFilter, setMonthFilter] = React.useState(moment().format("MM"));
	const [yearFilter, setYearFilter] = React.useState(moment().format("YYYY"));
  const [data, setData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(false);

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: "วันที่", accessor: "date", width: 40 },
      { 
        Header: "", 
        accessor: "supplier", 
        style: {textAlign: "left"},
        width: 120 
      },
      { 
        Header: "รายการ", 
        accessor: "description",
        style: {textAlign: "left"},
        width: 240,
      },
      { 
        Header: "รับ",
        accessor: "received",
        columns: [
          { 
            Header: "หน่วย", 
            accessor: "received_amount", 
            width: 70,
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: "@", 
            accessor: "received_price_per_unit", 
            style: {textAlign: "right"},
            width: 70, 
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: `จำนวนเงิน`, 
            accessor: "received_total_price",
            style: {textAlign: "right"}, 
            Cell: ({value}) => value ? formatComma(value) : null 
          },
        ] 
      },
      { 
        Header: "จ่าย",
        accessor: "paid",
        columns: [
          { 
            Header: "หน่วย", 
            accessor: "paid_amount",
            width: 70,
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: "@", 
            accessor: "paid_price_per_unit", 
            style: {textAlign: "right"}, 
            width: 70, 
            Cell: ({value}) => value ? formatComma(value) : null },
          { 
            Header: `จำนวนเงิน`, 
            accessor: "paid_total_price", 
            style: {textAlign: "right"}, 
            Cell: ({value}) => value ? formatComma(value) : null 
          },
        ] 
      },
      { 
        Header: "คงเหลือ",
        accessor: "remain",
        columns: [
          { 
            Header: "หน่วย", 
            accessor: "remain_amount", 
            width: 70,
            Cell: ({value}) => value ? formatComma(value, true) : null 
          },
          { 
            Header: "@", 
            accessor: "remain_price_per_unit", 
            style: {textAlign: "right"},
            width: 70, 
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: `จำนวนเงิน`, 
            accessor: "remain_total_price", 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value, true) : null 
          },
        ] 
      },
    ])
  ])

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_REPORT.SPARE_PART_REPORT, {month: monthFilter, year: yearFilter})
      setData(response.data)
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleExport = (exportType) => {
    window.open(`${URL_REPORT.SPARE_PART_REPORT_EXPORT}${exportType}/?month=${monthFilter}&year=${yearFilter}`);
  }

  return (
    <Container fluid style={{marginTop: 24}}>

      { errorMessages && <Message error>{errorMessages}</Message> }

      <Form>
        <Form.Group inline>
					<label>{"เดือน"}</label>
          {/* Warning: Only available current year */}
          <Form.Field
            control={MonthInput}
            dateFormat="MM"
            locale="en-US"
            placeholder={"เลือก..."}
            name={"month"}
            value={MONTH_STRING_TH[monthFilter]}
            onChange={(_, data) => setMonthFilter(data.value)}
          />
          <label>{"ค.ศ."}</label>
          <Form.Field
            control={YearInput}
            dateFormat="YYYY"
            locale="en-US"
            maxDate={moment()}
            placeholder={"เลือก..."}
            name={"year"}
            value={yearFilter}
            onChange={(_, data) => setYearFilter(data.value)}
          />
          <Button primary onClick={() => fetchData()}>ค้นหา</Button>
        </Form.Group>
      </Form>

      <Segment basic style={{padding: 0}}>
				<Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
				
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => handleExport("pdf")}
					>
						<Icon name={"file pdf"}/>
						PDF
				</Button>

				<Button 
					style={{marginTop: 8}}
					color={"green"}
					floated={"right"}
					size={"mini"}
					onClick={() => handleExport("excel")}
					>
						<Icon name={"file excel"}/>
						Excel
				</Button>
			</Segment>
    </Container>
  );
}