import React, { useState } from 'react';
import QualityView from "../views/QualityView";
import PondView from "../views/PondView";
import TankView from "../views/TankView";
import LabOfPondView from "../views/LabOfPondView";
import LabOfAdjustView from "../views/LabOfAdjustView";
import LabOfControlView from "../views/LabOfControlView";
import StoreOfPondView from "../views/StoreOfPondView";
import TankValidateView from "../views/TankValidateView";
// Stock
import RequisitionView from "../views/store/RequisitionView";
import RequisitionApprovalView from "../views/store/RequisitionApprovalView";
import WithdrawManagerView from "../views/store/WithdrawManagerView";
import PickupGoodsView from "../views/store/PickupGoodsView";
import PRManagerView from "../views/store/PRManagerView";
import PurchaseApprovalTabView from '../views/store/PurchaseApprovalTabView';
import VerificationGoodsView from "../views/store/VerificationGoodsView";
import CashPaymentView from "../views/store/CashPaymentView";
import MemoPaymentView from "../views/store/MemoPaymentView";
import InventoryView from "../views/InventoryView";
import StockCountView from "../views/StockCountView";
// Report
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { USER_GROUP } from "../constances";
import { GET } from '../frameworks/HttpClient';
import { URL_NOTIFICATION } from '../constances/urls';
import POManagerView from '../views/store/POManagerView';
import CreditPaymentView from '../views/store/CreditPaymentView';
import ReceivingGoodsView from '../views/store/ReceivingGoodsView';
import PondTruckView from '../views/PondTruckView';
import StoreOfTankView from '../views/StoreOfTankView';
import MainHeader from '../components/common/MainHeader';
import { PATH } from '../routes/Routes';
import ReportView from '../views/ReportView';
import useUserData from '../hooks/useUserData';


let currentPage = null;
let badgesTimer = null;
let focused = false;

const defaultsBadgesModel = {
  cash_payment_badge_count: 0,
  credit_payment_badge_count: 0,
  lab_order_badge_count: 0,
  latex_sell_badge_count: 0,
  memo_payment_badge_count: 0,
  purchase_approval_badge_count: 0,
  purchase_request_owner_action_badge_count: 0,
  quotation_wait_create_po_badge_count: 0,
  tank_lab_order_badge_count: 0,
  truck_transaction_approved_badge_count: 0,
  truck_transaction_badge_count: 0,
  verify_goods_badge_count: 0,
  withdraw_transaction_wait_confirm_badge_count: 0,
  withdraw_transaction_wait_progress_badge_count: 0,
}

export default function MenuScreen() {
  const history = useHistory();
  const params = useParams();
  const { user } = useUserData();
  const [activeMenu, setActiveMenu] = React.useState(null)
  const [badges, setBadges] = useState({...defaultsBadgesModel});
  const qualityRef = React.useRef();
  const pondRef = React.useRef();
  const pondTruckRef = React.useRef();

  const handleBadges = async () => {
    let myBadges = null
    try {
      const response = await GET(URL_NOTIFICATION.MENU_BADGES);
      myBadges = response.data
      setBadges(myBadges);
    } catch (error) {
      console.log(error.errorMessages);
    }

    // Refresh current view
    if (myBadges) {
      if ((currentPage === "quality") && (qualityRef) && (qualityRef.current)
        && (myBadges.truck_transaction_badge_count > 0)) {
          qualityRef.current.refresh();
      }
      else if ((currentPage === "pond") && (pondRef) && (pondRef.current)
        && (myBadges.lab_order_badge_count > 0)) {
          pondRef.current.refresh();
      }
      else if ((currentPage === "pond_truck") && (pondTruckRef) && (pondTruckRef.current)
        && (myBadges.truck_transaction_approved_badge_count > 0)) {
          pondTruckRef.current.refresh();
        }
    }
    if (focused)
      badgesTimer = setTimeout(handleBadges, 180000);
  }

  const permissions = {
    'lab': user.groups.find(group => group.name === USER_GROUP.LAB) ? true : false,
    'factoryPond': user.groups.find(group => group.name === USER_GROUP.FACTORY_POND) ? true : false,
    'factoryTank': user.groups.find(group => group.name === USER_GROUP.FACTORY_TANK) ? true : false,
    'manager': user.groups.find(group => group.name === USER_GROUP.MANAGER) ? true : false,
    'store': user.groups.find(group => group.name === USER_GROUP.STORE) ? true : false,
    'storeManager': user.groups.find(group => group.name === USER_GROUP.STORE_MANAGER) ? true : false,
    'storeNonManager': user.groups.find(group => (group.name.includes("store_") && (group.name != USER_GROUP.STORE_MANAGER))) ? true : false,
    'hasDepartment': user.department ? true : false,
    'accounting': user.groups.find(group => group.name === USER_GROUP.ACCOUNTING) ? true : false,
  }

  const menus = [
    {
      key: 'quality',
      text: 'ตรวจสอบคุณภาพ',
      permission: permissions['lab'],
      path: PATH.QUALITY,
      count: badges.truck_transaction_badge_count,
    },
    {
      key: 'pond_truck',
      text: 'รถรอเทน้ำยาง',
      permission: permissions['factoryPond'],
      path: PATH.POND_TRUCK,
      count: badges.truck_transaction_approved_badge_count,
    },
    {
      key: 'pond',
      text: 'บ่อพัก',
      permission: permissions['factoryPond'],
      path: PATH.POND_DETAIL,
      count: badges.lab_order_badge_count,
    },
    {
      key: 'tank',
      text: 'แท็งก์เก็บน้ำยาง',
      permission: permissions['factoryTank'],
      path: PATH.TANK_DETAIL,
      count: badges.tank_lab_order_badge_count,
    },
    {
      key: 'lab_pond',
      text: 'บ่อพัก (แลป)',
      permission: permissions['lab'],
      path: PATH.LAB_POND,
    },
    {
      key: 'lab_adjust',
      text: 'แท็งก์ผลิต (แลป)',
      permission: permissions['lab'],
      path: PATH.LAB_ADJUST,
    },
    {
      key: 'lab_control',
      text: 'แท็งก์เก็บน้ำยาง (แลป)',
      permission: permissions['lab'],
      path: PATH.LAB_CONTROL,
    },
    {
      key: 'validate_tank',
      text: 'ตรวจสอบน้ำหนักแท็งก์',
      permission: permissions['store'],
      path: PATH.VALIDATE_TANK,
    },
    {
      key: 'store_pond',
      text: 'ร้านค้า (น้ำยางสด)',
      permission: permissions['store'],
      path: PATH.STORE_POND,
    },
    {
      key: 'store',
      text: 'ร้านค้า (น้ำยางข้น)',
      permission: permissions['store'],
      path: PATH.STORE,
    },
    {
      key: 'requisition',
      text: 'รายการขอเบิก',
      permission: permissions['hasDepartment'],
      path: PATH.REQUISITION,
    },
    {
      key: 'requisition_approval',
      text: 'รออนุมัติเบิก',
      permission: permissions['storeManager'],
      path: PATH.REQUISITION_APPROVAL,
      count: badges.withdraw_transaction_wait_confirm_badge_count,
    },
    {
      key: 'withdraw_manager',
      text: 'จัดการคำขอเบิก',
      permission: permissions['storeNonManager'],
      path: PATH.WITHDRAW_MANAGER,
      count: badges.withdraw_transaction_wait_progress_badge_count,
    },
    {
      key: 'pickup_goods',
      text: 'เบิกสินค้า',
      permission: permissions['storeNonManager'],
      path: PATH.PICKUP_GOODS,
    },
    {
      key: 'pr_manager',
      text: 'รายการสั่งซื้อ',
      permission: permissions['hasDepartment'],
      path: PATH.PR_MANAGER,
      count: badges.purchase_request_owner_action_badge_count,
    },
    {
      key: 'purchase_approval',
      text: 'รออนุมัติสั่งซื้อ',
      permission: permissions['manager'],
      path: PATH.PURCHASE_APPROVAL,
      count: badges.purchase_approval_badge_count,
    },
    {
      key: 'po_manager',
      text: 'จัดการ PO',
      permission: permissions['storeNonManager'],
      path: PATH.PO_MANAGER,
      count: badges.quotation_wait_create_po_badge_count,
    },
    {
      key: 'receive_goods',
      text: 'ตรวจรับสินค้า',
      permission: permissions['storeNonManager'],
      path: PATH.RECEIVE_GOODS,
    },
    {
      key: 'verify_goods',
      text: 'ตรวจรับสินค้าควบคุม',
      permission: permissions['lab'],
      path: PATH.VERIFY_GOODS,
      count: badges.verify_goods_badge_count,
    },
    {
      key: 'cash_payment',
      text: 'สั่งซื้อ (เงินสด)',
      permission: permissions['accounting'],
      path: PATH.CASH_PAYMENT,
      count: badges.cash_payment_badge_count,
    },
    {
      key: 'credit_payment',
      text: 'สั่งซื้อ (เครดิต)',
      permission: permissions['accounting'],
      path: PATH.CREDIT_PAYMENT,
      count: badges.credit_payment_badge_count,
    },
    {
      key: 'memo_payment',
      text: 'สั่งซื้อ (memo)',
      permission: permissions['accounting'],
      path: PATH.MEMO_PAYMENT,
      count: badges.memo_payment_badge_count,
    },
    {
      key: 'inventory',
      text: 'สินค้าคงคลัง',
      permission: permissions['storeNonManager'],
      path: PATH.INVENTORY,
    },
    {
      key: 'stock_count',
      text: 'ตรวจนับ Stock',
      permission: permissions['store'],
      path: PATH.STOCK_COUNT,
    },
    {
      key: 'report',
      text: 'รายงาน',
      path: PATH.REPORT
    }
  ]

  React.useEffect(() => {
    focused = true
    if (badgesTimer) {
      clearTimeout(badgesTimer);
      badgesTimer = null;
    }
    handleBadges();

    return function cleanup() {
      focused = false
      if (badgesTimer) {
        clearTimeout(badgesTimer);
        badgesTimer = null;
      }
    };
  }, [])

  React.useEffect(() => {
    if (params.menu) {
      const targetActive = menus.find(menu => menu.path && menu.path.replaceAll('/', '') === params.menu)
      if (targetActive) {
        setActiveMenu(targetActive.key)
      }
    } else {
      if (permissions['lab']) {
        history.replace(PATH.QUALITY);
      } else if (permissions['factoryPond']) {
        history.replace(PATH.POND_DETAIL)
      } else if (permissions['factoryTank']) {
        history.replace(PATH.TANK_DETAIL)
      } else if (permissions['store']) {
        history.replace(PATH.STORE_POND)
      } else if (permissions['manager']) {
        history.replace(PATH.PURCHASE_APPROVAL)
      } else if (permissions['accounting']) {
        history.replace(PATH.CASH_PAYMENT)
      } else if(permissions['hasDepartment']) {
        history.replace(PATH.REQUISITION)
      } else {
        history.replace(PATH.REPORT)
      }
    }
  }, [params])

  return (
    <div>
      <MainHeader
        menus={menus}
        activeMenu={activeMenu}
      >
        <Switch>
          <Route exact path={PATH.QUALITY} component={() => <QualityView ref={qualityRef} />} />
          <Route exact path={PATH.POND_TRUCK} component={() => <PondTruckView ref={pondTruckRef} />} />
          <Route exact path={PATH.POND_DETAIL} component={() => <PondView ref={pondRef} />} />
          <Route exact path={PATH.TANK_DETAIL} component={TankView} />
          <Route exact path={PATH.LAB_POND} component={LabOfPondView} />
          <Route exact path={PATH.LAB_ADJUST} component={LabOfAdjustView} />
          <Route exact path={PATH.LAB_CONTROL} component={LabOfControlView} />
          <Route exact path={PATH.VALIDATE_TANK} component={TankValidateView} />
          <Route exact path={PATH.STORE_POND} component={StoreOfPondView} />
          <Route exact path={PATH.STORE} component={StoreOfTankView} />
          <Route exact path={PATH.REQUISITION} component={RequisitionView} />
          <Route exact path={PATH.REQUISITION_APPROVAL} component={RequisitionApprovalView} />
          <Route exact path={PATH.WITHDRAW_MANAGER} component={WithdrawManagerView} />
          <Route exact path={PATH.PICKUP_GOODS} component={PickupGoodsView} />
          <Route exact path={PATH.PR_MANAGER} component={PRManagerView} />
          <Route exact path={PATH.PURCHASE_APPROVAL} component={PurchaseApprovalTabView} />
          <Route exact path={PATH.PO_MANAGER} component={POManagerView} />
          <Route exact path={PATH.RECEIVE_GOODS} component={ReceivingGoodsView} />
          <Route exact path={PATH.VERIFY_GOODS} component={VerificationGoodsView} />
          <Route exact path={PATH.CASH_PAYMENT} component={CashPaymentView} />
          <Route exact path={PATH.CREDIT_PAYMENT} component={CreditPaymentView} />
          <Route exact path={PATH.MEMO_PAYMENT} component={MemoPaymentView} />
          <Route exact path={PATH.INVENTORY} component={InventoryView} />
          <Route exact path={PATH.STOCK_COUNT} component={StockCountView} />
          <Route exact path={PATH.REPORT} component={ReportView} />
          <Route path={`${PATH.REPORT}:menu`} component={ReportView} />

          <Route path='*'><Redirect to={PATH.HOME}/></Route>
        </Switch>
      </MainHeader>
    </div>
  )
}
