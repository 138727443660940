import React from 'react';
import { Container, Dimmer, Loader, Message } from 'semantic-ui-react';
import TitleBar from "../components/common/TitleBar";
import RefillSegment from "../components/pond/RefillSegment";
import CloseSegment from "../components/pond/CloseSegment";
import ProduceSegment from "../components/pond/ProduceSegment";
import CleanSegment from "../components/pond/CleanSegment";
import BurnSegment from "../components/pond/BurnSegment";
import { GET } from "../frameworks/HttpClient";
import { useParams } from "react-router-dom";
import { POND_STATUS, URL_POND } from "../constances";

export default function PondSummaryScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState(null);
	const [detail, setDetail] = React.useState(null);

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_POND.POND}${params.id}/`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const fetchDetail = async (transactionId) => {
		setLoading(true);
		try {
			const response = await GET(`${URL_POND.POND_TRANSACTION}${transactionId}/`);
			setDetail(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (data) {
			fetchDetail(data.transaction);
		}
	}, [data])

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={data ? `${data.name}` : "บ่อพักน้ำยาง"}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
      </Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				{(data 
					&& (POND_STATUS[data.state] !== POND_STATUS.REFILL)
					&& (POND_STATUS[data.state] !== POND_STATUS.CLOSE)
					&& (POND_STATUS[data.state] !== POND_STATUS.PRODUCE)
					&& (POND_STATUS[data.state] !== POND_STATUS.CLEAN)) && (
						<BurnSegment
							style={{marginBottom: 24}}
							data={detail} />
				)}

				{(data 
					&& (POND_STATUS[data.state] !== POND_STATUS.REFILL)
					&& (POND_STATUS[data.state] !== POND_STATUS.CLOSE)
					&& (POND_STATUS[data.state] !== POND_STATUS.PRODUCE)) && (
						<CleanSegment
							style={{marginBottom: 24}}
							data={detail}
							onReload={() => fetchData()} />
				)}
				
				{(data 
					&& (POND_STATUS[data.state] !== POND_STATUS.REFILL)
					&& (POND_STATUS[data.state] !== POND_STATUS.CLOSE)) && (
						<ProduceSegment
							style={{marginBottom: 24}}
							data={detail} />
				)}
				
			  {(data && (POND_STATUS[data.state] !== POND_STATUS.REFILL)) && (
					<CloseSegment 
						style={{marginBottom: 24}}
						data={detail}
						disableAction={(data && data.warning_lab_input) ? true : false}
						isFinished={(detail 
							&& (POND_STATUS[detail.state] !== POND_STATUS.REFILL) 
							&& (POND_STATUS[detail.state] !== POND_STATUS.CLOSE))}
						onReload={() => fetchData()}/>
				)}

				<RefillSegment
					data={detail}
					isFinished={(detail && (POND_STATUS[detail.state] !== POND_STATUS.REFILL))}
					onReload={() => fetchData()}/>
			</Container>
		</Container>
	)
}