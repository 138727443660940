import React from "react";
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import { Container, Header, Grid, Dimmer, Loader, Message, Button, Icon, Popup } from "semantic-ui-react";
import TitleBar from "../../components/common/TitleBar";
import TSReactTable from "../../frameworks/TSReactTable";
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import { GET, POST } from "../../frameworks/HttpClient";
import { formatComma, formatFileName } from "../../frameworks/Util";
import { URL_PURCHASE, URL_REPORT } from "../../constances/urls";
import DOListModal from "../../components/store/DOListModal";
import PREditorModal from "../../components/store/purchase/PREditorModal";
import InfoModal from "../../components/common/InfoModal";
import GoodsReceivedModal from "../../components/store/GoodsReceivedModal";
import { USER_GROUP } from "../../constances";

const defaultPODetail = {
	id: null,
	quotations: [],
	supplier: "",
	code: "",
}

export default function MemoPaymentScreen() {
	const params = useParams();
	const user = JSON.parse(Cookies.get("user"));
	const [loading, setLoading] = React.useState(false);
	const [paymentLoading, setPaymentLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [targetHistory, setTargetHistory] = React.useState(null);
	const [targetReceived, setTargetReceived] = React.useState(null);
	const [targetEdit, setTargetEdit] = React.useState(null);
	const [targetMemo, setTargetMemo] = React.useState(null);
	const [data, setData] = React.useState(defaultPODetail);
	const [dataAccounting , setDataAccounting] = React.useState({
		hasAccount: user.groups.find(group => group.name === USER_GROUP.ACCOUNTING) ? true : false,
		isWaitPay: (data && (data.status == "รอชำระเงิน")) ? true : false,
		isReceived: (data && (data.status == "รับสินค้าแล้ว")) ? true : false,
	})

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "รายการสินค้า", accessor: "product.name" },
			{ Header: "จำนวน", accessor: "amount", width: 100, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคาต่อหน่วย", accessor: "selected_quotation.unit_price", width: 120, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคารวม", accessor: "selected_quotation.total_price", width: 120, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคาจริง", accessor: "selected_quotation.real_price", width: 120, Cell: ({ value }) => formatComma(value) },
			{ 
				Header: "ใบเสนอราคา", 
				accessor: "selected_quotation.attachment",
				width: 220,
				Cell: ({ value }) => formatFileName(value)
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 60,
			Cell: ({original}) => (
				<div>
					<Popup
						content={"ใบขอเบิก"}
						trigger={
							<Button
								style={{marginLeft: 4, marginRight: 4}}
								icon 
								color={"red"}
								size={"mini"}
								onClick={() => gotoPRExport(original)}>
								<Icon name={"file pdf"}/>
							</Button>}
					/>
				</div>
			)
		},
	]);

	const fetchData = async () => {
		if (!params.id) {
			setErrorMessages("ID Not found!");
			return;
		}

		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_ORDER}${params.id}/`);
			setData({...defaultPODetail, ...response.data});
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handlePayAction = async () => {
		const poID = data ? data.id : "";
		// Call API for change state each purchase request
		setPaymentLoading(true);
		try {
			const response = await POST(`${URL_PURCHASE.PURCHASE_ORDER}${params.id}/memo_to_state_wait_product/`);
			fetchData();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setPaymentLoading(false);
		}
	}

	const gotoPRExport = (item) => {
		window.open(`${URL_REPORT.CR_STO_03_REPORT_EXPORT}?id=${item.id}`)
	}

	React.useEffect(() => {
		if (data) {
			setDataAccounting({
				...dataAccounting,
				isWaitPay: (data && (data.status == "รอชำระเงิน")) ? true : false,
				isReceived: (data && (data.status == "รับสินค้าแล้ว")) ? true : false,
			})
		}
	}, [data])

	React.useEffect(() => {
		if (!targetEdit)
			fetchData();

	}, [targetEdit]);

	return (
		<Container fluid>
			<TitleBar title={`รายละเอียด PO [${data.code}]`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Header as={"h2"} textAlign={"center"}>รายละเอียด</Header>
				<Grid style={{marginTop: 16}}>
					<Grid.Column width={6} textAlign={"left"}>
						<p style={{fontSize: 15}}>{`รหัส PO: ${data.code}`}</p>
					</Grid.Column>
					<Grid.Column width={10} textAlign={"left"}>
						<p style={{fontSize: 15}}>{`ผู้ผลิต: ${data ? data.supplier.name : "-"}`}</p>
					</Grid.Column>
				</Grid>

				<TSReactTable
					style={{marginTop: 16}}
					columns={columns}
					data={data.list_purchase_request}
					pageSize={(data.list_purchase_request && (data.list_purchase_request.length > 0)) ? data.list_purchase_request.length : 5}
					showPagination={false}/>

				<div style={{marginTop: 24, textAlign: "center"}}>
					<Button 
						primary 
						disabled={data.printable ? false : true}
						onClick={() => 
							window.open(`${URL_REPORT.CR_STO_04_REPORT_EXPORT}pdf/?id=${data.id}`)}>
						พิมพ์ใบ PO
					</Button>

					{dataAccounting && dataAccounting.hasAccount && (
							<Button 
							disabled={!dataAccounting.isWaitPay}
							loading={paymentLoading}
							color={"green"}
							onClick={handlePayAction}>
							ชำระเงิน
						</Button>
					)}
					
				</div>
			</Container>

			<DOListModal
				purchaseRequest={targetHistory}
				open={targetHistory ? true : false}
				onClose={() => setTargetHistory(null)}/>
			
			<GoodsReceivedModal
				open={targetReceived ? true : false}
				data={targetReceived}
				onClose={() => {
					setTargetReceived(null)
					fetchData();
				}}/>
			
			<PREditorModal 
				open={targetEdit ? true : false}
				pr={targetEdit}
				onClose={() => setTargetEdit(null)}/>
			
			{/* Detail memo */}
			<InfoModal
				open={targetMemo ? true : false}
				size={"tiny"}
				title={targetMemo ? targetMemo.product.name : ""}
				detail={
					<div>
						<p>{`สถานะ : ${targetMemo ? targetMemo.state_name : ""}`}</p>
						<p>{`หมายเหตุ : ${targetMemo ? targetMemo.memo_wait_product_approve_rejected : ""}`}</p>
					</div>
				}
				onClose={() => setTargetMemo(null)}/>

		</Container>
	)
}