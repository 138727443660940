import React, { useMemo } from "react";
import { URL_PURCHASE } from '../../constances/urls';
import propTypes from "prop-types";
import { getColumnsList } from "../../utils/Util";
import { dateToString, formatComma } from "../../frameworks/Util";
import ListModal from "../common/ListModal";

export default function PurchaseProductHistoryModal(props) {
    const columns = useMemo(() => [
		...getColumnsList([
			{ 
                Header: "วันที่", 
                accessor: "created",
                width: 100,
                Cell: ({ value }) => dateToString(value)
            },
			{ 
                Header: "ร้านค้า", 
                style: {textAlign: "left"},
                accessor: "selected_quotation.supplier", 
                Cell: ({value}) => value ? value.name : "กำหนดราคาซื้อเอง"
            },
			{ 
                Header: "ผู้ขอซื้อ", 
                accessor: "created_name", 
                width: 120
            },
			{ 
                Header: "สถานะ", 
                accessor: "state_name", 
                width: 120
            },
            { 
                Header: "จำนวน", 
                accessor: "amount",
                width: 120,
                Cell: ({ value }) => formatComma(value)
            },
            { 
                Header: "หน่วย", 
                accessor: "unit", 
                width: 100
            },
            {
                Header: "ราคา (รวม)",
                accessor: "selected_quotation.total_price",
                width: 120,
                style: {textAlign: "right"},
                Cell: ({ value }) => value != null ? formatComma(value) : 0
            }
		]),
	])

    return (
        <ListModal
			size={"large"}
			title={`ประวัติการสั่งซื้อสินค้า`}
			columns={columns}
			open={props.open}
			onClose={props.onClose}
			url={`${URL_PURCHASE.PURCHASE_REQUEST}?product_history=${props.product ? props.product.id : ""}`}/>
    )
}

PurchaseProductHistoryModal.defaultProps = {
    open: false,
    product: null,
    onClose: () => {},
}

PurchaseProductHistoryModal.propTypes = {
    open: propTypes.bool,
    product : propTypes.object,
    onClose: propTypes.func,
}