import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Image, Label, Icon, Loader } from 'semantic-ui-react';
import logo from '../assets/mmg_logo.jpg'
import { MOBILE_WIDTH } from '../Config';
import useWindowDimensions from '../frameworks/hooks/useWindowDimensions';
import { PATH } from '../routes/Routes';


export default function GenerateReportScreen () {

  const { t, i18n } = useTranslation();
  const { width, height } = useWindowDimensions();
  const history = useHistory();


  return (
    <div className='not-found'>
      <style>
        {`
          body > div,
          body > div > div,
          body > div > div > div.not-found {
            height: 100%;
          }
        `}
      </style>
      {
        width > MOBILE_WIDTH && 
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle' centered columns='2' stackable>
          <Grid.Column textAlign='right'>
            <Image src={logo} size='medium' floated='right'/>
          </Grid.Column>
          <Grid.Column>
            <Label color='teal' size='massive' tag>
              <Icon name='file' />
              กำลังสร้างรายงาน...
              <Loader active inline inverted/>
            </Label>
            
          </Grid.Column>
        </Grid>
      }
      {
        width <= MOBILE_WIDTH && 
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle' centered>
          <Grid.Column textAlign='center'>
            <Image src={logo} size='medium' centered/>
            <Header color='teal' style={{fontSize: '6rem'}}>404</Header>
            <Header size='large' color='grey'>{'404.page_not_found'}</Header>
            <Button 
              content={'404.back_to_home'}
              color='blue'
              size='big'
              icon='arrow left'
              onClick={() => history.replace(PATH.HOME)}/>
          </Grid.Column>
        </Grid>
      }
    </div>
  )
}