import React from 'react';
import propTypes from "prop-types";
import { URL_REPORT } from "../../constances/urls";
import ListModal from '../common/ListModal';
import { getColumnsList, TABLE_HEADER_STYLE } from '../../utils/Util';
import { dateToString, formatComma, formatFileName } from '../../frameworks/Util';
import { Button, Icon, List, Popup } from 'semantic-ui-react';

export default function PRListModal(props) {

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่รับสินค้า", 
				accessor: "delivery_order_last_date", 
				width: 120,
				Cell: ({value}) => (<p>{value ? dateToString(value) : ""}</p>)
			},
			{ 
				Header: "รายการ", 
				accessor: "product.name",
				width: 280,
				Cell: ({original, value}) => 
					(<p>{original.ref_product ? `${value} (${original.ref_product.name})` : value}</p>)
			},
			{ 
				Header: "จำนวน", 
				accessor: "amount", 
				width: 120,
				Cell: ({original ,value}) => 
					(<p>{`${value ? formatComma(parseFloat(value)) : "-"} ${original.unit}`}</p>),
			},
			{ 
				Header: "ราคารวม", 
				accessor: "selected_quotation.real_price",
				Cell: ({value}) => (<p>{formatComma(value)}</p>)
			},
			{ 
				Header: "เอกสารการเงิน", 
				accessor: "receipt", 
				style: { textAlign: "left" },
				width: 220,
				Cell: ({value}) => (
					<List bulleted>
						{value.map((item, index) => {
							return (
								<List.Item>{formatFileName(item)}</List.Item>
							)
						})}
					</List>
				)
			},
			{ 
				Header: "แผนก", 
				accessor: "department",
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: (row) => (
				<div>
					<Popup
						content={"Export PDF"}
						trigger={
							<Button 
								style={{marginLeft: 4, marginRight: 4}}
								icon 
								color={"red"}
								size={"mini"}
								onClick={() => gotoExport(row.original)}>
								<Icon name={"file pdf"}/>
							</Button>}
					/>
				</div>
			)
		},
	])

	const gotoExport = (item) => {
		window.open(`${URL_REPORT.CR_STO_03_REPORT_EXPORT}?id=${item.id}`)
	}

	return (
		<ListModal 
			size={"large"}
			title={`เลขที่บัญชี : ${props.data ? props.data.account_code : ""} (${props.data ? props.data.account_name : ""})`}
			columns={columns}
			open={props.open}
			onClose={props.onClose}
			url={`${URL_REPORT.PURCHASE_REQUEST_BY_PETTY_CASH}${props.data ? "?" + props.data.params : ""}`}/>
	)
}

PRListModal.defaultProps = {
	open: false,
	onClose: () => {},
	data: null,
}

PRListModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	data: propTypes.object,
}