import React from "react";
import { Container, Dimmer, Loader, Message } from "semantic-ui-react";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import reject from "../../assets/img_reject.png";
import accept from "../../assets/img_accept.png";
import edit from "../../assets/img_edit.png";
import { GET, POST } from "../../frameworks/HttpClient";
import { INVENTORY_TYPE, URL_PURCHASE } from "../../constances";
import { ellipsisString, formatComma, formatFileName } from "../../frameworks/Util";
import ConfirmModalWithNote from "../../components/common/ConfirmModalWithNote";
import { Toast } from "../../frameworks/Toast";
import DOChemicalCheckModal from "../../components/store/purchase/DOChemicalCheckModal";


export default function VerificationGoodsView() {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [targetReject, setTargetReject] = React.useState(null);
	const [targetApprove, setTargetApprove] = React.useState(null);
	const [targetLabResult, setTargetLabResult] = React.useState(null);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "สินค้า", accessor: "purchase_request.product.name", width: 200 },
			{ Header: "ผู้ขาย", accessor: "purchase_request.selected_quotation.supplier.name", width: 180 },
			{ 
				Header: "จำนวนที่รับมา", 
				accessor: "received_amount", 
				Cell: ({ original, value }) => 
					`${formatComma(value)} ${original && original.quotation && original.quotation.purchase_request ? original.quotation.purchase_request.unit : ""}` 
			},
			{ 
				Header: "จำนวนสุทธิ", 
				accessor: "into_stock_amount", 
				Cell: ({ original, value }) => 
				`${formatComma(value)} ${original && original.quotation && original.quotation.purchase_request ? original.quotation.purchase_request.product.unit : ""}`
			},
			{ 
				Header: "หมายเหตุการรับสินค้า", 
				accessor: "note", 
				width: 180, 
				Cell: ({ value }) => ellipsisString(value) 
			},
			{ 
				Header: "เอกสารแนบ", 
				accessor: "attachment", 
				width: 220, 
				Cell: ({ value }) => formatFileName(value) 
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: ({original}) => {
				const type = original.purchase_request ? original.purchase_request.product.type : ""
				const isChemical = (INVENTORY_TYPE[type] === INVENTORY_TYPE.chemical) ? true : false
				return (
					<div>
						{!isChemical && (
							<ImageButton 
								title={"ปฏิเสธ"}
								src={reject}
								color={"red"}
								onClick={() => setTargetReject(original)}/>
						)}
						{!isChemical && (
							<ImageButton 
								title={"ยอมรับ"}
								src={accept}
								onClick={() => handleApprove(original)}/>
						)}
						{isChemical && (
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={() => setTargetLabResult(original)}/>
						)}
						
					</div>
				)
			}
		},
	]);

	const fetchData = async () => {
		setLoading(true);
		setErrorMessages(null);
		try {
			const response = await GET(URL_PURCHASE.DELIVERY_ORDER, { status: "PENDING" })
			setData(response.data)
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleApprove = async (target) => {
		setLoading(true);
		setErrorMessages(null);
		try {
			await POST(`${URL_PURCHASE.DELIVERY_ORDER}${target.id}/approve/`)
			setTargetApprove(null);
			Toast.success("สำเร็จ");
			fetchData();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleReject = async (values) => {
		setLoading(true);
		setErrorMessages(null);
		try {
			await POST(`${URL_PURCHASE.DELIVERY_ORDER}${targetReject.id}/not_approve/`, { reject_note: values.note })
			setTargetReject(null);
			Toast.success("สำเร็จ");
			fetchData();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		setErrorMessages(null);
	}, [targetApprove, targetReject])

	React.useEffect(() => {
		if (!targetLabResult)
			fetchData();
			
	}, [targetLabResult])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/>

			<ConfirmModalWithNote
				title={"ปฏิเสธการรับสินค้า"}
				loading={loading}
				errorMessages={errorMessages}
				open={targetReject ? true : false}
				onConfirm={(values) => {
					setErrorMessages(null);
					handleReject(values)
			}}
				onClose={() => setTargetReject(null)}/>
			
			<DOChemicalCheckModal 
				open={targetLabResult ? true : false}
				deliveryOrder={targetLabResult}
				onClose={() => setTargetLabResult(null)}/>
		</Container>
	)
}
