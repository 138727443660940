import React from 'react';
import { Container, Button, Icon, Label, Form, Dimmer, Loader, Message } from 'semantic-ui-react';
import TitleBar from "../../components/common/TitleBar";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import SaleOrderLatexModal from '../../components/store/sale/SaleOrderLatexModal';
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import edit from "../../assets/img_edit.png";
import remove from "../../assets/img_delete.png";
import OrderDCModal from '../../components/store/sale/OrderDCModal';
import { useParams } from 'react-router-dom';
import { DELETE, GET } from '../../frameworks/HttpClient';
import { URL_SALE, URL_TANK } from '../../constances';
import useListItem from '../../frameworks/hooks/useItemList';
import { dateTimeToString, formatComma } from '../../frameworks/Util';
import ConfirmModal from '../../components/common/ConfirmModal';
import { LATEX_ORDER_TYPE } from '../../constances/string';

export default function SaleOrderLatexScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState(null);
	// Sale
	const [openSale, setOpenSale] = React.useState(false);
	const [targetData, setTargetData] = React.useState(null);
	const [deleteData, setDeleteData] = React.useState(null);
	const [deleteErrorMessages, setDeleteErrorMessages] = React.useState(null);
	// DC
	const [openDC, setOpenDC] = React.useState(false);
	// Table
	const list = useListItem({url: `${URL_SALE.CONCENTRATED_LATEX_SELL_TRANSACTION}?tank_transaction=${params.id}`});

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลาสร้าง", 
				accessor: "created", 
				width: 140, 
				Cell: ({value}) => (<p>{value ? dateTimeToString(value) : "-"}</p>) 
			},
			{ 
				Header: "คำสั่ง", 
				accessor: "type", 
				width: 80,
				Cell: ({value}) => (<p>{value ? LATEX_ORDER_TYPE[value] : "-"}</p>)
			},
			{ Header: "Advice", accessor: "advice", Cell: ({value}) => (<p>{value ? value : "-"}</p>) },
			{ Header: "Contact", accessor: "contact", Cell: ({value}) => (<p>{value ? value : "-"}</p>) },
			{ Header: "Buyer", accessor: "buyer", Cell: ({value}) => (<p>{value ? value : "-"}</p>) },
			{ 
				Header: "Packing", 
				accessor: "packing", 
				width: 140,
				Cell: ({value}) => (<p>{value ? value : "-"}</p>) 
			},
			{ 
				Header: "นน.สั่งขาย (kg)", 
				accessor: "weight", 
				width: 120,
				Cell: ({value}) => (<p>{value ? formatComma(value) : "0"}</p>) 
			},
			{ 
				Header: "นน.โหลด (kg)", 
				accessor: "total_load_weight", 
				width: 120,
				Cell: ({value}) => (<p>{value ? formatComma(value) : "0"}</p>) 
			},
			{ 
				Header: "สถานะ", 
				accessor: "is_finished",
				width: 80,
				Cell: ({value}) => {
					return (
						<div>
							<Icon size={"large"}
								name={value ? "check circle" : "minus circle"} 
								color={value ? "green" : "grey"} />
						</div>
					)
				}
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			accessor: "is_finished",
			width: 100,
			Cell: ({original, value}) => {
				const loadWeight = original.total_load_weight ? parseFloat(original.total_load_weight) : 0;
				return (
				<div>
					<ImageButton 
						title={"แก้ไข"}
						src={edit}
						disabled={value}
						onClick={(e, data) => setTargetData(original)}/>
					
					<ImageButton 
						title={"ลบ"}
						src={remove}
						color={"red"}
						disabled={value || (loadWeight > 0)}
						onClick={(e, data) => setDeleteData(original)}/>
				</div>
			)},
		},
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_TANK.TANK_TRANSACTION}${params.id}/`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	/** delete data */
	const handleDelete = async (data) => {
		let response = null;
		try {
			response = await DELETE(`${URL_SALE.CONCENTRATED_LATEX_SELL_TRANSACTION}${data.id}/`);
		} catch (error) {
			setDeleteErrorMessages(error.errorMessages);
		} 
		
		if (response)
			setDeleteData(null);
	}

	const closeSaleModal = () => {
		if (targetData) {
			setTargetData(null);
		} else {
			if (openSale)
				setOpenSale(false);
			else if (openDC)
				setOpenDC(false);
		}
	}

	React.useEffect(() => {
		/** Open modal */
		if (targetData) {
			const isSale = (targetData.type === "sell") ? true : false;
			if (isSale)
				setOpenSale(true);
			else 
				setOpenDC(true);
		} 
		/** Close modal */
		else {
			if (openSale)
				setOpenSale(false);
			else if (openDC)
				setOpenDC(false);
		}
	}, [targetData]);

	React.useEffect(() => {
		if (!openSale && !openDC && !deleteData) {
			/** Load list */
			list.fetchData();
		}
	}, [openSale, openDC, deleteData])

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={`คำสั่งขายน้ำยางข้น ${data ? `[ ${data.lot_no} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Form style={{marginTop: 16}}>
					<Form.Group inline>
						<Button 
							color={"green"} 
							onClick={() => setOpenSale(true)}>
							<Icon name={"add"}/>คำสั่งขาย
						</Button>

						<Button 
							color={"green"}
							onClick={() => setOpenDC(true)}>
							<Icon name={"add"}/>คำสั่ง DC
						</Button>

						<Form.Field style={{marginLeft: 24}}>
							<label>น้ำหนักคงเหลือในแท็งก์</label>
							<Label color={"blue"}>
								{(data && data.current_weight) ? formatComma(data.current_weight) : "0"}
							</Label>
						</Form.Field>
					</Form.Group>
				</Form>
				
				<TSReactTable 
					style={{marginTop: 16}}
					columns={columns}
					data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 5}
					showPagination={false}/>
			</Container>

			<SaleOrderLatexModal
				open={openSale}
				tankTransaction={data ? data.id : null}
				data={targetData}
				onClose={() => closeSaleModal()}
			/>

			<OrderDCModal 
				open={openDC}
				tankTransaction={data ? data.id : null}
				data={targetData}
				onClose={() => closeSaleModal()} />
			
			{/* Delete Order */}
			<ConfirmModal 
				open={deleteData ? true : false}
				title={`ลบคำสั่งขาย`}
				errorMessages={deleteErrorMessages}
				detail={`คุณต้องการลบคำสั่งขาย [ ${deleteData ? deleteData.packing : ""} 
					: ${deleteData ? parseFloat(deleteData.weight) : "0"} Kg ] นี้ใช่หรือไม่ ?`}
				onClose={() => setDeleteData(null)}
				onCancel={() => setDeleteData(null)}
				onConfirm={() => handleDelete(deleteData)}/>
		</Container>
	)
}