import moment from "moment";
import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Popup, Dropdown } from "semantic-ui-react";
import UploadModal from "../../../components/common/UploadModal";
import { INVENTORY_TYPE, INVENTORY_TYPE_OPTIONS } from "../../../constances";
import { URL_REPORT, URL_PURCHASE } from "../../../constances/urls";
import { GET, POST } from "../../../frameworks/HttpClient";
import TSReactTable from "../../../frameworks/TSReactTable";
import { formatComma, formatFileName } from "../../../frameworks/Util";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../../utils/Util";

export default function DeliveryOrderReportView() {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [filterType, setFilterType] = React.useState("ALL");
	const [filterStorage, setFilterStorage] = React.useState("ALL");
	const [dateRange, setDateRange] = React.useState(null);
	const [dateRangeError, setDateRangeError] = React.useState(false);
	const [targetAttach, setTargetAttach] = React.useState(null);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ Header: "วันที่ตรวจรับ", accessor: "date" },
			{ Header: "PO", accessor: "po", width: 120 },
			{ Header: "ผู้ขาย", accessor: "supplier",style: {textAlign: "left"}, width: 220 },
			{ Header: "สินค้า", accessor: "product", width: 220 },
			{ 
				Header: "จำนวนที่รับเข้า", 
				accessor: "received_amount",
				Cell: ({value}) => <p>{value ? formatComma(value) : ""}</p>
			},
			{ 
				Header: "การอนุมัติ", 
				accessor: "is_approved",
				width: 70,
				Cell: ({ original, value }) => 
					value != undefined && value != null ?  
						value ? 
							<div style={{textAlign: "center"}}><Icon color='green' name="check"/></div> 
							: <div style={{textAlign: "center"}}>
								<Popup
									trigger={<Icon color='red' name="cancel"/>}
									content={original.reject_note}
									inverted
									basic/>
								</div> 
						: <div style={{textAlign: "center"}}><Icon color='grey' name="clock"/></div> 
			},
			{ Header: "Lot No./Batch No.", accessor: "lot_no", width: 140 },
			{ Header: "วันหมดอายุ", accessor: "expiry_date" },
			{ 
				Header: "เอกสารแนบ", 
				accessor: "attachment",
				width: 220,
				Cell: ({ value }) => formatFileName(value) 
			},
			{ Header: "ผู้ตรวจรับ", accessor: "created_by" },
			{ Header: "ผู้ทดสอบ", accessor: "approved_by" },
			{ Header: "สั่งซื้อโดย", accessor: "department", width: 80 },
			{ 
				Header: "เอกสารตรวจรับ", 
				accessor: "document",
				width: 220,
				Cell: ({ value }) => formatFileName(value)
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			accessor: "product_type",
			width: 80,
			Cell: ({ original, value }) => (
				<div>
					{value && (INVENTORY_TYPE[value] === INVENTORY_TYPE.chemical) && 
						(original.is_inspect) && (
						<Popup
							content={"PDF"}
							trigger={
								<Button
									icon
									color={"red"}
									size={"mini"}
									onClick={() => chemicalDOExport(original)}>
									<Icon name={"file pdf"}/>
								</Button>}
						/>
					)}
					{value && ((INVENTORY_TYPE[value] === INVENTORY_TYPE.chemical) 
						|| (INVENTORY_TYPE[value] === INVENTORY_TYPE.packaging)) && (
						<Popup
							content={"แนบใบตรวจรับ"}
							trigger={
								<Button
									icon 
									color={"blue"}
									size={"mini"}
									onClick={() => setTargetAttach(original)}>
									<Icon name={"attach"}/>
								</Button>}
						/>
					)}
				</div>
			)
		},
	])

	const fetchData = async () => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);

		setLoading(true);
		setErrorMessages(null);
			const params = {
				date_before: moment(dateRange[0]).format('YYYY-MM-DD'),
				date_after: moment(dateRange[1]).format('YYYY-MM-DD'),
				type: filterType,
				storage: filterStorage,
			}
			try {
				const response = await GET(URL_REPORT.DELIVERY_ORDER_REPORT, params);
				setData(response.data)
			} catch (error) {
				setErrorMessages(error.errorMessages)
			} finally {
				setLoading(false)
			}
	}

	const handleUploadDocument = async (file) => {
		if (!targetAttach) {
			return [null, "ไม่พบข้อมูลใบรับสินค้า"]
		}

		let response = null;
		let error = null;
    try {
			response = await POST(
				`${URL_PURCHASE.DELIVERY_ORDER}${targetAttach.id}/update_document/`,
				{"document": file},
				true
			);
    } catch (e) {
			error = e.errorMessages;
    }
		return [response, error];
	}

	const chemicalDOExport = (item) => {
		if (!item || !item.id) 
			return;
		
		window.open(`${URL_REPORT.GENERATE_CHEMICAL_RECEIPT_REPORT}?delivery_order=${item.id}`)
	}

	const gotoExport = (exportType) => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);
		const dateBefore = moment(dateRange[0]).format('YYYY-MM-DD')
		const dateAfter = moment(dateRange[1]).format('YYYY-MM-DD')
		window.open(`${URL_REPORT.DELIVERY_ORDER_REPORT_EXPORT}${exportType}/?date_before=${dateBefore}&date_after=${dateAfter}&type=${filterType}&storage=${filterStorage}`)
	}
	
	React.useEffect(() => {
		if (dateRange)
			fetchData();
	
	}, [targetAttach])

	return (
		<Container fluid style={{marginTop: 24}}>
			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group>
					<Form.Field
						label={"วันที่ตรวจรับ"}
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						error={dateRangeError}
						onChange={(_, data) => setDateRange(data.value)}/>

					<Form.Field 
						label={"ประเภทสินค้า"}
						control={Dropdown}
						selection
						selectOnBlur={false}
						placeholder={"เลือก..."}
						options={[
							{key: "ALL", value: "ALL", text: "ทั้งหมด"},
							...INVENTORY_TYPE_OPTIONS
						]}
						value={filterType}
						onChange={(_, {value}) => setFilterType(value)}/>
						
					<Form.Field 
						label={"เก็บรักษาโดย"}
						control={Dropdown}
						selection
						selectOnBlur={false}
						placeholder={"เลือก..."}
						options={[
							{key: "ALL", value: "ALL", text: "ทั้งหมด"},
							{key: "STO", value: "STO", text: "คลังสินค้า"},
							{key: "OTHERS", value: "OTHERS", text: "แผนก"},
						]}
						value={filterStorage}
						onChange={(_, {value}) => setFilterStorage(value)}/>
					
					<div style={{marginTop: "auto"}}>
						<Button primary onClick={() => fetchData()}>ค้นหา</Button>
					</div>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
				
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport("pdf")}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>

				<Button 
					style={{marginTop: 8}}
					color={"green"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport("excel")}>
						<Icon name={"file excel"}/>
						Excel
				</Button>
			</Segment>

			<UploadModal 
				open={targetAttach ? true : false}
				onClose={() => setTargetAttach(null)}
				onUpload={handleUploadDocument}/>
		</Container>
	)
}