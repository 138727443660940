import React from "react";
import { Container, Header, Grid, Button, Dimmer, Loader, Message, Table, Form, Input, Checkbox, Popup, List } from "semantic-ui-react";
import TitleBar from "../../components/common/TitleBar";
import { useParams, useHistory } from "react-router-dom";
import { GET, POST } from "../../frameworks/HttpClient";
import { dateToString } from "../../utils/Util";
import { PRODUCT_TYPE } from "../../constances/string";
import { Toast } from "../../frameworks/Toast";
import { formatComma } from "../../frameworks/Util";
import { useFormik } from "formik";
import * as Yup from 'yup';
import ConfirmModalWithNote from "../../components/common/ConfirmModalWithNote";
import { URL_STOCK, URL_ACCOUNTING } from "../../constances/urls";

const defaultPRDetail = {
	id: null,
	created: null,
	due_date: null,
	department: "",
	product: {
		id: null,
		name: "",
		type: "",
		unit: "",
		amount: 0,
		deposit_transactions: [],
	},
	require_amount: 0,
	amount: 0,
	create_note: "",
}


export default function PRDetailScreen() {
	const params = useParams();
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState(defaultPRDetail);
	const [isRejectOpen, setIsRejectOpen] = React.useState(false);

	// custom deposit transaction list form
	const [valueList, setValueList] = React.useState([]);
	const [errorList, setErrorList] = React.useState({});
	const [validateError, setValidateError] = React.useState(null);

	const [accountCodeOptions, setAccountCodeOptions] = React.useState([]);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			account_code: "",
			place_to_use: "",
			note: "",
		},
		validationSchema: Yup.object({
			account_code: Yup.string().required("กรุณาเลือก"),
			place_to_use: Yup.string().required("กรุณากรอกข้อมูล"),
			note: Yup.string(),
		}),
		//onSubmit: props.onSubmit,
	});

	const handleChange = (e, {name, value}) => {
		let newValueList = valueList;
		newValueList[name].amount = value
		setValueList([...newValueList]);
	}

	const validateForm = async () => {
		setErrorList({});
		setValidateError(null);
		const sum = valueList.reduce((p, c) => p + Number(c.amount), Number(0))
		if (!sum) {
			setValidateError("กรุณาใส่จำนวนที่ต้องการเบิก")
			return false;
		}
		let isValid = true;
		for(let [index, value] of valueList.entries()) {
			if (isNaN(value.amount)) {
				let newErrorList = errorList;
				newErrorList[index] = "กรุณาจำนวนเป็นตัวเลข"
				setErrorList({...newErrorList});
				isValid = false;
			}
			if (Number(data.product.deposit_transactions[index].amount) < value.amount) {
				let newErrorList = errorList;
				newErrorList[index] = "สินค้ามีจำนวนไม่พอ"
				setErrorList({...newErrorList});
				isValid = false;
			}
		}

		// Validate formik if needed to submit accountcodeoptions
		if (accountCodeOptions.length > 0) {
			const validation = await formik.validateForm();
			isValid = Object.keys(validation).length === 0;
		}

		return isValid;
	}

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_STOCK.WITHDRAW_TRANSACTION}${params.id}/`);
			setData({...defaultPRDetail, ...response.data});
			fetchMasterWithdrawRecord(response.data['department_id'], response.data['product']['id']);
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const fetchMasterWithdrawRecord = async (department, product) => {
		try {
			setAccountCodeOptions([]);
			const response = await GET(`${URL_ACCOUNTING.MASTER_WITHDRAW_RECORD}${department}/${product}/`);
			setAccountCodeOptions(
				response.data.map(item => {
					return {
						key: item.account_code,
						value: item.account_code,
						text: item.account_code_display,
						place_to_use: item.place_to_use,
					}
				})
			);

			// Only found 1 posibility it'll auto set value
			if (response.data.length === 1) {
				formik.setFieldValue('account_code', response.data[0].account_code)
				formik.setFieldValue("place_to_use", response.data[0].place_to_use)
				formik.setFieldValue("note", response.data[0].note)
			}
		} catch (error) {
			setAccountCodeOptions([]);
			console.log(error.errorMessages)
		}
	}

	const handleApprove = async () => {
		const isFormValid = await validateForm();
		if (!isFormValid) {
			return;
		}
		setLoading(true);
		try {
			const response = await POST(`${URL_STOCK.WITHDRAW_TRANSACTION_ACTION}${params.id}/to_state_already_withdraw/`, {
				deposit_transaction_list: valueList.filter(e => Number(e.amount) > 0),
				...(accountCodeOptions.length > 0 && formik.values)
			});
			Toast.success('สำเร็จ')
			history.goBack();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleReject = async (values) => {
		setLoading(true);
		try {
			const response = await POST(`${URL_STOCK.WITHDRAW_TRANSACTION_ACTION}${params.id}/to_state_reject/`, values);
			Toast.success('สำเร็จ')
			history.goBack();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (data.product.deposit_transactions.length) {
			setValueList(data.product.deposit_transactions.map(
				deposit_transaction => ({ id: deposit_transaction.id, amount: 0 })
			))
		}
	}, [data])

	React.useEffect(() => {
		formik.resetForm();
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={"จัดการคำขอเบิก"}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Header as={"h2"} textAlign={"center"}>รายละเอียด</Header>
				<Grid style={{marginTop: 16}}>
					<Grid.Column width={6} textAlign={"left"}>
						<p style={{fontSize: 15}}>วันที่: {dateToString(data.created)}</p>
					</Grid.Column>
					<Grid.Column width={4} textAlign={"center"}>
						<p style={{fontSize: 15}}>Due Date: {dateToString(data.due_date)}</p>
					</Grid.Column>
					<Grid.Column width={6} textAlign={"right"}>
						<p style={{fontSize: 15}}>แผนก: {data.department}</p>
					</Grid.Column>
				</Grid>

				{/* Table */}
				<Table celled textAlign={"center"}>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>รายการ</Table.HeaderCell>
							<Table.HeaderCell>ประเภท</Table.HeaderCell>
							<Table.HeaderCell>หน่วย</Table.HeaderCell>
							<Table.HeaderCell collapsing>จำนวนที่ต้องการ</Table.HeaderCell>
							<Table.HeaderCell>สินค้าคงคลัง</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						<Table.Row>
							<Table.Cell>{data.product.name}</Table.Cell>
							<Table.Cell>{PRODUCT_TYPE[data.product.type]}</Table.Cell>
							<Table.Cell>{data.product.unit}</Table.Cell>
							<Table.Cell>{formatComma(data.require_amount)}</Table.Cell>
							<Table.Cell>
							<Popup
								content={
									<div>
										<Header as="h5">{data.product.name}</Header>
										<List bulleted>
											{ data.product.deposit_transactions && data.product.deposit_transactions.map(deposit_transaction => (
												<List.Item>{deposit_transaction.lot_no}: {formatComma(deposit_transaction.amount)} {data.product.unit}</List.Item>
											)) }
										</List>
									</div>
								}
								trigger={<label>{formatComma(data.product.amount)}</label>}
								position="right center"/>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Form>
					<Form.Field 
						style={{width: "60%"}}
						label={"เหตุผล"}
						control={Input}
						placeholder={"หมายเหตุ..."}
						readOnly
						value={data.create_note}/>

					<Form.Field label={"เบิกจากคลังสินค้า"} error={validateError}/>
					<Table style={{width: "60%"}} celled textAlign={"center"} size={"small"}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Lot number</Table.HeaderCell>
								<Table.HeaderCell>วันหมดอายุ</Table.HeaderCell>
								<Table.HeaderCell>จำนวนคงคลัง</Table.HeaderCell>
								<Table.HeaderCell>เบิกออก</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{ valueList.map((value, index) => (
								<Table.Row>
									<Table.Cell>{data.product.deposit_transactions[index].lot_no}</Table.Cell>
									<Table.Cell>
										{data.product.deposit_transactions[index].lot_no_expire_date 
											? dateToString(data.product.deposit_transactions[index].lot_no_expire_date)
											: "-"}
									</Table.Cell>
									<Table.Cell>
										{(data && data.product) ? formatComma(data.product.deposit_transactions[index].amount) : "0"}
									</Table.Cell>
									<Table.Cell>
										<Form.Field
											control={Input}
											name={index}
											style={{width: "40%", textAlign: "center"}}
											value={value.amount}
											error={errorList[index]}
											onFocus={evt => evt.target.select()}
											onChange={handleChange}/>
									</Table.Cell>
								</Table.Row>
							)) }
						</Table.Body>
					</Table>

					{accountCodeOptions.length > 0 &&
						<Form.Group widths={"equal"}>
							<Form.Dropdown
								label={"รหัส Account Code"}
								selection
								placeholder={"เลือก..."}
								options={accountCodeOptions}
								name={"account_code"}
								error={formik.errors.account_code}
								value={formik.values.account_code}
								selectOnBlur={false}
								onChange={(_, data) => {
									formik.setFieldValue(data.name, data.value)
									formik.setFieldValue(
										"place_to_use",
										data.options.find(item => item.key === data.value).place_to_use
									)
								}} />
							<Form.Field
								label={"Place to use"}
								control={Input}
								name={"place_to_use"}
								error={formik.errors.place_to_use}
								value={formik.values.place_to_use}
								onChange={formik.handleChange} />
							<Form.Field
								label={"Note"}
								control={Input}
								name={"note"}
								error={formik.errors.note}
								value={formik.values.note}
								onChange={formik.handleChange} />
						</Form.Group>
					}

					{/* Button */}
					<div style={{textAlign: "center"}}>
						<Button negative loading={loading} onClick={() => setIsRejectOpen(true)}>
							ปฏิเสธ
						</Button>
						<Button primary loading={loading} onClick={() => handleApprove()}>
							ยืนยัน
						</Button>
					</div>
				</Form>

				<ConfirmModalWithNote		
					title={"ปฏิเสธการเบิก"}
					loading={loading}
					errorMessages={errorMessages}
					open={isRejectOpen}
					onClose={() => setIsRejectOpen(false)}
					onConfirm={(values) => handleReject({ memo: values.note })}/>
			</Container>
		</Container>
	)
}