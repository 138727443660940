import React from 'react';
import { Container, Button, Icon, Message, Dimmer, Loader, Label, Form } from 'semantic-ui-react';
import TitleBar from "../components/common/TitleBar";
import AdjustInPondModal from "../components/lab/AdjustInPondModal";
import ImageButton from "../components/common/ImageButton";
import LabNotify from "../components/lab/LabNotify";
import WeightInputWithCompareModal from "../components/tank/WeightInputWithCompareModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import edit from "../assets/img_edit.png";
import { useParams } from "react-router-dom";
import { GET, POST } from "../frameworks/HttpClient";
import useListItem from "../frameworks/hooks/useItemList";
import { URL_POND, URL_LAB, USER_GROUP } from "../constances";
import { dateTimeToString, formatComma } from "../frameworks/Util";
import Cookies from 'js-cookie';
import TSReactTableFixedColumns from '../frameworks/TSReactTableFixedColumns';

export default function LabPondScreen() {
	const params = useParams();
	const user = JSON.parse(Cookies.get("user"));
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [targetData, setTargetData] = React.useState(null);
	const [openLabResult, setOpenLabResult] = React.useState(false);
	const [openWeightInput, setOpenWeightInput] = React.useState(false);
	const [notify, setNotify] = React.useState(null);
	const [readOnly,  setReadOnly] = React.useState(false);
	// Table
	const [data, setData] = React.useState(null);
	const list = useListItem({url: `${URL_LAB.POND_LAB_RESULT}?pond_transaction=${data ? data.id : ""}`});
	const columns = React.useMemo(() => [
		{
			Header: "วันที่และเวลา",
			fixed: "left",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{
					Header: "Action",
					headerStyle: TABLE_HEADER_STYLE,
					width: 70,
					Cell: (row) => (
						<div>
						{(user.groups.find(group => group.name === USER_GROUP.LAB)) && (
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={(e, data) => handleEdit(row.original)}/>
						)}
						</div>
					),
				},
				{
					Header: "ตรวจ",
					Cell: (row) => {
						const date = dateTimeToString(row.original.created);
						const name = row.original.inspector_name;
						return (
							<div>
								<p style={{margin: 0}}>{`${date ? date : "-"}`}</p>
								<p style={{fontSize: 12}}>{name ? `(${name})` : ""}</p>
							</div>
						)
					}
				},
				{
					Header: "เติมสาร", 
					Cell: (row) => {
						const date = dateTimeToString(row.original.finish_datetime);
						const name = row.original.input_user_name;
						return (
							<div>
								<p style={{margin: 0}}>{`${date ? date : "-"}`}</p>
								<p style={{fontSize: 12}}>{name ? `(${name})` : ""}</p>
							</div>
						)
					}
				},
			], 140),
		},
		{
			Header: "ผลตรวจ",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ 
					Header: "%TSC", 
					Cell: (row) => columnItem(row.original.results, "tsc")
				},
				{ 
					Header: "%DRC", 
					Cell: (row) => columnItem(row.original.results, "drc")
				},
				{ 
					Header: "%NH3", 
					Cell: (row) => columnItem(row.original.results, "nh3")
				},
				{ 
					Header: "Mg", 
					Cell: (row) => columnItem(row.original.results, "mg")
				},
				{ 
					Header: "VFA", 
					Cell: (row) => columnItem(row.original.results, "vfa")
				},
			], 70)
		},
		{
			Header: "สารเคมีที่ต้องการเติมเพิ่ม",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ 
					Header: "น้ำ", 
					Cell: (row) => columnItem(row.original.lab_inputs, "water")
				},
				{ 
					Header: "La", 
					Cell: (row) => columnItem(row.original.lab_inputs, "la")
				},
				{ 
					Header: "DAP", 
					Cell: (row) => columnItem(row.original.lab_inputs, "dap")
				},
				{ 
					Header: "NH3", 
					Cell: (row) => columnItem(row.original.lab_inputs, "nh3")
				},
				{ 
					Header: "TZ", 
					Cell: (row) => columnItem(row.original.lab_inputs, "tz")
				},
				{ 
					Header: "น้ำล้าง", 
					Cell: (row) => columnItem(row.original.lab_inputs, "washer")
				},
				{ 
					Header: "อื่นๆ",
					width: 150,
					Cell: (row) => columnOthers(row.original.lab_inputs)
				},
			], 70)
		},
		{
			Header: "น้ำหนัก (Kg)",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{
					Header: "คำนวณ", 
					accessor: "calculate_weight",
					Cell: ({value}) => <p>{value ? parseFloat(value).toLocaleString("en-US") : "-"}</p>
				},
				{
					Header: "วัดได้", 
					accessor: "measure_weight",
					Cell: ({value}) => <p>{value ? parseFloat(value).toLocaleString("en-US") : "-"}</p>
				},
				{
					Header: "ส่วนต่าง", 
					accessor: "different_weight",
					Cell: ({value}) => <p>{value ? parseFloat(value).toLocaleString("en-US") : "-"}</p>
				},
			], 120),
		},
		...getColumnsList([
		{
			Header: "สถานะ",
			headerStyle: TABLE_HEADER_STYLE,
			width: 70,
			Cell: (row) => {
				const isFinished = row.original.is_finished;
				return (
					<div>
						<Icon size={"large"}
							name={isFinished ? "check circle" : "minus circle"} 
							color={isFinished ? "green" : "red"} />
					</div>
				)
			}
		}]),
	]);

	const columnItem = (list, keyName) => {
		let item = null;
		for (let it of list) {
			if (it.chemical === keyName) {
				item = it;
				break;
			}
		}
		
		return (<p>{item ? parseFloat(item.amount).toLocaleString("en-US") : "-"}</p>)
	}

	const columnOthers = (list) => {
		const except = ["water", "la", "dap", "nh3", "tz", "washer"];
		let result = "";
		for (let it of list) {
			let isOther = true;
			for (let ex of except) {
				if (it.chemical === ex) {
					isOther = false;
					break;
				}
			}
			
			if (isOther) {
				if (result) {
					result = `${result} \n${it.chemical} [${it.amount ? parseFloat(it.amount) : "-"}]`;
				} else {
					result = `${it.chemical} [${it.amount ? parseFloat(it.amount) : "-"}]`;
				}
			}
		}
		return (<p style={{whiteSpace: 'pre'}}>{result ? result : "-"}</p>)
	}

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_POND.POND_TRANSACTION}${params.id}/`);
			setData(response.data);
			setReadOnly((response.data.state === "END") ? true : false)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	/** Confirm Input */
	const handleInputSubmit = async (args) => {
		if (!notify || !notify.id) {
			return [null, "ไม่มีข้อมูล Lab ID"];
		}

		let response = null;
		let error = null;
		const data = {measure_weight: args.weight}
		try {
			response = await POST(`${URL_LAB.POND_LAB_RESULT}${notify.id}/confirm/`, data);
		} catch (e) {
			error = e.errorMessages;
		}
		return [response, error]
	}

	const handleEdit = (data) => setTargetData(data);

	const closeLabResult = () => {
		if (targetData) {
			setTargetData(null);
		} else {
			setOpenLabResult(false);
		}
	}

	React.useEffect(() => {
		setOpenLabResult(targetData ? true : false);
	}, [targetData]);
	
	React.useEffect(() => {
		const target = list.response.find(item => item.is_finished === false);
		setNotify(target);
	}, [list.response])

	React.useEffect(() => {
		if (data && !openLabResult && !openWeightInput) {
			/** load list */
			list.fetchData();
		}
	}, [data, openLabResult, openWeightInput]);

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={`แลป ${data ? `[ ${data.name} : ${data.state_name} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				{(user.groups.find(group => group.name === USER_GROUP.FACTORY_POND)) &&
					notify && (
					<LabNotify 
						title={"สารเคมีที่รอการเติมลงบ่อพัก"}
						items={notify ? notify.lab_inputs : []}
						onConfirm={() => setOpenWeightInput(true)}
					/>
				)}
				
				{(user.groups.find(group => group.name === USER_GROUP.LAB)) && (
					<Button 
						style={{marginBottom: 16}}
						color={"green"} 
						disabled={notify ? true : false}
						onClick={() => setOpenLabResult(true)}>
						<Icon name={"add"}/>เพิ่มผลตรวจ
					</Button>
				)}
				
				<Form>
					<Form.Group inline>
						<Form.Field width={3}>
							<label>น้ำหนักสะพานชั่ง</label>
							<Label color={"blue"}>{data ? formatComma(data.truck_transaction_weight) : ""}</Label>
						</Form.Field>
						<Form.Field width={3}>
							<label>น้ำหนักวัดเทียบ</label>
							<Label color={"blue"}>{data ? formatComma(data.measure_weight) : ""}</Label>
						</Form.Field>
					</Form.Group>
				</Form>
				
				<TSReactTableFixedColumns
					style={{marginTop: 16}}
					columns={columns}
					data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 5}
					showPagination={false}/>
			</Container>

			<AdjustInPondModal 
				open={openLabResult}
				data={targetData}
				currentWeight={data ? data.weight : 0}
				lastMeasureWeight={(list.response.length > 0) ? list.response[0].measure_weight : "0"}
				id={data ? data.id : null}
				disableLabInput={readOnly}
				onClose={() => closeLabResult()} />

			<WeightInputWithCompareModal 
				open={openWeightInput}
				title={"ยืนยันการเติมสารเคมี"}
				message1={"น้ำหนักที่ได้จากการคำนวณ"}
				targetWeight={notify ? parseFloat(notify.calculate_weight) : 0}
				message2={"กรุณากรอกน้ำหนักน้ำยางในบ่อพัก (kg)"}
				remarkEnable={false}
				onClose={() => setOpenWeightInput(false)}
				onSubmit={handleInputSubmit}/>
		</Container>
	)
}