import React from "react";
import propTypes from "prop-types";
import { Container, Dimmer, Message, Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { URL_PURCHASE } from "../../constances";
import ImageButton from "../../components/common/ImageButton";
import done from "../../assets/img_done.png"
import summary from "../../assets/img_summary.png"
import edit from "../../assets/img_edit.png"
import TSReactTable from "../../frameworks/TSReactTable";
import GoodsReceivedModal from "../../components/store/GoodsReceivedModal";
import { GET } from "../../frameworks/HttpClient";
import { formatComma } from "../../frameworks/Util";
import DOListModal from "./DOListModal";


export default function CashReceivedSegment(props) {
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [targetReceived, setTargetReceived] = React.useState(null);
	const [targetHistory, setTargetHistory] = React.useState(null);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ Header: "สินค้า", accessor: "product.name", minWidth: 260 },
			{ 
				Header: "จำนวนขอซื้อ", 
				accessor: "amount", 
				width: 120,
				Cell: ({ value }) => <p>{formatComma(value)}</p>,
			},
			{ 
				Header: "จำนวนที่รับมาแล้ว", 
				accessor: "total_received_amount", 
				width: 120,
				Cell: ({ value }) => <p>{formatComma(value)}</p>,
			},
			{ Header: "หน่วย", accessor: "unit", width: 100 },
			{ Header: "ผู้ขอซื้อ", accessor: "created_name", width: 100},
			{ Header: "แผนก", accessor: "department", width: 80},
			{ Header: "สถานะ", accessor: "state_name", width: 140 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 140,
			Cell: (row) => {
				let item = row.original;
				let isReceived = !(item.total_received_amount < parseFloat(item.amount))
				return (
					<div>
						<ImageButton 
								title={"แก้ไขจำนวน"}
								src={edit}
								color={"yellow"}
								onClick={(e, data) => {}}/>
						<ImageButton
							title={"ดูใบรับสินค้า"}
							src={summary}
							onClick={(e, data) => setTargetHistory(item)}/>
						<ImageButton 
							title={"รับสินค้า"}
							src={done}
							onClick={(e, data) => setTargetReceived(item)}/>
					</div>
				)
			}
		},
	]);

	const fetchData = async () => {
		setLoading(true);
		setErrorMessages(null);
		let params = {
			transaction_type: "cash",
			state: "WAIT_PRODUCT",
		}
		try {
			const response = await GET(URL_PURCHASE.PURCHASE_REQUEST, params);
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid style={props.style}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<TSReactTable
				columns={columns}
				data={data}
				defaultPageSize={10}/>

			<GoodsReceivedModal
				open={targetReceived ? true : false}
				data={targetReceived}
				onClose={() => {
					setTargetReceived(null)
					fetchData();
				}}/>
			
			<DOListModal
				purchaseRequest={targetHistory}
				open={targetHistory ? true : false}
				onClose={() => setTargetHistory(null)}/>
		</Container>
	)
}

CashReceivedSegment.defaultProps = {
	style: {},
}

CashReceivedSegment.propTypes = {
	style: propTypes.object,
}