import { POND_STATUS, TANK_STATUS, INVENTORY_TYPE, WITHDRAW_STATUS, PAYMENT_TYPE } from "./string";

export const POND_STATUS_OPTIONS = [
	{key: "ALL", value: "ALL", text: "ทั้งหมด"},
	{key: "REFILL", value: "REFILL", text: `${POND_STATUS.REFILL}/กำลังเติมน้ำยาง`},
	{key: "CLOSE", value: "CLOSE", text: POND_STATUS.CLOSE},
	{key: "PRODUCE", value: "PRODUCE", text: POND_STATUS.PRODUCE},
	{key: "CLEAN", value: "CLEAN", text: POND_STATUS.CLEAN},
	{key: "BURN", value: "BURN", text: POND_STATUS.BURN},
]

export const TANK_STATUS_OPTIONS = [
	{key: "ALL", value: "ALL", text: "ทั้งหมด"},
	{key: "EMPTY", value: "EMPTY", text: TANK_STATUS.EMPTY},
	{key: "REFILL", value: "REFILL", text: TANK_STATUS.REFILL},
	{key: "CLOSE", value: "CLOSE", text: TANK_STATUS.CLOSE},
	{key: "SELL", value: "SELL", text: TANK_STATUS.SELL},
	{key: "CLEAN", value: "CLEAN", text: TANK_STATUS.CLEAN},
	{key: "BURN", value: "BURN", text: TANK_STATUS.BURN},
]

export const INVENTORY_TYPE_OPTIONS = [
	{key: "chemical", value: "chemical", text: INVENTORY_TYPE.chemical},
	{key: "packaging", value: "packaging", text: INVENTORY_TYPE.packaging},
	{key: "spare_part", value: "spare_part", text: INVENTORY_TYPE.spare_part},
	{key: "ppe", value: "ppe", text: INVENTORY_TYPE.ppe},
	{key: "stationery", value: "stationery", text: INVENTORY_TYPE.stationery},
	{key: "others", value: "others", text: INVENTORY_TYPE.others},
	{key: "oil", value: "oil", text: INVENTORY_TYPE.oil},
]

export const STORE_DOC_OPTIONS = [
	{key: "quotation", value: "quotation", text: "ใบเสนอราคา"},
	{key: "set_price", value: "set_price", text: "กำหนดราคาซื้อ"},
]

export const WITHDRAW_STATUS_OPTIONS = [
	{key: "ALL", value: "ALL", text: "ทั้งหมด"},
	{key: "WAIT_CONFIRM", value: "WAIT_CONFIRM", text: WITHDRAW_STATUS.WAIT_CONFIRM},
	{key: "NOT_CONFIRM", value: "NOT_CONFIRM", text: WITHDRAW_STATUS.NOT_CONFIRM},
	{key: "WAIT_PROGRESS", value: "WAIT_PROGRESS", text: WITHDRAW_STATUS.WAIT_PROGRESS},
	{key: "REJECT", value: "REJECT", text: WITHDRAW_STATUS.REJECT},
	{key: "ALREADY_WITHDRAW", value: "ALREADY_WITHDRAW", text: WITHDRAW_STATUS.ALREADY_WITHDRAW},
	{key: "ALREADY_RECEIVE", value: "ALREADY_RECEIVE", text: WITHDRAW_STATUS.ALREADY_RECEIVE},
]

export const PAYMENT_TYPE_OPTIONS = [
	{key: "ALL", value: "ALL", text: "ทั้งหมด"},
	{key: "cash", value: "cash", text: PAYMENT_TYPE.cash},
	{key: "credit", value: "credit", text: PAYMENT_TYPE.credit},
	{key: "memo", value: "memo", text: PAYMENT_TYPE.memo},
]

export const LATEX_GRADE_OPTIONS = [
	{key: "OTHERS", value: "OTHERS", text: "ทั่วไป"},
	{key: "FSC", value: "FSC", text: "FSC"},
	{key: "EUDR", value: "EUDR", text: "EUDR"},
	{key: "FIELD_LATEX", value: "FIELD_LATEX", text: "FIELD_LATEX"},
	{key: "DC", value: "DC", text: "DC"},
	{key: "ALL", value: "ALL", text: "ทั้งหมด"},
]