import React from "react";
import { Container, Dimmer, Message, Button, Loader, Icon, Form } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { PAYMENT_TYPE, TRANSACTION_TYPE_WORKFLOW, URL_PURCHASE } from "../../constances";
import ImageButton from "../../components/common/ImageButton";
import remove from "../../assets/img_delete.png"
import edit from "../../assets/img_edit.png"
import summary from "../../assets/img_summary.png"
import summary_noti from "../../assets/img_summary_noti.png"
import { PATH } from "../../routes/Routes";
import TSReactTable from "../../frameworks/TSReactTable";
import DepartmentPurchaseModal from "../../components/store/DepartmentPurchaseModal";
import TransportModal from "../../components/store/TransportModal";
import { GET, POST } from "../../frameworks/HttpClient";
import { formatComma, dateToString } from "../../frameworks/Util";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import ConfirmModal from "../../components/common/ConfirmModal";
import DOListModal from "../../components/store/DOListModal";
import { PRODUCT_TYPE } from "../../constances/string";
import MultipleAttachQuotation from "../../components/store/MultipleAttachQuotation";


export default function PRManagerView() {
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [addOrder, setAddOrder] = React.useState(false);
	const [addTransport, setAddTransport] = React.useState(false);
	const [targetHistory, setTargetHistory] = React.useState(null);
	const [targetDelete, setTargetDelete] = React.useState(null);
	const [dateFilter, setDateFilter] = React.useState(moment().format("YYYY-MM-DD"));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: (row) => {
				let item = row.original;
				const isReceiveProduct = item.notify_receive_product;
				return (
					<div>
						{(item.state === "ATTACH_QUOTATION" || item.state === "NOT_APPROVED") && (
							<ImageButton 
								title={"ลบ"}
								src={remove}
								color={"red"}
								onClick={(e, data) => setTargetDelete(item)}/>						
						)}
						{(item.state === "ATTACH_QUOTATION" || item.state === "NOT_APPROVED") && (
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={(e, data) => handleEdit(row.original)}/>						
						)}
						{/* case: payment=cash, status=waiting_to_receive */}
						{(item.state === "WAIT_PRODUCT" || item.state === "RECEIVED" || item.state === "END") && (
							<ImageButton
								title={"ดูใบรับสินค้า"}
								src={isReceiveProduct ? summary_noti : summary}
								onClick={(e, data) => {
									setTargetHistory(row.original)
								}}/>
						)}
					</div>
				)
			}
		},
		...getColumnsList([
			{ Header: "สถานะ", accessor: "state_name", width: 120 },
			{ Header: "วันที่", 
				accessor: "created", 
				width: 90,
				Cell: ({ value }) => <p>{dateToString(value)}</p> 
			},
			{ 
				Header: "สินค้า", 
				accessor: "product.name", 
				minWidth: 260,
				Cell: ({original, value}) => 
					(<p>{original.ref_product ? `${value} (${original.ref_product.name})` : value}</p>)
			},
			{ 
				Header: "จำนวนขอซื้อ", 
				accessor: "amount", 
				width: 120,
				Cell: ({ value }) => <p>{formatComma(value)}</p>,
			},
			{ 
				Header: "จำนวนที่รับมาแล้ว", 
				accessor: "total_received_amount", 
				width: 120,
				Cell: ({ original, value }) => {
					let myValue = formatComma(value);
					let type = original.product.type
					if (PRODUCT_TYPE[type] === PRODUCT_TYPE.transport)
						myValue = '-'
					return (<p>{myValue}</p>)
				}
			},
			{ Header: "หน่วย", accessor: "unit", width: 100 },
			{ 
				Header: "ประเภท", 
				accessor: "transaction_type", 
				width: 80,
				Cell: ({value}) => (<p>{PAYMENT_TYPE[value]}</p>) 
			},
			{ Header: "ผู้ขอซื้อ", accessor: "created_name", width: 100},
			{ 
				Header: "ร้านค้า", 
				accessor: "selected_quotation", 
				width: 180,
				Cell: ({value}) => (<p>{(value && value.supplier) ? value.supplier.name : "-"}</p>)
			},
		]),
	]);

	const handleEdit = (data) => {
		// Go to summary
		history.push({pathname: `${PATH.PURCHASE_DETAIL}${data.id}`})
	}

	const handleDelete = async (item) => {
		setErrorMessages(null);
		setLoading(true);
		try {
			await POST(`${TRANSACTION_TYPE_WORKFLOW[item.transaction_type]}${item.object_id}/to_state_cancel/`);
			history.push(PATH.HOME);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const fetchData = async () => {
		// verify date
		if (!dateFilter || !moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
			if (!dateFilterError)
				setDateFilterError(true);
			return;
		}
			
		if (dateFilterError)
			setDateFilterError(false);
		setLoading(true);
		setErrorMessages(null)
		const params = {
			filter_by_department: true,
			date: dateFilter,
		}
		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_REQUEST}`, params);
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	React.useEffect(() => {
		if (!targetDelete && !addOrder && !targetHistory && !addTransport)
			fetchData();

	}, [targetDelete, addOrder, targetHistory, addTransport, dateFilter])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<Form>
				<Form.Group inline>
					<label>{"วันที่ขอซื้อ"}</label>
					<Form.Field
						control={DateInput}
						closable
						dateFormat="YYYY-MM-DD"
						maxDate={moment()}
						placeholder={"เลือก..."}
						error={dateFilterError}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>

					<Button
						color={"green"} 
						onClick={() => setAddOrder(true)}>
						<Icon name={"add"}/>
						สั่งซื้อ
					</Button>
					<Button
						color={"green"} 
						onClick={() => setAddTransport(true)}>
						<Icon name={"add"}/>
						ค่าขนส่ง
					</Button>

					<MultipleAttachQuotation onClose={() => fetchData()}/>
				</Form.Group>
			</Form>

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}
				getTrProps={(state, rowInfo, column, instance) => {
					if (rowInfo) {
						const isReceiveProduct = rowInfo.original.notify_receive_product;
						if (isReceiveProduct) {
							return {
								style: { background: "#F5B7B1"},
							}
						}
						else 
							return {}
					} 
					else 
						return {}
				}}/>
			
			<DepartmentPurchaseModal 
				open={addOrder}
				onClose={() => setAddOrder(false)}/>
			
			<TransportModal 
				open={addTransport}
				onClose={() => setAddTransport(false)}/>

			<DOListModal 
				purchaseRequest={targetHistory}
				open={targetHistory ? true : false}
				onClose={() => setTargetHistory(null)}
				readOnly={false}/>

			<ConfirmModal 
				open={targetDelete ? true : false}
				title={`ลบรายการขอซื้อ`}
				detail={`คุณต้องการลบรายการขอซื้อ [${targetDelete ? targetDelete.product.name : ""}] ใช่หรือไม่ ?`}
				onClose={() => setTargetDelete(null)}
				onCancel={() => setTargetDelete(null)}
				onConfirm={async () => {
					await handleDelete(targetDelete);
					setTargetDelete(null);
				}}/>
		</Container>
	)
}
