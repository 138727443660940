import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { PATH } from '../../routes/Routes';


export default function ReportMenu({menus}) {
  const history = useHistory();

  return (
    <>
      { menus.map((menu) => (
        <Segment raised>
          <Header>
            <Icon name={menu.icon}/>
            {menu.text}
          </Header>
          <Grid columns='3' stackable>
            {menu.subMenus.map(subMenu => (
              <Grid.Column>
                <Button
                  size='small'
                  fluid
                  color={menu.color}
                  content={subMenu.text}
                  onClick={() => {
                    history.replace(PATH.REPORT + subMenu.path)
                    window.scroll(0, 0)
                  }}/>
              </Grid.Column>
            ))}
          </Grid>
        </Segment>
      )) }
    </>
  )
}

