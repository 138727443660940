import React from "react";
import ACGroupContainer from "../../../components/reports/ACGroupContainer";
import POListModal from "../../../components/reports/POListModal";
import { URL_REPORT } from "../../../constances/urls";

export default function ACGroupByCreditReportView() {
	const [targetCode, setTargetCode] = React.useState(null);

	const gotoExport = (exportType, params) => {
		let myParams = `transaction_type=${params.transactionType}&date_after=${params.dateAfter}&date_before=${params.dateBefore}`
		window.open(`${URL_REPORT.AC_GROUP_REPORT_EXPORT}${exportType}/?${myParams}`);
	}

	const handleAction = (data, params) => {
		setTargetCode({
			account_code: data.account_code,
			account_name: data.account_name,
			params: `transaction_type=${params.transactionType}&account_code=${data.account_code}&date_before=${params.dateBefore}&date_after=${params.dateAfter}`
		})
	}

	return (
		<div>
			<ACGroupContainer 
				transactionType={"credit"}
				onActionClick={handleAction}
				onExportPDFClick={(params) => gotoExport("pdf", params)}
				optionsType={[
					{key: "credit", value: "credit", text: "เครดิต"},
					{key: "memo", value: "memo", text: "memo"}
				]}/>
			
			<POListModal 
				open={targetCode ? true : false}
				onClose={() => setTargetCode(null)}
				data={targetCode}/>
		</div>
		
	)
}