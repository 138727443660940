import React from 'react';
import propTypes from "prop-types";
import LabAuth from './LabAuth';
import ConfirmModal from '../common/ConfirmModal';
import { Message } from 'semantic-ui-react';
import LabControlResult from './LabControlResult';
import LabControlInput from './LabControlInput';
import { URL_LAB } from '../../constances';
import { PATCH, POST } from '../../frameworks/HttpClient';

export default function ControlInTankModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Initial Data
	const [initialResult, setInitialResult] = React.useState(null);
	const [initialInput, setInitialInput] = React.useState(null);
	const [initialInputOthers, setInitialInputOthers] = React.useState([]);
	// Ref
	const resultRef = React.useRef(null);
	const inputRef = React.useRef(null);
	const authRef = React.useRef(null);

	const handleSubmit = async () => {
		// Verify
		const authFormik = authRef.current.formik;
		const resultFormik = resultRef.current.formik;
		const inputFormik = inputRef.current.formik;
		const authValidation = await authFormik.validateForm();
		const resultValidation = await resultFormik.validateForm();
		const inputValidation = await inputFormik.validateForm();
		const isAuthValid = Object.keys(authValidation).length === 0;
		const isResultValid = Object.keys(resultValidation).length === 0;
		const isInputValid = Object.keys(inputValidation).length === 0;
		if (!isResultValid || !isAuthValid || !isInputValid) return;

		// Prepare Data
		setLoading(true);
		const isCreate = props.data ? false : true;
		let user = authRef.current.getUser();
		let data = { inspector:  user.id }
		if (isCreate)
			data = {...data, tank_transaction: props.transaction}

		const resultValue = resultFormik.values;
		let results = []
		for (let key in resultValue) {
			let amount = resultValue[key];
			results.push({
				chemical: key,
				amount: (amount ? parseFloat(amount) : 0),
			})
		}

		const inputValue = inputFormik.values;
		let inputs = []
		for (let key in inputValue) {
			let it = inputValue[key];
			if ((key === "tz") || (key === "la")) {
				inputs.push({
					chemical: key,
					amount: (it.amount ? parseFloat(it.amount) : 0),
					percent: (it.percent ? parseFloat(it.percent) : 0),
					batch: (it.batch ? it.batch : ""),
				})
			} else {
				inputs.push({
					chemical: key,
					amount: (it ? parseFloat(it) : 0),
				})
			}
		}
		const inputOthers = inputRef.current.getOthers();
		for (let other of inputOthers) {
			inputs.push(other);
		}
		
		data = { 
			...data,
			results: results, 
			lab_inputs: inputs 
		}

		// Create lab order
		let response = null
		try {
			if (isCreate) { // Create
				response = await POST(URL_LAB.CLOSED_TANK_LAB_RESULT, data);
			} else { // Update
				response = await PATCH(`${URL_LAB.CLOSED_TANK_LAB_RESULT}${props.data.id}/`, data);
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (props.data) {
			/** Lab results */
			let resultData = {};
			for (let it of props.data.results) {
				resultData = {...resultData, [it.chemical]: parseFloat(it.amount)}
			}
			setInitialResult(resultData);

			/** Lab inputs */
			const inputExcept =  ["water", "la", "dap", "nh3", "tz"];
			let inputData = {};
			let otherList = [];
			for (let it of props.data.lab_inputs) {
				let isOther = true;
				for (let ex of inputExcept) {
					if (it.chemical === ex) {
						isOther = false;
						break;
					}
				}
				let amount = parseFloat(it.amount);
				if (isOther) {
					otherList.push({id: (otherList.length + 1), chemical: it.chemical, amount: amount});
				} else if ((it.chemical === "tz") || (it.chemical === "la")) {
					inputData = {
						...inputData, 
						[it.chemical]: {
							amount: parseFloat(it.amount),
							percent: parseFloat(it.percent),
							batch: it.batch
						}}
				} else {
					inputData = {...inputData, [it.chemical]: amount}
				}
			}
			setInitialInput(inputData);
			setInitialInputOthers(otherList);
		} else {
			setInitialResult(null);
			setInitialInput(null);
			setInitialInputOthers([]);
		}
	}, [props.data])

	React.useEffect(() => {
		if (!props.open)
			setErrorMessages(null);
	}, [props.open])

	return (
		<ConfirmModal
			open={props.open}
			title={props.data ? "แก้ไขผลตรวจ" : "เพิ่มผลตรวจ"}
			size={null}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={props.data ? "แก้ไข" : "ยืนยัน"}
			positiveColor={props.data ? "yellow" : "green"}
			onClose={props.onClose}
			onConfirm={handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}
					{/* Lab Result */}
					<LabControlResult 
						ref={resultRef}
						initialModel={initialResult} />

					{/* Lab Input */}
					<LabControlInput
						style={{marginTop: 24}}
						readOnly={props.data ? true : false}
						ref={inputRef}
						initialModel={initialInput}
						initialOthersModal={initialInputOthers} />

					{/* Authentication */}
					<div style={{width: "68%"}}>
						<LabAuth 
							style={{marginTop: 24}}
							ref={authRef}/>
					</div>
				</div>
			}
		/>
	)
}

ControlInTankModal.defaultProps = {
	open: false, 
	transaction: null,
	data: null,
	onClose: () => {},
}

ControlInTankModal.propTypes = {
	open: propTypes.bool,
	transaction: propTypes.string,
	data: propTypes.object,
	onClose: propTypes.func,
}