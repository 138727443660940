import React from "react";
import propTypes from "prop-types";
import { Header, Form, Input } from "semantic-ui-react";
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const LabControlResult = React.forwardRef((props, ref) => {
	const gradeOptions = useDropdownItem({url: `/api/common/grade/`})

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
			tsc: "", drc: "", non_rubber: "", nh3: "", vfa: "", koh: "", 
			mst: "", mg: "", coagulum: "", micro_coagulum: "", viscosity: "", 
			sludge: "", filterability: "", la: "", water_dilution: "",
		},
    validationSchema: Yup.object().shape({
			tsc: Yup.number().notRequired(),
			drc: Yup.number().notRequired(),
			non_rubber: Yup.number().notRequired(),
			nh3: Yup.number().notRequired(),
			vfa: Yup.number().notRequired(),
			koh: Yup.number().notRequired(),
			mst: Yup.number().notRequired(),
			mg: Yup.number().notRequired(),
			coagulum: Yup.number().notRequired(),
			micro_coagulum: Yup.number().notRequired(),
			viscosity: Yup.number().notRequired(),
			sludge: Yup.number().notRequired(),
			filterability: Yup.number().notRequired(),
			la: Yup.number().notRequired(),
			water_dilution: Yup.number().notRequired(),
		}),
    onSubmit: props.onSubmit,
  });

	React.useImperativeHandle(ref, () => ({ formik }));

	React.useEffect(() => {
		formik.resetForm();
		gradeOptions.fetchData(); 
		if (props.initialModel) {
			formik.setValues(props.initialModel);
		}
	}, []);

	return (
		<div style={{...props.style}}>
			<Header as={"h3"} style={{marginLeft: 24}}>ผลตรวจ</Header>
			<Form style={{textAlign: "right"}}>
				<Form.Group widths={"3"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%TSC"}
						control={Input}
						placeholder={"0"}
						name={"tsc"}
						error={formik.errors.tsc}
						value={formik.values.tsc}
						onChange={(_, data) => {
							formik.setFieldValue(data.name, data.value);
							let non = (data.value - formik.values.drc);
							formik.setFieldValue("non_rubber", isNaN(non) ? 0 : parseFloat(non).toFixed(2));
						}}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%DRC"}
						control={Input}
						placeholder={"0"}
						name={"drc"}
						error={formik.errors.drc}
						value={formik.values.drc}
						onChange={(_, data) => {
							formik.setFieldValue(data.name, data.value);
							let non = (formik.values.tsc - data.value);
							formik.setFieldValue("non_rubber", isNaN(non) ? 0 : parseFloat(non).toFixed(2));
						}}
					/>
					
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"NON Rubber"}
						control={Input}
						placeholder={"0"}
						name={"non_rubber"}
						error={formik.errors.non_rubber}
						value={formik.values.non_rubber}
						onChange={formik.handleChange}
					/>
				</Form.Group>
				<Form.Group widths={"3"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%NH3"}
						control={Input}
						placeholder={"0"}
						name={"nh3"}
						error={formik.errors.nh3}
						value={formik.values.nh3}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"VFA"}
						control={Input}
						placeholder={"0"}
						name={"vfa"}
						error={formik.errors.vfa}
						value={formik.values.vfa}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"KOH"}
						control={Input}
						placeholder={"0"}
						name={"koh"}
						error={formik.errors.koh}
						value={formik.values.koh}
						onChange={formik.handleChange}
					/>
				</Form.Group>
				<Form.Group widths={"3"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"MST"}
						control={Input}
						placeholder={"0"}
						name={"mst"}
						error={formik.errors.mst}
						value={formik.values.mst}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"Mg"}
						control={Input}
						placeholder={"0"}
						name={"mg"}
						error={formik.errors.mg}
						value={formik.values.mg}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%Coagulum"}
						control={Input}
						placeholder={"0"}
						name={"coagulum"}
						error={formik.errors.coagulum}
						value={formik.values.coagulum}
						onChange={formik.handleChange}
					/>
				</Form.Group>
				<Form.Group widths={"3"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"Micro Coagulum"}
						control={Input}
						placeholder={"0"}
						name={"micro_coagulum"}
						error={formik.errors.micro_coagulum}
						value={formik.values.micro_coagulum}
						onChange={formik.handleChange}
					/>
					
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"Viscosity"}
						control={Input}
						placeholder={"0"}
						name={"viscosity"}
						error={formik.errors.viscosity}
						value={formik.values.viscosity}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"Sludge"}
						control={Input}
						placeholder={"0"}
						name={"sludge"}
						error={formik.errors.sludge}
						value={formik.values.sludge}
						onChange={formik.handleChange}
					/>
				</Form.Group>
				<Form.Group widths={"3"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"Filterability"}
						control={Input}
						placeholder={"0"}
						name={"filterability"}
						error={formik.errors.filterability}
						value={formik.values.filterability}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%Lauric"}
						control={Input}
						placeholder={"0"}
						name={"la"}
						error={formik.errors.la}
						value={formik.values.la}
						onChange={formik.handleChange}
					/>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%Water Dilution"}
						control={Input}
						placeholder={"0"}
						name={"water_dilution"}
						error={formik.errors.water_dilution}
						value={formik.values.water_dilution}
						onChange={formik.handleChange}
					/>
				</Form.Group>
			</Form>
		</div>
	)
});

LabControlResult.defaultProps = {
	style: null,
	initialModel: null,
}

LabControlResult.propTypes = {
	style: propTypes.object,
	initialModel: propTypes.object,
}

export default LabControlResult;