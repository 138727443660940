import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import { Checkbox, Form, Icon, Input, Message, Popup, TextArea } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import { DateInput } from "semantic-ui-calendar-react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_ACCOUNTING, URL_PURCHASE } from "../../constances";
import { Toast } from "../../frameworks/Toast";
import CustomFileField from "../common/CustomFileField";
import { getColumnsList } from "../../utils/Util";
import { formatComma, toFixed } from "../../frameworks/Util";
import TSReactTable from "../../frameworks/TSReactTable";
import { PURCHASE_VAT_TYPE } from "../../constances/string";

export default function PODocumentsModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [doList, setDoList] = React.useState([]);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			purchase_order: props.data ? props.data.id : null,
			date: moment().format("YYYY-MM-DD"),
			tax_invoice_no: "",
			tax_invoice: null,
			net_price: "",
			note: "",
			delivery_orders: [],
		},
		validationSchema: Yup.object().shape({
			purchase_order: Yup.string().required("เลือกรายการ PO ผิดพลาด"),
			date: Yup.string().required("กรุณากรอกข้อมูล"),
			tax_invoice_no: Yup.string().required("กรุณากรอกข้อมูล"),
			tax_invoice: Yup.string().notRequired().nullable(true),
			net_price: Yup.number().required("กรุณากรอกข้อมูล"),
			note: Yup.string().notRequired().nullable(true),
			delivery_orders: (props.data 
				&& ((props.data.transaction_type === "memo") 
				|| (props.data.transaction_type === "credit"))) 
				? Yup.array().of(Yup.string()).min(0, "")
				: Yup.array().of(Yup.string()).min(1, "กรุณาเลือกใบรับสินค้าอย่างน้อย 1 ใบ"),
    }),
		onSubmit: values => handleSubmit(values),
	});

	const DOColumns = React.useMemo(() => [
		...getColumnsList([ 
			{ 
				Header: "", 
				width: 50,
				Cell: (row) => {
					let id = row.original.id;
					return (
						<Checkbox
							style={{margin: "auto"}} 
							disabled={props.data ? false : true}
							checked={formik.values.delivery_orders.find(item => id === item) ? true : false}
							onChange={(_, data) => {
								calculatePrice(data.checked, row.original)
							}}/>
					)
				}
			},
			{ 
				Header: "วันที่", 
				accessor: "created", 
				width: 90,
				Cell: ({ value }) => new Date(value).toLocaleDateString("en-GB") 
			},
			{
				Header: "สินค้า",
				accessor: "purchase_request.product.name"
			},
			{ 
				Header: "รับเข้าสุทธิ", 
				accessor: "into_stock_amount", 
				width: 110,
				Cell: ({ original, value }) => {
					let unit = original.quotation ? original.quotation.purchase_request.product.unit : ""
					return <p>{`${formatComma(value)} ${unit}`}</p>
				}
			},
			{ Header: "หมายเหตุการรับสินค้า", accessor: "note" },
			{ 
				Header: "อนุมัติ", 
				accessor: "is_approved",
				width: 60,
				Cell: ({ original, value }) => 
					value != undefined && value != null ?  
						value ? 
							<div style={{textAlign: "center"}}><Icon color='green' name="check"/></div> 
							: <div style={{textAlign: "center"}}>
								<Popup
									trigger={<Icon color='red' name="cancel"/>}
									content={original.reject_note}
									inverted
									basic/>
								</div> 
						: <div style={{textAlign: "center"}}><Icon color='grey' name="clock"/></div> 
			},
			{ 
				Header: "รับสินค้า", 
				accessor: "is_received",
				width: 60,
				Cell: ({ original, value }) => 
					value != undefined && value != null ?  
						value ? 
							<div style={{textAlign: "center"}}><Icon color='green' name="check"/></div> 
							: <div style={{textAlign: "center"}}>
								<Popup
									trigger={<Icon color='red' name="cancel"/>}
									content={original.reject_note}
									inverted
									basic/>
								</div> 
						: <div style={{textAlign: "center"}}><Icon color='grey' name="clock"/></div> 
			},
		]) 
	]);

	const calculatePrice = (checked, item) => {
		if (!item)
			return;
		
		const id = item.id;
		let deliveryOrdersID = formik.values.delivery_orders;
		if (checked) {
			deliveryOrdersID = [...deliveryOrdersID, id];
		} else {
			let index = deliveryOrdersID.indexOf(id)
			deliveryOrdersID.splice(index, 1)
		}
		formik.setFieldValue("delivery_orders", deliveryOrdersID)

		// Calculate net price
		let netPrice = 0
		for (let order of deliveryOrdersID) {
			let found = doList.find(element => element.id === order);
			if (found && found.purchase_request) {
				netPrice += parseFloat(found.purchase_request.selected_quotation.unit_price) * parseFloat(found.received_amount);
			}
		}
		// Calculate vat
		if (props.data && (props.data.vat_type === PURCHASE_VAT_TYPE.EXCLUDE_VAT))
			netPrice += ((netPrice * 7) / 100);
		formik.setFieldValue("net_price", toFixed(parseFloat(netPrice), 2));
	}

	const fetchDeliveryOrder = async () => {
		setLoading(true);
		let params = { 
			purchase_order: props.data ? props.data.id : "",
			has_tax_invoice: false,
			is_approved: true,
		}
		try {
			const response = await GET(URL_PURCHASE.DELIVERY_ORDER, params);
			setDoList(response.data)
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleSubmit = async (values) => {
		setLoading(true);
		if (!values['tax_invoice']) {
			// if values is null remove values key to avoid parsing error on backend
			delete values['tax_invoice']
		}
		try {
			await POST(URL_ACCOUNTING.PURCHASE_TAX_INVOICE, values, true);
			props.onClose();
		} catch (e) {
			setErrorMessages(e.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
			fetchDeliveryOrder();
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={`เอกสารการเงิน`}
			size={null}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}
					{formik.errors.delivery_orders && (
						<Message error content={formik.errors.delivery_orders}/>
					)}
					<Form textAlign={"center"}>
						<Form.Group widths={"equal"}>
							<Form.Input
								label={"เลขที่ PO"}
								selection
								readOnly={true}
								name={"product"}
								error={formik.errors.purchase_order}
								value={props.data ? props.data.code : ""}/>
								
							<Form.Field 
								label={"เลขที่ใบกำกับภาษี"}
								control={Input}
								placeholder={"xxxxx..."}
								name={"tax_invoice_no"}
								error={formik.errors.tax_invoice_no}
								value={formik.values.tax_invoice_no}
								onChange={formik.handleChange}/>
						</Form.Group>
						
						<CustomFileField
							label={"ใบกำกับภาษี"}
							error={formik.errors.tax_invoice}
							value={formik.values.tax_invoice ? formik.values.tax_invoice.name : ''}
							onChange={(value) => formik.setFieldValue('tax_invoice', value)}/>
						
						<Form.Group widths={"equal"}>
							<Form.Field
								label={"วันที่รับสินค้า"}
								control={DateInput}
								dateFormat="YYYY-MM-DD"
								maxDate={moment()}
								placeholder={"เลือกวันที่..."}
								name={"date"}
								error={formik.errors.date}
								value={formik.values.date}
								onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>

							<Form.Field 
								label={"จำนวนเงิน"}
								control={Input}
								placeholder={"0"}
								name={"net_price"}
								error={formik.errors.net_price}
								value={formik.values.net_price}
								onChange={formik.handleChange}/>
						</Form.Group>
						
						<Form.Field error={formik.errors.delivery_orders}>
							<TSReactTable
								columns={DOColumns}
								data={doList}
								pageSize={(doList && (doList.length > 0)) ? doList.length : 3}
								showPagination={false}/>
						</Form.Field>
						
						<Form.Field
								label={"หมายเหตุ"}
								control={TextArea}
								placeholder={"หมายเหตุ..."}
								name={"note"}
								error={formik.errors.note}
								value={formik.values.note}
								onChange={formik.handleChange}/>

					</Form>
				</div>
			}/>
	)
}

PODocumentsModal.defaultProps = {
	data: null,
	open: false,
	onClose: () => {},
}

PODocumentsModal.propTypes = {
	data: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}