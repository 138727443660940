import React from 'react';
import { Button, Container, Dimmer, Form, Header, Icon, Item, Loader, Message, Search, Segment } from 'semantic-ui-react';
import { URL_REPORT, URL_STOCK } from '../../constances/urls';
import useListItem from '../../frameworks/hooks/useItemList';
import { MonthInput, YearInput } from 'semantic-ui-calendar-react';
import { MONTH_STRING_TH } from '../../constances';
import moment from "moment";
import TSReactTable from '../../frameworks/TSReactTable';
import { getColumnsList } from '../../utils/Util';
import { GET } from '../../frameworks/HttpClient';
import { formatComma } from '../../frameworks/Util';


export default function CRSTO07ReportView() {

  const productList = useListItem({ url: URL_STOCK.PRODUCT });

  const [monthFilter, setMonthFilter] = React.useState(moment().format("MM"));
	const [yearFilter, setYearFilter] = React.useState(moment().format("YYYY"));
  const [productName, setProductName] = React.useState("");
  const [product, setProduct] = React.useState(null);
  const [data, setData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(false);

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: "วันที่", accessor: "date", width: 60 },
      { Header: "รายการ", accessor: "detail", width: 200, style: {textAlign: "left"}},
      
      { Header: "เลขที่ใบสำคัญ", accessor: "receipt_no", width: 120 },
      { 
        Header: "รับ",
        accessor: "received",
        columns: [
          { 
            Header: () => <>หน่วย<div>{product ? product.unit : ""}</div></>, 
            accessor: "received_amount", 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: "@", 
            accessor: "received_price_per_unit", 
            width: 60, 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: `จำนวนเงิน`, 
            accessor: "received_total_price", 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value) : null 
          },
        ] 
      },
      { 
        Header: "จ่าย",
        accessor: "paid",
        columns: [
          { 
            Header: () => <>หน่วย<div>{product ? product.unit : ""}</div></>, 
            accessor: "paid_amount",
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: "@", 
            accessor: "paid_price_per_unit", 
            width: 60, 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: `จำนวนเงิน`, 
            accessor: "paid_total_price", 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value) : null 
          },
        ] 
      },
      { 
        Header: "คงเหลือ",
        accessor: "remain",
        columns: [
          { 
            Header: () => <>หน่วย<div>{product ? product.unit : ""}</div></>, 
            accessor: "remain_amount", 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value, true) : null 
          },
          { 
            Header: "@", 
            accessor: "remain_price_per_unit", 
            width: 60, 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value) : null 
          },
          { 
            Header: `จำนวนเงิน`, 
            accessor: "remain_total_price", 
            style: {textAlign: "right"},
            Cell: ({value}) => value ? formatComma(value, true) : null 
          },
        ] 
      },
      { Header: "หมายเหตุ", accessor: "remark", width: 180, style: {textAlign: "left"}},
    ])
  ])

  const fetchData = async () => {
    if (!product) {
      return;
    }
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(
        URL_REPORT.CR_STO_07_REPORT, 
        {product: product.id, month: monthFilter, year: yearFilter}
      );
      setData(response.data);
    } catch(error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleExport = (exportType) => {
    if (!product) {
      return;
    }
    window.open(`${URL_REPORT.CR_STO_07_REPORT_EXPORT}${exportType}/?product=${product.id}&month=${monthFilter}&year=${yearFilter}`);
  }

  return (
    <Container fluid style={{marginTop: 24}}>
      { errorMessages && <Message error>{errorMessages}</Message> }

      <Form>
        <Form.Group inline>
          <label>ชื่อสินค้า</label>
          <Form.Field
            minCharacters={2}
            control={Search}
            results={productList.response.map(e => ({ ...e, key: e.id, value: e.id, title: e.name }) )}
            showNoResults={true}
            onSearchChange={(_, data) => {
              if(product) {
                setProduct(null);
                setProductName("")
              }
              if(data.value.length > 2) {
                productList.fetchData({ control_stock: true, name: data.value })
              }
              setProductName(data.value)
            }}
            onResultSelect={(_, data) => {
              setProduct(data.result);
              setProductName(data.result.name);
            }}
            value={productName}
            loading={productList.isLoading}
          />
					<label>{"เดือน"}</label>
          {/* Warning: Only available current year */}
          <Form.Field
            control={MonthInput}
            dateFormat="MM"
            locale="en-US"
            placeholder={"เลือก..."}
            name={"month"}
            value={MONTH_STRING_TH[monthFilter]}
            onChange={(_, data) => setMonthFilter(data.value)}
          />
          <label>{"ค.ศ."}</label>
          <Form.Field
            control={YearInput}
            dateFormat="YYYY"
            locale="en-US"
            maxDate={moment()}
            placeholder={"เลือก..."}
            name={"year"}
            value={yearFilter}
            onChange={(_, data) => setYearFilter(data.value)}
          />
          <Button primary onClick={() => fetchData()}>ค้นหา</Button>
        </Form.Group>
      </Form>

      <Segment basic style={{padding: 0}}>
				<Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
				
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => handleExport("pdf")}
					>
						<Icon name={"file pdf"}/>
						PDF
				</Button>

				<Button 
					style={{marginTop: 8}}
					color={"green"}
					floated={"right"}
					size={"mini"}
					onClick={() => handleExport("excel")}
					>
						<Icon name={"file excel"}/>
						Excel
				</Button>
			</Segment>
    </Container>
  );
}