import React from 'react';
import ReactTable from "react-table-v6";
import propTypes from "prop-types";


export default function PaginationTable(props) {
  const {
    data,
    columns,
    onPageChange,
    style,
  } = props;

  const [page, setPage] = React.useState(0);

  return (
    <ReactTable
      manual
      noDataText="ไม่มีข้อมูล"
      data={data.results}
      columns={columns}
      showPagination={true}
      defaultPageSize={10}
      showPageSizeOptions={false}
      className="-striped -highlight"
      getTdProps={() => ({
        style: { whiteSpace: "unset", textAlign: "center" }
      })}
      page={page}
      pages={data.pages}
      style={{borderRadius: 5, ...style}}
      onPageChange={(pageIndex) => {
        setPage(pageIndex);
        onPageChange(pageIndex+1);
      }}
    />
  );
}

PaginationTable.defaultProps = {
  data: {
    links: {
      next: null,
      previous: null,
    },
    total: 0,
    pages: 0,
    results: [],
  },
  columns: [],
  onPageChange: () => {},
  style: {},
}

PaginationTable.propTypes = {
  data: propTypes.object,
  columns: propTypes.array,
  onPageChange: propTypes.func,
  style: propTypes.object,
}