import React from 'react';
import { Button, Container, Form, Header, Message } from 'semantic-ui-react';
import { MonthInput, YearInput } from 'semantic-ui-calendar-react';
import { MONTH_STRING_TH } from '../../constances';
import moment from 'moment';
import { URL_REPORT } from '../../constances/urls';


export default function MaterialReportView() {
  const [monthFilter, setMonthFilter] = React.useState(moment().format("MM"));
	const [yearFilter, setYearFilter] = React.useState(moment().format("YYYY"));

  const exportReport = () => {
    window.open(`${URL_REPORT.MATERIAL_REPORT}?year=${yearFilter}&month=${monthFilter}`)
  }

  return (
		<Container fluid style={{marginTop: 24}}>
      <Form>
        <Form.Group inline>
					<label>{"เดือน"}</label>
          {/* Warning: Only available current year */}
          <Form.Field
            control={MonthInput}
            dateFormat="MM"
            locale="en-US"
            placeholder={"เลือก..."}
            name={"month"}
            value={MONTH_STRING_TH[monthFilter]}
            onChange={(_, data) => setMonthFilter(data.value)}
          />
          <label>{"ค.ศ."}</label>
          <Form.Field
            control={YearInput}
            dateFormat="YYYY"
            locale="en-US"
            maxDate={moment()}
            placeholder={"เลือก..."}
            name={"year"}
            value={yearFilter}
            onChange={(_, data) => setYearFilter(data.value)}
          />
          <Button color="blue" icon="file outline alternate" content="เรียกดู" onClick={() => exportReport()} />
        </Form.Group>
      </Form>
    </Container>
  )
}