export const URL_AUTH = {
  API_TOKEN_AUTH: "/api/users/api-token-auth/",
  CHANGE_PASSWORD: "/api/users/change-password/",
  SIGN_OUT: "/api/users/sign-out/",
}

export const URL_COMMON = {
  CHEMICAL: "/api/common/chemical/",
  GRADE: "/api/common/grade/",
  DEPARTMENT: "/api/common/department/",
}

export const URL_TRUCK = {
  TRUCK_TRANSACTION: "/api/truck/truck-transaction/",
  TRUCK_PRODUCT: "/api/truck/truck-product/",
}

export const URL_POND = {
  POND :"/api/pond/pond/",
  POND_TRANSACTION :"/api/pond/pond-transaction/",
  POND_TRUCK_TRANSACTION: "/api/pond/pond-truck-transaction/",
  POND_LAB: "/api/pond/pond-lab/",
  POND_STORE: "/api/pond/pond-store/",
  POND_SPLIT: "/api/pond/split-pond/",
  POND_READY_TO_PRODUCT: "/api/pond/pond-ready-to-produce/",
}

export const URL_LAB = {
  TRUCK_TRANSACTION_QUALITY_CHECK: "/api/lab/truck-transaction-quality-check/",
  POND_LAB_RESULT: "/api/lab/pond-lab-result/",
  REFILLED_TANK_LAB_RESULT: "/api/lab/refilled-tank-lab-result/", // Tank Adjust
  CLOSED_TANK_LAB_RESULT: "/api/lab/closed-tank-lab-result/", // Tank Control
  TANK_WEIGHT_FACTORY_CHECK: "/api/lab/tank-weight-factory-check/",
}

export const URL_TANK = {
  TANK: "/api/tank/tank/",
  TANK_TRANSACTION: "/api/tank/tank-transaction/",
  TANK_POND_TRANSACTION: "/api/tank/tank-pond-transaction/",
  TANK_LAB_REFILLED: "/api/tank/tank-lab-refilled/",
  TANK_LAB_CLOSED: "/api/tank/tank-lab-closed/",
  TANK_WEIGHT_STORE_CHECK: "/api/tank/tank-weight-store-check/",
  TANK_WEIGHT_LOG_LIST: "/api/tank/tank-weight-log-list/",
  TANK_TRANSACTION_TRANSFER: "/api/tank/tank-transaction-transfer/",
}

export const URL_STOCK = {
  PRODUCT: "/api/stock/product/",
  PRODUCT_PAGINATION: "/api/stock/product-pagination/",
  PRODUCT_UNIT: "/api/stock/product-unit/",
  PRODUCT_UPDATE_LOG: "/api/stock/product-update-log/",
  WITHDRAW_TRANSACTION: "/api/stock/withdraw-transaction/",
  WITHDRAW_TRANSACTION_ACTION: "/api/stock/withdraw-transaction-action/",
  WITHDRAW_TRANSACTION_STORE: "/api/stock/withdraw-transaction-store/",
  DEPOSIT_TRANSACTION: "/api/stock/deposit-transaction/",
  DEPOSIT_TRANSACTION_EMPTY_AMOUNT: "/api/stock/deposit-transaction-empty-amount/",
  STOCK_RECORD: "/api/stock/record/",
  STOCK_COUNT_PRODUCT: "/api/stock/product-count/",
}

export const URL_ACCOUNTING = {
  PURCHASE_RECORD: "/api/accounting/purchase-record/",
  ACCOUNT_CHART: "/api/accounting/account-chart/",
  PURCHASE_TAX_INVOICE: "/api/accounting/purchase-tax-invoice/",
  MASTER_WITHDRAW_RECORD: "/api/accounting/master-withdraw-record/",
}

export const URL_PURCHASE = {
  PURCHASE_ORDER: "/api/purchase/purchase-order/",
  PURCHASE_ORDER_PAYMENT_CREDIT_LIST: "/api/purchase/purchase-order-payment-credit-list/",
  PURCHASE_ORDER_PAYMENT_MEMO_LIST: "/api/purchase/purchase-order-payment-memo-list/",
  PURCHASE_REQUEST: "/api/purchase/purchase-request/",
  QUOTATION: "/api/purchase/quotation/",
  QUOTATION_WITH_PO: "/api/purchase/quotation-with-po/",
  SUPPLIER: "/api/purchase/supplier/",
  DELIVERY_ORDER: "/api/purchase/delivery-order/",
  DELIVERY_ORDER_LAST_LOT_NO: "/api/purchase/delivery-order-last-lot-no/",
  DELIVERY_INSPECT: "/api/purchase/delivery-inspect/",
  DELIVERY_INSPECT_TEMPLATE: "/api/purchase/delivery-inspect-template/",
  MASTER_PRODUCT_DELIVERY_INSPECT: "/api/purchase/master-product-delivery-inspect/",
  PURCHASE_CONTACT_PERSON: "/api/purchase/purchase-contact-person/",
  ORDER_CASH_TRANSACTION: "/api/purchase/order-cash-transaction-action/",
  ORDER_CREDIT_TRANSACTION: "/api/purchase/order-credit-transaction-action/",
  ORDER_MEMO_TRANSACTION: "/api/purchase/order-memo-transaction-action/",
}

export const URL_SALE = {
  PACKING : "/api/sale/packing/",
  RAW_LATEX_SELL_TRANSACTION: "/api/sale/raw-latex-sell-transaction/",
  CONCENTRATED_LATEX_SELL_TRANSACTION: "/api/sale/concentrated-latex-sell-transaction/",
  LOAD_CONCENTRATED_LATEX_SELL_TRANSACTION: "/api/sale/load-concentrated-latex-sell-transaction/",
}

export const TRANSACTION_TYPE_WORKFLOW = {
  cash: URL_PURCHASE.ORDER_CASH_TRANSACTION,
  credit: URL_PURCHASE.ORDER_CREDIT_TRANSACTION,
  memo: URL_PURCHASE.ORDER_MEMO_TRANSACTION,
}

export const URL_REPORT = {
  TRUCK_TRANSACTION_QUALITY_CHECK_REPORT: "/api/report/truck-transaction-quality-check-report/",
  TRUCK_TRANSACTION_QUALITY_CHECK_REPORT_EXPORT: "/api/report/truck-transaction-quality-check-report-export/",
  RAW_LATEX_SUMMARY_REPORT: "/api/report/raw-latex-summary-report/",
  RAW_LATEX_SUMMARY_REPORT_EXPORT: "/api/report/raw-latex-summary-report-export/",
  POND_STATISTIC_REPORT: {
    DAILY: "/api/report/pond-statistic-summary-by-date-report/",
    MONTHLY: "/api/report/pond-statistic-summary-by-month-report/",
    YEARLY: "/api/report/pond-statistic-summary-by-year-report/"
  },
  POND_STATISTIC_REPORT_EXPORT: {
    DAILY: "/api/report/pond-statistic-summary-by-date-report-export/",
    MONTHLY: "/api/report/pond-statistic-summary-by-month-report-export/",
    YEARLY: "/api/report/pond-statistic-summary-by-year-report-export/"
  },
  LAB_RECEPTION_SUMMARY_REPORT: "/api/report/lab-reception-summary-report/",
  LAB_RECEPTION_SUMMARY_REPORT_EXPORT: "/api/report/lab-reception-summary-report-export/",
  FIELD_LATEX_SELL_REPORT: "/api/report/field-latex-sell-report/",
  FIELD_LATEX_SELL_REPORT_EXPORT: "/api/report/field-latex-sell-report-export/",
  CR_STO_06_REPORT: "/api/report/cr-sto-06-report/",
  CR_STO_06_REPORT_EXPORT: "/api/report/cr-sto-06-report-export/",
  CR_STO_04_REPORT_EXPORT: "/api/report/cr-sto-04-report-export/",
  CR_STO_03_REPORT: "/api/report/cr-sto-03-report/",
  CR_STO_03_REPORT_EXPORT: "/api/report/cr-sto-03-report-export/",
  CR_STO_07_REPORT: "/api/report/cr-sto-07-report/",
  CR_STO_07_REPORT_EXPORT: "/api/report/cr-sto-07-report-export/",
  EXPENSE_MONTHLY_REPORT: "/api/report/expense-monthly-report/",
  EXPENSE_MONTHLY_REPORT_EXPORT: "/api/report/expense-monthly-report-export/",
  PURCHASE_ORDER_REPORT: "/api/report/purchase-order-report/",
  REPORT_VERIFY_LOG: "/api/report/report-verify-log/",
  AC_GROUP_REPORT: "/api/report/ac-group-report/",
  AC_GROUP_REPORT_EXPORT: "/api/report/ac-group-report-export/",
  PURCHASE_REQUEST_BY_PETTY_CASH: "/api/report/purchase-request-by-petty-cash/",
  PURCHASE_ORDER_BY_ACCOUNTING: "/api/report/purchase-order-by-accounting/",
  SPARE_PART_REPORT: "/api/report/spare-part-report/",
  SPARE_PART_REPORT_EXPORT: "/api/report/spare-part-report-export/",
  DELIVERY_ORDER_REPORT: "/api/report/delivery-order-report/",
  DELIVERY_ORDER_REPORT_EXPORT: "/api/report/delivery-order-report-export/",
  GENERATE_CHEMICAL_RECEIPT_REPORT: "/api/report/generate-chemical-receipt-report/",
  MATERIAL_REPORT: "/api/report/material-report/",
  // Tank
  LOT_NO_REPORT: "/api/report/lot-no-report/",
  LOT_NO_REPORT_EXPORT: "/api/report/lot-no-report-export/",
  LOT_NO_EUDR_REPORT_EXPORT: "/api/report/lot-no-report-eudr-export/",
  ST_WEEKLY_REPORT: "/api/report/st-weekly-report/",
  ST_WEEKLY_REPORT_EXPORT: "/api/report/st-weekly-report-export/",
  ST_WEEKLY_STATISTIC_REPORT: "/api/report/st-weekly-statistic-report/", 
  ST_WEEKLY_STATISTIC_REPORT_EXPORT: "/api/report/st-weekly-statistic-report-export/",
  CONCENTRATED_LATEX_SELL_REPORT: "/api/report/concentrated-latex-sell-report/",
  CONCENTRATED_LATEX_SELL_REPORT_EXPORT: "/api/report/concentrated-latex-sell-report-export/",
  CONCENTRATED_LATEX_SUMMARY_REPORT: "/api/report/concentrated-latex-summary-report/",
  CONCENTRATED_LATEX_SUMMARY_REPORT_EXPORT: "/api/report/concentrated-latex-summary-report-export/"
}

export const URL_NOTIFICATION = {
  MENU_BADGES: "/api/notification/menu-badges/",
}