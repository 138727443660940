import React from 'react';
import { Container, Button, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import TitleBar from "../components/common/TitleBar";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import AddConcentLatexModal from "../components/tank/AddConcentLatexModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import edit from "../assets/img_edit.png";
import iconDelete from "../assets/img_delete.png";
import { useParams } from "react-router-dom";
import { URL_TANK } from "../constances";
import { GET, DELETE } from "../frameworks/HttpClient";
import useListItem from "../frameworks/hooks/useItemList";
import { dateTimeToString, formatComma } from "../frameworks/Util";
import ConfirmModal from "../components/common/ConfirmModal";

export default function TankDetailScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Modal
	const [openEdit, setOpenEdit] = React.useState(false);
	const [targetData, setTargetData] = React.useState(null);
	const [openDelete, setOpenDelete] = React.useState(false);
	const [deleteData, setDeleteData] = React.useState(null);
	// Table
	const [data, setData] = React.useState(null);
	const [canEdit, setCanEdit] = React.useState(false);
	const [fieldTotal, setFieldTotal] = React.useState(0);
	const list = useListItem({url: `${URL_TANK.TANK_POND_TRANSACTION}?tank_transaction=${params.id}`});

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลา", 
				accessor: "created", 
				width: 140,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>),
				Footer: "รวม", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "บ่อพักน้ำยาง", accessor: "pond_name" },
			{ 
				Header: "น้ำยางสด (kg)", 
				accessor: "raw_latex_weight",
				Cell: ({value}) => (<p>{value ? formatComma(value) : "-"}</p>),
				Footer: formatComma(fieldTotal),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "วันที่-เวลา เริ่มผลิต", 
				accessor: "start_date",
				width: 160,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
			},
			{ 
				Header: "วันที่-เวลา สิ้นสุดการผลิต", 
				accessor: "finish_date",
				width: 160,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: ({ original }) => {
				return (
					<div>
						<ImageButton 
							title={"แก้ไข"}
							disabled={!canEdit}
							src={edit}
							onClick={(e, data) => handleEdit(original)}/>
						<ImageButton 
							title={"ลบ"}
							disabled={!canEdit}
							src={iconDelete}
							color={"red"}
							onClick={(e, data) => handleDelete(original)}/>
					</div>
				)
			},
		},
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_TANK.TANK_TRANSACTION}${params.id}/`);
			setData(response.data);
			setCanEdit((response.data.state === "REFILL") ? true : false);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleEdit = (data) => {
		setTargetData(data);
	}

	/** DELETE */
	const handleDelete = (data) => setDeleteData(data)

	const handleConfirmDelete = async (id) => {
		if (!id) return;

		try {
			const response = await DELETE(`${URL_TANK.TANK_POND_TRANSACTION}${id}/`);
		} catch (error) {
			console.log("Error: ", error.errorMessages);
		} finally {
			setDeleteData(null);
		}
	}

	const closeEditModal = () => {
		if (targetData) {
			setTargetData(null)
		} else {
			setOpenEdit(false);
		}
	}

	React.useEffect(() => {
		setOpenEdit(targetData ? true : false);
	}, [targetData])

	React.useEffect(() => {
		setOpenDelete(deleteData ? true : false);
	}, [deleteData])

	React.useEffect(() => {
		/** Calculate total */
		let fieldSum = list.response.reduce((prev, current) => prev + parseFloat(current.raw_latex_weight), 0);
		setFieldTotal(fieldSum);
	}, [list.response])

	React.useEffect(() => {
		if (data && !openEdit && !openDelete) {
			/** load list */
			list.fetchData();
		}
	}, [data, openEdit, openDelete]);

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={`รายละเอียด ${data ? `[ ${data.lot_no} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Button color={"green"} onClick={() => setOpenEdit(true)}>
					<Icon name={"add"}/>เติมน้ำยาง
				</Button>
				<TSReactTable 
					style={{marginTop: 16}}
					columns={columns}
					data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 5}
					showPagination={false}/>
			</Container>

			<AddConcentLatexModal 
				open={openEdit}
				id={data ? data.id : null}
				initialModel={targetData}
				onClose={() => closeEditModal()}/>
			
			<ConfirmModal 
				open={openDelete}
				title={`ลบรายการเติมน้ำยาง`}
				iconTitle={"trash"}
				detail={"คุณต้องการลบรายการเติมน้ำยาง [ " + (deleteData ? deleteData.pond_name : "")
					+ " น้ำยางสด " + (deleteData ? formatComma(deleteData.raw_latex_weight) : "0")
					+ " (Kg) ]ใช่หรือไม่ ?"}
				onClose={() => setDeleteData(null)}
				onCancel={() => setDeleteData(null)}
				onConfirm={async () => {
					handleConfirmDelete(deleteData ? deleteData.id : null);
				}}/>
		</Container>
	)
}