import React, { useMemo } from 'react';
import moment from "moment";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message } from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import TSReactTable from "../../../frameworks/TSReactTable";
import { 
	TABLE_HEADER_STYLE,
	getColumnsList,
	dateToString,
} from "../../../utils/Util";
import { URL_REPORT } from "../../../constances/urls";
import { GET } from '../../../frameworks/HttpClient';
import { formatComma } from "../../../frameworks/Util";

export default function SellConcentLatexReportView() {
	const [dateRange, setDateRange] = React.useState([]);
	// Table
	const [data, setData] = React.useState([]);
	const [total, setTotal] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columns = useMemo(() => [
		...getColumnsList([
			{ 
				Header: "ลำดับที่", 
				accessor: "order", 
				width: 60,
			},
			{ 
				Header: "วันที่", 
				accessor: "date", 
				width: 110,
				Footer: "TOTAL", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "Contract No.", accessor: "contact", width: 120 },
			{ Header: "Advice No.", accessor: "advice", width: 80 },
			{ Header: "Buyer", accessor: "buyer", minWidth: 280 },
			{ Header: "เบอร์ตู้/ทะเบียนรถ", accessor: "car_registration", width: 140 },
			{ Header: "เลขที่ D/O", accessor: "do_no", width: 100 },
			{ 
				Header: "น้ำหนักขาย (kg)", 
				accessor: "weight", 
				width: 120,
				Cell: ({value}) => (<p>{value ? formatComma(parseFloat(value)) : "-"}</p>),
				Footer: parseFloat(total).toLocaleString("en-US"),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "เกรด", accessor: "grade", width: 70},
			{ Header: "Lot No.", accessor: "lot_no", width: 110 },
			{ Header: "Packing", accessor: "packing", width: 140 },
			{ Header: "Container", accessor: "container_number", width: 140 },
			{ Header: "Seal", accessor: "seal_number", width: 120 },
			{ Header: "Lump", accessor: "lump_number", width: 70 },
			{ Header: "Filter", accessor: "filter_number", width: 70 },
		]),
	])

	const fetchData = async () => {
		setLoading(true)
		setErrorMessages(null)
		const params = {
			date_before: moment(dateRange[0]).format('YYYY-MM-DD'),
			date_after: moment(dateRange[1]).format('YYYY-MM-DD')
		}
		try {
			const response = await GET(URL_REPORT.CONCENTRATED_LATEX_SELL_REPORT, params);
			setData(response.data);
			setTotal(response.data.reduce((a, b) => a + Number(b.weight), Number(0)));
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Container fluid style={{marginTop: 24}}>
			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group inline>
					<Form.Field
						style={{width: "500px"}}
						inline
						label={"วันที่"}
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						onChange={(_, data) => setDateRange(data.value)}/>

					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
					
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => window.open(
						`${URL_REPORT.CONCENTRATED_LATEX_SELL_REPORT_EXPORT}pdf/?date_before=${moment(dateRange[0]).format('YYYY-MM-DD')}&date_after=${moment(dateRange[1]).format('YYYY-MM-DD')}`
					)}
					>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}