import React from 'react';
import { Button, Container, Dimmer, Dropdown, Form, Icon, Loader, Message, Popup } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import PaginationTable from '../components/common/PaginationTable';
import { dateToString, getColumnsList } from '../utils/Util';
import useListItem from '../frameworks/hooks/useItemList';
import ImageButton from '../components/common/ImageButton';
import summary from '../assets/img_summary.png';
import trash from "../assets/img_delete.png";
import { useHistory } from 'react-router';
import { URL_STOCK } from '../constances/urls';
import ConfirmModal from '../components/common/ConfirmModal';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Toast } from '../frameworks/Toast';
import { DELETE, POST } from '../frameworks/HttpClient';
import { ellipsisString } from '../frameworks/Util';
import { PATH } from '../routes/Routes';


export default function StockCountView() {
	const history = useHistory();
	const stockRecordList = useListItem({ url: URL_STOCK.STOCK_RECORD, usePagination: true })
	// filter state
	const [page, setPage] = React.useState(1);
	const [dateRange, setDateRange] = React.useState([moment()._d, moment()._d]);
	const statusOptions = [
		{ id: 'ALL', value: 'ALL', text: 'ทั้งหมด' },
		{ id: 'PROGRESS', value: 'PROGRESS', text: 'กำลังดำเนินการ' },
		{ id: 'COMPLETE', value: 'COMPLETE', text: 'นับเสร็จสิ้น' },
	];
	const [statusFilter, setStatusFilter] = React.useState('ALL');
	
	// create state
	const [isCreateModalVisible, setIsCreateModalVisible] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [note, setNote] = React.useState(null);

	// Delete data
	const [targetDelete, setTargetDelete] = React.useState(null);
	const [isActionLoading, setIsActionLoading] = React.useState(false);
  const [actionErrorMessages, setActionErrorMessages] = React.useState(null);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ Header: 'วันที่', accessor: 'created', width: 180, Cell: ({ value }) => dateToString(value) },
			{ Header: 'ผู้เริ่มการตรวจ', accessor: 'created_name' },
			{ Header: 'สถานะ', accessor: 'is_finished', width: 120, Cell: ({ value }) => (
				<Popup
					content={value ? 'เสร็จสิ้น' : 'กำลังดำเนินการ'}
					position='top center'
					trigger={
						<div style={{textAlign: 'center'}}>
							<Icon color={value ? 'green' : 'grey'} name={value ? 'check' : 'clock'}/>
						</div>
					}/>
			) },
			{ Header: 'หมายเหตุ', accessor: 'note', Cell: ({ value }) => value ? (
				<Popup
					content={value}
					position='top center'
					trigger={<div>{value ? ellipsisString(value, 40) : '-'}</div>}/>
			) : '-' },
			{ Header: 'Action', accessor: 'id', width: 100, Cell: ({ original, value }) => (
				<div>
					<ImageButton
						title='ดูรายละเอียด'
						src={summary}
						color='blue'
						onClick={() => history.push(`${PATH.STOCK_RECORD}${value}`)}/>
					{!original.is_finished && (
						<ImageButton
							title='ลบ'
							src={trash}
							color={"red"}
							onClick={() => setTargetDelete(original)}/>
					)}
				</div>
			) },
		])
	])

	const refetchList = () => {
		if (!dateRange) {
			return;
		}
		
		stockRecordList.fetchData({
			page: page,
			date_after: dateRange ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
			date_before: dateRange ? moment(dateRange[1]).format('YYYY-MM-DD') : null,
			status: statusFilter,
		});
	}

	const handleAddSession = async () => {
		setIsLoading(true);
		setErrorMessages(null);
		try {
			const response = await POST(URL_STOCK.STOCK_RECORD, { note });
			Toast.success('สำเร็จ');
			setIsCreateModalVisible(false);
			refetchList();
		} catch (error){
			setErrorMessages(false);
		} finally {
			setIsLoading(false);
		}
	}

	const handleDeleteSession = async () => {
    if (!targetDelete || !targetDelete.id) {
      setActionErrorMessages('ไม่พบ ID ของรายการตรวจนับ Stock')
      return;
    }

    setIsActionLoading(true);
		setActionErrorMessages(null);
		try {
			const response = await DELETE(`${URL_STOCK.STOCK_RECORD}${targetDelete.id}`);
			setTargetDelete(null);
			refetchList();
    } catch (error){
			setActionErrorMessages(error.errorMessages);
		} finally {
			setIsActionLoading(false);
		}
  }

	React.useEffect(() => {
		refetchList();
	}, [page, statusFilter])
	
	React.useEffect(() => {
		if (!dateRange || dateRange.length > 1) {
			refetchList();
		}
	}, [dateRange])

	return (
		<Container fluid style={{marginTop: 24}}>

			<Dimmer inverted active={stockRecordList.isLoading}>
				<Loader content='Loading'/>
			</Dimmer>

			{stockRecordList.errorMessages && (
				<Message 
					negative
					header='เกิดข้อผิดพลาด'
					content={stockRecordList.errorMessages}/>
			)}

			<Form>
				<Form.Group inline>
					<Form.Field
						label='วันที่'
						control={SemanticDatepicker}
						locale='en-US'
          	type='range'
						showToday={true}
						format='DD/MM/YY'
						placeholder='เลือกช่วงเวลา...'
						value={dateRange}
						onChange={(_, data) => setDateRange(data.value)}/>
					<Form.Field
						label='สถานะ'
						control={Dropdown}
						selection
						name='status'
						placeholder='เลือก...'
						options={statusOptions}
						value={statusFilter}
						selectOnBlur={false}
						onChange={(_, data) => setStatusFilter(data.value)}/>
					<Button 
						positive 
						icon='add' 
						content='เริ่มการตรวจนับ Stock' 
						onClick={() => setIsCreateModalVisible(true)}/>
				</Form.Group>
			</Form>

			<PaginationTable
				columns={columns}
				data={stockRecordList.response}
				onPageChange={(page) => setPage(page)}/>

			{/* create modal */}
			<ConfirmModal
				open={isCreateModalVisible}
				title='เริ่มการตรวจนับ Stock'
				detail={(
					<div>
						<Form>
							<Form.Group inline widths='equal'>
								<Form.Input
									inline
									label='ผู้เริ่มการตรวจ'
									value={JSON.parse(Cookies.get('user')) ? JSON.parse(Cookies.get('user')).display_name : null}/>
								<Form.Input
									inline
									label='วันที่'
									value={dateToString(moment())}/>
							</Form.Group>
							<Form.TextArea
								label='หมายเหตุ'
								value={note}
								onChange={(_, data) => setNote(data.value)}/>
						</Form>
					</div>
				)}
				loading={isLoading}
				errorMessages={errorMessages}
				onClose={() => {
					setIsCreateModalVisible(false);
					refetchList();
				}}
				onConfirm={() => handleAddSession()}
				onCancel={() => {
					setIsCreateModalVisible(false);
					refetchList();
				}}
			/>
			
			{/* Delete modal */}
			<ConfirmModal
				open={targetDelete ? true : false}
				title='ยืนยันการลบรายการนับ Stock'
				detail={`คุณต้องการลบรายการนับ Stock [วันที่ : ${targetDelete ? dateToString(targetDelete.created) : ""}] ใช่หรือไม่ ?`}
				loading={isActionLoading}
				errorMessages={actionErrorMessages}
				onConfirm={handleDeleteSession}
				onClose={() => setTargetDelete(null)}
				onCancel={() => setTargetDelete(null)}
			/>
			{/* <ConfirmModal
				open={targetDelete ? true : false}
				title='ยืนยันการลบรายการนับสินค้า'
				detail={
					<Message warning>
						<Message.List>
							<Message.Item>สินค้า: {targetDelete ? targetDelete.product_name : ""}</Message.Item>
							<Message.Item>จำนวนในสต๊อก: {targetDelete ? targetDelete.stock_amount : ""}</Message.Item>
							<Message.Item>จำนวนที่นับจริง: {targetDelete ? targetDelete.counting_amount : ""}</Message.Item>
							<Message.Item>หมายเหตุ: {targetDelete ? targetDelete.note : ""}</Message.Item>
						</Message.List>
					</Message>
        }
				loading={isActionLoading}
				errorMessages={actionErrorMessages}
				onConfirm={handleDeleteSession}
				onClose={() => setTargetDelete(null)}
				onCancel={() => setTargetDelete(null)}
			/> */}
		</Container>
	)
}
