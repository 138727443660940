import React from 'react';
import { Container, Button, Icon, Label, Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import TitleBar from "../../components/common/TitleBar";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import SaleConcentratedLatexModal from "../../components/store/sale/SaleConcentratedLatexModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import edit from "../../assets/img_edit.png";
import { dateTimeToString, formatComma } from '../../frameworks/Util';
import { useParams } from 'react-router-dom';
import { GET, PATCH } from '../../frameworks/HttpClient';
import { URL_SALE } from '../../constances';
import useListItem from '../../frameworks/hooks/useItemList';
import ConfirmModal from '../../components/common/ConfirmModal';
import { LATEX_ORDER_TYPE } from '../../constances/string';

const ItemField = ({label, value}) => {
	return (
		<Form.Field inline>
			<label>{label}</label>
			<Label color={"grey"}>{value}</Label>
		</Form.Field>
	)
}

export default function SaleConcentratedLatexScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState(null);

	const [openSale, setOpenSale] = React.useState(false);
	const [targetData, setTargetData] = React.useState(null);
	const [changeToFinish, setChangeToFinish] = React.useState(false);
	// Table
	const list = useListItem({url: `${URL_SALE.LOAD_CONCENTRATED_LATEX_SELL_TRANSACTION}?concentrated_latex_sell_transaction=${params.id}`});
	const [total, setTotal] = React.useState(0);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลาโหลด", 
				accessor: "created", 
				width: 140,
				Cell: ({value}) => (<p>{value ? dateTimeToString(value) : "-"}</p>),
				Footer: "รวม", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "เบอร์ตู้", 
				accessor: "container_number", 
				Cell: ({value}) => (<p>{value ? value : "-"}</p>) 
			},
			{ Header: "หมายเลขซีล", accessor: "seal_number" },
			{ Header: "Lump", accessor: "lump_number" },
			{ Header: "หมายเลข Filter", accessor: "filter_number" },
			{ Header: "ทะเบียนรถ", accessor: "car_registration", width: 180 },
			{ 
				Header: "เลขที่ DO", 
				accessor: "do_no",
				Cell: ({value}) => (<p>{value ? value : "-"}</p>) 
			},
			{ 
				Header: "นน.โหลด (kg)", 
				accessor: "weight", 
				width: 120,
				Cell: ({value}) => (<p>{value ? formatComma(value) : "0"}</p>),
				Footer: formatComma(total),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}), 
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			show: data ? !data.is_finished : true,
			width: 60,
			Cell: ({original}) => (
				<ImageButton 
					title={"แก้ไข"}
					src={edit}
					onClick={() => handleEdit(original)}/>
			),
		},
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_SALE.CONCENTRATED_LATEX_SELL_TRANSACTION}${params.id}/`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	/** Change To Finish */
	const handleChangeFinish = async () => {
		setLoading(true);
		let response = null;
		try {
			response = await PATCH(
				`${URL_SALE.CONCENTRATED_LATEX_SELL_TRANSACTION}${params.id}/`, 
				{ is_finished: true} 
			);	
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			setChangeToFinish(false);
	}

	const handleEdit = (data) => {
		setTargetData(data)
	}

	const closeSaleModal = () => {
		if (targetData) 
			setTargetData(null);
		else
			setOpenSale(false);
	}

	React.useEffect(() => {
		setOpenSale(targetData ? true : false);
	}, [targetData]);

	React.useEffect(() => {
		/** Calculate total */
		let sum = list.response.reduce((prev, current) => prev + parseFloat(current.weight), 0);
		setTotal(sum);
	}, [list.response])

	React.useEffect(() => {
		if (!openSale) {
			/** Load list */
			list.fetchData();
		}
	}, [openSale])

	React.useEffect(() => {
		fetchData();
	}, [changeToFinish])

	return (
		<Container fluid>
			<TitleBar title={`ขายน้ำยางข้น ${data ? `[ ${data.lot_no} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Form>
					<Form.Group>
						{data && data.contact && (
							<ItemField label={"Contact: "} value={data.contact}/>
						)}
						{data && data.advice && (
							<ItemField label={"Advice: "} value={data.advice}/>
						)}
						{data && data.buyer && (
							<ItemField label={"Buyer: "} value={data.buyer}/>
						)}
						{data && data.packing && (
							<ItemField label={"Packing: "} value={data.packing}/>
						)}
						<ItemField 
							label={"ประเภทคำสั่ง: "} 
							value={(data && data.type) ? LATEX_ORDER_TYPE[data.type] : "-"}/>
						<ItemField 
							label={"นน.สั่งขาย (Kg): "} 
							value={data ? formatComma(data.weight) : "0"}/>
					</Form.Group>
				</Form>

				<div>
					<Button 
						color={"green"} 
						disabled={data ? data.is_finished : false}
						onClick={() => setOpenSale(true)}>
						<Icon name={"add"}/>คำสั่งโหลด
					</Button>

					<Button 
						primary 
						floated={"right"}
						disabled={data ? data.is_finished : false}
						onClick={() => setChangeToFinish(true)}>
						<Icon name={"stop circle"}/>จบการขาย
					</Button>
				</div>

				<TSReactTable 
					style={{marginTop: 16}}
					columns={columns}
					data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 5}
					showPagination={false}/>
			</Container>

			<SaleConcentratedLatexModal
				open={openSale}
				sellTransaction={data ? data.id : ""}
				data={targetData}
				onClose={() => closeSaleModal()}
			/>

			{/* Change to finish */}
			<ConfirmModal 
				open={changeToFinish}
				title={`จบการขาย`}
				detail={"คุณต้องการจบการขายสำหรับการสั่งซื้อนี้ใช่หรือไม่ ?"}
				onClose={() => setChangeToFinish(false)}
				onCancel={() => setChangeToFinish(false)}
				onConfirm={handleChangeFinish}/>
		</Container>
	)
}