import React from 'react';
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Search, Label, Grid } from 'semantic-ui-react';
import TSReactTable from "../../../frameworks/TSReactTable";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../../utils/Util";
import { URL_REPORT, URL_TANK } from '../../../constances/urls';
import { GET } from '../../../frameworks/HttpClient';
import { formatComma } from "../../../frameworks/Util";
import _ from 'lodash';
import moment from "moment";
import { PATH } from '../../../routes/Routes';

export default function LotNoReportView() {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(null);
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [transactionOptions, setTransactionOptions] = React.useState([]);
	const [selectedLotNo, setSelectedLotNo] = React.useState(null);
	const [data, setData] = React.useState(null);
	const [totalTruckWeight, setTotalTruckWeight] = React.useState(0);

	const columnsProduction = React.useMemo(() => [
		...getColumnsList([
			{ Header: "วันที่ - เวลา", accessor: "datetime", width: 140 },
			{ 
				Header: "จุดรับซื้อ", 
				accessor: "customer_name",
				style: {textAlign: "left"},
				Footer: "TOTAL", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "ทะเบียน", accessor: "license_plate", width: 100 },
			{ 
				Header: "น้ำหนัก", 
				accessor: "net_weight", 
				width: 100,
				Cell: ({value}) => (<p>{parseFloat(value).toLocaleString("en-US")}</p>),
				Footer: parseFloat(totalTruckWeight).toLocaleString("en-US"),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "NH3", accessor: "nh3", width: 60 },
			{ Header: "VFA", accessor: "vfa", width: 60 },
			{ 
				Header: "BULKING TANK NO.", 
				accessor: "bulking_tank_no", 
				width: 90,
				Cell: ({value, original}) => {
					return (<a href={`/report/report-lab-log-sheet/?date=${original.created ? moment(original.created).format('YYYY-MM-DD') : ''}`} target="_blank">{value}</a>)
				}
			},
			{ Header: "%DAP", accessor: "dap", width: 70 },
			{ Header: "%La", accessor: "la", width: 70 },
			{ Header: "%TZ", accessor: "tz", width: 70 },
		]),
	])

	const columnsTransfer = React.useMemo(() => [
		...getColumnsList([
			{ Header: "Date", accessor: "date", width: 120 },
			{ Header: "ถ่ายโอนไปยัง ST", accessor: "lot_no_origin" },
			{ Header: "รับโอนมาจาก ST", accessor: "lot_no_destination" },
			{ 
				Header: "น้ำหนัก (Kgs.)", 
				accessor: "weight", 
				width: 140,
				Cell: ({value}) => (<p>{value ? formatComma(value) : 0}</p>) 
			},
		]),
	])

	const columnsLoad = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "Date", 
				accessor: "date", 
				width: 100,
				Footer: "รวม",
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "โหลดที่", accessor: "index", width: 60 },
			{ Header: "ADVICE NO.", accessor: "advice_no", width: 100 },
			{ Header: "ชื่อลูกค้า", accessor: "buyer" },
			{ Header: "ทะเบียนรถ", accessor: "car_registration", width: 140 },
			{ Header: "เบอร์ตู้", accessor: "container_number", width: 120 },
			{ 
				Header: "น้ำหนัก (Kgs.)", 
				accessor: "weight", 
				width: 140,
				Cell: ({value}) => (<p>{value ? formatComma(value) : 0}</p>),
				Footer: data ? formatComma(data.load_concentrated_total_weight) : 0,
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
		]),
	])

	const columnsDeliver = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "Date", 
				accessor: "date", 
				width: 100,
				Footer: "รวม",
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "โหลดที่", accessor: "index", width: 60 },
			{ Header: "ADVICE NO.", accessor: "advice_no", width: 100 },
			{ Header: "ชื่อลูกค้า", accessor: "buyer" },
			{ Header: "ทะเบียนรถ", accessor: "car_registration", width: 140 },
			{ Header: "เบอร์ตู้", accessor: "container_number", width: 120 },
			{ 
				Header: "น้ำหนัก (Kgs.)", 
				accessor: "weight", 
				width: 140,
				Cell: ({value}) => (<p>{value ? formatComma(value) : 0}</p>),
				Footer: data ? formatComma(data.deliver_concentrated_total_weight) : 0,
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
		]),
	])

	/** START: Delay for search */
	const debounceFunc = React.useCallback(_.debounce(e => fetchTankTransactionOptions(e), 500), []);
	const handleSearchChange = e => debounceFunc(e);
	/** END: Delay for search */
	const fetchTankTransactionOptions = async (search) => {
		if (!search)
			return;
		
		setSearchLoading(true)
		setTransactionOptions([]);
		try {
			const response = await GET(URL_TANK.TANK_TRANSACTION, {lot_no: search});
			setTransactionOptions(
				response.data.map(item => {
					return {
						id: item.id,
						key: item.id,
						value: item.id,
						title: item.lot_no,
					}
				})
			);
		} catch (error) {
			// setErrorMessages(null);
		} finally {
			setSearchLoading(false)
		}
	}

	const fetchData = async (lotNo) => {
		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await GET(URL_REPORT.LOT_NO_REPORT, {lot_no: lotNo})
			setData(response.data);
			let totalWeight = response.data.tank_truck.reduce((c, p) => c + Number(p.net_weight), Number(0));
			setTotalTruckWeight(totalWeight);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (selectedLotNo) {
			fetchData(selectedLotNo.title)
		}
	}, [selectedLotNo])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<label>{"Lot No."}</label>
					<Form.Field 
						control={Search}
						placeholder={"ค้นหา..."}
						minCharacters={3}
						results={transactionOptions}
						showNoResults={true}
						onSearchChange={(_, data) => {
							handleSearchChange(data.value);
							setSelectedLotNo(null);
						}}
						onResultSelect={(_, data) => {
							setSelectedLotNo(data.result);
						}}
						loading={searchLoading}
					/>
				</Form.Group>
			</Form>

			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<Form>
					<Form.Group widths={"equal"}>
						<Form.Field inline width={1}>
							<label>{"Grade"}</label>
							<Label>{data ? data.grade : "-"}</Label>
						</Form.Field>

						<Form.Field inline width={1}>
							<label>{"Quantity"}</label>
							<Label>{data ? formatComma(data.quantity) : "-"}</Label>
						</Form.Field>

						<Form.Field inline width={2}>
							<label>{"Date Manufactured"}</label>
							<Label>{data ? data.date_manufactured : "-"}</Label>
						</Form.Field>

						<Form.Field inline width={1}>
							<label>{"Date Close"}</label>
							<Label>{data ? data.date_closed : "-"}</Label>
						</Form.Field>
					</Form.Group>

					<Form.Field inline>
						<label>{"ยอดก่อนส่งมอบ"}</label>
						<Label>{data ? formatComma(data.close_weight) : "-"}</Label>
					</Form.Field>
				

					<Form.Field inline>
						<label>{"ยอดรวมการส่งมอบทั้งหมด"}</label>
						<Label>{data ? formatComma(data.total_ready_weight) : "-"}</Label>
					</Form.Field>
				</Form>

				
				<Grid columns='2'>
					<Grid.Column floated='left' width={"5"}>
						<Header as="h4">{"ข้อมูลการผลิต"}</Header>
					</Grid.Column>
					<Grid.Column floated='right' width={"10"}>
						<Button
							style={{marginBottom: 8}}
							color={"red"}
							floated={"right"}
							size={"mini"}
							disabled={!selectedLotNo}
							onClick={() => window.open(
								`${URL_REPORT.LOT_NO_EUDR_REPORT_EXPORT}pdf/?lot_no=${selectedLotNo.title}`
							)}>
							<Icon name={"file pdf"} />
							Product Segregation EUDR
						</Button>
					</Grid.Column>
				</Grid>

				<TSReactTable
					columns={columnsProduction}
					data={data ? data.tank_truck : []}
					pageSize={(data && data.tank_truck.length > 0) ? data.tank_truck.length : 3}
					showPagination={false}/>

				{selectedLotNo && (
					<Button 
						primary
						style={{marginTop: 16}} 
						onClick={() => window.open(`${PATH.LAB_CONTROL}${selectedLotNo.id}`)}>
							Lab of Lot No.</Button>
				)}

				<Header as={"h4"}>{"ข้อมูลการรับโอนและถ่ายโอน"}</Header>
				<TSReactTable
					columns={columnsTransfer}
					data={data ? data.tank_transfer : []}
					pageSize={(data && data.tank_transfer.length > 0) ? data.tank_transfer.length : 3}
					showPagination={false}/>
				<Form>
					<Form.Group style={{marginTop: 8}}>
						<Form.Field inline>
							<label>{"ยอดรับโอน"}</label>
							<Label>{data ? formatComma(data.total_receive) : "-"}</Label>
						</Form.Field>

						<Form.Field inline>
							<label>{"ยอดถ่ายโอน"}</label>
							<Label>{data ? formatComma(data.total_take) : "-"}</Label>
						</Form.Field>
					</Form.Group>
				</Form>
				
				<Header as={"h4"}>{"ข้อมูลการโหลด Drum, IBC, Good Pack"}</Header>
				<TSReactTable
					columns={columnsLoad}
					data={data ? data.tank_loader : []}
					pageSize={(data && data.tank_loader.length > 0) ? data.tank_loader.length : 3}
					showPagination={false}/>
					
				<Header as={"h4"}>{"ข้อมูลการส่งมอบ"}</Header>
				<TSReactTable
					columns={columnsDeliver}
					data={data ? data.tank_deliver : []}
					pageSize={(data && data.tank_deliver.length > 0) ? data.tank_deliver.length : 3}
					showPagination={false}/>
				<Form>
					<Form.Group style={{marginTop: 8}} widths={"equal"}>
						<Form.Field inline width={4}>
							<label>{"Total Load"}</label>
							<Label>{data ? formatComma(data.total_load) : "-"}</Label>
							<label>{"Kgs."}</label>
						</Form.Field>

						<Form.Field inline width={3}>
							<Label>{data ? data.total_load_percent : "-"}</Label>
							<label>{"%"}</label>
						</Form.Field>

						<Form.Field inline width={5}>
							<label>{"Diff (+/-)"}</label>
							<Label>{data ? formatComma(data.diff_weight) : "-"}</Label>
							<label>{"Kgs."}</label>
						</Form.Field>

						<Form.Field width={4}/>
					</Form.Group>
				</Form>
					
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					disabled={!selectedLotNo}
					onClick={() => window.open(
						`${URL_REPORT.LOT_NO_REPORT_EXPORT}pdf/?lot_no=${selectedLotNo.title}`
					)}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}