import React from 'react';
import propTypes from "prop-types";
import { Header, Form, Input, Message } from "semantic-ui-react";
import { GET } from "../../frameworks/HttpClient";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const LabAuth = React.forwardRef((props, ref) => {
	const [code, setCode] = React.useState("");
	const [selected, setSelected] = React.useState(null);
	const [errorMessages, setErrorMessages] = React.useState(null);

	let validationSchema = {
		username: Yup.string().required("กรุณากรอกข้อมูลพนักงานให้ถูกต้อง"),
	}

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { username: "" },
    validationSchema: Yup.object(validationSchema),
    onSubmit: props.onSubmit,
  });

	const handleInputChange = (_, data) => {
		setCode(data.value);

		// Change Username
		if (selected && (selected.username !== data.value )) {
			setSelected(null);
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			// search user
			searchUser(code)
		}
	}

	const searchUser = async (username) => {
		setErrorMessages(null);
		try {
      const response = await GET(`/api/users/lab-inspector/${username}`);
      setSelected(response.data)
    } catch (error) {
			let n = error.errorMessages.search("not found");
			if (n > 0) {
				setErrorMessages("รหัสพนักงานไม่ถูกต้อง");
			} else {
				setErrorMessages(error.errorMessages);
			}
    } 
	}

	React.useImperativeHandle(ref, () => ({
		getUser: () => {
			return selected;
		},
		formik
	}))

	React.useEffect(() => {
		let name = ""
		if (selected) {
			name = selected.display_name
		}
		formik.setFieldValue("username", name)
		formik.setFieldError("username", null)
		
	}, [selected])

	return (
		<div style={{...props.style}}>
			<Header as={"h3"} style={{marginLeft: 24}}>ผู้ตรวจแลป</Header>

			<Form style={{textAlign: "right"}}>
				<Form.Group widths={"equal"}>
					<Form.Field 
						inline
						style={{width: "55%"}}
						label={"รหัส"}
						control={Input}
						placeholder={"รหัสพนักงาน"} 
						type={"text"}
						value={code}
						error={formik.errors.username}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
					/>
					<Form.Input
						fluid
						disabled={selected ? false : true}
						placeholder={"ชื่อพนักงาน"}
						value={selected ? selected.display_name : ""}/>
				</Form.Group>
			</Form>

			{errorMessages && (
				<Message 
					negative
					content={errorMessages}/>
			)}
		</div>
	)
});

LabAuth.defaultProps = {
	style: null,
}

LabAuth.propTypes = {
	style: propTypes.object,
}

export default LabAuth;