import React from "react";
import ACGroupContainer from "../../../components/reports/ACGroupContainer";
import PRListModal from "../../../components/reports/PRListModal";
import { URL_REPORT } from "../../../constances/urls";

export default function PettyCashReportView() {
	const [targetCode, setTargetCode] = React.useState(null);

	const gotoExport = (exportType, params) => {
		let myParams = `transaction_type=cash&date_after=${params.dateAfter}&date_before=${params.dateBefore}`
		window.open(`${URL_REPORT.AC_GROUP_REPORT_EXPORT}${exportType}/?${myParams}`);
	}

	const handleAction = (data, params) => {
		setTargetCode({
			account_code: data.account_code,
			account_name: data.account_name,
			params: `transaction_type=cash&account_code=${data.account_code}&date_before=${params.dateBefore}&date_after=${params.dateAfter}`
		})
	}

	return (
		<div>
			<ACGroupContainer
				transactionType={"cash"}
				onActionClick={handleAction}
				onExportPDFClick={(params) => gotoExport("pdf", params)}/>
			
			<PRListModal 
				open={targetCode ? true : false}
				onClose={() => setTargetCode(null)}
				data={targetCode}/>
		</div>
	)
}