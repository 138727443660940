import React from 'react';
import { Container, Form, Search } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import edit from "../assets/img_edit.png";
import historyIMG from "../assets/img_history.png";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import useListItem from '../frameworks/hooks/useItemList';
import { URL_TANK } from '../constances';
import { dateTimeToString, dateToString, formatComma } from '../frameworks/Util';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import _ from 'lodash';
import { GET } from '../frameworks/HttpClient';

export default function LabOfAdjustView() {
	const history = useHistory();
	const [dateFilter, setDateFilter] = React.useState(moment().format('YYYY-MM-DD'));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [transactionOptions, setTransactionOptions] = React.useState([]);
	const [selectedLotNo, setSelectedLotNo] = React.useState(null);
	// Table
	const list = useListItem({url: `${URL_TANK.TANK_LAB_REFILLED}?date=${dateFilter}&&lot_no=${selectedLotNo ? selectedLotNo.title : ""}`});
	const columns = React.useMemo(() => [
		{
			Header: "",
			headerStyle: TABLE_HEADER_STYLE,
			width: 60,
			Cell: ({original}) => {
				return (
					<ImageButton
						title={(original.state === "END") ? "ประวัติ" : "แก้ไข"}
						color={(original.state === "END") ? "teal" : "blue"}
						src={(original.state === "END") ? historyIMG : edit}
						onClick={(e, data) => handleDetail(original.uid)}/>
				)
			}
		},
		...getColumnsList([
			{ 
				Header: "วันที่เริ่มทำงาน", 
				accessor: "created",
				width: 100,
				Cell: ({value}) => (<p>{dateToString(value)}</p>)
			},
			{ 
				Header: "วันที่และเวลาล่าสุด", 
				accessor: "last_date_event",
				width: 140,
				Cell: ({value}) => (<p>{value ? dateTimeToString(value) : "-"}</p>)
			},
			{ Header: "เลขที่แท็งก์", accessor: "tank_name", width: 90 },
			{ Header: "Lot No.", accessor: "lot_no", width: 120 },
			{ Header: "เกรด", accessor: "grade", width: 90 },
			{ 
				Header: "น้ำหนัก (Kg)", 
				accessor: "current_weight",
				Cell: ({value}) => (<p>{value ? formatComma(value, true) : "0"}</p>) 
			}
		]),
		...getColumnsList([
			{ Header: "%TSC", accessor: "refilled_lab", Cell: ({value}) => columnItem(value, "tsc") },
			{ Header: "%DRC", accessor: "refilled_lab", Cell: ({value}) => columnItem(value, "drc") },
			{ Header: "%NH3", accessor: "refilled_lab", Cell: ({value}) => columnItem(value, "nh3") },
			{ Header: "Mg", accessor: "refilled_lab", Cell: ({value}) => columnItem(value, "mg") },
			{ Header: "VFA", accessor: "refilled_lab", Cell: ({value}) => columnItem(value, "vfa") },
		], 90),
	]);

	const columnItem = (list, keyName) => {
		let item = null;
		for (let it of list) {
			if (it.chemical === keyName) {
				item = it;
				break;
			}
		}
		return (<p>{item ? parseFloat(item.amount) : "-"}</p>)
	}

	const handleDetail = (id) => {
		// Go to detail
		history.push({pathname: `${PATH.LAB_ADJUST}${id}`})
	}

	/** START: Delay for search */
	const debounceFunc = React.useCallback(_.debounce(e => fetchTankTransactionOptions(e), 500), []);
	const handleSearchChange = e => debounceFunc(e);
	/** END: Delay for search */
	const fetchTankTransactionOptions = async (search) => {
		if (!search)
			return;
		
		setSearchLoading(true)
		setTransactionOptions([]);
		try {
			const response = await GET(URL_TANK.TANK_TRANSACTION, {lot_no: search});
			setTransactionOptions(
				response.data.map(item => {
					return {
						id: item.id,
						key: item.id,
						value: item.id,
						title: item.lot_no,
					}
				})
			);
		} catch (error) {
			// setErrorMessages(null);
		} finally {
			setSearchLoading(false)
		}
	}

	React.useEffect(() => {
		if (dateFilter) {
			if (!moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
				if (!dateFilterError)
					setDateFilterError(true);
				return;
			}
			
			if (dateFilterError)
				setDateFilterError(false);
			list.fetchData();
		}
	}, [dateFilter, selectedLotNo]);

	React.useEffect(() => {
		list.fetchData();
	}, []);

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<label>{"วันที่"}</label>
					<Form.Field
						control={DateInput}
						closable={true}
						dateFormat="YYYY-MM-DD"
						maxDate={moment()}
						placeholder={"เลือก..."}
						name={"date"}
						error={dateFilterError}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>
					
					<label style={{marginLeft: 18}}>{"Lot No."}</label>
					<Form.Field
						control={Search}
						placeholder={"ค้นหา..."}
						minCharacters={3}
						results={transactionOptions}
						showNoResults={true}
						onSearchChange={(_, data) => {
							handleSearchChange(data.value);
							setSelectedLotNo(null);
						}}
						onResultSelect={(_, data) => {
							setSelectedLotNo(data.result);
						}}
						loading={searchLoading}
					/>
				</Form.Group>
			</Form>

			<TSReactTable
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>
		</Container>
	)
}