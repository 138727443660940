import React from "react";
import propTypes from "prop-types";
import { Form, Message, TextArea } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DELETE } from "../../frameworks/HttpClient";

let validationSchema = {
	note: Yup.string().required("กรุณากรอกข้อมูล"),
}

export default function NoteModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			note: "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: props.onSubmit,
	});

	const handleSubmit = async () => {
		// Verify
		const validation = await formik.validateForm();
		const isValid = Object.keys(validation).length === 0;
		if (!isValid) return;

		if (!props.baseURL) {
			setErrorMessages("BASE URL not found!");
			return;
		}
		if (!props.id) {
			setErrorMessages("ID not found!");
			return;
		}

		setLoading(true);
		let response = null;
		let data = {}
		if (props.noteName)
			data[props.noteName] = formik.values.note;
		else
			data = formik.values;
		
		try {
			response = await DELETE(`${props.baseURL}${props.id}`, data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
		
		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={props.title}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form>
						<Form.Field 
							required
							label={"กรุณากรอกเหตุผล"}
							control={TextArea}
							placeholder={"เหตุผล..."}
							name={"note"}
							error={formik.errors.note}
							value={formik.values.note}
							onChange={formik.handleChange}/>
					</Form>
				</div>
			}/>
	)
}

NoteModal.defaultProps = {
	id: null,
	data: null,
	title: "",
	baseURL: "",
	noteName: null,
	open: false,
	onClose: () => {},
}

NoteModal.propTypes = {
	id: propTypes.string,
	data: propTypes.object,
	title: propTypes.string,
	baseURL: propTypes.string,
	noteName: propTypes.string,
	open: propTypes.bool,
	onClose: propTypes.func,
}