import React from "react";
import propTypes from "prop-types";
import {Form, Input, Button, Dropdown} from "semantic-ui-react";

const ChemicalInputForm = React.forwardRef((props, ref) => {
	const [chemicalSelected, setChemicalSelected] = React.useState(props.data ? props.data.chemical : "");
	const [amount, setAmount] = React.useState(props.data ? props.data.amount : "");

	const handleDropdownChange = (_, data) => setChemicalSelected(data.value);

	const handleInputChange = (_, data) => setAmount(data.value);

	React.useImperativeHandle(ref, () => ({ 
		getValue: () => {
			const data = {chemical: chemicalSelected, amount: (amount ? parseFloat(amount) : 0)}
			return data;
		}
	}));

	return (
		<Form.Field inline style={{textAlign: "left"}}>
			<label style={{width: "20%", textAlign: "right"}}>อื่นๆ</label>
			<Dropdown 
				selection
				disabled={props.readOnly}
				placeholder={"เลือก..."} 
				options={props.options}
				value={chemicalSelected}
				onChange={handleDropdownChange}/>
			<Input 
				style={{width: "24%", marginLeft: 8}} 
				readOnly={props.readOnly}
				placeholder={"0"} 
				type={"text"}
				value={amount}
				onChange={handleInputChange}/>
			{(!props.readOnly) && (!props.canDelete) && (
				<Button 
				style={{textAlign: "right", marginLeft: 8}} 
				icon={"add"} 
				color={"green"}
				onClick={() => props.onAdd()}/>
			)}
			{(props.readOnly) && (props.canDelete) && (
				<Button 
				style={{textAlign: "right", marginLeft: 8}} 
				icon={"minus"} 
				color={"red"}
				onClick={() => props.onDelete(props.data)}/>
			)}
		</Form.Field>
	)
})

ChemicalInputForm.defaultProps = {
	style: null,
	readOnly: false,
	initialModel: null,
	initialOthersModal: [],

	data: null,
	options: [],
	canDelete: false,
	onAdd: () => {},
	onDelete: (data) => {},
}

ChemicalInputForm.propTypes = {
	style: propTypes.object,
	readOnly: propTypes.bool,
	initialModel: propTypes.object,
	initialOthersModal: propTypes.array,

	data: propTypes.object,
	options: propTypes.array,
	canDelete: propTypes.bool,
	onAdd: propTypes.func,
	onDelete: propTypes.func,
}

export default ChemicalInputForm;