import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form, Button, Message } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import { dateTimeToString } from "../../frameworks/Util";
import { POST } from '../../frameworks/HttpClient';
import { URL_TANK } from '../../constances';
import ConfirmModal from '../common/ConfirmModal';

export default function CleanSegment(props) {
	const [openModal, setOpenModal] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [errorMessages, setErrorMessages] = React.useState(null)

	/** Back to SELL state */
	const handleChangeState = async (args) => {
		let response = null;
		setLoading(true);
		try {
			await POST(`${URL_TANK.TANK_TRANSACTION}${props.data ? props.data.id : ""}/reverse_to_sell/`)
			setOpenModal(false);
			props.onReload();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (openModal && errorMessages)
			setErrorMessages(null)
	}, [openModal])

	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>ทำความสะอาด</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"เริ่มทำความสะอาด"}
					value={(props.data && props.data.clean_datetime) 
						? dateTimeToString(props.data.clean_datetime)
						: "-"} />
			</Form>

			<div style={{textAlign: "right", marginTop: 16}}>
				{/* <Button primary 
					icon={"undo"} 
					content={"ย้อนสถานะ [เปิดเพื่อขาย]"} 
					disabled={!(props.data && (parseFloat(props.data.current_weight) > 0))}
					onClick={() => setOpenModal(true)}/> */}
			</div>

			<ConfirmModal
				open={openModal}
				title={"ย้อนสถานะ [เปิดเพื่อขาย]"}
				detail={
					<div>
						{errorMessages && (
							<Message
								negative
								header={"เกิดข้อผิดพลาด"}
								content={errorMessages}/>
						)}
						<p>{`คุณต้องการแก้ไขสถานะจาก "ทำความสะอาด" เป็น "เปิดเพื่อขาย" ใช่หรือไม่ ?`}</p>
					</div>
				}
				loading={loading}
				onClose={() => setOpenModal(false)}
				onCancel={() => setOpenModal(false)}
				onConfirm={handleChangeState}/>
		</Segment>
	)
}

CleanSegment.defaultProps = {
	style: null,
	data: null,
	onReload: () => {},
}

CleanSegment.propTypes = {
	data: propTypes.object,
	onReload: propTypes.func
}