import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import { Form, Input, Message, Radio, Search } from "semantic-ui-react";
import { DateInput } from 'semantic-ui-calendar-react'
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { INVENTORY_TYPE_OPTIONS } from "../../constances";
import { GET, POST } from "../../frameworks/HttpClient";
import { Toast } from "../../frameworks/Toast";
import { URL_PURCHASE, URL_STOCK } from "../../constances/urls";
import _ from 'lodash';

let validationSchema = {
	transaction_type: Yup.string().required("กรุณากรอกข้อมูล"),
	amount: Yup.number().required("กรุณากรอกข้อมูล"),
	unit: Yup.string().required("กรุณากรอกข้อมูล"),
	due_date: Yup.string().required("กรุณากรอกข้อมูล"),
	department: Yup.string().required("ผู้ใช้งานไม่มีข้อมูลแผนก"),
}

export default function DepartmentPurchaseModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [productOptions, setProductOptions] = React.useState(null);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			product: "",
			transaction_type: "cash",
			is_control: false,
			is_eco_friendly: false,
			amount: "",
			unit: props.initialModel ? props.initialModel.unit : "",
			due_date: moment().format("YYYY-MM-DD"),
			department: localStorage.getItem('department') || "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: values => handleSubmit(values),
	});

	const productFormik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			name: "",
			type: "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("กรุณากรอกข้อมูล"),
			type: Yup.string().required("กรุณากรอกข้อมูล"),
		}),
	})

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			if (!values.product) {
				// Create product
				const productFormikValidation = await productFormik.validateForm();
				if (Object.keys(productFormikValidation).length) {
					return;
				}
				const response = await POST(URL_STOCK.PRODUCT, productFormik.values);
				values = { ...values, product: response.data.id};
			}
			await POST(URL_PURCHASE.PURCHASE_REQUEST, values);
			Toast.success("สำเร็จ")
			props.onClose();
		} catch (e) {
			setErrorMessages(e.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	/** START: Delay for search */
	const debounceFunc = React.useCallback(_.debounce(e => fetchProductOptions(e), 500), []);
	const handleChange = e => debounceFunc(e);
	/** END: Delay for search */

	const fetchProductOptions = async (search) => {
		if (!search)
			return;
		
		setSearchLoading(true)
		setProductOptions([]);
		try {
			const response = await GET(URL_STOCK.PRODUCT, {type: productFormik.values.type, name: search});
			setProductOptions(
				response.data.map(item => {
					return {
						...item,
						key: item.id,
						value: item.id,
						title: item.name,
					}
				})
			);
		} catch (error) {
			setErrorMessages(null);
		} finally {
			setSearchLoading(false)
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
			productFormik.resetForm();
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={"สั่งซื้อ"}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form textAlign={"center"}>
						<Form.Group inline>
							<label style={{width: "16%"}}>ประเภทสินค้า</label>
							<Form.Dropdown 
								width={13}
								style={{width: "100%"}}
								selection
								placeholder={"เลือก..."}
								options={INVENTORY_TYPE_OPTIONS}
								name={"type"}
								value={productFormik.values.type}
								error={productFormik.errors.type}
								selectOnBlur={false}
								onChange={(_, data) => {
									productFormik.setFieldValue(data.name, data.value);
									// Clear data
									if (formik.values.product) {
										productFormik.setFieldValue("name", "");
										formik.setFieldValue("product", "");
										formik.setFieldValue("unit", "");
									}
									setProductOptions([]);
								}}/>
						</Form.Group>
						<Form.Group inline>
							<label style={{width: "16%"}}>ชื่อสินค้า</label>
							<Form.Field 
								width={15}
								style={{width: "100%"}}
								control={Search}
								placeholder={"ค้นหา..."}
								name={"name"}
								minCharacters={2}
								results={productOptions}
								showNoResults={true}
								onSearchChange={(_, data) => {
									formik.setFieldValue("unit", "");
									productFormik.setFieldValue(data.name, data.value);
									handleChange(data.value)
								}}
								onResultSelect={(_, data) => {
									const myProduct = data.result;
									formik.setFieldValue("product", myProduct.id);
									if (myProduct.unit && myProduct.unit != "unit"){
										formik.setFieldValue("unit", myProduct.unit);
									}
									productFormik.setFieldValue("name", myProduct.name);
									productFormik.setFieldValue("type", myProduct.type);
								}}
								value={productFormik.values.name}
								error={productFormik.errors.name}
								loading={searchLoading}
							/>

						</Form.Group>
						<Form.Field label={"ประเภทการสั่งซื้อ"}/>
						<Form.Group inline>
							<Form.Field 
								width={4}
								control={Radio}
								label={"เงินสด"}
								name={"cash"}
								checked={formik.values.transaction_type === "cash"}
								onClick={(_, {name}) => formik.setFieldValue("transaction_type", name)}/>
							<Form.Field 
								width={4}
								control={Radio}
								label={"เครดิต"}
								name={"credit"}
								checked={formik.values.transaction_type === "credit"}
								onClick={(_, {name}) => formik.setFieldValue("transaction_type", name)}/>
							<Form.Field 
								width={4}
								control={Radio}
								label={"memo"}
								name={"memo"}
								checked={formik.values.transaction_type === "memo"}
								onClick={(_, {name}) => formik.setFieldValue("transaction_type", name)}/>
						</Form.Group>
						<Form.Field label={"ประเภทสินค้า"}/>
						<Form.Group inline>
							<Form.Field 
								width={4}
								control={Radio}
								label={"ควบคุม"}
								name={"is_control"}
								checked={formik.values.is_control}
								onClick={() => formik.setFieldValue("is_control", true)}/>
							<Form.Field 
								width={4}
								control={Radio}
								label={"ไม่ควบคุม"}
								name={"is_control"}
								checked={!formik.values.is_control}
								onClick={() => formik.setFieldValue("is_control", false)}/>
							<Form.Field width={4}/>
						</Form.Group>

						<Form.Field label={"ความเป็นมิตรต่อสิ่งแวดล้อม"}/>
						<Form.Group inline>
							<Form.Field 
								width={8}
								control={Radio}
								label={"เป็นมิตรต่อสิ่งแวดล้อม"}
								name={"is_eco_friendly"}
								checked={formik.values.is_eco_friendly}
								onClick={() => formik.setFieldValue("is_eco_friendly", true)}/>
							<Form.Field 
								width={8}
								control={Radio}
								label={"ไม่เป็นมิตรต่อสิ่งแวดล้อม"}
								name={"is_eco_friendly"}
								checked={!formik.values.is_eco_friendly}
								onClick={() => formik.setFieldValue("is_eco_friendly", false)}/>
						</Form.Group>
						<Form.Group inline>
						<Form.Field width={4} label={"จำนวนสั่งซื้อ"}/>
							<Form.Field 
								width={12}
								control={Input}
								type={"number"}
								placeholder={"จำนวน..."}
								name={"amount"}
								error={formik.errors.amount}
								value={formik.values.amount}
								onChange={formik.handleChange}/>
						</Form.Group>
						<Form.Group inline>
							<Form.Field width={4} label={"หน่วยที่สั่งซื้อ"}/>
							<Form.Field 
								width={12}
								control={Input}
								placeholder={"หน่วย..."}
								name={"unit"}
								error={formik.errors.unit}
								value={formik.values.unit}
								onChange={formik.handleChange}/>
						</Form.Group>

						<Form.Group inline>
							<Form.Field width={4} label={"กำหนดวันส่งสินค้า"}/>
							<Form.Field
								control={DateInput}
								closable={true}
								dateFormat="YYYY-MM-DD"
								minDate={moment()}
								placeholder={"เลือกวันที่..."}
								name={"due_date"}
								error={formik.errors.due_date}
								value={formik.values.due_date}
								onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
						</Form.Group>
						
						<Form.Group inline>
							<Form.Field width={4} label={"แผนกขอสั่งซื้อ"}/>
							<Form.Input
								width={12}
								style={{width: "100%"}}
								selection
								placeholder={"กรุณาติดต่อเพื่อเพิ่มแผนกให้ผู้ใช้งาน"}
								readOnly={true}
								name={"department"}
								error={formik.errors.department}
								value={localStorage.getItem('department_name')}/>
						</Form.Group>
					</Form>
				</div>
			}/>
	)
}

DepartmentPurchaseModal.defaultProps = {
	initialModel: null,
	open: false,
	onClose: () => {},
}

DepartmentPurchaseModal.propTypes = {
	initialModel: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}