import React from 'react';
import propTypes from "prop-types";
import { Header, Modal, Button, Dimmer, Loader } from 'semantic-ui-react';

export default function InfoModal(props) {

  return (
		<Modal 
			centered={false}
			open={props.open} 
			onClose={props.onClose}
			size={props.size}>
			<Header icon={props.iconTitle} content={props.title}/>
			<Modal.Content>
				<Dimmer.Dimmable dimmed={props.loading}>
					{props.detail}
					<Dimmer inverted active={props.loading}>
						<Loader size={"large"} content={"Loading"}/>
					</Dimmer>
				</Dimmer.Dimmable>
			</Modal.Content>
			<Modal.Actions>
				<Button color={props.buttonColor} onClick={props.onClose}>{props.buttonText}</Button>
			</Modal.Actions>
		</Modal>
	)
}

InfoModal.defaultProps = {
	open: false,
	title: "",
	iconTitle: null,
	detail: "",
	size: "small",
	loading: false,
	buttonText: "ปิด",
	buttonColor: "grey",
	onClose: () => {},
}

InfoModal.propTypes = {
	open: propTypes.bool,
	title: propTypes.string,
	detail: propTypes.oneOfType([
		propTypes.string,
		propTypes.element,
	]),
	size: propTypes.string,
	loading: propTypes.bool,
	buttonText: propTypes.string,
	buttonColor: propTypes.string,
	onClose: propTypes.func,
}