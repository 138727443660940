import React from 'react';
import propTypes from "prop-types";
import ConfirmModal from "../common/ConfirmModal"
import { Form, Header, Input, Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LabTankInput from '../lab/LabTankInput';
import { URL_LAB } from '../../constances';
import { PATCH, POST } from '../../frameworks/HttpClient';

export default function AddOnChemicalModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Initial Data
	const [initialInput, setInitialInput] = React.useState(null);
	const [initialInputOthers, setInitialInputOthers] = React.useState([]);
	const inputRef = React.useRef(null);

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
			weight: ""
		},
    validationSchema: Yup.object().shape({
			weight: Yup.number().notRequired(),
		}),
    onSubmit: props.onSubmit,
  });

	const handleSubmit = async () => {
		// Verify
		const inputFormik = inputRef.current.formik;
		const inputValidation = await inputFormik.validateForm();
		const isInputValid = Object.keys(inputValidation).length === 0;
		if (!isInputValid) return;

		// Prepare Data
		setLoading(true);
		let data = {}
		const isCreate = props.data ? false : true;
		if (isCreate)
			data = { tank_transaction: props.id }
		
		const weight = formik.values.weight;
		if (weight !== "") {
			data = { ...data, weight: weight }
		}

		const inputValue = inputFormik.values;
		let inputs = []
		for (let key in inputValue) {
			let obj = inputValue[key];
			inputs.push({
				chemical: key,
				amount: (obj.amount ? parseFloat(obj.amount) : 0),
				percent: (obj.percent ? parseFloat(obj.percent) : 0),
				batch: (obj.batch ? obj.batch : ""),
			})
		}
		const inputOthers = inputRef.current.getOthers();
		for (let other of inputOthers) {
			inputs.push(other);
		}

		data = { ...data, inputs: inputs }
		// Input chemical by factory 
		let response = null
		try {
			if (isCreate) { // Create
				response = await POST(URL_LAB.TANK_WEIGHT_FACTORY_CHECK, data);
			} else { // Update
				response = await PATCH(`${URL_LAB.TANK_WEIGHT_FACTORY_CHECK}${props.data.id}/`, data);
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (props.data) {
			/** inputs */
			const inputExcept =  ["tz", "la"];
			let inputData = {};
			let otherList = [];
			for (let it of props.data.inputs) {
				let isOther = true;
				for (let ex of inputExcept) {
					if (it.chemical === ex) {
						isOther = false;
						break;
					}
				}
				let amount = parseFloat(it.amount);
				if (isOther) {
					otherList.push({id: (otherList.length + 1), chemical: it.chemical, amount: amount});
				} else {
					inputData = {
						...inputData, 
						[it.chemical]: {
							amount: parseFloat(it.amount),
							percent: parseFloat(it.percent),
							batch: it.batch
						}}
				}
			}
			const weight = parseFloat(props.data.weight);
			formik.setFieldValue("weight", weight);
			setInitialInput(inputData);
			setInitialInputOthers(otherList);
		} else {
			formik.resetForm();
			setInitialInput(null);
			setInitialInputOthers([]);
		}
	}, [props.data])

	React.useEffect(() => {
		if (!props.open)
			setErrorMessages(null);
	}, [props.open])

	return (
		<ConfirmModal
			open={props.open}
			title={props.data ? "แก้ไขน้ำหนักและเติมสารเคมี" : "เพิ่มน้ำหนักและเติมสารเคมี"}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={props.data ? "แก้ไข" : "ยืนยัน"}
			positiveColor={props.data ? "yellow" : "green"}
			onClose={props.onClose}
			onConfirm={handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}

					<Form style={{marginLeft: 24}}>
						<Header as={"h3"}>น้ำหนักที่วัดได้จากแท็งก์</Header>
						<Form.Field
						 control={Input}
						 placeholder={"0"}
						 name={"weight"}
						 value={formik.values.weight}
						 error={formik.errors.weight}
						 onChange={formik.handleChange}/>
					</Form>

					{/* Lab Input */}
					<LabTankInput 
						style={{marginTop: 24}}
						ref={inputRef}
						initialModel={initialInput}
						initialOthersModal={initialInputOthers} />
				</div>
			}
		/>
	)
}

AddOnChemicalModal.defaultProps = {
	open: false, 
	id: null,
	data: null,
	onClose: () => {},
}

AddOnChemicalModal.propTypes = {
	open: propTypes.bool,
	id: propTypes.string,
	data: propTypes.object,
	onClose: propTypes.func,
}