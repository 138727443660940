import moment from 'moment';
import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { MonthRangeInput, YearInput } from 'semantic-ui-calendar-react';
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Checkbox, Dropdown } from 'semantic-ui-react';
import { URL_REPORT } from '../../constances/urls';
import { GET } from '../../frameworks/HttpClient';
import TSReactTable from '../../frameworks/TSReactTable';
import { getColumnsList, TABLE_HEADER_STYLE } from '../../utils/Util';
import { LATEX_GRADE_OPTIONS } from '../../constances/options';


export default function PondStatisticReportView () {
  const [period, setPeriod] = React.useState('DAILY')
	const [renderPeriod, setRenderPeriod] = React.useState('DAILY')
  const [dateRange, setDateRange] = React.useState([]);
	const [filterType, setFilterType] = React.useState("INITIAL");
	const [monthRange, setMonthRange] = React.useState('');
	const [yearRange, setYearRange] = React.useState('');
	const [tscAverage, setTscAverage] = React.useState(0);
	const [drcAverage, setDrcAverage] = React.useState(0);
	const [mgAverage, setMgAverage] = React.useState(0);
	const [nh3Average, setNh3Average] = React.useState(0);
	const [vfaAverage, setVfaAverage] = React.useState(0);
	const [dilutionAverage, setDilutionAverage] = React.useState(0);
	// Table
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Checkbox
	const [chemicalCheck, setChemicalCheck] = React.useState(["tsc", "drc", "mg", "nh3", "vfa", "dilution"]);
	const [trigger, setTrigger] = React.useState(false);
	// Options
	const [grade, setGrade] = React.useState("ALL");

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "เวลา", 
				accessor: "time",
				Footer: "TOTAL",
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: " ค่าเฉลี่ย TSC", 
				accessor: "tsc",
				Footer: tscAverage,
				show: chemicalCheck.includes("tsc"),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: " ค่าเฉลี่ย DRC", 
				accessor: "drc",
				Footer: drcAverage,
				show: chemicalCheck.includes("drc"),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: " ค่าเฉลี่ย NH3", 
				accessor: "nh3",
				Footer: nh3Average,
				show: chemicalCheck.includes("nh3"),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: " ค่าเฉลี่ย MG", 
				accessor: "mg",
				Footer: mgAverage,
				show: chemicalCheck.includes("mg"),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "ค่าเฉลี่ย VFA", 
				accessor: "vfa",
				show: chemicalCheck.includes("vfa"),
				Cell: ({value}) => (<p>{parseFloat(value).toFixed(3)}</p>),
				Footer: parseFloat(vfaAverage).toFixed(3),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "ค่าเฉลี่ย %DILUTION", 
				accessor: "dilution",
				show: chemicalCheck.includes("dilution"),
				Footer: dilutionAverage,
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
		]),
	])

	const handleChemicalCheck = (_, data) => {
		let myData = chemicalCheck
		if (data.checked)
			myData.push(data.name)
		else
			myData = myData.filter(item => item !== data.name)
		
		setChemicalCheck(myData);
		setTrigger(!trigger);
	}

	const gotoExport = (exportType) => {
		if (period === 'DAILY') {			
				const date_after = moment(dateRange[0]).format('YYYY-MM-DD')
				const date_before = moment(dateRange[1]).format('YYYY-MM-DD')
			window.open(`${URL_REPORT.POND_STATISTIC_REPORT_EXPORT.DAILY}${exportType}/?date_after=${date_after}&date_before=${date_before}&type=${filterType}&grade=${grade}`)
		} else if (period === 'MONTHLY') {
			const filterRange =  monthRange.split('-');
			if ((filterRange.length < 2) || !filterRange[1].trim()) {
				setErrorMessages('กรุณากรอกช่วงเดือนให้ครบถ้วน')
				return;
			}
			const before = filterRange[0].trim().split('/');
			const after = filterRange[1].trim().split('/');
			const	month_after = Number(before[0])
			const	year_after = Number(before[1])
			const	month_before = Number(after[0])
			const	year_before = Number(after[1])
			window.open(`${URL_REPORT.POND_STATISTIC_REPORT_EXPORT.MONTHLY}${exportType}/?month_after=${month_after}&year_after=${year_after}&month_before=${month_before}&year_before=${year_before}&type=${filterType}&grade=${grade}`)
		} else if (period === 'YEARLY') {
			const filterRange =  yearRange.split('-');
			if (filterRange.length < 2) {
				setErrorMessages('กรุณากรอกช่วงปีให้ครบถ้วน')
				return;
			}
			const year_after = Number(filterRange[0].trim())
			const year_before = Number(filterRange[1].trim())
			window.open(`${URL_REPORT.POND_STATISTIC_REPORT_EXPORT.YEARLY}${exportType}/?year_after=${year_after}&year_before=${year_before}&type=${filterType}&grade=${grade}`)
		}
	}

  const fetchData = async () => {
		setLoading(true)
		setErrorMessages(null)
		try {
			let response;
			let params = { type: filterType, grade: grade };
			if (period === 'DAILY') {
				params["date_after"] = moment(dateRange[0]).format('YYYY-MM-DD')
				params["date_before"] = moment(dateRange[1]).format('YYYY-MM-DD')
				response = await GET(URL_REPORT.POND_STATISTIC_REPORT.DAILY, params)
			} else if (period === 'MONTHLY') {
				const filterRange =  monthRange.split('-');
				if ((filterRange.length < 2) || !filterRange[1].trim()) {
					setErrorMessages('กรุณากรอกช่วงเดือนให้ครบถ้วน')
					return;
				}
				const before = filterRange[0].trim().split('/');
				const after = filterRange[1].trim().split('/');
				params["month_after"] = Number(before[0])
				params["year_after"] = Number(before[1])
				params["month_before"] = Number(after[0])
				params["year_before"] = Number(after[1])
				response = await GET(URL_REPORT.POND_STATISTIC_REPORT.MONTHLY, params)
			} else if (period === 'YEARLY') {
				const filterRange =  yearRange.split('-');
				if (filterRange.length < 2) {
					setErrorMessages('กรุณากรอกช่วงปีให้ครบถ้วน')
					return;
				}
				params["year_after"] = Number(filterRange[0].trim())
				params["year_before"] = Number(filterRange[1].trim())
				response = await GET(URL_REPORT.POND_STATISTIC_REPORT.YEARLY, params)
			}
			setRenderPeriod(period);
			setData(response.data)
			let myData = response.data;
			let totalWeight = myData.reduce((total, item) => total += Number(item.weight), 0)
			setTscAverage((myData.length > 0) 
				? (myData.reduce((total, item) => 
					total += (Number(item.tsc) * Number(item.weight)), Number(0)) / totalWeight).toFixed(2) 
				: 0)
			setDrcAverage((myData.length > 0) 
				? (myData.reduce((total, item) => 
					total += (Number(item.drc) * Number(item.weight)), Number(0)) / totalWeight).toFixed(2) 
				: 0)
			setMgAverage((myData.length > 0) 
				? (myData.reduce((total, item) => 
					total += (Number(item.mg) * Number(item.weight)), Number(0)) / totalWeight).toFixed(0)
				: 0)
			setNh3Average((myData.length > 0)
				? (myData.reduce((total, item) => 
					total += (Number(item.nh3) * Number(item.weight)), Number(0)) / totalWeight).toFixed(2)
				: 0)
			setVfaAverage((myData.length > 0)
				? (myData.reduce((total, item) => 
					total += (Number(item.vfa) * Number(item.weight)), Number(0)) / totalWeight).toFixed(3)
				: 0)
			// Calculate dilution
			let totalDilution = myData.reduce((total, item) => total += Number(item.total_dilution), 0)
			let totalKgsReceived = myData.reduce((total, item) => total += Number(item.kgs_received), 0)
			setDilutionAverage((totalKgsReceived > 0) ? (totalDilution / totalKgsReceived * 100).toFixed(2) : 0)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
  }

  return (
		<Container fluid style={{marginTop: 24}}>
      <Form>
				<Form.Group>
          <Form.Field
					label={"ประเภทรายงาน"}
						control={Dropdown}
						selectOnBlur={false}
            button
            basic
            options={[
              {value: 'DAILY', text: 'รายวัน'},
              {value: 'MONTHLY', text: 'รายเดือน'},
              {value: 'YEARLY', text: 'รายปี'},
            ]}
            defaultValue={period}
            onChange={(e, data) => {
              setPeriod(data.value)
            }}/>
					{ period == 'DAILY' &&
						<Form.Field
							style={{width: "500px"}}
							label={"ช่วงเวลา"}
							control={SemanticDatepicker}
							locale="en-US"
							type="range"
							showToday={true}
							format="DD/MM/YY"
							placeholder={"เลือกช่วงเวลา..."}
							value={dateRange}
							onChange={(_, data) => setDateRange(data.value)}
						/>
					}
					{ period == 'MONTHLY' &&
						<Form.Field
							label={"ช่วงเวลา"}
							locale="en-US"
							placeholder={"เลือกช่วงเวลา..."}
							control={MonthRangeInput}
							dateFormat="MM/YYYY"
							value={monthRange}
							clearable
							onClear={() => setMonthRange('')}
							onChange={(_, data) => {
								const range = data.value.split(' - ')
								let monthString = '';
								if (monthRange.length >  10) {
									setMonthRange('')
								} else if ( monthRange != '' ) {
									const [range_1_month, range_1_year] = range[0].split('/')
									const [range_2_month, range_2_year] = range[1].split('/')
									if (Number(range_1_year) > Number(range_2_year)) {
										monthString = [range[1], range[0]].join(' - ');
									} else if (Number(range_1_year) < Number(range_2_year)) {
										monthString = [range[0], range[1]].join(' - ');
									} else if (Number(range_1_month) > Number(range_2_month)) {
										monthString = [range[1], range[0]].join(' - ');
									} else {
										monthString = [range[0], range[1]].join(' - ');
									}
									setMonthRange(monthString);
								} else {
									setMonthRange(data.value);
								}
							}}
						/>
					}
					{ period == 'YEARLY' &&
						<Form.Field
							label={"ช่วงเวลา"}
							locale="en-US"
							placeholder={"เลือกช่วงเวลา..."}
							control={YearInput}
							value={yearRange}
							clearable
							onClear={(_, data) => setYearRange('')}
							onChange={(_, data) => {
								if (yearRange.length > 10) {
									setYearRange('');
								}
								else {
									let yearString = '';
									if (Number(yearRange) >= Number(data.value)) {
										yearString = `${data.value}${yearRange.length ? ' - ' : ''}${yearRange}`
									} else {
										yearString = `${yearRange}${yearRange.length ? ' - ' : ''}${data.value}`
									}
									setYearRange(yearString)
								}
							}}
						/>
					}
					<Form.Field 
						label={"ประเภทค่าเฉลี่ย"}
						control={Dropdown}
						selectOnBlur={false}
						selection
						placeholder={"เลือก..."}
						value={filterType}
						onChange={(_, data) => setFilterType(data.value)}
						options={[
							{key: "INITIAL", value: "INITIAL", text:  "INITIAL"},
							{key: "FINAL", value: "FINAL", text:  "FINAL"}
						]}/>
						
					<Form.Field 
            label={"เกรดผลิต"}
            control={Dropdown}
						selectOnBlur={false}
            selection
            value={grade}
            onChange={(_, data) => setGrade(data.value)}
            options={LATEX_GRADE_OPTIONS}/>
					
					<div style={{marginTop: "auto"}}>
						<Button primary onClick={() => fetchData()}>ค้นหา</Button>
					</div>
				</Form.Group>
					
				<Form.Group inline style={{display: 'none'}}>
					<label>สารเคมีที่ต้องการ</label>
						<Form.Field 
							label={"TSC"}
							control={Checkbox}
							name={"tsc"}
							checked={chemicalCheck.includes("tsc")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"DRC"}
							control={Checkbox}
							name={"drc"}
							checked={chemicalCheck.includes("drc")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"NH3"}
							control={Checkbox}
							name={"nh3"}
							checked={chemicalCheck.includes("nh3")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"MG"}
							control={Checkbox}
							name={"mg"}
							checked={chemicalCheck.includes("mg")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"VFA"}
							control={Checkbox}
							name={"vfa"}
							checked={chemicalCheck.includes("vfa")}
							onChange={handleChemicalCheck}/>
						<Form.Field 
							label={"%DILUTION"}
							control={Checkbox}
							name={"dilution"}
							checked={chemicalCheck.includes("dilution")}
							onChange={handleChemicalCheck}/>
				</Form.Group>
			</Form>

			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>

				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport("pdf")}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
				<Button 
					style={{marginTop: 8}}
					color={"green"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport("excel")}
					>
						<Icon name={"file excel"}/>
						Excel
				</Button>
			</Segment>

    </Container>    
  )
}