import React from 'react';
import moment from "moment";
import { Container, Form } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import { DateInput } from "semantic-ui-calendar-react";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import editIMG from "../assets/img_edit.png";
import historyIMG from "../assets/img_history.png";
import {  TABLE_HEADER_STYLE, TABLE_BODY_STYLE, getColumnsList, dateTimeToString } from "../utils/Util";
import { URL_POND, POND_STATUS  } from "../constances";
import useListItem from "../frameworks/hooks/useItemList";
import { dateToString, formatComma } from '../frameworks/Util';

export default function LabOfPondView() {
	const history = useHistory();
	const [dateFilter, setDateFilter] = React.useState(moment().format('YYYY-MM-DD'));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	// Table
	const list = useListItem({url: `${URL_POND.POND_LAB}?date=${dateFilter}`});
	const columns = React.useMemo(() => [
		{
			Header: "",
			headerStyle: TABLE_HEADER_STYLE,
			width: 60,
			Cell: (row) => {
				const state = row.original.state
				return (
					<ImageButton 
						title={(state === "END") ? "ประวัติ" : "แก้ไข"}
						color={(state === "END") ? "teal" : "blue"}
						src={(state === "END") ? historyIMG : editIMG}
						onClick={(e, data) => handleDetail(row.original)}/>
				)
			}
		},
		...getColumnsList([
			{ 
				Header: "วันที่เริ่มทำงาน", 
				accessor: "created",
				width: 100,
				Cell: ({value}) => (<p>{dateToString(value)}</p>)
			},
			{ 
				Header: "วันที่และเวลาล่าสุด", 
				accessor: "latest_event_date",
				width: 140,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
			},
			{ Header: "เลขที่บ่อพัก", accessor: "name", minWidth: 90 },
			{ 
				Header: "เกรดผลิต", 
				accessor: "closing_grade", 
				width: 120,
				Cell: ({value}) => (<p>{value ? value : "-"}</p>)
			},
			{ 
				Header: "น้ำหนัก (Kg)", 
				accessor: "weight", 
				minWidth: 120,
				Cell: ({value}) => (<p>{formatComma(value)}</p>)
			},
		]),
		{
			Header: "สถานะ",
			headerStyle: TABLE_HEADER_STYLE,
			width: 120,
			Cell: (row) => {
				const STATUS = POND_STATUS[row.original.state];
				return (<p style={{...TABLE_BODY_STYLE, height: "100%",}}>{STATUS}</p>)
			}
		},
		...getColumnsList([
		{ 
			Header: "%TSC", 
			Cell: (row) => columnItem(row.original.lab_result, "tsc")
		},
		{ 
			Header: "%DRC", 
			Cell: (row) => columnItem(row.original.lab_result, "drc")
		},
		{ 
			Header: "%NH3", 
			Cell: (row) => columnItem(row.original.lab_result, "nh3") 
		},
		{ 
			Header: "Mg", 
			Cell: (row) => columnItem(row.original.lab_result, "mg") 
		},
		{ 
			Header: "VFA", 
			Cell: (row) => columnItem(row.original.lab_result, "vfa") 
		},
		], 80),
	]);

	const columnItem = (list, keyName) => {
		let item = null;
		for (let it of list) {
			if (it.chemical === keyName) {
				item = it;
				break;
			}
		}
		return (<p>{item ? parseFloat(item.amount) : "-"}</p>)
	}

	const handleDetail = (data) => {
		// Go to detail
		history.push({pathname: `${PATH.LAB_POND}${data.id}`})
	}

	React.useEffect(() => {
		if (dateFilter) {
			if (!moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
				if (!dateFilterError)
					setDateFilterError(true);
				return;
			}
			
			if (dateFilterError)
				setDateFilterError(false);
			list.fetchData();
		}
	}, [dateFilter])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<label>{"วันที่"}</label>
					<Form.Field
						control={DateInput}
						closable={true}
						dateFormat="YYYY-MM-DD"
						maxDate={moment()}
						placeholder={"เลือก..."}
						name={"date"}
						error={dateFilterError}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>
				</Form.Group>
			</Form>

			<TSReactTable 
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>
		</Container>
	)
}