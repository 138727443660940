import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import { dateTimeToString } from "../../frameworks/Util";

export default function SaleSegment(props) {
	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>เปิดเพื่อขาย</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"เปิดแท็งก์เพื่อขายเมื่อ"}
					value={(props.data && props.data.sell_datetime) 
						? dateTimeToString(props.data.sell_datetime)
						: "-"} />
			</Form>

			<div style={{marginTop: 16}}></div>
		</Segment>
	)
}

SaleSegment.defaultProps = {
	style: null,
	data: null,
}

SaleSegment.propTypes = {
	data: propTypes.object,
}