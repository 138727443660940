import React, { useMemo } from 'react';
import moment from "moment";
import Cookies from "js-cookie";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Popup, Dropdown, List} from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import TSReactTable from "../../frameworks/TSReactTable";
import AccountingCodeModal from "../../components/store/AccountingCodeModal";
import {
	getColumnsList,
	dateToString,
	TABLE_HEADER_STYLE,
} from "../../utils/Util";
import { URL_COMMON, URL_REPORT } from '../../constances/urls';
import { GET } from '../../frameworks/HttpClient';
import { currencyFormat, formatComma, formatFileName } from "../../frameworks/Util";
import { PAYMENT_TYPE_OPTIONS } from '../../constances/options';
import { PAYMENT_TYPE, USER_GROUP } from '../../constances';

export default function CRSTO03ReportView() {
	const user = JSON.parse(Cookies.get("user"));
	const [dateRange, setDateRange] = React.useState(null);
	const [dateRangeError, setDateRangeError] = React.useState(false);
	const [filterStatus, setFilterStatus] = React.useState("ALL");
	const [filterType, setFilterType] = React.useState("ALL");
	const [filterDepartment, setFilterDepartment] = React.useState("ALL");
	const [departmentOptions, setDepartmentOptions] = React.useState([]);
	const [targetEditAccounting, setTargetEditAccounting] = React.useState(null);
	// Table
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columns = useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่", 
				accessor: "created", 
				width: 100,
				Cell: ({value}) => (<p>{dateToString(value)}</p>)
			},
			{ 
				Header: "รายการ", 
				accessor: "product.name",
				style: { textAlign: "left" },
				width: 280,
				Cell: ({original, value}) => 
					(<p>{original.ref_product ? `${value} (${original.ref_product.name})` : value}</p>)
			},
			{ 
				Header: "จำนวน", 
				accessor: "amount", 
				width: 120,
				Cell: ({original ,value}) => 
					(<p>{`${value ? formatComma(parseFloat(value)) : "-"} ${original.unit}`}</p>),
			},
			{ 
				Header: "ราคารวม", 
				style: { textAlign: "right" },
				accessor: "selected_quotation.real_price",
				Cell: ({value}) => (<p>{currencyFormat(value)}</p>)
			},
			{ 
				Header: "การสั่งซื้อ", 
				accessor: "transaction_type",
				Cell: ({value}) => (<p>{value ? PAYMENT_TYPE[value] : ""}</p>)
			},
			{ 
				Header: "เอกสารแนบ", 
				accessor: "selected_quotation.attachment", 
				style: { textAlign: "left" },
				width: 220,
				Cell: ({value}) => formatFileName(value)
			},
			{ 
				Header: "รายการบัญชี", 
				accessor: "purchase_record.account_code_detail.name", 
				style: { textAlign: "left" },
				width: 200,
				Cell: ({original, value}) => {
					return (
						<div>
							{(user.groups.find(group => group.name === USER_GROUP.ACCOUNTING)) && (
								<Icon
									style={{marginRight: 8}} 
									name={"edit"}
									color={"yellow"}
									onClick={() => setTargetEditAccounting(original)}/>
							)}
							<label>{value}</label>
						</div>
					)
				}
			},
			{ 
				Header: "เอกสารการเงิน", 
				accessor: "receipt", 
				style: { textAlign: "left" },
				width: 220,
				Cell: ({value}) => (
					<List bulleted>
						{value.map((item, index) => {
							return (
								<List.Item>{formatFileName(item)}</List.Item>
							)
						})}
					</List>
				)
			},
			{ 
				Header: "แผนก", 
				accessor: "department",
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: (row) => (
				<div>
					<Popup
						content={"Export PDF"}
						trigger={
							<Button 
								style={{marginLeft: 4, marginRight: 4}}
								icon 
								color={"red"}
								size={"mini"}
								onClick={() => gotoExport(row.original)}>
								<Icon name={"file pdf"}/>
							</Button>}
					/>
				</div>
			)
		},
	])

	const fetchData = async () => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);
		setLoading(true)
		setErrorMessages(null)
		
		const params = {
			date_before: moment(dateRange[0]).format('YYYY-MM-DD'),
			date_after: moment(dateRange[1]).format('YYYY-MM-DD'),
			status: filterStatus,
			transaction_type: filterType,
			department: filterDepartment,
		}
		try {
			const response = await GET(URL_REPORT.CR_STO_03_REPORT, params);
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const fetchOptions = async () => {
    try {
      const response = await GET(URL_COMMON.DEPARTMENT);
      setDepartmentOptions(
        response.data.map(item => {
          return {
            key: item.name, 
            value: item.name, 
            text: item.name
          }
        })
      );
    } catch (error) {
      console.log(error.errorMessages)
    }
  };

	const gotoExport = (item) => {
		window.open(`${URL_REPORT.CR_STO_03_REPORT_EXPORT}?id=${item.id}`)
	}

	React.useEffect(() => {
		if (!targetEditAccounting) {
			if (dateRange)
				fetchData();
		}

	}, [targetEditAccounting]);

	React.useEffect(() => {
		fetchOptions();
	}, []);

	return (
		<Container fluid style={{marginTop: 24}}>
			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group>
					<Form.Field
						label={"วันที่ขอซื้อ"}
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						error={dateRangeError}
						onChange={(_, data) => setDateRange(data.value)}/>
					
					<Form.Field 
						label={"สถานะ"}
						control={Dropdown}
						selection
						value={filterStatus}
						selectOnBlur={false}
						onChange={(_, data) => setFilterStatus(data.value)}
						options={[
							{ key: "ALL", value: "ALL", text: "ทั้งหมด" },
							{ key: "END", value: "END", text: "จบการสั่งซื้อ" }
						]}/>

					<Form.Field 
						label={"ประเภทการสั่งซื้อ"}
						control={Dropdown}
						selection
						value={filterType}
						selectOnBlur={false}
						onChange={(_, data) => setFilterType(data.value)}
						options={PAYMENT_TYPE_OPTIONS}/>
					
					<Form.Field
						label={"แผนก"}
						control={Dropdown}
						selection
						placeholder={"เลือก..."}
						options={[
							{key: "ALL", value: "ALL", text: "ทั้งหมด"},
							...departmentOptions
						]}
						value={filterDepartment}
						selectOnBlur={false}
						onChange={(_, data) => setFilterDepartment(data.value)}/>

					<div style={{marginTop: "auto"}}>
						<Button primary onClick={() => fetchData()}>ค้นหา</Button>
					</div>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
			</Segment>

			<AccountingCodeModal
				title={"รหัสทางบัญชี"}
				open={targetEditAccounting ? true : false}
				data={targetEditAccounting}
				onSuccess={() => {}}
				onClose={() => setTargetEditAccounting(null)}/>
		</Container>
	)
}