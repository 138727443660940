import React from 'react';
import propTypes from "prop-types";
import { Form, Input, Message } from 'semantic-ui-react';
import ConfirmModal from "../common/ConfirmModal";
import LabQualityResult from "./LabQualityResult";
import LabAuth from "./LabAuth";
import { POST } from "../../frameworks/HttpClient";
import { URL_LAB } from '../../constances';

export default function QualityCheckedModal(props) {
	const resultRef = React.useRef(null);
	const authRef = React.useRef(null);
	const [loading, setLoading] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(null);

	const handleSubmit = async (isApproved) => {
		// Verify
		const authFormik = authRef.current.formik;
		const resultFormik = resultRef.current.formik;
		const authValidation = await authFormik.validateForm();
		const resultValidation = await resultFormik.validateForm();
		const isAuthValid = Object.keys(authValidation).length === 0;
		const isResultValid = Object.keys(resultValidation).length === 0;
		if (!isResultValid || !isAuthValid) return;

		// Prepare Data
		setLoading(true);
		let user = authRef.current.getUser();
		let data = { 
			truck_transaction: props.data.id, 
			inspector:  user.id,
			is_approved: isApproved,
		}
		
		const resultValue = resultFormik.values;
		let results = []
		for (let key in resultValue) {
			if ((key === "grade") || (key === "note")) {
				data[key] = resultValue[key]
				continue;
			}

			results.push({
				chemical: key,
				amount: resultValue[key]
			})
		}
		data = { ...data, results: results }
		
		// Create quality check
		let response = null
		try {
			response = await POST(URL_LAB.TRUCK_TRANSACTION_QUALITY_CHECK, data);
		} catch (error) {
			setErrorMessage(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	const handleConfirm = () => handleSubmit(true);
	const handleCancel = () => handleSubmit(false);

	React.useEffect(() => {
		if (props.open) {
			setErrorMessage(null);
		}
	}, [props.open])

	return (
		<ConfirmModal
			open={props.open}
			title={"ตรวจคุณภาพน้ำยาง"}
			size={"tiny"}
			loading={loading}
			negativeText={"ปฏิเสธ"}
			positiveText={"ยอมรับ"}
			negativeColor={"red"}
			onClose={props.onClose}
			onConfirm={handleConfirm}
			onCancel={handleCancel}
			detail={
				<div>
					{errorMessage && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessage}/>
					)}
					
					<Form style={{textAlign: "right"}}>
						<Form.Field inline>
							<label style={{width: "17%"}}>ทะเบียนรถ</label>
							<Input style={{width: "78%"}} value={props.data ? props.data.license_plate : ""}/>
						</Form.Field> 
						<Form.Field inline>
							<label style={{width: "17%"}}>จุดรับซื้อ</label>
							<Input style={{width: "78%"}} value={props.data ? props.data.customer_name : ""}/>
						</Form.Field>
						<Form.Field inline>
							<label style={{width: "17%"}}>ชื่อสินค้า</label>
							<Input style={{width: "78%"}} value={props.data ? props.data.product_name : ""}/>
						</Form.Field>
					</Form>
					
					{/* Lab */}
					<LabQualityResult 
						style={{marginTop: 24}}
						ref={resultRef}/>

					{/* Authentication */}
					<LabAuth 
						style={{marginTop: 24}}
						ref={authRef}/>
				</div>
			}
		/>
	)
}

QualityCheckedModal.defaultProps = {
	open: false,
	data: null,
	onClose: () => {},
}

QualityCheckedModal.propTypes = {
	open: propTypes.bool,
	data: propTypes.object,
	onClose: propTypes.func,
}