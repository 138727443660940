import React, { useMemo } from 'react';
import moment from "moment";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message } from 'semantic-ui-react';
import { MonthInput, YearInput } from 'semantic-ui-calendar-react';
import TSReactTable from "../../frameworks/TSReactTable";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { URL_REPORT } from '../../constances/urls';
import { GET } from '../../frameworks/HttpClient';
import { currencyFormat } from "../../frameworks/Util";
import { MONTH_STRING_TH } from '../../constances';

export default function ExpenseMonthlyReportView() {
	const [monthFilter, setMonthFilter] = React.useState(moment().format("MM"));
	const [yearFilter, setYearFilter] = React.useState(moment().format("YYYY"));
	// Table
	const [sumPrice, setSumPrice] = React.useState(0);
	const [sumVat, setSumVat] = React.useState(0);
	const [sumTotal, setSumTotal] = React.useState(0);
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columns = useMemo(() => [
		...getColumnsList([
			{ Header: "ลำดับที่", accessor: "order", width: 60 },
			{ Header: "วันที่", accessor: "payment_date", width: 100 },
			{ Header: "ใบกำกับภาษี", accessor: "tax_invoice_no", style: {textAlign: "left"}, width: 140 },
			{ Header: "รหัสเจ้าหนี้", accessor: "supplier_code", width: 100 },
			{ Header: "เลขที่ PO", accessor: "code", width: 120 },
			{ Header: "ร้านค้า", accessor: "supplier_name", style: {textAlign: "left"}, width: 240 },
			{ Header: "เลขที่บัญชี", accessor: "account_code", style: {textAlign: "left"},width: 120 },
			{ 
				Header: "รายการบัญชี", 
				accessor: "account_name", 
				style: {textAlign: "left"},
				width: 180 
			},
			{ 
				Header: "ราคา", 
				accessor: "price",
				style: {textAlign: "right"},
				Cell: ({value}) => (<p>{currencyFormat(value)}</p>),
				Footer: currencyFormat(sumPrice),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
			{ 
				Header: "ภาษีซื้อ", 
				accessor: "vat",
				style: {textAlign: "right"},
				Cell: ({value}) => (<p>{currencyFormat(value)}</p>),
				Footer: currencyFormat(sumVat),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
			{ 
				Header: "จำนวนเงิน", 
				accessor: "total_price", 
				style: {textAlign: "right"},
				Cell: ({value}) => (<p>{currencyFormat(value)}</p>),
				Footer: currencyFormat(sumTotal),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
			{ 
				Header: "หมายเหตุ", 
				accessor: "note", 
				style: {textAlign: "left"},
				width: 160 
			},
		]),
	])

	const fetchData = async () => {
		setLoading(true)
		setErrorMessages(null)
		const params = {
			month: monthFilter,
			year: yearFilter
		}
		try {
			const response = await GET(URL_REPORT.EXPENSE_MONTHLY_REPORT, params);
			setData(response.data)
			if (response.data) {
				/** Calculate total */
				let sum1 = response.data.reduce((prev, current) => parseFloat(prev) + parseFloat(current.price), 0);
				let sum2 = response.data.reduce((prev, current) => parseFloat(prev) + parseFloat(current.vat), 0);
				let sum3 = response.data.reduce((prev, current) => parseFloat(prev) + parseFloat(current.total_price), 0);
				setSumPrice(sum1);
				setSumVat(sum2);
				setSumTotal(sum3);
			}
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const gotoExport = (exportType) => {
		const params = `month=${monthFilter}&year=${yearFilter}`
		window.open(`${URL_REPORT.EXPENSE_MONTHLY_REPORT_EXPORT}${exportType}/?${params}`)
	}

	React.useEffect(() => {
	}, []);

	return (
		<Container fluid style={{marginTop: 24}}>
			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group inline>
					<label>{"เดือน"}</label>
						{/* Warning: Only available current year */}
						<Form.Field
							control={MonthInput}
							dateFormat="MM"
							locale="en-US"
							placeholder={"เลือก..."}
							name={"month"}
							value={MONTH_STRING_TH[monthFilter]}
							onChange={(_, data) => setMonthFilter(data.value)}
						/>
						<label>{"ค.ศ."}</label>
						<Form.Field
							control={YearInput}
							dateFormat="YYYY"
							locale="en-US"
							maxDate={moment()}
							placeholder={"เลือก..."}
							name={"year"}
							value={yearFilter}
							onChange={(_, data) => setYearFilter(data.value)}
						/>

					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
				
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport("pdf")}
					>
						<Icon name={"file pdf"}/>
						PDF
				</Button>

				<Button 
					style={{marginTop: 8}}
					color={"green"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport("excel")}
					>
						<Icon name={"file excel"}/>
						Excel
				</Button>
			</Segment>
		</Container>
	)
}