import React from 'react';
import { Container, Icon, Message } from 'semantic-ui-react';
import { POND_STATUS } from "../constances";
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import sale from "../assets/img_sale.png";
import { TABLE_HEADER_STYLE, TABLE_BODY_STYLE, getColumnsList } from "../utils/Util";
import useListItem from "../frameworks/hooks/useItemList";
import { URL_POND } from "../constances";
import { dateTimeToString, formatComma } from "../frameworks/Util";

export default function StoreOfPondView() {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const list = useListItem({url: `${URL_POND.POND_STORE}`});
	
	const history = useHistory();

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่", 
				accessor: "date", 
				width: 150,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>) 
			}, 
			{ Header: "เลขที่บ่อพัก", accessor: "name" },
			{ Header: "เกรดผลิต", accessor: "closing_grade", width: 110},
			{ 
				Header: "นำ้หนัก (Kg)", 
				accessor: "weight",
				Cell: (row) => {
					const weight = row.original.weight;
					const isIncomplete = row.original.warning_incomplete_weight;
					return (
						<div>
							{formatComma(weight)}
							{isIncomplete && (
								<Icon name={"warning sign"} color={"yellow"} style={{marginLeft: 8}}/>
							)}
						</div>
					)
				}
			},
		]),
		{
			Header: "สถานะ",
			headerStyle: TABLE_HEADER_STYLE,
			Cell: (row) => {
				const STATUS = POND_STATUS[row.original.state];
				return (<p style={{...TABLE_BODY_STYLE, height: "100%",}}>{STATUS}</p>)
			}
		},
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 120,
			Cell: (row) => {
				const STATUS = POND_STATUS[row.original.state];
				return (
					<div>
						{(STATUS === POND_STATUS.PRODUCE) && (
							<ImageButton 
								title={"ขายน้ำยางสด"}
								src={sale}
								onClick={(e, data) => handleSale(row.original)}/>
						)}
					</div>
				)
			}
		},
	]);

	const handleSale = (data) => {
		// Go to sale
		history.push({pathname: `${PATH.SALE_FIELD_LATEX}${data.id}`})
	}

	React.useEffect(() => {
		list.fetchData();
	}, []);

	return (
		<Container fluid style={{marginTop: 24}}>
			{/* Error */}
			{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

			{/* Table */}
			<TSReactTable
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>

		</Container>
	);
}