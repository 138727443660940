import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Header, Grid, Dimmer, Loader, Message, Button, Form, Dropdown, Segment, Input, Checkbox, TextArea, List } from "semantic-ui-react";
import TitleBar from "../../components/common/TitleBar";
import QuotationSegment from "../../components/store/QuotationSegment";
import PricingSegment from "../../components/store/PricingSegment";
import AccountingCodeModal from "../../components/store/AccountingCodeModal";
import PurchaseProductHistoryModal from "../../components/store/PurchaseProductHistoryModal";
import { DateInput } from "semantic-ui-calendar-react";
import Cookies from 'js-cookie';
import { USER_GROUP, STORE_DOC_OPTIONS, TRANSACTION_TYPE_WORKFLOW, URL_PURCHASE } from "../../constances";
import { DELETE, GET, PATCH, POST } from "../../frameworks/HttpClient";
import { TRANSACTION_TYPE } from "../../constances/string";
import { PATH } from "../../routes/Routes";
import { Toast } from "../../frameworks/Toast";
import ConfirmModalWithNote from "../../components/common/ConfirmModalWithNote";
import { formatComma } from "../../frameworks/Util";
import moment from "moment";

const ContactInput = React.forwardRef((props, ref) => {
	const [company, setCompany] = React.useState(props.data.company);
	const [name, setName] = React.useState(props.data.name);
	const [tel, setTel] = React.useState(props.data.tel);
	const [date, setDate] = React.useState(props.data.date);

	React.useImperativeHandle(ref, () => ({ 
		getValue: () => {
			const isEmpty = (!company || !name || !tel || !date) ? true : false
			const data = {
				id: props.data.id,
				company: company,
				name: name,
				tel: tel,
				date: date,
			}
			return [isEmpty, data];
		}
	}));

	return (
		<Form.Group>
			<Form.Field 
				width={6}
				readOnly={props.readOnly}
				label={"บริษัท/ห้างร้าน"}
				control={Input}
				placeholder={"ร้าน/บริษัท..."}
				value={company}
				onChange={(_, {value}) => setCompany(value)}/>
			
			<Form.Field 
				width={4}
				readOnly={props.readOnly}
				label={"บุคคลที่ติดต่อ"}
				control={Input}
				placeholder={"ชื่อ..."}
				value={name}
				onChange={(_, {value}) => setName(value)}/>

			<Form.Field 
				width={3}
				readOnly={props.readOnly}
				label={"โทรศัพท์"}
				control={Input}
				placeholder={"0xx-xxxxxxx"}
				value={tel}
				onChange={(_, {value}) => setTel(value)}/>
			
			<Form.Field 
				width={3}
				readOnly={props.readOnly}
				label={"วันที่"}
				control={DateInput}
				dateFormat={"YYYY-MM-DD"}
				placeholder={"วันที่"}
				value={date}
				onChange={(_, {value}) => setDate(value)}/>
			
			{/* Button */}
			<div style={{marginTop: "auto"}} >
				{(!props.canDelete) && (
					<Button
					icon={"add"} 
					color={"green"}
					disabled={props.readOnly}
					onClick={() => props.onAdd()}/>
				)}
				{(props.canDelete) && (
					<Button
					icon={"minus"} 
					color={"red"}
					disabled={props.readOnly}
					onClick={() => props.onDelete(props.data)}/>
				)}
			</div>
		</Form.Group>
	)
});

const initialModel = {
	id: 1,
	company: "",
	name: "",
	tel: "",
	date: "",
}

const defaultPurchaseDetail = {
	id: null,
	product: {
		id: null,
		name: "",
		type: "",
		unit: "",
		amount: 0,
	},
	amount: 0,
	department: "",
	note: "",
	is_control: false,
	is_eco_friendly: false,
	account_code: null,
	transaction_type: "",
	state: "",
	state_name: "",
	selected_quotation: null
}

let targetId = 1;
export default function PurchaseDetailScreen() {
	const params = useParams();
	const history = useHistory();
	const [user, setUser] = React.useState(JSON.parse(Cookies.get("user")));
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [openHistory, setOpenHistory] = React.useState(false);
	const [openReject, setOpenReject] = React.useState(false);
	const [openAccounting, setOpenAccounting] = React.useState(false);
	const [data, setData] = React.useState(defaultPurchaseDetail);
	const [note, setNote] = React.useState("");
	const [purchaseTypeRender, setPurchaseTypeRender] = React.useState("quotation");
	const quotationSegmentRef = React.useRef();
	const pricingSegmentRef = React.useRef();
	// Contact
	const [contactData, setContactData] = React.useState([]);
	const contactDataRef = React.useRef([]);
	const [trigger, setTrigger] = React.useState(false);

	const canEdit = (data.state === "ATTACH_QUOTATION" || data.state === "NOT_APPROVED");

	const fetchData = async () => {
		if (!params.id) {
			setErrorMessages("ID Not found!");
			return;
		}

		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_REQUEST}${params.id}/`);
			setData({...defaultPurchaseDetail, ...response.data});
			setNote(response.data.note)
			if (response.data.contact_person.length > 0)
				setContactData(response.data.contact_person);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleSubmit = async () => {
		setErrorMessages(null);
		setLoading(true);
		try {
			// Save PR
			const isSuccess = await handleSave()
			if (!isSuccess) {
				return;
			}

			let action = "reverse_to_state_wait_approved";
			if (data.state === "ATTACH_QUOTATION")
				action = "to_state_wait_approved";
			// Change State
			await POST(`${TRANSACTION_TYPE_WORKFLOW[data.transaction_type]}${data.object_id}/${action}/`);
			// history.push(PATH.HOME);
			history.goBack();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleDoAction = async (action, params) => {
		setErrorMessages(null);
		setLoading(true);
		try {
			await POST(`${TRANSACTION_TYPE_WORKFLOW[data.transaction_type]}${data.object_id}/${action}/`, {...params});
			setOpenReject(false);
			setOpenAccounting(false);
			// history.push(PATH.HOME);
			history.goBack();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleSave = async () => {
		let isSuccess = false;
		setErrorMessages(null);
		setLoading(true);
		// Save contact person
		for (let i in contactData) {
			let [isEmpty, myData] = contactDataRef.current[i].getValue();
			// Don't create and update object that empty field
			if (!isEmpty) {
				let [response, error] = await saveContactPerson({...myData, purchase_request: params.id});
				if (!response) {
					setErrorMessages(error);
					return false;
				}
			} 
		}

		try {
			if (purchaseTypeRender === "quotation") {
				if (!quotationSegmentRef.current) {
					return false;
				}	
				const isSelectSuccess = await quotationSegmentRef.current.handleSelectQuotation()
				if (!isSelectSuccess) {
					return false;
				}
			} else if (purchaseTypeRender === "set_price") {
				if (!pricingSegmentRef.current) {
					return false;
				}	
				const isSaveSuccess = await pricingSegmentRef.current.handleSaveQuotation();
				if (!isSaveSuccess) {
					return false;
				}
			}
			await PATCH(`${URL_PURCHASE.PURCHASE_REQUEST}${params.id}/`, { note: note });	
			Toast.success('สำเร็จ')
			fetchData();
			if (purchaseTypeRender === "quotation") {
				quotationSegmentRef.current.fetchData();
			}
			isSuccess = true;
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
		return isSuccess;
	}

	const handleAddContact = () => {
		targetId = targetId + 1;
		setContactData([
			...contactData,
			{...initialModel, id: targetId},
		])
	}

	const handleDeleteContact = async (target) => {
		// Delete from server
		if (target.id.length && (target.id.length > 0)) {
			setLoading(true);
			try {
				await DELETE(`${URL_PURCHASE.PURCHASE_CONTACT_PERSON}${target.id}/`);
			} catch (error) {
				setLoading(false);
				setErrorMessages(error.errorMessages);
				return;
			}
			setLoading(false);
		}

		// Delete from local
		let myList = contactData;
		let index = 0;
		for (let i = 0; i < myList.length; i++) {
			if (myList[i].id === target.id) {
				index = i;
				break;
			}
		}
		if (index > 0) {
			myList.splice(index, 1);
			setContactData(myList);
			setTrigger(!trigger);
		}
	}

	const saveContactPerson = async (obj) => {
		let response = null;
		let error = null;
		try {
			if (obj.id.length && (obj.id.length > 0)) {
				// Update
				response = await PATCH(`${URL_PURCHASE.PURCHASE_CONTACT_PERSON}${obj.id}/`, obj);
			} else {
				response = await POST(URL_PURCHASE.PURCHASE_CONTACT_PERSON, obj);
			}
		} catch (error) {
			error = error.errorMessages;
		}
		return [response, error];
	}

	const handleQuotationSelectCleared = () => {
		setData({ ...data, selected_quotation: null})
	}

	React.useEffect(() => {
		if(data) {
			if(data.selected_quotation) {
				setPurchaseTypeRender(data.selected_quotation.type);
			}
			else if (data.transaction_type === "cash") {
				setPurchaseTypeRender("set_price");
			}
		}
	}, [data])

	React.useEffect(() => {
		setContactData([initialModel]);
		targetId = 1;
		fetchData();
	}, []);

	return (
		<Container fluid>
			<TitleBar title={`ขออนุมัติสั่งซื้อ [${data.product.name}]`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{(data && data.memo_not_approve) && (data.state !== "WAIT_PRODUCT_APPROVE_REQUESTED") && (
					<Message warning>
						<Message.Header>{"เหตุผลการปฏิเสธ"}</Message.Header>
						<List bulleted items={data.memo_not_approve.split(", ")} />
					</Message>
				)}
				{(data && data.memo_wait_product_approve_requested) && (
					<Message warning>
						<Message.Header>{"รายการแก้ไขการสั่งซื้อ"}</Message.Header>
						<div dangerouslySetInnerHTML={{ __html: data.memo_wait_product_approve_requested }} />
					</Message>
				)}

				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Header as={"h2"} textAlign={"center"}>รายละเอียด</Header>
				<Grid style={{marginTop: 16}}>
					<Grid.Column width={6} textAlign={"left"}>
						<label style={{fontSize: 15}}>
							ชื่อสินค้า: {(data && data.ref_product) ? `${data.product.name} (${data.ref_product.name})` : data.product.name}
						</label>
					</Grid.Column>
					<Grid.Column width={4} textAlign={"center"}>
						<label style={{fontSize: 15}}>{`จำนวน: ${formatComma(data.amount)} ${data.unit}`}</label>
					</Grid.Column>
					<Grid.Column width={6} textAlign={"right"}>
						<label style={{fontSize: 15}}>ประเภทการสั่งซื้อ: {TRANSACTION_TYPE[data.transaction_type]}</label>
					</Grid.Column>
					<Form>
						<Form.Group inline>
							<label>ประเภทสินค้า: </label>
							<Form.Field 
								control={Checkbox}
								label={"ควบคุม"}
								checked={data.is_control}/>
							<Form.Field 
								control={Checkbox}
								label={"ไม่ควบคุม"}
								checked={!data.is_control}/>
							<Form.Field 
								control={Checkbox}
								label={"เป็นมิตร"}
								checked={data.is_eco_friendly}/>
							<Form.Field 
								control={Checkbox}
								label={"ไม่เป็นมิตร"}
								checked={!data.is_eco_friendly}/>
						</Form.Group>
					</Form>
				</Grid>

				{ data.transaction_type === "cash" && (
					<Segment basic style={{padding: 0, marginTop: 24}}>
						<Form>
							<Form.Field 
								inline
								label={"เอกสารประกอบ"}
								control={Dropdown}
								selection
								disabled={!canEdit || data.selected_quotation != null}
								placeholder={"เลือก..."}
								options={STORE_DOC_OPTIONS}
								value={purchaseTypeRender}
								selectOnBlur={false}
								onChange={(_,{value}) => setPurchaseTypeRender(value)}/>
						</Form>
					</Segment>				
				)}
				
				<Segment style={{marginTop: 24}}>
					{ purchaseTypeRender === "quotation" && (
						<QuotationSegment 
							ref={quotationSegmentRef}
							initialModel={data}
							canEdit={canEdit}
							onSelectCleared={handleQuotationSelectCleared}/>
					)}

					{ purchaseTypeRender === "set_price" && (
						<PricingSegment 
							ref={pricingSegmentRef}
							initialModel={data}
							canEdit={canEdit}/>
					)}

					<Form style={{marginTop: 16}}>
						<Form.Field label={"กรณีสอบถามราคาทางโทรศัพท์"}/>
						<Segment>
							{contactData.map((item, index) => {
								return (
									<ContactInput 
										ref={(el) => (contactDataRef.current[index] = el)}
										key={item.id}
										data={item} 
										onAdd={handleAddContact} 
										onDelete={handleDeleteContact}
										canDelete={index !== 0}
										readOnly={!canEdit}/>
								)
							})}
						</Segment>
						<Form.Field 
							label={"หมายเหตุ"}
							control={TextArea}
							placeholder={"เหตุผล..."}
							readOnly={!canEdit}
							value={note ? note : ""}
							onChange={(_, data) => setNote(data.value)}/>
					</Form>
				</Segment>

				<div style={{marginTop: 24, textAlign: "center"}}>
				{canEdit && (
						<Button
						icon={"save"}
						color={"yellow"}
						content={"บันทึก"}
						onClick={() => handleSave()}/>
					)}
					{canEdit && (
						<Button 
							primary 
							onClick={() => setOpenAccounting(true)}>
							ขออนุมัติสั่งซื้อ
						</Button>
					)}
					
					{((data.state === "WAIT_APPROVED") || (data.state === "WAIT_PRODUCT_APPROVE_REQUESTED")) && (
						<>
							{(user.groups.find(group => group.name === USER_GROUP.MANAGER)) ? (
								<>
									<Button negative onClick={() => setOpenReject(true)}>
										ไม่อนุมัติ
									</Button>
									<Button positive onClick={() => {
										if (data.state === "WAIT_APPROVED")
											setOpenAccounting(true)
										else if (data.state === "WAIT_PRODUCT_APPROVE_REQUESTED")
											handleDoAction("approve_receive_product");
									}}>
										อนุมัติ
									</Button>
									<Button primary onClick={() => setOpenHistory(true)}>
										ประวัติ
									</Button>
								</>
							) : (<></>)}
						</>
					)}
				</div>
			</Container>
						
			<ConfirmModalWithNote
				title={"ไม่อนุมัติรายการสั่งซื้อ"}
				loading={loading}
				errorMessages={errorMessages}
				open={openReject}
				onClose={() => setOpenReject(false)}
				onConfirm={(values) => {
					if (data.state === "WAIT_APPROVED")
						handleDoAction("to_state_not_approved", { memo: values.note });
					else if (data.state === "WAIT_PRODUCT_APPROVE_REQUESTED")
						handleDoAction("to_state_wait_product_approve_rejected", { memo: values.note });
				}}
			/>
			
			<AccountingCodeModal 
				title={"ยืนยันรหัสทางบัญชี"}
				open={openAccounting}
				data={data}
				onSuccess={() => {
					if (data.state === "WAIT_APPROVED")
						handleDoAction("to_state_approved");
					else
						handleSubmit();
				}}
				onClose={() => setOpenAccounting(false)}/>

			<PurchaseProductHistoryModal
				open={openHistory}
				product={data.product}
				onClose={() => setOpenHistory(false)}/>
			
		</Container>
	)
}