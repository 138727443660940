import React from "react";
import propTypes from "prop-types";
import { Segment, Message, Button, Dimmer, Loader , Icon, Checkbox} from "semantic-ui-react";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../common/ImageButton";
import QuotationModal from "./QuotationModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import edit from "../../assets/img_edit.png";
import remove from "../../assets/img_delete.png";
import { DELETE, GET, POST } from "../../frameworks/HttpClient";
import { URL_PURCHASE } from "../../constances";
import { formatComma, formatFileName } from "../../frameworks/Util";
import ConfirmModal from "../common/ConfirmModal";

const QuotationSegment = React.forwardRef((props, ref) => {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const [addQT, setAddQT] = React.useState(false);
	const [selectQuotation, setSelectQuotation] = React.useState(null);
	const [note, setNote] = React.useState("");
	const [data, setData] = React.useState([]);

	// delete confirm modal
	const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
	const [deleteTarget, setDeleteTarget] = React.useState(null);
	const [targetEdit, setTargetEdit] = React.useState(null);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "", 
				width: 60,
				Cell: (row) => {
					let id = row.original.id;
					return (
						<Checkbox 
							style={{margin: "auto"}} 
							checked={selectQuotation === id}
							disabled={!props.canEdit}
							onClick={() => {
								if (!props.canEdit) {
									return;
								}
								if (selectQuotation === id) {
									setSelectQuotation(null);
								} else {
									setSelectQuotation(id)
								}
							}}/>
					)
				}
			},
			{ Header: "ผู้ขาย", accessor: "supplier.name" },
			{ Header: "ราคาต่อหน่วย", accessor: "unit_price", width: 120, Cell: ({ value }) => formatComma(value, false, 4) },
			{ Header: "ราคารวม", accessor: "total_price", width: 120, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคาจริง", accessor: "real_price", width: 120, Cell: ({ value }) => formatComma(value) },
			{ Header: "ใบเสนอราคา", accessor: "attachment", Cell: ({ value }) => formatFileName(value) },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: (row) => {
				return (
					<div>
						{props.canEdit && 
							<ImageButton 
								title={"ลบ"}
								color={"red"}
								src={remove}
								onClick={(e, data) => {
									setIsDeleteModalVisible(true);
									setDeleteTarget(row.original);
								}}/>
						}
						{props.canEdit && 
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={(e, data) => {
									setTargetEdit(row.original);
								}}/>
						}
					</div>
				)
			}
		},
	]);

	const handleDelete = async (item) => {
		setIsDeleteModalVisible(false);
		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await DELETE(`${URL_PURCHASE.QUOTATION}${item.id}`);
			await fetchData();
			if(selectQuotation == null) {
				props.onSelectCleared()
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const fetchData = async () => {
		if (!props.initialModel.id) {
			return;
		}

		setSelectQuotation(null);
		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await GET(`${URL_PURCHASE.QUOTATION}`, { purchase_request: props.initialModel.id });
			setData(response.data);
			response.data.map(e => {
				if (e.selected === true) {
					setSelectQuotation(e.id)
				}
			})
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleSelectQuotation = async () => {
		if (!selectQuotation) {
			setErrorMessages("กรุณาเลือกใบเสนอราคา")
			return false;
		}
		let isSuccess = false;
		setErrorMessages(null);
		setLoading(true);
		try {
			await POST(`${URL_PURCHASE.PURCHASE_REQUEST}${props.initialModel.id}/select_quotation/`, {selected_quotation: selectQuotation});
			isSuccess = true;
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		return isSuccess;
	}

	React.useImperativeHandle(ref, () => ({
		handleSelectQuotation: () => handleSelectQuotation(),
		fetchData: () => fetchData()
	}));

	React.useEffect(() => {
		setAddQT(targetEdit ? true : false);
	}, [targetEdit])

	React.useEffect(() => {
		if (!addQT)
			fetchData();
			
	}, [props.initialModel, addQT]);

	return (
		<Segment basic style={{padding: 0, ...props.style}}>
			<Dimmer inverted active={loading}>
        <Loader inverted>โหลดข้อมูล...</Loader>
      </Dimmer>
      {errorMessages && (
        <Message negative content={errorMessages}/>
			)}
			{ (props.canEdit && props.initialModel) && 
				<Button 
					positive 
					onClick={() => setAddQT(true)}>
					<Icon name={"add"}/>
					เพิ่มใบเสนอราคา
				</Button>
			}

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={(data && (data.length > 0)) ? data.length : 5}
				showPagination={false}/>

			<QuotationModal 
				open={addQT}
				prModel={props.initialModel}
				initialModel={targetEdit}
				onClose={() => {
					if (targetEdit) setTargetEdit(null);
					else setAddQT(false)
				}}/>
			
			<ConfirmModal
				open={isDeleteModalVisible}
				loading={loading}
				title={"ลบใบเสนอราคา"}
				detail={
					<Message warning>
						<Message.List>
							<Message.Item>ผู้ขาย: {deleteTarget ? deleteTarget.supplier.name : ""}</Message.Item>
							<Message.Item>ราคาต่อหน่วย: {deleteTarget ? formatComma(deleteTarget.unit_price) : ""}</Message.Item>
							<Message.Item>ราคารวม: {deleteTarget ? formatComma(deleteTarget.total_price) : ""}</Message.Item>
							<Message.Item>ราคาจริง: {deleteTarget ? formatComma(deleteTarget.real_price) : ""}</Message.Item>
							<Message.Item>ใบเสนอราคา: {deleteTarget ? formatFileName(deleteTarget.attachment) : ""}</Message.Item>
						</Message.List>
					</Message>
				}
				onClose={() => {
					setIsDeleteModalVisible(false);
					setDeleteTarget(null);
				}}
				onCancel={() => {
					setIsDeleteModalVisible(false);
					setDeleteTarget(null);
				}}
				onConfirm={() => handleDelete(deleteTarget)}/>
		</Segment>
	)
})

export default QuotationSegment;

QuotationSegment.defaultProps = {
	style: {},
	initialModel: {},
	canEdit: true,
	onSelectCleared: () => {},
}

QuotationSegment.propTypes = {
	style: propTypes.object,
	initialModel: propTypes.object,
	canEdit: propTypes.bool,
	onSelectCleared: propTypes.func,
}