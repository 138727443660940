import React from 'react';
import propTypes from "prop-types";
import { Form, Input, Message, TextArea } from 'semantic-ui-react';
import ConfirmModal from "../../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PATCH, POST } from "../../../frameworks/HttpClient";
import { URL_SALE } from "../../../constances";

export default function SaleFieldLatexModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	let validationSchema = {
		do_no: Yup.string().required("กรุณากรอกข้อมูล"),
		weight: Yup.string().required("กรุณากรอกข้อมูล"),
		note: Yup.string().notRequired(),
	}

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: defaultInitialModal,
    validationSchema: Yup.object(validationSchema),
    onSubmit: props.onSubmit,
  });

	const handleSubmit = async () => {
		// Verify
		const validation = await formik.validateForm();
		const isValid = Object.keys(validation).length === 0;
		if (!isValid) return;

		if (!props.id) {
			setErrorMessages("ไม่พบข้อมูล POND Transaction");
			return;
		}
		
		// Prepare data
		setLoading(true);
		const isCreate = props.initialModel ? false : true;
		let data = {...formik.values, pond_transaction: props.id}
		let response = null;
		try {
			if (isCreate) {
				// Create
				response = await POST(URL_SALE.RAW_LATEX_SELL_TRANSACTION, data);
			} else {
				// Update
				response = await PATCH(`${URL_SALE.RAW_LATEX_SELL_TRANSACTION}${props.initialModel.id}/`, data);
			}
				
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			formik.resetForm();
			setErrorMessages(null);

			if (props.initialModel)
				formik.setValues(props.initialModel);
		}
	}, [props.open])
	
	return (
		<ConfirmModal
			open={props.open}
			title={props.initialModel ? "แก้ไขการขายน้ำยางสด" : "ขายน้ำยางสด"}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={props.initialModel ? "แก้ไข" : "ยืนยัน"}
			positiveColor={props.initialModel ? "yellow" : "green"}
			onClose={props.onClose}
			onConfirm={handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}
					<Form 
						style={{width: "95%", margin: "auto" }}>
						<Form.Group inline>
							<label style={{width: "24%"}}>{"เลขที่ใบ DO"}</label>
							<Form.Field 
								width={12}
								control={Input}
								placeholder={"เลขที่"} 
								name={"do_no"}
								error={formik.errors.do_no}
								value={formik.values.do_no}
								onChange={formik.handleChange}
							/>
						</Form.Group>

						<Form.Group inline>
							<label style={{width: "24%"}}>{"น้ำหนักขาย (kg)"}</label>
							<Form.Field 
								width={12}
								control={Input}
								placeholder={"0"} 
								type={"number"}
								name={"weight"}
								error={formik.errors.weight}
								value={formik.values.weight}
								onChange={formik.handleChange}
							/>
						</Form.Group>

						<Form.Field 
							label={"หมายเหตุ"}
							control={TextArea}
							placeholder={"หมายเหตุ..."}
							name={"note"}
							error={formik.errors.note}
							value={formik.values.note}
							onChange={formik.handleChange}
						/>
					</Form>
				</div>
			}
		/>
	)
}

const defaultInitialModal = {
	id: "",
  do_no: "",
  weight: "",
	note: "",
};

SaleFieldLatexModal.defaultProps = {
	open: false,
	id: null,
	initialModel: null,
	onClose: () => {},
	
}

SaleFieldLatexModal.propTypes = {
	open: propTypes.bool,
	id: propTypes.string,
	initialModel: propTypes.object,
	onClose: propTypes.func,
}