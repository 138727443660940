import React from 'react';
import ReactTable from "react-table-v6";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default function TSReactTableFixedColumns(props) {

  return (
		<ReactTableFixedColumns
			noDataText={"ไม่มีข้อมูล"}
			defaultPageSize={10}
			showPagination={true}
			showPageSizeOptions={false}
			className="-striped -highlight"
			getTdProps={() => ({
				style: { whiteSpace: 'unset', textAlign: "center" }
			})}
			{...props}
			style={{borderRadius: 5, ...props.style}}
		/>
	)
}