import React from "react";
import propTypes from "prop-types";
import { Popup, Icon } from "semantic-ui-react";
import ListModal from "../common/ListModal";
import done from "../../assets/img_done.png";
import { URL_PURCHASE } from "../../constances";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { formatComma, formatFileName } from "../../frameworks/Util";
import ImageButton from "../common/ImageButton";
import { POST } from "../../frameworks/HttpClient";

export default function DOListModal(props) {
	const url = `${URL_PURCHASE.DELIVERY_ORDER}?purchase_request=${props.purchaseRequest ? props.purchaseRequest.id : ""}`
	const doRef = React.useRef();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const DOColumns = React.useMemo(() => [
		...getColumnsList([ 
			{
				Header: "Action",
				headerStyle: TABLE_HEADER_STYLE,
				width: 60,
				Cell: (row) => {
					let item = row.original;
					return (
						<div>
							{!props.readOnly && item.is_approved 
								&& (item.is_received === null) && (
								<ImageButton
									title={"รับสินค้า"}
									src={done}
									onClick={(e, data) => updateReceived(item)}/>
							)}
						</div>
					)
				}
			},
			{ 
				Header: "วันที่", 
				accessor: "created", 
				width: 90,
				Cell: ({ value }) => new Date(value).toLocaleDateString("en-GB") 
			},
			{ 
				Header: "รับมา", 
				accessor: "received_amount", 
				Cell: ({ original, value }) => {
					let unit = original.quotation ? original.quotation.purchase_request.unit : ""
					return <p>{`${formatComma(value)} ${unit}`}</p>
				}
			},
			{ 
				Header: "ส่งคืน", 
				accessor: "return_amount", 
				Cell: ({ original, value }) => {
					let myValue = "-"
					if (parseFloat(value) > 0) {
						let unit = original.quotation ? original.quotation.purchase_request.unit : ""
						myValue = `${formatComma(value)} ${unit}`
					}
					return (<p>{myValue}</p>)
				}
			},
			{ 
				Header: "รับเข้าสุทธิ", 
				accessor: "into_stock_amount", 
				Cell: ({ original, value }) => {
					let unit = original.quotation ? original.quotation.purchase_request.product.unit : ""
					return <p>{`${formatComma(value)} ${unit}`}</p>
				}
			},
			{ Header: "หมายเหตุการรับสินค้า", accessor: "note", width: 220 },
			{ 
				Header: "การอนุมัติ", 
				accessor: "is_approved",
				width: 70,
				Cell: ({ original, value }) => 
					value != undefined && value != null ?  
						value ? 
							<div style={{textAlign: "center"}}><Icon color='green' name="check"/></div> 
							: <div style={{textAlign: "center"}}>
								<Popup
									trigger={<Icon color='red' name="cancel"/>}
									content={original.reject_note}
									inverted
									basic/>
								</div> 
						: <div style={{textAlign: "center"}}><Icon color='grey' name="clock"/></div> 
			},
			{ 
				Header: "การรับสินค้า", 
				accessor: "is_received",
				width: 70,
				Cell: ({ original, value }) => 
					value != undefined && value != null ?  
						value ? 
							<div style={{textAlign: "center"}}><Icon color='green' name="check"/></div> 
							: <div style={{textAlign: "center"}}>
								<Popup
									trigger={<Icon color='red' name="cancel"/>}
									content={original.reject_note}
									inverted
									basic/>
								</div> 
						: <div style={{textAlign: "center"}}><Icon color='grey' name="clock"/></div> 
			},
			{ 
				Header: "ไฟล์แนบ", 
				accessor: "attachment", 
				width: 220, 
				Cell: ({ value }) => formatFileName(value) 
			},
		]) 
	]);

	/* Update DO (is_received, received_by) */
	const updateReceived = async (item) => {
		const doID = item.id
		if (!doID) {
			setErrorMessages("ID Not found!");
			return;
		}
		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await POST(`${URL_PURCHASE.DELIVERY_ORDER}${doID}/receive/`);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		doRef.current.refresh();
	}

	return (
		<ListModal
			ref={doRef}
			size={"large"}
			title={`รายการใบรับสินค้า : ${props.purchaseRequest ? props.purchaseRequest.product.name : ""}`}
			columns={DOColumns}
			open={props.open}
			onClose={props.onClose}
			url={url}
		/>
	)
}

DOListModal.defaultProps = {
	purchaseRequest: null,
	open: false,
	onClose: () => {},
	readOnly: true,
}

DOListModal.propTypes = {
	purchaseRequest: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
	readOnly: propTypes.bool,
}