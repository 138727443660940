import { useFormik } from "formik";
import React from "react";
import { Form, Message, TextArea } from "semantic-ui-react";
import * as Yup from 'yup';
import propTypes from "prop-types";
import ConfirmModal from "./ConfirmModal";


export default function ConfirmModalWithNote(props) {
  const {
    title,
    size,
    loading,
    errorMessages,
    open,
    onClose,
    onConfirm,
  } = props;

  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			note: "",
		},
		validationSchema: Yup.object().shape({
      note: Yup.string().required("กรุณากรอกข้อมูล"),
    }),
		onSubmit: (values) => onConfirm(values),
  })

  React.useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [open])

  return (
    <ConfirmModal
      open={open}
      title={title}
      size={size}
      loading={loading}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={formik.handleSubmit}
      detail={
        <Form error={errorMessages} >
          { errorMessages && 
            <Message error>{errorMessages}</Message>
          }
          <Form.Field
            required
            label="กรุณากรอกเหตุผล"
            control={TextArea}
            placeholder="เหตุผล..."
            name="note"
            error={formik.errors.note}
            value={formik.values.note}
            onChange={formik.handleChange}
          />
        </Form>
      }
    />
  )
}

ConfirmModalWithNote.defaultProps = {
  title: "",
  size: "tiny",
  loading: false,
  errorMessages: null,
  open: false,
  onClose: () => null,
  onConfirm: () => null,
}

ConfirmModalWithNote.propTypes = {
  title: propTypes.string,
  size: propTypes.string,
  loading: propTypes.bool,
  errorMessages: propTypes.string,
  open: propTypes.bool,
	onClose: propTypes.func,
  onConfirm: propTypes.func,
}
