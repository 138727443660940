import 'dotenv'
import React from 'react';
import { toast } from 'react-toastify';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes/Routes';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import { MediaContextProvider } from "./AppMedia";
import { Dimmer, Loader } from 'semantic-ui-react';


toast.configure();
function App(props) {

  // const {
  //   listen
  // } = props;

  return (
    <MediaContextProvider>
      <React.Suspense fallback={
        <Dimmer active>
          <Loader>Loading</Loader>
        </Dimmer>
      }>
        <Router>
          <div className="App">
            {/* <Routes listen={listen} /> */}
            <Routes />
          </div>
        </Router>
      </React.Suspense>
    </MediaContextProvider>
  );
}

export default App;
