import React from "react";
import moment from "moment";
import { Container, Form, Button, Icon, Segment, Dimmer, Loader, Message, Dropdown } from "semantic-ui-react";
import { DateInput } from 'semantic-ui-calendar-react';
import TSReactTable from "../../frameworks/TSReactTable";
import { URL_REPORT } from "../../constances/urls";
import { GET } from "../../frameworks/HttpClient";
import { formatComma } from "../../frameworks/Util";
import ReportVerify from "../../components/report/ReportVerify";
import Cookies from 'js-cookie';
import { USER_GROUP } from "../../constances";
import { LATEX_GRADE_OPTIONS } from "../../constances/options";


export default function LabReceptionSummaryReportView () {
  const user = JSON.parse(Cookies.get("user"));
  const [date, setDate] = React.useState("");
  const [dateError, setDateError] = React.useState(false);
  const [grade, setGrade] = React.useState("");
  const [verifyDate, setVerifyDate] = React.useState(null);

  const initialData = [
    { header_name: 'PRODUCTION GRADE'},
    { header_name: 'น้ำหนักวัดเทียบ (KGS)' },
    { header_name: 'KGS. RECEIVED สะพานชั่ง' },
    { header_name: 'DIFT' },
    { header_name: 'INITIAL TSC (ก่อน)' },
    { header_name: 'INITIAL DRC (ก่อน)' },
    { header_name: 'INITIAL NH3 (ก่อน)' },
    { header_name: 'INITIAL Mg (ก่อน)' },
    { header_name: 'INITIAL VFA (ก่อน)' },
    { header_name: 'TIME SAMPLED (1st)'},
    { header_name: 'WATER ADDED' },
    { header_name: 'น้ำล้าง' },
    
    { header_name: 'SOAP(La^) ADDED' },
    { header_name: 'DAP ADDED' },
    { header_name: 'NH3 ADDED' },
    { header_name: 'TZ ADDED' },
    { header_name: 'อื่น ๆ' },
    { header_name: 'TOTAL DILUTION' },
    { header_name: '%DILUTION' },
    { header_name: 'TIME COMPLETED' },
    { header_name: 'TIME SAMPLED (2nd)' },
    { header_name: 'FINAL MEASURED' },

    { header_name: 'FINAL TSC (หลัง)' },
    { header_name: 'FINAL DRC (หลัง)' },
    { header_name: 'FINAL NH3 (หลัง)' },
    { header_name: 'FINAL Mg (หลัง)' },
    { header_name: 'FINAL VFA (หลัง)' },
    { header_name: 'DRY RUBBER BEFORE' },
    { header_name: 'DRY RUBBER AFTER' },
    { header_name: 'DIFT (Kg)' },
  ];
  const initialColumns = [
    {
      Header: "BULKING TANK NO.",
      accessor: "header_name",
      width: 180,
    }
  ];

  const [data, setData] = React.useState(initialData);
  const [columns, setColumns] = React.useState(initialColumns);

	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

  const fetchData = async () => {
    // Verify date
    if (!date || !moment(date, "DD/MM/YYYY", true).isValid()) {
      if (!dateError)
        setDateError(true);
      return;
    }
    
    if (dateError)
			setDateError(false);
    setLoading(true)
		setErrorMessages(null)
    const params = {
      date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      grade: grade,
    }
		try {
      const response = await GET(URL_REPORT.LAB_RECEPTION_SUMMARY_REPORT, params);
      const newData = initialData;

      setColumns([
        ...initialColumns,
        ...response.data.map(pond_data => {
          return {
            Header: pond_data.bulking_tank_no,
            accessor: pond_data.bulking_tank_no,
          }
        }),
      ])

      response.data.map(pond_data => {
        newData[0] = { ...newData[0], [pond_data.bulking_tank_no]: pond_data.production_grade };
        newData[1] = { ...newData[1], [pond_data.bulking_tank_no]: formatComma(pond_data.measure_weight) };
        newData[2] = { ...newData[2], [pond_data.bulking_tank_no]: formatComma(pond_data.kgs_received) };
        newData[3] = { ...newData[3], [pond_data.bulking_tank_no]: formatComma(pond_data.dift, true) };
        newData[4] = { ...newData[4], [pond_data.bulking_tank_no]: pond_data.initial_tsc };
        newData[5] = { ...newData[5], [pond_data.bulking_tank_no]: pond_data.initial_drc };
        newData[6] = { ...newData[6], [pond_data.bulking_tank_no]: pond_data.initial_nh3 };
        newData[7] = { ...newData[7], [pond_data.bulking_tank_no]: parseFloat(pond_data.initial_mg).toFixed(0) };
        newData[8] = { ...newData[8], [pond_data.bulking_tank_no]: pond_data.initial_vfa };
        newData[9] = { ...newData[9], [pond_data.bulking_tank_no]: pond_data.time_sampled_first };
        newData[10] = { ...newData[10], [pond_data.bulking_tank_no]: formatComma(pond_data.water_added) };
        newData[11] = { ...newData[11], [pond_data.bulking_tank_no]: formatComma(pond_data.washer_added) };

        newData[12] = { ...newData[12], [pond_data.bulking_tank_no]: formatComma(pond_data.la_added) };
        newData[13] = { ...newData[13], [pond_data.bulking_tank_no]: formatComma(pond_data.dap_added) };
        newData[14] = { ...newData[14], [pond_data.bulking_tank_no]: formatComma(pond_data.nh3_added) };
        newData[15] = { ...newData[15], [pond_data.bulking_tank_no]: formatComma(pond_data.tz_added) };
        newData[16] = { ...newData[16], [pond_data.bulking_tank_no]: formatComma(pond_data.other_chemical) };
        newData[17] = { ...newData[17], [pond_data.bulking_tank_no]: formatComma(pond_data.total_dilution) };
        newData[18] = { ...newData[18], [pond_data.bulking_tank_no]: pond_data.dilution };
        newData[19] = { ...newData[19], [pond_data.bulking_tank_no]: pond_data.time_completed };
        newData[20] = { ...newData[20], [pond_data.bulking_tank_no]: pond_data.time_sampled_second };
        newData[21] = { ...newData[21], [pond_data.bulking_tank_no]: formatComma(pond_data.final_measured) };

        newData[22] = { ...newData[22], [pond_data.bulking_tank_no]: pond_data.final_tsc };
        newData[23] = { ...newData[23], [pond_data.bulking_tank_no]: pond_data.final_drc };
        newData[24] = { ...newData[24], [pond_data.bulking_tank_no]: pond_data.final_nh3 };
        newData[25] = { ...newData[25], [pond_data.bulking_tank_no]: parseFloat(pond_data.final_mg).toFixed(0) };
        newData[26] = { ...newData[26], [pond_data.bulking_tank_no]: pond_data.final_vfa };
        newData[27] = { ...newData[27], [pond_data.bulking_tank_no]: formatComma(pond_data.dry_rubber_before) };
        newData[28] = { ...newData[28], [pond_data.bulking_tank_no]: formatComma(pond_data.dry_rubber_after) };
        newData[29] = { ...newData[29], [pond_data.bulking_tank_no]: formatComma(pond_data.dry_rubber_dift, true) };
      });

      setData(newData);
      setVerifyDate(date)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
  }

  const exportToPDF = () => {
    const params = `date=${moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')}&&grade=${grade}`
    window.open(
      `${URL_REPORT.LAB_RECEPTION_SUMMARY_REPORT_EXPORT}?${params}`
    )
  }

  React.useEffect(() => {
    if (window.location.search) {
      const sp = new URLSearchParams(window.location.search);
      if (sp.has("date")) {
        setDate(moment(sp.get("date")).format('DD/MM/YYYY'))
        setGrade("ALL")
      } 
    } else {
      setDate(moment().format('DD/MM/YYYY'))
      setGrade("OTHERS")
    }
  }, [])

  React.useEffect(() => {
    if (grade && date) {
      fetchData();
    }
    
  }, [grade, date])

  return (
		<Container fluid style={{marginTop: 24}}>
      <Form>
				<Form.Group inline>
					<p 
						style={{
							marginTop: "auto", 
							marginBottom: "auto", 
							marginRight: 16, 
							fontWeight: "bold"}}>
								{"วันที่"}
					</p>
					<Form.Field
						control={DateInput}
						dateFormat="DD/MM/YYYY"
						maxDate={moment()}
						placeholder={"เลือก..."}
            error={dateError}
						value={date}
						onChange={(_, data) => setDate(data.value)}/>
          
          <Form.Field style={{}}>
            <Button icon={"angle left"} primary onClick={() => setDate(moment(date, 'DD/MM/YYYY').add(-1, "days"))} />
            <Button icon={"angle right"} primary onClick={() => setDate(moment(date, 'DD/MM/YYYY').add(1, "days"))} />
          </Form.Field>
          
          <Form.Field 
            label={"เกรดผลิต"}
            control={Dropdown}
            selection
            value={grade}
            selectOnBlur={false}
            onChange={(_, data) => setGrade(data.value)}
            options={LATEX_GRADE_OPTIONS}/>
      
          <Button primary onClick={() => fetchData()}>ค้นหา</Button>
          <ReportVerify 
            reportType="LAB_LOG_SHEET" 
            reportDate={moment(verifyDate, 'DD/MM/YYYY').format('YYYY-MM-DD')} 
            buttonEnabled={!errorMessages && columns && (columns.length > 1) && (user.groups.find(group => group.name === USER_GROUP.LAB_REPORT_APPROVER))}
            readOnly={(user.groups.find(group => group.name === USER_GROUP.LAB)) ? false : true}
          />
				</Form.Group>
			</Form>
      { errorMessages && <Message error>{errorMessages}</Message> }
			<Segment basic style={{padding: 0}}>
        <Dimmer active={loading} inverted><Loader inverted/></Dimmer>
        <TSReactTable
          columns={columns}
          data={data}
          defaultPageSize={(data.length > 0) ? data.length : 10}
          showPagination={false}/>
        <Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={exportToPDF}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
      </Segment>
    </Container>
  )
}