import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import LoginScreen from "../screens/LoginScreen";
import GenerateReportScreen from "../screens/GenerateReportScreen";
import MenuScreen from "../screens/MenuScreen";
import LabPondScreen from "../screens/LabPondScreen";
import LabAdjustScreen from "../screens/LabAdjustScreen";
import LabControlScreen from "../screens/LabControlScreen";
import VerifyWeightScreen from "../screens/VerifyWeightScreen";
import PondDetailScreen from "../screens/PondDetailScreen";
import PondSummaryScreen from "../screens/PondSummaryScreen";
import TankDetailScreen from "../screens/TankDetailScreen";
import TankSummaryScreen from "../screens/TankSummaryScreen";
import SaleFieldLatexScreen from "../screens/sale/SaleFieldLatexScreen";
import SaleConcentratedLatexScreen from "../screens/sale/SaleConcentratedLatexScreen";
// Stock
import PurchaseDetailScreen from "../screens/store/PurchaseDetailScreen";
import PRDetailScreen from "../screens/store/PRDetailScreen";
import PODetailScreen from "../screens/store/PODetailScreen";
import CashPaymentScreen from "../screens/store/CashPaymentScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import MemoPaymentScreen from "../screens/store/MemoPaymentScreent";
import StockCountScreen from "../screens/StockCountScreen";
import SaleOrderLatexScreen from "../screens/sale/SaleOrderLatexScreen";

export const PATH = {
  LOGIN: "/login",
  HOME: "/",
  API_REPORT: "/api/report/",

  QUALITY: "/quality/",
  POND_TRUCK: "/pond-truck/",
  POND_DETAIL: "/pond-detail/",
  POND_SUMMARY: "/pond-summary/",
  TANK_DETAIL: "/tank-detail/",
  TANK_SUMMARY: "/tank-summary/",
  SALE_FIELD_LATEX: "/sale/field-latex/",
  SALE_ORDER_LATEX: "/sale/order-latex/",
  SALE_CON_LATEX: "/sale/concentrated-latex/",
  LAB_POND: "/lab-pond/",
  LAB_ADJUST: "/lab-adjust/",
  LAB_CONTROL: "/lab-control/",
  VERIFY_WEIGHT: "/verify-weight/",
  VALIDATE_TANK: "/validate-tank/",
  STORE_POND: "/store-pond/",
  STORE: "/store/",
  REQUISITION: "/requisition/",
  REQUISITION_APPROVAL: "/requisition_approval/",
  WITHDRAW_MANAGER: "/withdraw-manager/",
  PICKUP_GOODS: "/pickup-goods/",
  PR_MANAGER: "/pr-manager/",
  PURCHASE_DETAIL: "/purchase-detail/",
  PURCHASE_APPROVAL: "/purchase-approval/",
  PR_DETAIL: "/pr-detail/",
  PO_DETAIL: "/po-detail/",
  PO_MANAGER: "/po-manager/",
  RECEIVE_GOODS: "/receive-goods/",
  VERIFY_GOODS: "/verify-goods/",
  CASH_PAYMENT: "/cash-payment/",
  CREDIT_PAYMENT: "/credit-payment/",
  MEMO_PAYMENT: "/meno-payment/",
  INVENTORY: "/inventory/",
  STOCK_RECORD: "/stock-record/",
  STOCK_COUNT: "/store-count/",
  REPORT: "/report/",
}

export default function Routes(){

  return (
    <Switch>
      <Route exact path={PATH.LOGIN} component={LoginScreen} />
      <PrivateRoute path={PATH.API_REPORT} component={GenerateReportScreen} />
      {/* type: [pond, adjust, store] */}
      <PrivateRoute exact path={`${PATH.LAB_POND}:id`} component={LabPondScreen} />
      <PrivateRoute exact path={`${PATH.LAB_ADJUST}:id`} component={LabAdjustScreen} />
      <PrivateRoute exact path={`${PATH.LAB_CONTROL}:id`} component={LabControlScreen} />
      <PrivateRoute exact path={`${PATH.VERIFY_WEIGHT}:id`} component={VerifyWeightScreen} />
      <PrivateRoute exact path={`${PATH.POND_DETAIL}:id`} component={PondDetailScreen} />
      <PrivateRoute exact path={`${PATH.POND_SUMMARY}:id`} component={PondSummaryScreen} />
      <PrivateRoute exact path={`${PATH.TANK_DETAIL}:id`} component={TankDetailScreen} />
      <PrivateRoute exact path={`${PATH.TANK_SUMMARY}:id`} component={TankSummaryScreen} />
      <PrivateRoute exact path={`${PATH.SALE_FIELD_LATEX}:id`} component={SaleFieldLatexScreen} />
      <PrivateRoute exact path={`${PATH.SALE_ORDER_LATEX}:id`} component={SaleOrderLatexScreen} />
      <PrivateRoute exact path={`${PATH.SALE_CON_LATEX}:id`} component={SaleConcentratedLatexScreen} />
      {/* Stock */}
      <PrivateRoute exact path={`${PATH.PURCHASE_DETAIL}:id`} component={PurchaseDetailScreen} />
      <PrivateRoute exact path={`${PATH.PR_DETAIL}:id`} component={PRDetailScreen} />
      <PrivateRoute exact path={`${PATH.PO_DETAIL}:id`} component={PODetailScreen} />
      <PrivateRoute exact path={`${PATH.CASH_PAYMENT}:id`} component={CashPaymentScreen} />
      <PrivateRoute exact path={`${PATH.MEMO_PAYMENT}:id`} component={MemoPaymentScreen} />
      <PrivateRoute exact path={`${PATH.STOCK_RECORD}:id`} component={StockCountScreen} />
      <PrivateRoute exact path={PATH.HOME} component={MenuScreen} />
      <PrivateRoute path={`${PATH.HOME}:menu`} component={MenuScreen} />
      <Route path="*" component={NotFoundScreen}/>
    </Switch>
  )
}