import React from 'react';
import propTypes from "prop-types";
import { Form, Dropdown, Message, Input } from 'semantic-ui-react';
import ConfirmModal from "../common/ConfirmModal";
import { POST } from "../../frameworks/HttpClient";
import { URL_COMMON, URL_TANK } from "../../constances";
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import * as Yup from 'yup';
import { useFormik } from 'formik';

export default function CreateLotNoModal(props) {
	const gradeOptions = useDropdownItem({url: URL_COMMON.GRADE});
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	let validationSchema = {
		grade: Yup.string().required("กรุณาเลือกเกรด"),
		nh3_solution: Yup.number().required("กรุณากรอกข้อมูล")
	}
	
	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { grade: "", nh3_solution: "" },
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => handleSubmit(values),
  });

	const handleSubmit = async (values) => {
		// Prepare data
		setLoading(true);
		let response = null;
		let data = {
			...values,
			tank: props.data.id,
		}
		
		// Create lot no.
		try {
			response = await POST(URL_TANK.TANK_TRANSACTION, data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			// Load option
			gradeOptions.fetchData();
			formik.resetForm();
		}
	}, [props.open])

	React.useEffect(() => {})
	return (
		<ConfirmModal
			open={props.open}
			title={`สร้าง Lot No. ${props.data ? `[${props.data.name}]` : ""}`}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={"ยืนยัน"}
			onClose={props.onClose}
			onConfirm={formik.handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}

					<Form>
						<Form.Group>
							<Form.Field 
								width={10}
								label={"เกรด"}
								control={Dropdown}
								selection 
								name={"grade"}
								placeholder={"เลือก..."}
								options={gradeOptions.response}
								selectOnBlur={false}
								onChange={(_, data) => {
									formik.setFieldValue(data.name, data.value)
								}}
								error={formik.errors.grade}
								value={formik.values.grade}
							/>

							<Form.Field 
								width={6}
								label={"NH3 Solution"}
								control={Input}
								name={"nh3_solution"}
								placeholder={"0"}
								onChange={(_, data) => formik.setFieldValue(data.name, data.value)}
								error={formik.errors.nh3_solution}
								value={formik.values.nh3_solution}/>
						</Form.Group>
					</Form>
				</div>
				
			}
		/>
	)
}

CreateLotNoModal.defaultProps = {
	open: false,
	data: null,
	onClose: () => {},
	
}

CreateLotNoModal.propTypes = {
	open: propTypes.bool,
	data: propTypes.object,
	onClose: propTypes.func,
}