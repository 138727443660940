import moment from "moment";
import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Popup } from "semantic-ui-react";
import { URL_REPORT } from "../../../constances/urls";
import { GET } from "../../../frameworks/HttpClient";
import TSReactTable from "../../../frameworks/TSReactTable";
import { currencyFormat, dateToString } from "../../../frameworks/Util";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../../utils/Util";

export default function PurchaseByPOReportView() {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [dateRange, setDateRange] = React.useState(null);
	const [dateRangeError, setDateRangeError] = React.useState(false);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่เปิด P/O", 
				accessor: "created", 
				width: 120,
				Cell: ({value}) => (<p>{dateToString(value)}</p>)
			},
			{ 
				Header: "ผู้ขาย", 
				accessor: "supplier",
				style: {textAlign: "left"},
			},
			{ 
				Header: "เลขที่ P/O", 
				accessor: "code",
				width: 160,
			},
			{ 
				Header: "ราคารวม", 
				accessor: "real_price", 
				style: {textAlign: "right"},
				width: 120,
				Cell: ({ value }) =>
					currencyFormat(value) 
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: ({ original }) => (
				<div>
					<Popup
						content={"พิมพ์"}
						trigger={
							<Button
								icon 
								color={"blue"}
								size={"mini"}
								onClick={() => window.open(`${URL_REPORT.CR_STO_04_REPORT_EXPORT}pdf/?id=${original.id}`)}>
								<Icon name={"print"}/>
							</Button>}
					/>
				</div>
			)
		},
	])

	const fetchData = async () => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);

		setLoading(true);
		setErrorMessages(null);
			const params = {
				date_before: moment(dateRange[0]).format('YYYY-MM-DD'),
				date_after: moment(dateRange[1]).format('YYYY-MM-DD'),
			}
			try {
				const response = await GET(URL_REPORT.PURCHASE_ORDER_REPORT, params);
				setData(response.data)
			} catch (error) {
				setErrorMessages(error.errorMessages)
			} finally {
				setLoading(false)
			}
	}

	return (
		<Container fluid style={{marginTop: 24}}>
			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group inline>
					<label>{"วันที่เปิด P/O"}</label>
					<Form.Field
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						error={dateRangeError}
						onChange={(_, data) => setDateRange(data.value)}/>

					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
			</Segment>
		</Container>
	)
}