import React from 'react';
import propTypes from "prop-types";
import { Form, Input, TextArea, Message } from 'semantic-ui-react';
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';

export default function WeightInputModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(null);

	let validationSchema = {
		weight: Yup.number().required("กรุณากรอกข้อมูล"),
		note: Yup.string().notRequired(),
	}

	let validationSchemaAllRequired = {
		weight: Yup.number().required("กรุณากรอกข้อมูล"),
		note: Yup.string().required("กรุณากรอกข้อมูล"),
	}

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
			weight: (props.initialWeight > -1) ? props.initialWeight : "",
			note: "",
		},
    validationSchema: Yup.object(props.remarkRequired 
			? validationSchemaAllRequired 
			: validationSchema),
    onSubmit: () => {},
  });

	const handleConfirm = async () => {
		// Validation
		const modalValidation = await formik.validateForm();
		const isModalValid = Object.keys(modalValidation).length === 0;
		if (!isModalValid)
			return;
		
		// Goto Update
		setErrorMessage(null);
		setLoading(true)
		const [response, error] = await props.onSubmit(formik.values);
		setLoading(false);

		if(error) {
			setErrorMessage(error)
			return;
		}
		props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			formik.resetForm();
		}
	}, [props.open]);

	return (
		<ConfirmModal
			open={props.open}
			title={props.title}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={(props.initialWeight >= 0) ? "แก้ไข" : "ยืนยัน"}
			positiveColor={(props.initialWeight >= 0) ? "yellow" : "green"}
			onClose={props.onClose}
			onConfirm={handleConfirm}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessage && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessage}/>
					)}

					<Form>
						{props.addOnDetail}
						<Form.Field 
							required
							label={props.message}
							control={Input}
							placeholder={"0"}
							name={"weight"}
							error={formik.errors.weight}
							value={formik.values.weight}
							onChange={formik.handleChange}
						/>
						{(props.remarkEnable) && (
							<Form.Field 
								required={props.remarkRequired}
								label={"เหตุผล"}
								control={TextArea}
								placeholder={"เหตุผล..."} 
								name={"note"}
								error={formik.errors.note}
								value={formik.values.note}
								onChange={formik.handleChange}
							/>
						)}
					</Form>
				</div>
			}
		/>
	)
}

WeightInputModal.defaultProps = {
	open: false,
	title: "น้ำหนัก",
	message: "กรุณากรอกน้ำหนัก",
	initialWeight: -1,
	addOnDetail: "",
	remarkEnable: false,
	remarkRequired: false,
	onSubmit: (data) => {},
	onClose: () => {},
	
}

WeightInputModal.propTypes = {
	open: propTypes.bool,
	title: propTypes.string,
	message: propTypes.string,
	initialWeight: propTypes.oneOfType([
		propTypes.number,
		propTypes.string,
	]),
	addOnDetail: propTypes.oneOfType([
		propTypes.string,
		propTypes.element,
	]),
	remarkEnable: propTypes.bool,
	remarkRequired: propTypes.bool,
	onSubmit: propTypes.func,
	onClose: propTypes.func,
}