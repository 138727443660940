import React, { useMemo } from 'react';
import { Container } from 'semantic-ui-react';
import edit from "../assets/img_edit.png";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import QualityCheckedModal from "../components/lab/QualityCheckedModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import useListItem from "../frameworks/hooks/useItemList";
import { dateTimeToString } from "../frameworks/Util";
import { URL_TRUCK } from "../constances";

const QualityView = React.forwardRef((props, ref) => {
	const list = useListItem({url: `${URL_TRUCK.TRUCK_TRANSACTION}?status=pending`});
	// Modal
	const [open, setOpen] = React.useState(false);
	const [targetData, setTargetData] = React.useState(null);
	// Table
	const columns = useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่ชั่งเข้า", 
				accessor: "in_datetime", 
				width: 160,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
			},
			{ Header: "ทะเบียนรถ", accessor: "license_plate" },
			{ Header: "จุดรับซื้อ", accessor: "customer_name" },
			{ Header: "ชื่อสินค้า", accessor: "product_name", width: 180 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: (row) => {
				return (
					<ImageButton 
						title={"แก้ไข"}
						src={edit}
						onClick={(e, data) => handleDetail(row.original)}/>
				)
			}
		},
	]);

	const handleDetail = (data) => {
		setTargetData(data);
	}

	React.useImperativeHandle(ref, () => ({ 
		refresh: () => list.fetchData()
	}));

	React.useEffect(() => {
		setOpen(targetData ? true : false);
		if (!targetData) {
			// Reload Data
			list.fetchData();
		}
	}, [targetData]);

	React.useEffect(() => {
		list.fetchData();
	}, [])

	return (
		<Container fluid style={{marginTop: 24}}>
			<TSReactTable 
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>

				<QualityCheckedModal 
					data={targetData}
					open={open}
					onClose={() => setTargetData(null)} />
		</Container>
	)
})

export default QualityView;