import React from 'react';
import { Dimmer, Loader, Message, Modal } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { GET } from '../../frameworks/HttpClient';
import TSReactTable from '../../frameworks/TSReactTable';

const ListModal = React.forwardRef((props, ref) => {
  const {
    title,
    columns,
    open,
    onClose,
    url,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [data, setData] = React.useState([]);

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(url);
      setData(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useImperativeHandle(ref, () => ({
		refresh: async () => await fetchData(),
	}));
  
  React.useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      size={props.size}
    >
      { errorMessages && <Message error content={errorMessages}/> }
      <Dimmer inverted active={loading}>
        <Loader content='Loading'/>
      </Dimmer>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <TSReactTable
          columns={columns}
          data={data}
        />
      </Modal.Content>
    </Modal>
  )
})

ListModal.defaultProps = {
  title: '',
  columns: [],
  open: false,
  onClose: () => null,
  url: '',
  size: null,
}

ListModal.propTypes = {
  title: propTypes.string,
  columns: propTypes.array,
  open: propTypes.bool,
  onClose: propTypes.func,
  url: propTypes.string,
  size: propTypes.string,
}

export default ListModal;