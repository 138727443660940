import React from 'react';
import propTypes from "prop-types";
import { Menu, Header, Icon } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

export default function TitleBar(props) {
	const history = useHistory();
	const user = JSON.parse(Cookies.get("user"));
	return (
		<div>
			<Menu style={{height: 56, borderRadius: 0}}>
					<Icon 
						style={{margin: "auto", marginLeft: 24, marginRight: 16}}
						name={"arrow left"} 
						size={"large"}
						onClick={() => history.goBack()} />
					<Header style={{margin: "auto", marginLeft: 0}}>{props.title}</Header>
				{props.isDisplayUser && (
					<Menu.Menu position={"right"}>
						<Menu.Item
							style={{fontSize: 12}}
							onClick={() => props.onPressUser}
						>
							<div style={{marginLeft: 8, marginRight: 8, textAlign: "center"}}>
								<Icon name={"user"} size={"large"}/>
								<p>{user ? user.display_name : "Unknown"}</p>
							</div>
						</Menu.Item>
					</Menu.Menu>
				)}
			</Menu>
		</div>
	)
}

TitleBar.defaultProps = {
	title: "",
	isDisplayUser: true,
	onPressUser: () => {},
}

TitleBar.propTypes = {
	title: propTypes.string,
	isDisplayUser: propTypes.bool,
	onPressUser: propTypes.func,
}