import React from 'react';
import { Container, Button, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import TSReactTable from "../frameworks/TSReactTable";
import TitleBar from "../components/common/TitleBar";
import ImageButton from "../components/common/ImageButton";
import WeightInputWithCompareModal from "../components/tank/WeightInputWithCompareModal";
import WeightInputModal from "../components/tank/WeightInputModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import edit from "../assets/img_edit.png";
import { URL_TANK } from '../constances';
import { useParams } from 'react-router-dom';
import useListItem from '../frameworks/hooks/useItemList';
import { GET, PATCH, POST } from '../frameworks/HttpClient';
import { dateTimeToString, formatComma } from '../frameworks/Util';

export default function VerifyWeightScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Modal
	const [openCheck, setOpenCheck] = React.useState(false);
	const [targetEdit, setTargetEdit] = React.useState(null);
	// Table
	const [data, setData] = React.useState(null);
	const list = useListItem({url: `${URL_TANK.TANK_WEIGHT_STORE_CHECK}?tank_transaction=${params.id}`});

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลาตรวจ", 
				accessor: "created",
				width: 140,
				Cell: ({value}) => (<p>{value ? dateTimeToString(value) : "-"}</p>)
			},
			{ Header: "น้ำหนัก (Kg) ตรวจ", accessor: "check_weight", Cell: ({value}) => (<p>{value ? formatComma(value) : "-"}</p>) },
			{ Header: "น้ำหนัก (Kg) โรงงาน", accessor: "factory_weight", Cell: ({value}) => (<p>{value ? formatComma(value) : "-"}</p>) },
			{ 
				Header: "ผลต่าง", 
				Cell: ({original}) => {
					const diff = parseFloat(original.check_weight) - parseFloat(original.factory_weight)
					return (<p>{formatComma(diff, true)}</p>)
				} 
			},
			{ Header: "หมายเหตุ", accessor: "note"},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			show: false,
			width: 80,
			Cell: ({original}) => (
				<ImageButton 
					title={"แก้ไข"}
					src={edit}
					disabled={true}
					onClick={() => setTargetEdit(original)}/>
			),
		},
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_TANK.TANK_TRANSACTION}${params.id}/`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	/** Confirm Weight */
	const handleWeightSubmit = async (args) => {
		if (!params.id) {
			return [null, "ไม่มีข้อมูล Tank Transcation"];
		}

		let response = null;
		let error = null;
		const data = {
			tank_transaction: params.id,
			check_weight: args.weight,
			note: args.note
		}
		try {
			response = await POST(URL_TANK.TANK_WEIGHT_STORE_CHECK, data);
		} catch (e) {
			error = e.errorMessages;
		}
		return [response, error]
	}

	/** Edit weight */
	const handleEditWeight = async (args) => {
		if (!targetEdit) {
			return [null, "ไม่มีข้อมูล Tank Weight Store Check"];
		}
		let response = null;
		let error = null;
		let data = { 
			check_weight: parseFloat(args.weight),
			edit_note: args.note,
		}
		try {
			response = await PATCH(`${URL_TANK.TANK_WEIGHT_STORE_CHECK}${targetEdit.id}/`, data)
		} catch (e) {
			error = e.errorMessages
		}
		return [response, error]
	}

	React.useEffect(() => {
		if (!openCheck && !targetEdit) {
			list.fetchData();
		}
	}, [openCheck, targetEdit])

	React.useEffect(() => {
		fetchData();
	}, []);

	return (
		<Container fluid>
			<TitleBar title={`การตรวจสอบน้ำหนัก ${data ? `[ ${data.lot_no} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Button color={"green"} onClick={() => setOpenCheck(true)}>
					<Icon name={"add"}/>ตรวจสอบ
				</Button>
				<TSReactTable
					style={{marginTop: 16}}
					columns={columns}
					data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 5}
					showPagination={false}
        />
			</Container>

			<WeightInputWithCompareModal 
				open={openCheck}
				title={"น้ำหนักตรวจ"}
				message1={"น้ำหนักที่วัดได้ล่าสุด"}
				targetWeight={data ? parseFloat(data.current_weight) : 0}
				message2={"กรุณากรอกน้ำหนักน้ำยางข้นในแท็งก์ (kg)"}
				remarkEnable={true}
				onClose={() => setOpenCheck(false)}
				onSubmit={handleWeightSubmit} />
			
			{/* For edit */}
			<WeightInputModal 
				open={targetEdit ? true : false}
				title={"แก้ไขน้ำหนักตรวจ"}
				message={"กรุณากรอกน้ำหนักน้ำยางข้นในแท็งก์ (kg)"}
				remarkEnable={true}
				remarkRequired={true}
				onClose={() => setTargetEdit(null)}
				onSubmit={handleEditWeight} />

		</Container>
	)
}