import React from "react";
import propTypes from "prop-types";
import { Container, Dimmer, Loader, Message } from "semantic-ui-react";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { INVENTORY_TYPE } from "../../constances";
import edit from "../../assets/img_edit.png";
import accept from "../../assets/img_accept.png";
import reject from "../../assets/img_reject.png";
import { GET, POST } from "../../frameworks/HttpClient";
import { dateTimeToString } from "../../frameworks/Util";
import RequisitionApprovalModal from "../../components/store/RequisitionApprovalModal";
import ConfirmModalWithNote from "../../components/common/ConfirmModalWithNote";
import { URL_STOCK } from "../../constances/urls";

export default function RequisitionApprovalView() {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [targetApproval, setTargetApproval] = React.useState(null);
	const [data, setData] = React.useState([]);
	// Reject
	const [targetReject, setTargetReject] = React.useState(null);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ 
				Header: "วันที่", 
				accessor: "created", 
				width: 140,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
			},
			{ Header: "สินค้า", accessor: "product.name", minWidth: 240 },
			{ 
				Header: "ประเภท", 
				accessor: "product.type", 
				width: 120,
				Cell: ({value}) => (<p>{INVENTORY_TYPE[value]}</p>) 
			},
			{ Header: "จำนวน", accessor: "require_amount", width: 100 },
			{ Header: "แผนก", accessor: "department", width: 100 },
			{ Header: "ผู้เบิก", accessor: "created_name" },
			{ Header: "สถานะ", accessor: "state_name" },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 140,
			Cell: (row) => {
				const myData = row.original;
				return (
					<div>
						<ImageButton 
							title={"แก้ไข"}
							src={edit}
							onClick={() => setTargetApproval(myData)}/>
						<ImageButton 
							title={"ปฏิเสธ"}
							src={reject}
							color={"red"}
							onClick={() => setTargetReject(myData)}/>
						<ImageButton 
							title={"อนุมัติ"}
							src={accept}
							color={"green"}
							onClick={() => submitAction(myData.id, "to_state_wait_progress")}/>
					</div>
				)
			}
		},
	]);

	const fetchData = async () => {
		setLoading(true);
		setErrorMessages(null);
		try {
			const response = await GET(`${URL_STOCK.WITHDRAW_TRANSACTION}?state=WAIT_CONFIRM`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	// Change state
	const submitAction = async (id, action, params={}) => {
		if (!id) {
			setErrorMessages("ID not found!")
			return;
		}

		setLoading(true);
		setErrorMessages(null);
		let response = null;
		try {
			response = await POST(`${URL_STOCK.WITHDRAW_TRANSACTION_ACTION}${id}/${action}/`, params)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			fetchData();
	}

	React.useEffect(() => {
		if (!targetApproval && !targetReject)
			fetchData();

	}, [targetApproval, targetReject])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/>
			
			<RequisitionApprovalModal 
				data={targetApproval}
				open={targetApproval ? true : false}
				onCancel={(data) => setTargetReject(data)}
				onClose={() => setTargetApproval(null)}/>

			<ConfirmModalWithNote	
				title={`ปฏิเสธคำขอเบิก ${targetReject ? "[" + targetReject.product.name + "]" : ""}`}
				open={targetReject ? true : false}
				onClose={() => setTargetReject(null)}
				onConfirm={async (values) => {
					await submitAction(targetReject.id, "to_state_not_confirm", { memo: values.note });
					setTargetReject(null);
				}}
				/>

		</Container>
	)
}

RequisitionApprovalView.defaultProps = {}

RequisitionApprovalView.propTypes = {}