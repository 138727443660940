import React from "react";
import propTypes from "prop-types";
import { Form, Input, Message, TextArea } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import { INVENTORY_TYPE } from "../../constances";
import { URL_STOCK } from "../../constances/urls";
import { POST } from "../../frameworks/HttpClient";
import { dateToString } from "../../frameworks/Util";

export default function RequisitionApprovalModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const submitAction = async (action) => {
		if (!props.data || !props.data.id) {
			setErrorMessages("ID not found!")
			return;
		}

		setLoading(true);
		let response = null;
		try {
			response = await POST(`${URL_STOCK.WITHDRAW_TRANSACTION_ACTION}${props.data.id}/${action}/`)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(false);
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={"รออนุมัติเบิก"}
			size={"tiny"}
			loading={loading}
			positiveText={"อนุมัติ"}
			negativeText={"ปฏิเสธ"}
			negativeColor={"red"}
			onClose={props.onClose}
			onCancel={() => {
				props.onCancel(props.data);
				props.onClose();
			}}
			onConfirm={() => submitAction("to_state_wait_progress")}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form textAlign={"center"}>
						<Form.Field 
							label={"ประเภทสินค้า"}
							control={Input}
							value={props.data ? INVENTORY_TYPE[props.data.product.type] : "-"}/>
						<Form.Field 
							label={"ชื่อสินค้า"}
							control={Input}
							value={props.data ? props.data.product.name : "-"}/>
						
						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"หน่วย"}
								control={Input}
								value={props.data ? props.data.product.unit : "-"}/>
							<Form.Field 
								label={"จำนวน"}
								control={Input}
								value={props.data ? props.data.require_amount : "-"}/>
						</Form.Group>
						
						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"Due Date"}
								control={Input}
								value={props.data ? dateToString(props.data.due_date) : "-"}/>
							<Form.Field 
								label={"แผนกขอเบิก"}
								control={Input}
								value={props.data ? props.data.department : "-"}/>
						</Form.Group>
						
						<Form.Field
							label={"เหตุผล"}
							control={TextArea}
							name={"create_note"}
							value={props.data ? props.data.create_note : "-"}/>
						
					</Form>
				</div>
			}/>
	)
}

RequisitionApprovalModal.defaultProps = {
	data: null,
	open: false,
	onCancel: (data) => {},
	onClose: () => {},
}

RequisitionApprovalModal.propTypes = {
	data: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}