import moment from "moment";

export const TABLE_HEADER_STYLE = {
	whiteSpace: 'unset', 
	display: 'flex', 
	flexDirection: 'column', 
	justifyContent: 'center',
	fontWeight: "bold", 
	height: 46
}

export const TABLE_BODY_STYLE = { 
	whiteSpace: 'unset', 
	display: 'flex', 
	flexDirection: 'column', 
	justifyContent: 'center',
}

export const getColumnsList = (data, width) => {
	const result = [];
	for (let column of data) {
		result.push({
			width: width,
			...column,
			headerStyle: { 
				...TABLE_HEADER_STYLE, 
				...column.headerStyle 
			},
			style: {
				...TABLE_BODY_STYLE,
				...column.style
			},
			footerStyle: {
				...TABLE_HEADER_STYLE,
				...column.footerStyle 
			}
		})
	}
	
	return result;
}

/** Convert Date-Time */
export const dateTimeToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY HH:mm");
}

export const dateToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY");
}

export const monthToString = (date) => {
	if (!date) {
		return "";
	}

	return moment(date, 'MM-YYYY').format("MMM YYYY")
}