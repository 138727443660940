import React from 'react';
import propTypes from "prop-types";
import { Form, Dropdown, Input, Message } from 'semantic-ui-react';
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { URL_POND, URL_TANK } from "../../constances";
import { GET, POST, PATCH } from "../../frameworks/HttpClient";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const CustomInput = (props) => {
	return (
		<Input 
			icon='calendar' 
			placeholder='เลือก...'
			onClick={props.onClick} 
			value={props.value}/>
	)
}

const validationSchema = {
	pond_transaction: Yup.string().required("กรุณากรอกข้อมูล"),
	raw_latex_weight: Yup.number().required("กรุณากรอกข้อมูล"),
	start_date: Yup.string().required("กรุณากรอกข้อมูล"),
	finish_date: Yup.string().required("กรุณากรอกข้อมูล")
}
export default function AddConcentLatexModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [options, setOptions] = React.useState([]);
	const isEdit = (props.initialModel) ? true : false;

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
			pond_transaction: (props.initialModel) ? props.initialModel.pond_transaction : "",
			raw_latex_weight: (props.initialModel) ? props.initialModel.raw_latex_weight : "",
			start_date: (props.initialModel) ? new Date(props.initialModel.start_date) : "",
			finish_date: (props.initialModel) ? new Date(props.initialModel.finish_date) : "",
		},
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => handleSubmit(values),
  });

	const fetchOptions = async () => {
		try {
			const response = await GET(URL_POND.POND_READY_TO_PRODUCT);
			setOptions(
        response.data.map(item => {
          return {
						...item,
            id: item.id, 
            key: item.transaction, 
            value: item.transaction, 
            text: item.name
          }
        })
      );
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Submit
	const handleSubmit = async (values) => {
		// Prepare data
		// setLoading(true);
		setErrorMessages(null);
		let response = null;
		let data = {
			...values,
			tank_transaction: props.id,
			start_date: moment(values.start_date).toISOString(),
			finish_date: moment(values.finish_date).toISOString(),
		}
		console.log(data)
		
		// Add Concentrated Latex
		try {
			if (isEdit) {
				// Update
				const transaction = props.initialModel.id;
				response = await PATCH(`${URL_TANK.TANK_POND_TRANSACTION}${transaction}/`, data);
			} else {
				// Create
				response = await POST(URL_TANK.TANK_POND_TRANSACTION, data);
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
		
		if (response)
			props.onClose();
	}

	React.useEffect(() => {
		if (props.open) {
			fetchOptions();
			if (props.id) {
				fetchOptions();
				setErrorMessages(null);
			} else {
				setErrorMessages("pond_transaction not found.")
			}
		}

		if (!props.open) {
			// Reset form after close modal
			formik.resetForm();
		}
	}, [props.open])
	return (
		<ConfirmModal
			open={props.open}
			title={isEdit ? "แก้ไขการเติมน้ำยาง" : "เติมน้ำยาง"}
			size={"small"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={isEdit ? "แก้ไข" : "ยืนยัน"}
			positiveColor={isEdit ? "yellow" : "green"}
			onClose={props.onClose}
			onConfirm={formik.handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}

					<Form>
						<Form.Group>
							<Form.Field
								width={10}
								selection
								disabled={isEdit}
								label={"บ่อพักน้ำยาง"}
								control={Dropdown}
								placeholder={"เลือก..."}
								options={options}
								name={"pond_transaction"}
								selectOnBlur={false}
								onChange={(_, data) => {
									const value = data.value;
									formik.setFieldValue(data.name, value);
									const pond = options.find(item => item.value === value);
									if (pond) {
										formik.setFieldValue("raw_latex_weight", pond.weight);
									}
								}}
								error={formik.errors.pond_transaction}
								value={formik.values.pond_transaction}
							/>
							
							<Form.Field
								width={6}
								label={"น้ำยางสด (kg)"}
								control={Input}
								placeholder={"0"} 
								type={"number"}
								name={"raw_latex_weight"}
								error={formik.errors.raw_latex_weight}
								value={formik.values.raw_latex_weight}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						
						<Form.Group widths={"equal"}>
							<Form.Field
								label={"วันที่-เวลา เริ่มผลิต"}
								control={DatePicker}
								customInput={<CustomInput />}
								name={"start_date"}
								showTimeSelect
								timeCaption="เวลา"
								timeFormat="HH:mm"
								timeIntervals={5}
								dateFormat="dd/MM/yyyy - HH:mm"
								selected={formik.values.start_date}
								error={formik.errors.start_date}
								onChange={(date) => formik.setFieldValue("start_date", date)}
							/>
							
							<Form.Field
								label={"วันที่-เวลา สิ้นสุดการผลิต"}
								control={DatePicker}
								customInput={<CustomInput />}
								name={"finish_date"}
								showTimeSelect
								timeCaption="เวลา"
								timeFormat="HH:mm"
								timeIntervals={5}
								dateFormat="dd/MM/yyyy - HH:mm"
								selected={formik.values.finish_date}
								error={formik.errors.finish_date}
								onChange={(date) => formik.setFieldValue("finish_date", date)}
							/>
						</Form.Group>
					</Form>
				</div>
			}
		/>
	)
}

AddConcentLatexModal.defaultProps = {
	open: false,
	id: null,
	initialModel: null,
	onClose: () => {},
	
}

AddConcentLatexModal.propTypes = {
	open: propTypes.bool,
	id: propTypes.string,
	initialModel: propTypes.object,
	onClose: propTypes.func,
}