import React, { useMemo } from 'react';
import moment from "moment";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react'
import TSReactTable from "../../frameworks/TSReactTable";
import { getColumnsList } from "../../utils/Util";
import { URL_COMMON, URL_REPORT } from '../../constances/urls';
import { GET } from '../../frameworks/HttpClient';
import { formatComma } from "../../frameworks/Util";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const typeOptions = [
	{key: "01", value: "01", text: "สารเคมี, บรรจุภัณฑ์"},
	{key: "02", value: "02", text: "PPE, เครื่องเขียน, อื่นๆ"},
	{key: "03", value: "03", text: "SPARE PART"},
	{key: "04", value: "04", text: "เชื้อเพลิง"},
];

let validationSchema = {
	date: Yup.string().required("กรุณากรอกข้อมูล"),
	department: Yup.string().required("กรุณากรอกข้อมูล"),
	type: Yup.string().required("กรุณากรอกข้อมูล"),
}

export default function CRSTO06ReportView() {
	const [departmentOptions, setDepartmentOptions] = React.useState([]);
	// Table
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {date: moment().format("DD/MM/YYYY"), department: "", type: ""},
    validationSchema: Yup.object(validationSchema),
    onSubmit: null,
  });

	const columns = useMemo(() => [
		...getColumnsList([
			{ 
				Header: "รายการ", 
				accessor: "product_name",
				style: {textAlign: "left"}
			},
			{ Header: "Lot No.", accessor: "lot_no", width: 80},
			{ 
				Header: "จำนวน", 
				accessor: "amount", 
				width: 60,
				Cell: ({value}) => (<p>{value ? formatComma(parseFloat(value)) : "-"}</p>),
			},
			{ Header: "หน่วย", accessor: "product_unit", width: 80 },
			{ 
				Header: "เหตุผลการเบิก", 
				accessor: "create_note", 
				style: {textAlign: "left"},
				width: 160 
			},
			{ Header: "ผู้เบิก", accessor: "created_by", width: 85 },
			{ Header: "ผู้รับของ", accessor: "recipient_by", width: 85 },
			{ Header: "ผู้จ่ายของ", accessor: "lender_by", width: 85 },
			{ Header: "ผู้อนุมัติ", accessor: "approved_by", width: 85 },
		]),
	])

	const fetchData = async () => {
		const validation = await formik.validateForm();
		const isValid = Object.keys(validation).length === 0;
		if (!isValid) return;

		setLoading(true)
		setErrorMessages(null)

		const params = {
			...formik.values,
			date: moment(formik.values.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
		}
		try {
			const response = await GET(URL_REPORT.CR_STO_06_REPORT, params);
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const fetchOptions = async () => {
    try {
      const response = await GET(URL_COMMON.DEPARTMENT);
      setDepartmentOptions(
        response.data.map(item => {
          return {
            ...item, 
            key: item.name, 
            value: item.name, 
            text: item.name
          }
        })
      );
    } catch (error) {
      console.log(error.errorMessages)
    }
  };

	const gotoExport = () => {
		const params = `date=${moment(formik.values.date, 'DD/MM/YYYY').format('YYYY-MM-DD')}
			&department=${formik.values.department}
			&type=${formik.values.type}`
		window.open(`${URL_REPORT.CR_STO_06_REPORT_EXPORT}?${params}`)
	}

	React.useEffect(() => {
		fetchOptions(); 
	}, []);

	return (
		<Container fluid style={{marginTop: 24}}>
			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group inline>
					<label>{"วันที่ขอเบิก"}</label>
					<Form.Field
						style={{width: 180}}
						control={DateInput}
						name={"date"}
						dateFormat="DD/MM/YYYY"
						maxDate={moment()}
						placeholder={"เลือก..."}
						value={formik.values.date}
						error={formik.errors.date}
						onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
					
					<label>{"แผนก"}</label>
					<Form.Field
						control={Dropdown}
						name={"department"}
						selection
						placeholder={"เลือก..."}
						options={departmentOptions}
						value={formik.values.department}
						error={formik.errors.department}
						selectOnBlur={false}
						onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
					
					<label>{"ประเภทสินค้า"}</label>
					<Form.Field
						control={Dropdown}
						name={"type"}
						selection
						placeholder={"เลือก..."}
						options={typeOptions}
						value={formik.values.type}
						error={formik.errors.type}
						selectOnBlur={false}
						onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>

					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
					
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport()}
					>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}