import React from 'react';
import propTypes from "prop-types";
import {Button, Image, Popup} from 'semantic-ui-react';

export default function ImageButton(props) {
	return (
		<Popup 
			content={props.title}
			disabled={props.title ? false : true}
			trigger={
				<Button 
					style={{marginLeft: 4, marginRight: 4}}
					primary={props.color ? false : true}
					icon 
					color={props.color}
					size={"mini"}
					disabled={props.disabled}
					onClick={props.onClick}>
					<Image src={props.src} style={{width: props.imageSize}}/>
				</Button>}
		/>
	)
}

ImageButton.defaultProps = {
	title: null,
	src: null,
	imageSize: 18,
	disabled: false,
	color: null,
	onClick: (e, data) => {},
}

ImageButton.propTypes = {
	title: propTypes.string,
	src: propTypes.string,
	imageSize: propTypes.number,
	disabled: propTypes.bool,
	color: propTypes.string,
	onClick: propTypes.func,
}