import React from 'react';
import propTypes from "prop-types";
import { Button, Checkbox, Dropdown, Form, Icon, Message, Modal } from 'semantic-ui-react';
import useListItem from '../../frameworks/hooks/useItemList';
import { PAYMENT_TYPE, URL_PURCHASE } from '../../constances';
import TSReactTable from '../../frameworks/TSReactTable';
import { dateToString, getColumnsList } from '../../utils/Util';
import { formatComma } from '../../frameworks/Util';
import useDropdownItem from '../../frameworks/hooks/useDropdownItem';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { DateInput } from 'semantic-ui-calendar-react';
import CustomFileField from '../common/CustomFileField';
import { POST } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';


export default function MultipleAttachQuotation(props) {
  const {
    onClose,
  } = props;

  const columns = React.useMemo(() => [
    ...getColumnsList([
      {
        Header: "",
        accessor: 'id',
        width: 40,
        Cell: ({ value, index }) => (
          <Checkbox 
            style={{margin: "auto"}}
            checked={byListForm.values.find(item => item.purchase_request === value) ? true : false}
            onChange={(_, data) => {
              let newList = [...byListForm.values];
              if (data.checked) {
                newList.push({
                  purchase_request: value,
                  unit_price: "",
                  total_price: "",
                  real_price: "",
                })
              } else {
                newList = newList.filter(item => item.purchase_request !== value ? item : null)
              }
              byListForm.setValues([...newList]);
            }}/>
        )
      },
			{ Header: "วันที่", 
				accessor: "created", 
				width: 100,
				Cell: ({ value }) => <p>{dateToString(value)}</p> 
			},
			{ 
				Header: "สินค้า", 
				accessor: "product.name", 
				width: 240,
				Cell: ({original, value}) => 
					(<p>{original.ref_product ? `${value} (${original.ref_product.name})` : value}</p>)
			},
			{ 
				Header: "จำนวนขอซื้อ", 
				accessor: "amount", 
				width: 100,
				Cell: ({ value }) => <p>{formatComma(value)}</p>,
			},
			{ Header: "หน่วย", accessor: "unit", width: 100 },
      { 
        Header: "ราคาต่อหน่วย", 
        accessor: "unit_price", 
        width: 120, 
        Cell: ({ original }) => {
          const myIndex = byListForm.values.findIndex(item => item.purchase_request === original.id);
          const myData = byListForm.values[myIndex];
          return (
            myData ? ( 
              <Form.Input
                style={{width: "100%"}}
                placeholder="0" 
                name={`${myIndex}.unit_price`}
                type="number"
                min="0"
                value={myData ? myData.unit_price : null}
                error={byListForm.errors[myIndex] ? byListForm.errors[myIndex].unit_price : null}
                onChange={(_, { value }) => {
                  let newList = byListForm.values;
                  newList[myIndex].unit_price = value;
                  newList[myIndex].total_price = Number(value) * Number(original.amount)
                  newList[myIndex].real_price = Number(value) * Number(original.amount)
                  byListForm.setValues([...newList]);
                }}/>
            ) : null
          )
        }
      },
      { 
        Header: "ราคารวม", 
        accessor: "total_price", 
        width: 120, 
        Cell: ({ original }) => {
          const myIndex = byListForm.values.findIndex(item => item.purchase_request === original.id);
          const myData = byListForm.values[myIndex];
          return (
            myData ? (
              <Form.Input
                style={{width: "100%"}}
                placeholder="0"
                name={`${myIndex}.total_price`}
                type="number"
                min="0"
                value={myData ? myData.total_price : null}
                error={byListForm.errors[myIndex] ? byListForm.errors[myIndex].total_price : null}/>
            ) : null
          )
        }
        
      },
      { 
        Header: "ราคาจริง", 
        accessor: "real_price", 
        width: 120, 
        Cell: ({ original }) => {
          const myIndex = byListForm.values.findIndex(item => item.purchase_request === original.id);
          const myData = (myIndex > (-1)) ? byListForm.values[myIndex] : null;
          return (
            myData ? (
              <Form.Input
                style={{width: "100%"}}
                placeholder="0" 
                name={`${myIndex}.real_price`}
                type="number"
                min="0"
                value={myData ? myData.real_price : null}
                error={byListForm.errors[myIndex] ? byListForm.errors[myIndex].real_price : null}
                onChange={(_, { value }) => {
                  let newList = byListForm.values;
                  newList[myIndex].real_price = value;
                  byListForm.setValues([...newList]);
                }}/>
            ) : null
          )
        }
      },
      { 
				Header: "ประเภท", 
				accessor: "transaction_type", 
				width: 80,
				Cell: ({value}) => (<p>{PAYMENT_TYPE[value]}</p>) 
			},
			{ Header: "ผู้ขอซื้อ", accessor: "created_name", width: 100},
		]),
  ]);

  const generalForm = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
      type: "quotation",
      supplier: "",
      quotation_date: moment().format("YYYY-MM-DD"),
    },
    validationSchema: Yup.object().shape({
      supplier: Yup.string().required("กรุณากรอกข้อมูล"),
      quotation_date: Yup.string().required("กรุณากรอกข้อมูล"),    
    }),
    onSubmit: () => byListForm.handleSubmit(),
  })

  const byListForm = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: [],
    validationSchema: Yup.array()
      .of(
        Yup.object().shape({
          purchase_request: Yup.string().required("เกิดข้อผิดพลาด กรุณาเลือกอีกครั้ง"),
          unit_price: Yup.string().required("กรุณากรอกข้อมูล"),
          total_price: Yup.string().required("กรุณากรอกข้อมูล"),
          real_price: Yup.string().required("กรุณากรอกข้อมูล"),
        })
      ),
    onSubmit: (values) => handleSubmit(values),
  })
  
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const purchaseRequestList = useListItem({ 
    url: URL_PURCHASE.PURCHASE_REQUEST, 
    params: { 
      state: "ATTACH_QUOTATION", 
      filter_by_department: true,
    }
  });
  const supplierOptions = useDropdownItem({url: URL_PURCHASE.SUPPLIER});
  const [attachment, setAttachment] = React.useState(null);
  const [validationErrorMessages, setValidationErrorMessages] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    setIsModalVisible(false);
    onClose();
  }

  const handleSubmit = async (values) => {
    setValidationErrorMessages(null);
    setIsLoading(true);
    if (!values || !values.length) {
      setValidationErrorMessages("กรุณาเลือกใบสั่งซื้ออย่างน้อย 1 รายการ")
      setIsLoading(false);
      return;
    }
    try {
      let generalParams = generalForm.values;
      const params = byListForm.values.map(value => ({ ...value, ...generalParams }));
      const response = await POST(URL_PURCHASE.QUOTATION, params);
      if (attachment) {
        await POST(`${URL_PURCHASE.QUOTATION}list_update_attachment/`, {
          attachment: attachment,
          quotation_list: response.data.map(quotation => quotation.id).join(','),
        }, true);
      }
      Toast.success("สำเร็จ")
      handleClose();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (isModalVisible) {
      supplierOptions.fetchData();
      purchaseRequestList.fetchData();
      byListForm.resetForm();
      generalForm.resetForm();
      setAttachment(null);
      setValidationErrorMessages(null);
      setErrorMessages(null);
    }
  }, [isModalVisible])

  return (
    <div>
      <Button primary icon="file alternate outline" content="แนบใบเสนอราคา" onClick={() => setIsModalVisible(true)}/>
      <Modal
        size={"large"}
        open={isModalVisible}
        centered={false}
        onClose={handleClose}
      >
        <Modal.Header><Icon name="file alternate outline"/>แนบใบเสนอราคา</Modal.Header>
        <Modal.Content>
          { validationErrorMessages && <Message error>{validationErrorMessages}</Message> }
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <Form.Group widths="3">
              <Form.Field
                width={8}
                label="ผู้ขาย"
                control={Dropdown}
                search
                selection
                options={supplierOptions.response}
                placeholder="เลือก..."
                name="supplier"
                error={generalForm.errors.supplier}
                value={generalForm.values.supplier}
                selectOnBlur={false}
                onChange={(_, data) => generalForm.setFieldValue(data.name, data.value)}/>
              <Form.Field 
                width={3}
                label="วันที่ออกใบเสนอราคา"
                control={DateInput}
                placeholder="วันที่"
                dateFormat="YYYY-MM-DD"
                maxDate={moment()}
                name="quotation_date"
                error={generalForm.errors.quotation_date}
                value={generalForm.values.quotation_date}
                onChange={(_, data) => generalForm.setFieldValue(data.name, data.value)}/>
              <CustomFileField
                width={5}
                label="แนบใบเสนอราคา"
                value={attachment ? attachment.name : ''}
                onChange={(value) => setAttachment(value)}/>
            </Form.Group>
          </Form>

          <TSReactTable
            columns={columns}
            data={purchaseRequestList.response}
            pageSize={
              (purchaseRequestList.response && (purchaseRequestList.response.length > 0)) 
              ? purchaseRequestList.response.length 
              : 5}
            showPagination={false}/>
          
        </Modal.Content>
        <Modal.Actions>
          <Button content="ยกเลิก" icon="cancel" onClick={handleClose}/>
          <Button 
            positive
            icon="check"
            content="ยืนยัน"
            type="submit"
            loading={purchaseRequestList.isLoading || isLoading}
            onClick={generalForm.handleSubmit}/>
        </Modal.Actions>
      </Modal>      
    </div>
  );
}

MultipleAttachQuotation.defaultProps = {
  onClose: () => null,
}

MultipleAttachQuotation.propTypes = {
  onClose: propTypes.func,
}
