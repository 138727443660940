import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon, Button, Segment, Header, Dimmer, Loader, Message } from 'semantic-ui-react'
import { formatBytes } from "../../frameworks/Util";
import Dropzone from 'react-dropzone';

export default function UploadModal(props)  {
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [files, setFiles] = React.useState([]);

  const handleDrop = (files) => {
    setFiles(files)
  };

  const renderFile = () => {
    if(files.length > 0) {
      let file = files[0];
      return(
        <Segment textAlign='center'>
          <Icon name='file outline'/>
          {file.name} - {formatBytes(file.size)}
        </Segment>
      )
    }
  }

  const handleUpload = async () => {
    setLoading(true);
    const [response, error] = await props.onUpload(files[0]);
    setLoading(false);

    if (error) {
      setErrorMessages(error);
    }
    if (response) {
      props.onClose();
    }
  }

  const isDisableUpload = () => {
    return files.length === 0;
  }

  React.useEffect(() => {
    if (props.open) {
      setFiles([]);
      setErrorMessages(null);
    }
  }, [props.open])

  return (
    <Modal dimmer='blurring'
      closeIcon
      open={props.open}
      onClose={props.onClose}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <Dimmer active={loading} inverted>
          <Loader inverted>โหลดข้อมูล...</Loader>
        </Dimmer>
        {errorMessages && (
          <Message error content={errorMessages}/>
        )}
        
        <Dropzone onDrop={handleDrop} multiple={false}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: 'dropzone'})}>
              <Segment placeholder>
                <Header icon>
                  <Icon name='upload' />
                  Click or Drag & Drop file.
                  <input {...getInputProps()} />
                </Header>
                {renderFile()}
              </Segment>
            </div>
          )}
        </Dropzone>
      </Modal.Content>
      <Modal.Actions>
          <Button
            color='blue'
            disabled={isDisableUpload()}
            onClick={handleUpload}
          >
            <Icon name='upload' />Upload
          </Button>
      </Modal.Actions>
    </Modal>
  )
}

UploadModal.defaultProps = {
  title: "Upload เอกสาร",
  open: false,
  onClose: () => {},
  onUpload: (file) => {},
}

UploadModal.propTypes = {
  title: PropTypes.string,
  /** Flag to hide on show this modal */
  open: PropTypes.bool,

  /** Callback functions */
  onClose: PropTypes.func,

  /** On uploading function */
  onUpload: PropTypes.func,
}