import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form, Button } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import { formatComma } from '../../frameworks/Util';
import WeightInputModal from './WeightInputModal';
import { URL_TANK } from '../../constances';
import { POST } from '../../frameworks/HttpClient';

export default function ProduceSegment(props) {
	const [openChangeState, setOpenChangeState] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);

	/** CLOSE */
	const handleChangeState = async (args) => {
		let response = null;
		let error = null;
		let data = { measure_weight: parseFloat(args.weight) }
		try {
			response = await POST(`${URL_TANK.TANK_TRANSACTION}${props.data ? props.data.id : ""}/close/`, data)
		} catch (e) {
			error = e.errorMessages
		}
		return [response, error]
	}

	/** EDIT */
	/** Waiting for "update_weight" APIs */
	const handleEditWeight = async (args) => {
		let response = null;
		let error = null;
		let data = { 
			measure_weight: parseFloat(args.weight),
			close_note: args.note,
		}
		try {
			response = await POST(`${URL_TANK.TANK_TRANSACTION}${props.data ? props.data.id : ""}/update_measure_weight/`, data)
		} catch (e) {
			error = e.errorMessages
		}
		return [response, error]
	}

	const closeModal = () => {
		/** Close Modal */
		if (openChangeState)
			setOpenChangeState(false);
		if (openEdit)
			setOpenEdit(false);
		
		/** RELOAD */
		if (props.data) {
			props.onReload();
		}
	}

	React.useEffect(() => {})
	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>เปิดเพื่อเติมน้ำยาง</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"น้ำหนักสะสม"}
					value={(props.data && (parseFloat(props.data.refill_weight) > 0)) 
						? formatComma(props.data.refill_weight)
						: formatComma(props.data.current_weight)}
					unit={"กิโลกรัม"}/>
				
				<SummaryField 
					title={"น้ำหนักปิดแท็งก์"}
					value={props.isFinished 
						? formatComma(parseFloat(props.data.measure_weight)) 
						: "-"}
					unit={"กิโลกรัม"}/>

				<SummaryField 
					title={"น้ำหนักส่วนต่าง"}
					value={props.isFinished 
						? formatComma(parseFloat(props.data.refill_weight) - parseFloat(props.data.measure_weight))
						: "-"}
					unit={"กิโลกรัม"}/>
			</Form>

			<div style={{textAlign: "right", marginTop: 16}}>
				{props.isFinished && (
					<Button color={"yellow"} onClick={() => setOpenEdit(true)}>แก้ไข</Button>
				)}
				
				{!props.isFinished && (
					<Button primary onClick={() => setOpenChangeState(true)}>ปิดแท็งก์</Button>
				)}
			</div>

			{/* Goto CLOSE */}
			<WeightInputModal
				open={openChangeState}
				title={`ปิดแท็งก์`}
				message={`กรุณากรอกน้ำหนักน้ำยางในแท็งก์ (kg)`}
				remarkEnable={false}
				remarkRequired={false}
				onSubmit={handleChangeState}
				onClose={closeModal}
			/>

			{/* For edit */}
			<WeightInputModal
				open={openEdit}
				title={`แก้ไขน้ำหนักหลังปิดแท็งก์`}
				message={`กรุณากรอกน้ำหนักน้ำยางในแท็งก์ (kg)`}
				remarkEnable={true}
				remarkRequired={true}
				onSubmit={handleEditWeight}
				onClose={closeModal}
			/>
		</Segment>
	)
}

ProduceSegment.defaultProps = {
	style: null,
	data: null,
	isFinished: false,
	onReload: () => {},
}

ProduceSegment.propTypes = {
	data: propTypes.object,
	isFinished: propTypes.bool,
	onReload: propTypes.func,
}