import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form, Button, Message } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import { dateTimeToString, formatComma } from '../../frameworks/Util';
import ConfirmModal from '../common/ConfirmModal';
import { URL_TANK } from '../../constances';
import { POST } from '../../frameworks/HttpClient';

export default function CloseSegment(props) {
	const [openModal, setOpenModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	/** Goto SELL state */
	const handleChangeState = async (args) => {
		let response = null;
		setLoading(true);
		try {
			response = await POST(`${URL_TANK.TANK_TRANSACTION}${props.data ? props.data.id : ""}/sell/`)
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
		
		if (response) {
			setOpenModal(false);
			props.onReload();
		}
	}

	React.useEffect(() => {})
	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>ปิดแท็งก์</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"วันที่ปิดแท็งก์"}
					value={(props.data && props.data.close_datetime) 
						? dateTimeToString(props.data.close_datetime) 
						: "-"} />

				<SummaryField 
					title={"น้ำหนักหลังปิดแท็งก์"}
					value={props.isFinished 
						? formatComma(parseFloat(props.data.close_weight)) 
						: "-"}
					unit={"กิโลกรัม"}/>
			</Form>

			<div style={{textAlign: "right", marginTop: 16}}>
				<Button 
					primary 
					disabled={props.isFinished || !props.canEdit}
					onClick={() => setOpenModal(true)}>
						เปิดแท็งก์ขาย
				</Button>
			</div>

			<ConfirmModal 
				open={openModal}
				title={"เปิดแท็งก์ขาย"}
				detail={
					<div>
						{errorMessages && (
							<Message 
								negative
								header={"เกิดข้อผิดพลาด"}
								content={errorMessages}/>
						)}
						<p>{"คุณต้องการเปิดแท็งก์เพื่อขายน้ำยางข้นใช่หรือไม่ ?"}</p>
					</div>
				}
				loading={loading}
				onClose={() => setOpenModal(false)}
				onCancel={() => setOpenModal(false)}
				onConfirm={handleChangeState}/>
		</Segment>
	)
}

CloseSegment.defaultProps = {
	data: null,
	isFinished: false,
	canEdit: false,
	style: null,
}

CloseSegment.propTypes = {
	data: propTypes.object,
	isFinished: propTypes.bool,
	canEdit: propTypes.bool
}