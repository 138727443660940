import React from 'react';
import propTypes from "prop-types";
import { Form, Input } from 'semantic-ui-react';

export default function SummaryField(props) {
	return (
		<Form.Field inline>
			<label style={{width: "30%", textAlign: "right"}}>
				{props.title}
			</label>
			<Input style={{width: "30%"}} value={props.value}/>
			<label style={{width: "20%", textAlign: "left", marginLeft: 16}}>
				{props.unit}
			</label>
		</Form.Field>
	)
}

SummaryField.defaultProps = {
	title: "",
	value: "",
	unit: "",
}

SummaryField.propTypes = {
	title: propTypes.string,
	value: propTypes.oneOfType([
		propTypes.string,
		propTypes.number,
	]),
	unit: propTypes.string,
}