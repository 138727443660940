import React from "react";
import propTypes from "prop-types";
import { Form, Input, Label, Message, TextArea } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_PURCHASE } from "../../constances";
import { Toast } from "../../frameworks/Toast";
import CustomFileField from "../common/CustomFileField";
import { DateInput } from "semantic-ui-calendar-react";

export default function GoodsReceivedModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [lastDO, setLastDO] = React.useState(null);
	const [isLotNo, setIsLotNo] = React.useState(false);
	let isWarning = (props.data && ( 
		(props.data.unit != props.data.product.unit) && (props.data.product.unit !== 'unit')
	)) // Unit เป็นหน่วยพิเศษที่รับเข้าเลยโดยไม่ต้องแปลงหน่วย

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			attachment: null,
			received_amount: props.data 
				? (parseFloat(props.data.amount) - props.data.total_received_amount)
				: "",
			purchase_request: props.data ? props.data.id : null,
			return_amount: "0",
			into_stock_amount: (props.data && !isWarning)
				? (parseFloat(props.data.amount) - props.data.total_received_amount)
				: "",
			note: "",
			lot_no: "",
			lot_no_expire_date: "",
			is_active: true,
			is_approved: null,
			is_received: null,
		},
		validationSchema: () => formSchema(props.data ? props.data.product.is_inspect : false),
		onSubmit: (values) => handleSubmit(values),
	});

	const formSchema = (isInspect) => {
		return Yup.object({
			note: Yup.string().notRequired(),
			purchase_request: Yup.mixed().required("ไม่เจอสินค้า โปรดลองอีกครั้ง"),
			received_amount: Yup.string().required("กรุณากรอกข้อมูล"),
			into_stock_amount: Yup.string().required("กรุณากรอกข้อมูล"),
			return_amount: Yup.string().required("กรุณากรอกข้อมูล"),
			attachment: isInspect 
				? Yup.mixed().required("กรุณาแนบใบส่งสินค้า") 
				: Yup.mixed().notRequired().nullable(true),
			lot_no_expire_date: Yup.string().notRequired(),
			lot_no: Yup.string().notRequired()
				.when("lot_no_expire_date", (expireDate) => {
					if (expireDate)
						return Yup.string().required("กรุณากรอกข้อมูล")
				}),
		})
	}

	const handleSubmit = async (values) => {
		setLoading(true);
		setErrorMessages(null);
		try {
			let params = values
			if (!params['attachment']) {
				// if attachment is null remove attachment key to avoid parsing error on backend
				delete params['attachment']
			}
			await POST(URL_PURCHASE.DELIVERY_ORDER, params, true)
			Toast.success("สำเร็จ")
			props.onClose();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const getLastLotNo = async () => {
		if (!props.data)
			return

		try {
			let response = await GET(`${URL_PURCHASE.DELIVERY_ORDER_LAST_LOT_NO}?product=${props.data.product.id}`)
			setLastDO(response.data)
		} catch (e) {}
	}

	React.useEffect(() => {
		if (isWarning)
			return;
		
			// Calculate
		const aReceived = formik.values.received_amount;
		const aReturn = formik.values.return_amount;
		const totalReceived = parseFloat(aReceived ? aReceived : "0") - parseFloat(aReturn ? aReturn : "0")
		formik.setFieldValue("into_stock_amount", totalReceived)
		
	}, [formik.values.received_amount, formik.values.return_amount])

	React.useEffect(() => {
		if (isLotNo)
			getLastLotNo();
		else {
			setLastDO(null);
		}
	}, [isLotNo])

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
			setIsLotNo(props.data && props.data.is_created_by_store && props.data.product 
				&& ((props.data.product.type === "chemical") || (props.data.product.type === "packaging")))
		} else {
			setIsLotNo(false)
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={"ใบรับสินค้า"}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}
					
					<Form warning={isWarning}>
						<Form.Field 
							label={"สินค้า"}
							control={Input}
							readOnly
							error={formik.errors.purchase_request}
							value={props.data ? props.data.product.name : ""}/>

						<Form.Group widths={"equal"}>
							<Form.Field
								width={8}
								label={"จำนวนที่รับมา"}
								control={Input}
								action={props.data ? props.data.unit : ""}
								placeholder={"0"}
								type={"number"}
								name={"received_amount"}
								error={formik.errors.received_amount}
								value={formik.values.received_amount}
								onChange={formik.handleChange}/>
							
							<Form.Field
								width={8}
								label={"จำนวนที่ส่งคืน"}
								control={Input}
								action={props.data ? props.data.unit : ""}
								placeholder={"0"}
								type={"number"}
								name={"return_amount"}
								error={formik.errors.return_amount}
								value={formik.values.return_amount}
								onChange={formik.handleChange}/>
						</Form.Group>

						<Message 
							content={"หน่วยที่สั่งไม่ตรงกับหน่วยของสินค้าในคลัง กรุณาแปลงจำนวนตามหน่วย"}
							warning/>
						
						{isWarning && (
							<Form.Field 
								label={"จำนวนรับเข้าสุทธิ"}
								control={Input}
								action={props.data ? props.data.product.unit : ""}
								placeholder={"0"}
								type={"number"}
								name={"into_stock_amount"}
								error={formik.errors.into_stock_amount}
								value={formik.values.into_stock_amount}
								onChange={formik.handleChange}/>
						)}

						{isLotNo &&
							<Form.Group widths={"equal"}>
								<Form.Field
									label={<div>
										<label style={{fontWeight: "bold"}}>{"ล็อตสินค้า"}</label>
										{(lastDO && lastDO.lot_no) && <Label color={"teal"}>{`ล่าสุด: ${lastDO.lot_no}`}</Label>}
									</div>}
									control={Input}
									placeholder={"เข้าล็อตสินค้ารวม"}
									name={"lot_no"}
									error={formik.errors.lot_no}
									value={formik.values.lot_no}
									onChange={formik.handleChange}/>
								<Form.Field 
									label={"วันหมดอายุ"}
									control={DateInput}
									closable={true}
									dateFormat="YYYY-MM-DD"
									placeholder={"เลือกวันที่..."}
									name={"lot_no_expire_date"}
									error={formik.errors.lot_no_expire_date}
									value={formik.values.lot_no_expire_date}
									onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
							</Form.Group>							
						}

						<Form.Field
							label="กรุณากรอกเหตุผล"
							control={TextArea}
							placeholder="เหตุผล..."
							name="note"
							error={formik.errors.note}
							value={formik.values.note}
							onChange={formik.handleChange}/>

						<CustomFileField
						label={"เอกสารแนบ"}
							error={formik.errors.attachment}
							value={formik.values.attachment ? formik.values.attachment.name : ""}
							onChange={(value) => formik.setFieldValue("attachment", value)}/>
					</Form>
				</div>
			}/>
	)
}

GoodsReceivedModal.defaultProps = {
	data: null,
	open: false,
	onClose: () => {},
}

GoodsReceivedModal.propTypes = {
	data: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}