import React from "react";
import propTypes from "prop-types";
import { Container, Icon, Dimmer, Loader, Form, Button, Message, Dropdown } from "semantic-ui-react";
import Cookies from 'js-cookie';
import { dateTimeToString, formatComma } from "../../frameworks/Util";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import remove from "../../assets/img_delete.png";
import done from "../../assets/img_done.png";
import ImageButton from "../../components/common/ImageButton";
import moment from "moment";
import { WITHDRAW_STATUS_OPTIONS } from "../../constances";
import { GET, POST } from "../../frameworks/HttpClient";
import TSReactTable from "../../frameworks/TSReactTable";
import NoteModal from "../../components/store/NoteModal";
import InfoModal from "../../components/common/InfoModal";
import { DateInput } from "semantic-ui-calendar-react";
import RequestModal from "../../components/store/RequestModal";
import { URL_STOCK } from "../../constances/urls";


export default function RequisitionView() {
	const user = JSON.parse(Cookies.get("user"));
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [openAdd, setOpenAdd] = React.useState(false);
	const [targetDelete, setTargetDelete] = React.useState(null);
	const [targetMemo, setTargetMemo] = React.useState(null);
	const [dateFilter, setDateFilter] = React.useState(moment().format("YYYY-MM-DD"));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	const [stateFilter, setStateFilter] = React.useState("ALL");
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ 
				Header: "วันที่", 
				accessor: "created", 
				width: 130,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>) },
			{ Header: "สินค้า", accessor: "product.name" },
			{ 
				Header: "จน.ขอเบิก",
				accessor: "require_amount",
				width: 90,
				Cell: ({value}) => (<p>{value ? formatComma(value) : 0}</p>)
			},
			{ 
				Header: "จ่ายจริง",
				accessor: "amount",
				width: 90,
				Cell: ({value}) => (<p>{value ? formatComma(value) : 0}</p>)
			},
			{ Header: "หน่วย", accessor: "product.unit", width: 100 },
			{ Header: "ผู้เบิก", accessor: "created_name", width: 100 },
			{ Header: "สถานะ", accessor: "state_name", width: 120 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: (row) => {
				const state = row.original.state
				const memo = row.original.memo
				let canDelete = false;
				if (user && (user.id === row.original.created_by))
					canDelete = true;

					return (
						<div>
							{state === "ALREADY_WITHDRAW" ? (
								<ImageButton 
									title={"รับสินค้า"}
									src={done}
									loading={loading}
									onClick={() => handleReceived(row.original)}/>
							) : ((state === "WAIT_CONFIRM") && canDelete) ? (
								<ImageButton 
									title={"ลบ"}
									disabled={!(state === "WAIT_CONFIRM")}
									src={remove}
									loading={loading}
									onClick={() => setTargetDelete(row.original)}/>
							) : (
								null
							)}

							{memo && (
								<Icon 
									name="warning circle" 
									color={"yellow"}
									onClick={() => setTargetMemo(row.original)} />
							)}
						</div>
					)
				}
			},
	]);
	
	const handleReceived = async (item) => {
		setLoading(true);
		try {
			const response = await POST(`${URL_STOCK.WITHDRAW_TRANSACTION_ACTION}${item.id}/to_state_already_receive/`);
			await fetchData();
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false);
		}		
	}

	const fetchData = async () => {
		if (!dateFilter || !moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
			if (!dateFilterError)
				setDateFilterError(true);
			return;
		}
			
		if (dateFilterError)
			setDateFilterError(false);
		setLoading(true);
		setErrorMessages(null);
		let params = `department=${user.department ? user.department.name : ""}&date=${dateFilter}&state=${stateFilter}`
		try {
			const response = await GET(`${URL_STOCK.WITHDRAW_TRANSACTION}?${params}`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (!openAdd && !targetDelete)
			fetchData();
	}, [openAdd, targetDelete, stateFilter, dateFilter])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<Form>
				<Form.Field>
					<Button 
						color={"green"} 
						onClick={() => setOpenAdd(true)}>
						<Icon name={"add"}/>
						เพิ่มรายการ
					</Button>
				</Form.Field>
			
				<Form.Group inline>
					<label>{"วันที่ขอเบิก"}</label>
					<Form.Field
						control={DateInput}
						required
						closable={true}
						dateFormat="YYYY-MM-DD"
						maxDate={moment()}
						placeholder={"เลือก..."}
						name={"date"}
						error={dateFilterError}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>
					
					<label>{"สถานะ"}</label>
					<Form.Field
						control={Dropdown}
						selectOnBlur={false}
						selection
						options={WITHDRAW_STATUS_OPTIONS}
						value={stateFilter}
						onChange={(_, data) => setStateFilter(data.value)}/>
				</Form.Group>
			</Form>

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/>
			
			<RequestModal 
				open={openAdd}
				onClose={() => setOpenAdd(false)}/>

			<NoteModal 
				title={"ลบรายการขอเบิก"}
				open={targetDelete ? true : false}
				id={targetDelete ? targetDelete.id : null}
				baseURL={URL_STOCK.WITHDRAW_TRANSACTION}
				noteName={"delete_note"}
				onClose={() => setTargetDelete(null)}/>

			{/* Detail memo */}
			<InfoModal 
				open={targetMemo ? true : false}
				size={"tiny"}
				title={targetMemo ? targetMemo.product.name : ""}
				detail={
					<div>
						<p>{`สถานะ : ${targetMemo ? targetMemo.state_name : ""}`}</p>
						<p>{`หมายเหตุ : ${targetMemo ? targetMemo.memo : ""}`}</p>
					</div>
				}
				onClose={() => setTargetMemo(null)}/>
		</Container>
	)
}

RequisitionView.defaultProps = {}

RequisitionView.propTypes = {}