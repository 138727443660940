import React from "react";
import { useParams } from "react-router-dom";
import { Container, Header, Grid, Dimmer, Loader, Message, Button, Form, Dropdown, Segment, Input, Checkbox, TextArea, Icon } from "semantic-ui-react";
import TitleBar from "../../components/common/TitleBar";
import QuotationSegment from "../../components/store/QuotationSegment";
import PricingSegment from "../../components/store/PricingSegment";
import { STORE_DOC_OPTIONS, TRANSACTION_TYPE_WORKFLOW, URL_PURCHASE } from "../../constances";
import { GET, PATCH, POST } from "../../frameworks/HttpClient";
import { PRODUCT_TYPE, TRANSACTION_TYPE } from "../../constances/string";
import { Toast } from "../../frameworks/Toast";
import CustomFileField from "../../components/common/CustomFileField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formatFileName } from "../../frameworks/Util";


const defaultPurchaseDetail = {
	id: null,
	product: {
		id: null,
		name: "",
		type: "",
		unit: "",
		amount: 0,
	},
	amount: 0,
	department: "",
	note: "",
	is_control: false,
	is_eco_friendly: false,
	account_code: null,
	transaction_type: "",
	state: "",
	state_name: "",
	selected_quotation: null
}

export default function CashPaymentScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState(defaultPurchaseDetail);
	const [purchaseTypeRender, setPurchaseTypeRender] = React.useState("quotation");

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			receipt: null,
			real_price: "",
			is_active: true,
		},
		validationSchema: Yup.object().shape({
			receipt: Yup.mixed().notRequired().nullable(true),
			real_price: Yup.mixed().required("กรุณากรอกข้อมูล"),
		}),
		onSubmit: (values) => handlePutReceipt(values),
	})

	const fetchData = async () => {
		setErrorMessages(null);
		setLoading(true);
		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_REQUEST}${params.id}`);
			setData({...defaultPurchaseDetail, ...response.data});
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handlePayment = async () => {
		setErrorMessages(null);
		setLoading(true);
		if (!data) {
			setErrorMessages(`ไม่พบข้อมูลการสั่งซื้อ`);
			return;
		}

		let action = "to_state_wait_product";
		// ค่าขนส่ง
		if (PRODUCT_TYPE[data.product.type] === PRODUCT_TYPE.transport) 
			action = "to_state_received";
		
		try {
			await POST(`${TRANSACTION_TYPE_WORKFLOW[data.transaction_type]}${data.object_id}/${action}/`);
			Toast.success("สำเร็จ");
			fetchData();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handlePutReceipt = async (values) => {
		setErrorMessages(null);
		setLoading(true);
		let params = values
		if (!params['receipt']) {
			// if receipt is null remove receipt key to avoid parsing error on backend
			delete params['receipt']
		}
		try {
			await PATCH(`${URL_PURCHASE.QUOTATION}${data.selected_quotation ? data.selected_quotation.id : null}/`, params, true)

			await POST(`${TRANSACTION_TYPE_WORKFLOW[data.transaction_type]}${data.object_id}/to_state_end/`);
			Toast.success("สำเร็จ")
			fetchData();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}
	
	React.useEffect(() => {
		if (data.selected_quotation) {
			setPurchaseTypeRender(data.selected_quotation.type);
		}
	}, [data.selected_quotation])

	React.useEffect(() => {
		fetchData();
	}, []);

	return (
		<Container fluid>
			<TitleBar title={`สั่งซื้อ ${data ? `[ ${data.product.name} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				{(data.state === "RECEIVED" || data.state === "END") && (
					<Message color={"yellow"}>
						<Form>
							<Form.Group>
								<Form.Field 
									style={{marginTop: "auto", marginBottom: "auto"}}
									label={"ราคาซื้อจริง"} />
								<Form.Field
										control={Input}
										placeholder={"0"}
										type={"number"}
										name={"real_price"}
										readOnly={data.state != "RECEIVED"}
										error={formik.errors.real_price}
										value={formik.values.real_price}
										onChange={formik.handleChange}/>
							</Form.Group>
							<Form.Group>
								<Form.Field 
									style={{marginTop: "auto", marginBottom: "auto"}}
									label={"เอกสารการเงิน"} />
								<Form.Field width={6}>
									{ data.state === "RECEIVED" ? (
										<CustomFileField
											error={formik.errors.receipt}
											value={formik.values.receipt ? formik.values.receipt.name : ''}
											onChange={(value) => formik.setFieldValue('receipt', value)}/>
										) : (
											<div>
												<Icon name="file"/>
												{formatFileName(data.selected_quotation.receipt)}
											</div>
										)
									}
								</Form.Field>
							</Form.Group>
							<div style={{marginTop: 24, textAlign: "right"}}>
								<Button 
									primary
									disabled={data.state != "RECEIVED"}
									onClick={formik.handleSubmit}>
									สำเร็จ
								</Button>
							</div>
						</Form>
					</Message>
				)}

				<Header as={"h2"} textAlign={"center"}>รายละเอียด</Header>
				<Grid style={{marginTop: 16}}>
					<Grid.Column width={6} textAlign={"left"}>
						<p style={{fontSize: 15}}>
							ชื่อสินค้า: {(data && data.ref_product) ? `${data.product.name} (${data.ref_product.name})` : data.product.name}
						</p>
					</Grid.Column>
					<Grid.Column width={4} textAlign={"center"}>
						<p style={{fontSize: 15}}>จำนวน: {data.amount} {data.unit}</p>
					</Grid.Column>
					<Grid.Column width={6} textAlign={"right"}>
						<p style={{fontSize: 15}}>ประเภทการสั่งซื้อ: {TRANSACTION_TYPE[data.transaction_type]}</p>
					</Grid.Column>
					<Form>
						<Form.Group inline>
							<Form.Field label={"ประเภทสินค้า: "}/>
							<Form.Field 
								control={Checkbox}
								label={"ควบคุม"}
								checked={data.is_control}/>
							<Form.Field 
								control={Checkbox}
								label={"ไม่ควบคุม"}
								checked={!data.is_control}/>
							<Form.Field 
								control={Checkbox}
								label={"เป็นมิตร"}
								checked={data.is_eco_friendly}/>
							<Form.Field 
								control={Checkbox}
								label={"ไม่เป็นมิตร"}
								checked={!data.is_eco_friendly}/>
						</Form.Group>
					</Form>
				</Grid>

				{ data.transaction_type === "cash" && (
					<Segment basic style={{padding: 0, marginTop: 24}}>
						<Form>
							<Form.Field 
								inline
								label={"เอกสารประกอบ"}
								control={Dropdown}
								selection
								disabled={true}
								placeholder={"เลือก..."}
								options={STORE_DOC_OPTIONS}
								value={purchaseTypeRender}
								selectOnBlur={false}
								onChange={(_,{value}) => setPurchaseTypeRender(value)}/>
						</Form>
					</Segment>				
				)}

				<Segment style={{marginTop: 24}}>
					{ purchaseTypeRender === "quotation" && (
						<QuotationSegment 
							initialModel={data}
							canEdit={false}/>				
					)}

					{ purchaseTypeRender === "set_price" && (
						<PricingSegment 
							initialModel={data}
							canEdit={false}/>
					)}

					<Form style={{marginTop: 16}}>
						<Form.Field 
							label={"หมายเหตุ"}
							control={TextArea}
							placeholder={"เหตุผล..."}
							readOnly={true}
							value={data.note ? data.note : ""}/>
					</Form>

				</Segment>

				<div style={{marginTop: 24, textAlign: "center"}}>
					<Button 
						primary 
						disabled={data.state !== "APPROVED"}
						content={data.state === "APPROVED" ? "สั่งซื้อ" : "สั่งซื้อแล้ว"}
						onClick={handlePayment}/>
				</div>
			</Container>

		</Container>
	)
}