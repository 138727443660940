import React, { useMemo } from 'react';
import { Container } from 'semantic-ui-react';
import TSReactTable from "../frameworks/TSReactTable";
import { getColumnsList } from "../utils/Util";
import useListItem from "../frameworks/hooks/useItemList";
import { dateTimeToString } from "../frameworks/Util";
import { URL_TRUCK } from "../constances";

const PondTruckView = React.forwardRef((props, ref) => {
	const list = useListItem({url: `${URL_TRUCK.TRUCK_TRANSACTION}?status=approved`});
	const columns = useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่ชั่งเข้า", 
				accessor: "in_datetime", 
				width: 140,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
			},
			{ Header: "ทะเบียนรถ", accessor: "license_plate", width: 160 },
			{ Header: "จุดรับซื้อ", accessor: "customer_name" },
			{ Header: "เกรด", accessor: "lab_results.grade", width: 120 },
			{ 
				Header: "%NH3", 
				accessor: "lab_results.results",
				width: 100,
				Cell: ({value}) => 
					(<p>{value ? parseFloat(getLabValue("nh3", value)).toFixed(2) : ""}</p>)
			},
			{ 
				Header: "VFA", 
				accessor: "lab_results.results",
				width: 100,
				Cell: ({value}) => 
					(<p>{value ? parseFloat(getLabValue("vfa", value)).toFixed(3) : ""}</p>)
			},
			{ Header: "หมายเหตุ", accessor: "lab_results.note" },
			{ Header: "ผู้ตรวจ", accessor: "lab_results.inspector_name", width: 120 },
		]),
	]);

	const getLabValue = (key, list) => {
		const target = list.find(item => item.chemical === key);
		return target ? target.amount : "0"
	}

	React.useImperativeHandle(ref, () => ({ 
		refresh: () => list.fetchData()
	}));

	React.useEffect(() => {
		list.fetchData();
	}, [])

	return (
		<Container fluid style={{marginTop: 24}}>
			<TSReactTable 
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>
		</Container>
	)
})

export default PondTruckView;