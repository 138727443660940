import React from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router";
import { Container, Loader, Dimmer, Message } from "semantic-ui-react";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { PATH } from "../../routes/Routes";
import edit from "../../assets/img_edit.png";
import { GET } from "../../frameworks/HttpClient";
import { URL_PURCHASE } from "../../constances";
import { dateToString } from "../../frameworks/Util";

export default function CashPaymentView() {
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "วันที่อนุมัติซื้อ", 
				accessor: "approved_date", 
				width: 110,
				Cell: ({ value }) => <p>{dateToString(value)}</p> 
			},
			{ 
				Header: "สินค้า", 
				accessor: "product.name",
				minWidth: 220,
				Cell: ({original, value}) => 
					(<p>{original.ref_product ? `${value} (${original.ref_product.name})` : value}</p>)
			},
			{ Header: "จำนวน", accessor: "amount", width: 80 },
			{ Header: "หน่วยสั่งซื้อ", accessor: "unit", width: 120 },
			{ Header: "ผู้ขอซื้อ", accessor: "created_name", width: 100},
			{ Header: "แผนก", accessor: "department", width: 80},
			{ 
				Header: "รหัสบัญชี", 
				accessor: "purchase_record", 
				width: 120,
				Cell: ({value}) => value ? value.account_code_detail.code : "-"
			},
			{ 
				Header: "รายการบัญชี", 
				accessor: "purchase_record", 
				width: 200,
				Cell: ({value}) => value ? value.account_code_detail.name : "-"
			},
			{ Header: "สถานะ", accessor: "state_name", width: 140 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 60,
			Cell: (row) => {
				return (
					<div>
						<ImageButton 
							title={"แก้ไข"}
							src={edit}
							onClick={() => handleEdit(row.original)}/>
					</div>
				)
			}
		},
	]);

	const fetchData = async () => {
		setErrorMessages(null);
		setLoading(true);
		let params = {
			transaction_type: 'cash', 
			state: "APPROVED, RECEIVED"
		}
		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_REQUEST}`, params);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleEdit = async (item) => {
		// Goto edit screen
		history.push(`${PATH.CASH_PAYMENT}${item.id}/`)
	}

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/>
		</Container>
	)
}

CashPaymentView.defaultProps = {}

CashPaymentView.propTypes = {}