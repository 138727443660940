import React from 'react';
import { Container, Form, Dropdown, Icon, Message } from 'semantic-ui-react';
import { POND_STATUS, POND_STATUS_OPTIONS } from "../constances";
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import ConfirmModal from "../components/common/ConfirmModal";
import cleaning from "../assets/img_cleaning.png";
import edit from "../assets/img_edit.png";
import lab from "../assets/img_lab.png";
import lab_noti from "../assets/img_lab_noti.png";
import fire from "../assets/img_fire.png";
import done from "../assets/img_done.png";
import summary from "../assets/img_summary.png";
import split from "../assets/img_split.png";
import { TABLE_HEADER_STYLE, TABLE_BODY_STYLE, getColumnsList } from "../utils/Util";
import useListItem from "../frameworks/hooks/useItemList";
import { URL_POND } from "../constances";
import { POST } from "../frameworks/HttpClient";
import { formatComma } from "../frameworks/Util";
import SplitPondModal from '../components/pond/SplitPondModal';

const PondView = React.forwardRef((props, ref) => {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [filterStatus, setFilterStatus] = React.useState("ALL");
	const list = useListItem({url: `${URL_POND.POND}?state=${filterStatus}`});
	const history = useHistory();
	// Change state with modal
	const [changeToClean, setChangeToClean] = React.useState(false);
	const [targetClean, setTargetClean] = React.useState(null);
	const [changeToBurn, setChangeToBurn] = React.useState(false);
	const [targetBurn, setTargetBurn] = React.useState(null);
	const [changeToReady, setChangeToReady] = React.useState(false);
	const [targetReady, setTargetReady] = React.useState(null);
	const [targetSplit, setTargetSplit] = React.useState(null);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ Header: "เลขที่บ่อพัก", accessor: "name" },
			{ Header: "เกรดผลิต", accessor: "closing_grade", width: 120},
			{ 
				Header: "นำ้หนัก (Kg)", 
				accessor: "weight",
				Cell: (row) => {
					const weight = row.original.weight;
					const isIncomplete = row.original.warning_incomplete_weight;
					return (
						<div>
							{(weight < 0) ? weight : formatComma(weight)}
							{isIncomplete && (
								<Icon name={"warning sign"} color={"yellow"} style={{marginLeft: 8}}/>
							)}
						</div>
					)
				}
			},
		]),
		{
			Header: "สถานะ",
			headerStyle: TABLE_HEADER_STYLE,
			Cell: (row) => {
				const weight = row.original.weight;
				let STATUS = POND_STATUS[row.original.state];
				if ((STATUS === POND_STATUS.REFILL)
						&& (weight > 0)) {
							STATUS = "กำลังเติมน้ำยาง";
				}

				return (<p style={{...TABLE_BODY_STYLE, height: "100%",}}>{STATUS}</p>)
			}
		},
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 180,
			Cell: (row) => {
				const STATUS = POND_STATUS[row.original.state];
				const weight = row.original.weight;
				const isLabInput = row.original.warning_lab_input;
				return (
					<div>
						{(STATUS === POND_STATUS.REFILL) && (
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={(e, data) => handleDetail(row.original)}/>
						)}
						{((STATUS === POND_STATUS.CLOSE) 
							|| ((STATUS === POND_STATUS.REFILL) && (weight > 0))) 
							&& (
							<ImageButton 
								title={"แลป"}
								src={isLabInput ? lab_noti : lab}
								onClick={(e, data) => handleLab(row.original)}/>
						)}
						{(STATUS === POND_STATUS.PRODUCE) && (
							<ImageButton 
								title={"ทำความสะอาด"}
								src={cleaning}
								onClick={(e, data) => handleClean(row.original)}/>
						)}
						{(STATUS === POND_STATUS.CLEAN || STATUS === POND_STATUS.BURN) && (
							<ImageButton 
								title={"เผาฆ่าเชื้อ"}
								src={fire}
								onClick={(e, data) => handleBurn(row.original)}/>
						)}
						{(STATUS === POND_STATUS.BURN) && (
							<ImageButton 
								title={"พร้อมใช้งาน"}
								src={done}
								onClick={(e, data) => handleReady(row.original)}/>
						)}
						{(STATUS === POND_STATUS.CLOSE) && (
							<ImageButton
								title={"แบ่งบ่อ"}
								src={split}
								onClick={(e, data) => handleSplit(row.original)} />
						)}
						<ImageButton 
							title={"สรุป"}
							src={summary}
							onClick={(e, data) => handleSummary(row.original)}/>
					</div>
				)
			}
		},
	]);

	const handleDetail = (data) => {
		// Go to detail
		history.push({pathname: `${PATH.POND_DETAIL}${data.id}`})
	}

	const handleLab = (data) => {
		// Go to lab
		history.push({pathname: `${PATH.LAB_POND}${data.transaction}`})
	}

	const handleSummary = (data) => {
		// Go to summary
		history.push({pathname: `${PATH.POND_SUMMARY}${data.id}`})
	}

	const handleClean = (data) => setTargetClean(data); 
	const handleBurn = (data) => setTargetBurn(data); 
	const handleReady = (data) => setTargetReady(data); 
	const handleSplit = (data) => setTargetSplit(data);
	

	// Function
	const handleStatusChange = (e, {value}) => setFilterStatus(value)

	// Change STATE [clean_up, burn, end]
	const handleChangeState = async (transaction, action) => {
		let response = null;
		let error = null;
		try {
			response = await POST(`${URL_POND.POND_TRANSACTION}${transaction}/${action}/`)
		} catch (e) {
			error = e.errorMessages
		}
		
		return [response, error]
	}

	React.useImperativeHandle(ref, () => ({ 
		refresh: () => list.fetchData()
	}));

	React.useEffect(() => {
		setChangeToClean(targetClean ? true : false);

	}, [targetClean]);

	React.useEffect(() => {
		setChangeToBurn(targetBurn ? true : false);
		
	}, [targetBurn]);

	React.useEffect(() => {
		setChangeToReady(targetReady ? true : false);
		
	}, [targetReady]);

	React.useEffect(() => {
		if (!changeToClean && !changeToBurn && !changeToReady) {
			list.fetchData();
		}
	}, [filterStatus, changeToClean, changeToBurn, changeToReady]);

	return (
		<Container fluid style={{marginTop: 24}}>
			{/* Error */}
			{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}
			{/* Filter */}
			<Form>
				<Form.Group>
					<Form.Field inline>
						<label>สถานะบ่อพัก</label>
						<Dropdown 
							selection
							options={POND_STATUS_OPTIONS}
							placeholder={"ทั้งหมด"}
							onChange={handleStatusChange}
							value={filterStatus}
						/>
					</Form.Field>
				</Form.Group>
			</Form>

			{/* Table */}
			<TSReactTable
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}
				getTrProps={(state, rowInfo, column, instance) => {
					if (rowInfo) {
						const isLabInput = rowInfo.original.warning_lab_input;
						if (isLabInput) {
							return {
								style: { background: "#F5B7B1"},
							}
						}
						else 
							return {}
					} 
					else 
						return {}
				}}
			/>

			{/* Change to clean */}
			<ConfirmModal 
				open={changeToClean}
				title={`ทำความสะอาด [${targetClean ? targetClean.name : ""}]`}
				detail={"คุณต้องการทำความสะอาดบ่อพักน้ำยางใช่หรือไม่ ?"}
				onClose={() => setTargetClean(null)}
				onCancel={() => setTargetClean(null)}
				onConfirm={async () => {
					await handleChangeState(targetClean.transaction, "clean_up");
					setTargetClean(null);
				}}/>
			
			{/* Change to burn */}
			<ConfirmModal 
				open={changeToBurn}
				title={`เผ่าฆ่าเชื้อ [${targetBurn ? targetBurn.name : ""}]`}
				detail={"คุณต้องการเผ่าฆ่าเชื้อบ่อพักน้ำยางใช่หรือไม่ ?"}
				onClose={() => setTargetBurn(null)}
				onCancel={() => setTargetBurn(null)}
				onConfirm={async () => {
					let action = "burn";
					if (POND_STATUS.BURN === POND_STATUS[targetBurn.state])
						action = "re_burn";

					await handleChangeState(targetBurn.transaction, action);
					setTargetBurn(null);
				}}/>

			{/* Change to Ready */}
			<ConfirmModal 
				open={changeToReady}
				title={`บ่อว่าง [${targetReady ? targetReady.name : ""}]`}
				detail={"บ่อพักน้ำยางพร้อมที่จะเติมน้ำยางใหม่แล้วใช่หรือไม่ ?"}
				onClose={() => setTargetReady(null)}
				onCancel={() => setTargetReady(null)}
				onConfirm={async () => {
					await handleChangeState(targetReady.transaction, "end");
					setTargetReady(null);
				}}/>

			<SplitPondModal
				open={targetSplit != null}
				from_pond={targetSplit}
				table_data={list.response}
				onClose={() => setTargetSplit(null)}
				onSuccess={() => {setTargetSplit(null); list.fetchData()}}
			/>

		</Container>
	);
});

export default PondView;