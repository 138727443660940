import React from 'react';
import { Container, Button, Icon, Dimmer, Message, Loader } from 'semantic-ui-react';
import TitleBar from "../components/common/TitleBar";
import TSReactTable from "../frameworks/TSReactTable";
import AdjustInTankModal from "../components/lab/AdjustInTankModal";
import ImageButton from "../components/common/ImageButton";
import LabNotify from "../components/lab/LabNotify";
import ConfirmModal from "../components/common/ConfirmModal";
import AddOnChemicalModal from "../components/tank/AddOnChemicalModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import edit from "../assets/img_edit.png";
import { useParams } from "react-router-dom";
import { URL_LAB, URL_TANK, USER_GROUP } from '../constances';
import { GET, POST } from '../frameworks/HttpClient';
import Cookies from 'js-cookie';
import useListItem from '../frameworks/hooks/useItemList';
import { dateTimeToString, formatComma } from '../frameworks/Util';

export default function LabAdjustScreen() {
	const params = useParams();
	const user = JSON.parse(Cookies.get("user"));
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [confirmError, setConfirmError] = React.useState(null);
	// Data
	const [data, setData] = React.useState(null);
	const [openLabResult, setOpenLabResult] = React.useState(false);
	const [openAddOn, setOpenAddOn] = React.useState(false);
	const [openWeightInput, setOpenWeightInput] = React.useState(false);
	const [notify, setNotify] = React.useState(null);
	const [targetLabData, setTargetLabData] = React.useState(null);
	const [targetTankData, setTargetTankData] = React.useState(null);
	const [readOnly,  setReadOnly] = React.useState(false);
	// Table
	const labList = useListItem({url: `${URL_LAB.REFILLED_TANK_LAB_RESULT}?tank_transaction=${params.id}`});
	const weightList = useListItem({url: `${URL_LAB.TANK_WEIGHT_FACTORY_CHECK}?tank_transaction=${params.id}`});

	/********************************************** */
	const columnItem = (list, keyName) => {
		let item = null;
		for (let it of list) {
			if (it.chemical === keyName) {
				item = it;
				break;
			}
		}
		return (<p>{item ? parseFloat(item.amount).toLocaleString("en-US") : "-"}</p>)
	}

	const columnOthers = (list, exceptChemicals) => {
		const except = exceptChemicals ? exceptChemicals : ["nh3"];
		let result = "";
		for (let it of list) {
			let isOther = true;
			for (let ex of except) {
				if (it.chemical === ex) {
					isOther = false;
					break;
				}
			}
			
			if (isOther) {
				if (result) {
					result = `${result} \n${it.chemical} [${it.amount ? parseFloat(it.amount) : "-"}]`;
				} else {
					result = `${it.chemical} [${it.amount ? parseFloat(it.amount) : "-"}]`;
				}
			}
		}
		return (<p style={{whiteSpace: 'pre'}}>{result ? result : "-"}</p>)
	}
	/********************************************** */

	const labColumns = React.useMemo(() => [
		{
			Header: "วันที่และเวลา",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{
					Header: "ตรวจ",
					Cell: ({original}) => {
						const date = dateTimeToString(original.created);
						const name = original.inspector_name;
						return (
							<div>
								<p style={{margin: 0}}>{`${date ? date : "-"}`}</p>
								<p style={{fontSize: 12}}>{name ? `(${name})` : ""}</p>
							</div>
						)
					}
				},
				{
					Header: "เติมสาร", 
					Cell: ({original}) => {
						const date = dateTimeToString(original.finish_datetime);
						const name = original.input_user_name;
						return (
							<div>
								<p style={{margin: 0}}>{`${(date && name) ? date : "-"}`}</p>
								<p style={{fontSize: 12}}>{name ? `(${name})` : ""}</p>
							</div>
						)
					}
				},
			]),
		},
		{
			Header: "ผลตรวจ",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ Header: "%TSC", accessor: "results", Cell: ({value}) => columnItem(value, "tsc") },
				{ Header: "%DRC", accessor: "results", Cell: ({value}) => columnItem(value, "drc") },
				{ Header: "%NH3", accessor: "results", Cell: ({value}) => columnItem(value, "nh3") },
				{ Header: "Mg", accessor: "results", Cell: ({value}) => columnItem(value, "mg") },
				{ Header: "VFA", accessor: "results", Cell: ({value}) => columnItem(value, "vfa") },
			], 85)
		},
		{
			Header: "สารเคมีที่ต้องการเติมเพิ่ม",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ Header: "NH3", accessor: "lab_inputs", Cell: ({value}) => columnItem(value, "nh3") },
				{ 
					Header: "อื่นๆ", 
					accessor: "lab_inputs", 
					width: 150,
					Cell: ({value}) => columnOthers(value) 
				},
			], 85)
		},
		...getColumnsList([
			{
				Header: "สถานะ",
				accessor: "is_finished",
				width: 70,
				Cell: ({value}) => {
					return (
						<div>
							<Icon size={"large"}
								name={value ? "check circle" : "minus circle"} 
								color={value ? "green" : "red"} />
						</div>
					)
				}
			}
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			show: !readOnly,
			width: 70,
			Cell: ({original}) => (
				<div>
					{(user.groups.find(group => group.name === USER_GROUP.LAB)) && (
						<ImageButton 
							title={"แก้ไข"}
							src={edit}
							onClick={(e, data) => setTargetLabData(original)}/>
					)}
				</div>
			),
		}
	]);

	const weightColumns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลา", 
				accessor: "created", 
				width: 180,
				Cell: ({value}) => dateTimeToString(value)
			},
			{ 
				Header: "น้ำหนัก (Kg)", 
				accessor: "weight", 
				Cell: ({value}) => (<p>{value ? formatComma(value) : ""}</p>)
			},
		]),
		{
			Header: "สารเคมีที่ต้องการเติมเพิ่ม",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ Header: "TZ", accessor: "inputs", Cell: ({value}) => columnItem(value, "tz") },
				{ Header: "La", accessor: "inputs", Cell: ({value}) => columnItem(value, "la") },
				{ 
					Header: "อื่นๆ", 
					accessor: "inputs", 
					Cell: ({value}) => columnOthers(value, ["tz", "la"]) 
				},
			])
		},
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			show: !readOnly,
			width: 70,
			Cell: ({original}) => {
				return (
					<div>
						{user.groups.find(group => group.name === USER_GROUP.FACTORY_TANK) && (
							<ImageButton 
								title={"แก้ไข"}
								src={edit}
								onClick={(e, data) => setTargetTankData(original)}
							/>
						)}
					</div>	
			)},
		}
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_TANK.TANK_TRANSACTION}${params.id}/`);
			setData(response.data);
			setReadOnly((response.data.state === "END") ? true : false);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	/** Confirm Input */
	const handleInputSubmit = async () => {
		if (!notify || !notify.id) {
			return [null, "ไม่มีข้อมูล Lab ID"];
		}

		try {
			await POST(`${URL_LAB.REFILLED_TANK_LAB_RESULT}${notify.id}/confirm/`);
			setOpenWeightInput(false);
		} catch (e) {
			setConfirmError(e.errorMessages);
		}
	}

	React.useEffect(() => {
		setOpenAddOn(targetTankData ? true : false)
	}, [targetTankData])

	React.useEffect(() => {
		setOpenLabResult(targetLabData ? true : false)
	}, [targetLabData])

	React.useEffect(() => {
		// Find lab that not finished
		const target = labList.response.find(item => item.is_finished === false);
		setNotify(target);
	}, [labList.response]);

	React.useEffect(() => {
		if (!openLabResult && !openWeightInput) {
			/** load list */
			labList.fetchData();
			/** Reset error */
			if (confirmError)
				setConfirmError(null);
		}
	}, [openLabResult, openWeightInput]);

	React.useEffect(() => {
		if (!openAddOn) {
			/** load list */
			weightList.fetchData();
		}
	}, [openAddOn]);

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={`แลปแท็งก์ผลิต ${data ? `[ ${data.lot_no} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				{(user.groups.find(group => group.name === USER_GROUP.FACTORY_TANK)) && notify && (
					<LabNotify 
						title={"สารเคมีที่รอการเติมลงแท็งก์"}
						items={notify ? notify.lab_inputs : []}
						onConfirm={() => setOpenWeightInput(true)}
					/>
				)}
				
				{(user.groups.find(group => group.name === USER_GROUP.LAB) && !readOnly) && (
					<div>
						<Button 
						color={"green"} 
						disabled={notify ? true : false}
						onClick={() => setOpenLabResult(true)}>
							<Icon name={"add"}/>เพิ่มผลตรวจ
						</Button>
					</div>
				)}
				
				<TSReactTable 
					style={{marginTop: 16}}
					columns={labColumns}
					data={labList.response}
					pageSize={(labList.response.length > 0) ? labList.response.length : 3}
					showPagination={false}
				/>
				
				<div style={{marginTop: 16}}>
					<Button 
						color={"green"} 
						disabled={(user.groups.find(group => group.name === USER_GROUP.FACTORY_TANK) && !readOnly) ? false : true}
						onClick={() => setOpenAddOn(true)}>
						<Icon name={"add"}/>วัดน้ำหนัก และ เติมสารเคมี
					</Button>

					<TSReactTable 
						style={{marginTop: 16}}
						columns={weightColumns}
						data={weightList.response}
						pageSize={(weightList.response.length > 0) ? weightList.response.length : 3}
						showPagination={false}
					/>
				</div>
			</Container>

			<AdjustInTankModal 
				open={openLabResult}
				data={targetLabData}
				id={data ? data.id : null}
				onClose={() => {
					if (targetLabData)
						setTargetLabData(null);
					else 
						setOpenLabResult(false);
				}} />

			<AddOnChemicalModal 
				open={openAddOn}
				data={targetTankData}
				id={data ? data.id : null}
				onClose={() => {
					if (targetTankData)
						setTargetTankData(null);
					else 
						setOpenAddOn(false);
				}} />

			<ConfirmModal 
				open={openWeightInput}
				title={"ยืนยันการเติมสารเคมี"}
				detail={"คุณเติมสารเคมีตามที่เจ้าหน้าที่ห้องแลปกำหนดแล้วใช่หรือไม่ ?"}
				errorMessages={confirmError}
				onClose={() => setOpenWeightInput(false)}
				onConfirm={handleInputSubmit}
				onCancel={() => setOpenWeightInput(false)} />
		</Container>
	)
}