import React from 'react';
import { Button, Icon, Label } from 'semantic-ui-react';
import useReportVerify from '../../frameworks/hooks/useReportVerify';
import ConfirmModal from '../common/ConfirmModal';
import propTypes from "prop-types";
import { Toast } from '../../frameworks/Toast';


export default function ReportVerify(props) {
  const {
    reportType,
    reportDate,
    buttonEnabled,
  } = props;
  const [isConfirmVisible, setIsConfirmVisible] = React.useState(false);
  const reportVerify = useReportVerify({reportType, reportDate});

  const handleConfirm = async () => {
    const isSuccess = await reportVerify.handleVerify();
    if (isSuccess) {
      setIsConfirmVisible(false);
      reportVerify.verifyCheck();
      Toast.success("สำเร็จ");
    }
  }

  return (
    <div>
      <ConfirmModal
        open={isConfirmVisible}
        title="อนุมัติรายงาน"
        detail="ต้องการอนุมัติรายงานดังกล่าวหรือไม่"
        loading={reportVerify.isLoading}
        errorMessages={reportVerify.errorMessages}
        onClose={() => setIsConfirmVisible(false)}
        onConfirm={() => handleConfirm()}
        onCancel={() => setIsConfirmVisible(false)}
      />
      { !reportVerify.data.id && buttonEnabled && (
        <Button 
          positive 
          disabled={props.readOnly}
          onClick={() => setIsConfirmVisible(true)} 
          icon="check" 
          content="อนุมัติรายงาน" />
      )}
      { reportVerify.errorMessages && 
        <Label color="red" pointing="left" basic>{reportVerify.errorMessages}</Label>
      }
      { reportVerify.data.id && (
        <Label basic color="green"><Icon name="check"/>อนุมัติแล้ว: {reportVerify.data.originator}</Label>
      )}
    </div>
  );
}

ReportVerify.defaultProps = {
  reportType: null,
  reportDate: '',
  buttonEnabled: false,
  readOnly: true,
}

ReportVerify.propTypes = {
  reportType: propTypes.string,
  reportDate: propTypes.string,
  buttonEnabled: propTypes.bool,
  readOnly: propTypes.bool,
}