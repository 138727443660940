import React from 'react';
import propTypes from "prop-types";
import { Message } from 'semantic-ui-react';
import InfoModal from '../common/InfoModal';
import { getColumnsList } from '../../utils/Util';
import { dateTimeToString, formatComma } from '../../frameworks/Util';
import TSReactTable from '../../frameworks/TSReactTable';
import useListItem from '../../frameworks/hooks/useItemList';
import { URL_TANK } from '../../constances';

export default function TankWeightLogModal(props) {
	const list = useListItem({url: `${URL_TANK.TANK_WEIGHT_LOG_LIST}?tank_transaction=${props.data ? props.data.transaction : ""}`});
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ 
				Header: "วันที่และเวลา", 
				accessor: "created", 
				width: 140,
				Cell: ({value}) => (<p>{value ? dateTimeToString(value) : "-"}</p>) 
			},
			{ 
				Header: "นำ้หนัก (Kg)", 
				accessor: "weight", 
				width: 120,
				Cell: ({value}) => (<p>{value ? formatComma(value, true) : "0"}</p>) 
			},
			{ Header: "หมายเหตุ", accessor: "note" },
			{ Header: "Update By", accessor: "created_name", width: 120 },
			
		]),
	]);

	React.useEffect(() => {
		if (props.open) {
			// Load list
			list.fetchData();
		}
	}, [props.open]);

	return (
		<InfoModal
			open={props.open}
			title={`รายละเอียดน้ำหนัก Lot No. ${props.data ? `[${props.data.lot_no}]` : ""}`}
			size={"small"}
			loading={loading}
			onClose={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}
					
					<TSReactTable
						columns={columns}
						data={list.response}
						pageSize={(list.response.length > 0) ? list.response.length : 5}
						showPagination={false} />
				</div>
			}
		/>
	)
}

TankWeightLogModal.defaultProps = {
	open: false,
	data: null,
	onClose: () => {},
	
}

TankWeightLogModal.propTypes = {
	open: propTypes.bool,
	data: propTypes.object,
	onClose: propTypes.func,
}