import React from "react";
import propTypes from "prop-types";
import { Segment, Container, List, Dimmer, Loader, Message, Icon, Button, Form, Input } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import { formatComma } from "../../frameworks/Util";
import { PAYMENT_TYPE, URL_PURCHASE } from "../../constances";
import { GET } from "../../frameworks/HttpClient";
import { PATH } from "../../routes/Routes";
import ImageButton from "../../components/common/ImageButton";
import edit from "../../assets/img_edit.png";
import printer from "../../assets/img_print.png";
import TSReactTable from "../../frameworks/TSReactTable";
import POModal from "../../components/store/POModal";
import { URL_REPORT } from "../../constances/urls";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import PaginationTable from "../../components/common/PaginationTable";
import _ from "lodash";


export default function POManagerView() {
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [addOrder, setAddOrder] = React.useState(false);
	const [codeFilter, setCodeFilter] = React.useState("");
	const [debouncedCode, setDebouncedCode] = React.useState("");
	const [dateFilter, setDateFilter] = React.useState(moment().format("YYYY-MM-DD"));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [targetEdit, setTargetEdit] = React.useState(null);

  const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "รหัส PO", accessor: "code", width: 120 },
			{ Header: "ผู้ขาย", accessor: "supplier" },
			{ 
				Header: "รายการสินค้า", 
				accessor: "list_purchase_request",
				Cell: ({ value }) => {
					return (
						<List style={{textAlign: "left"}} bulleted>
							{value.map(e => (<List.Item>{e.description}</List.Item>))}
						</List>
					)
				}
			},
			{ 
				Header: "ประเภท", 
				accessor: "transaction_type", 
				width: 70,
				Cell: ({value}) => (<p>{PAYMENT_TYPE[value]}</p>) 
			},
			{
				Header: "ราคารวม", accessor: "real_price", width: 90, Cell: ({ value }) => formatComma(value) 
			},
			{ Header: "สถานะ", accessor: "status", width: 120 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: (row) => {
				const myData = row.original;
				return (
					<div>
						<ImageButton 
							title={"แก้ไข"}
							src={edit}
							color={"yellow"}
							onClick={(e, data) => setTargetEdit(myData.id)}/>
						<ImageButton 
							title={"พิมพ์ PDF"}
							color='red'
							src={printer}
							disabled={myData.printable ? false : true}
							onClick={(e, data) => 
								window.open(`${URL_REPORT.CR_STO_04_REPORT_EXPORT}pdf/?id=${myData.id}`)}/>
						<ImageButton
							title={"พิมพ์ Excel"}
							color='green'
							src={printer}
							disabled={myData.printable ? false : true}
							onClick={(e, data) =>
								window.open(`${URL_REPORT.CR_STO_04_REPORT_EXPORT}excel/?id=${myData.id}`)} />
					</div>
				)
			}
		},
	]);

  const handleEdit = (data) => {
		// Go to summary
		history.push({pathname: `${PATH.PO_DETAIL}${data.id}`})
	}

	const fetchData = async (params) => {
		if (!dateFilter || !moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
			if (!dateFilterError)
				setDateFilterError(true);
			return;
		}
			
		if (dateFilterError)
			setDateFilterError(false);
		setLoading(true);
		setErrorMessages(null);
		params = { ...params }
		if (codeFilter)
			params['code'] = codeFilter
		else 
			params['date'] = dateFilter
		
		try {
			const response = await GET(URL_PURCHASE.PURCHASE_ORDER, params)
			setData(response.data)
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const debounce = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 1000),
    []
  );

	React.useEffect(() => {
		setAddOrder(targetEdit ? true : false);
	}, [targetEdit])

  React.useEffect(() => {
		if (!addOrder)
			fetchData();

	}, [addOrder, dateFilter, debouncedCode])


	return (
		<Container fluid style={{marginTop: 24}}>
      <Segment basic>
        <Dimmer inverted active={loading}>
          <Loader content={"Loading"}/>
        </Dimmer>

        {errorMessages && (
          <Message error content={errorMessages} />
        )}

			<Form>
				<Form.Group inline>
					<label>{"วันที่สร้าง PO"}</label>
					<Form.Field
						control={DateInput}
						closable={true}
						dateFormat="YYYY-MM-DD"
						maxDate={moment()}
						placeholder={"เลือก..."}
						error={dateFilterError}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>

					<Form.Field 
						label={"รหัส PO"}
						control={Input}
						value={codeFilter}
						onChange={(_, data) => {
							setCodeFilter(data.value)
							debounce(data.value)
						}}/>

					<Button 
						color={"green"} 
						onClick={() => setAddOrder(true)}>
						<Icon name={"add"}/>
						สร้าง PO
					</Button>
				</Form.Group>
			</Form>

			<PaginationTable
				columns={columns}
				data={data}
				onPageChange={(page) => fetchData({page})}/>

			{/* <TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/> */}

			<POModal 
				open={addOrder}
				dataId={targetEdit}
				onClose={() => {
					if (targetEdit)
						setTargetEdit(null);
					else
						setAddOrder(false);
				}}/>
      </Segment>
		</Container>
	)
}

POManagerView.defaultProps = {}

POManagerView.propTypes = {}