import React from "react";
import propTypes from "prop-types";
import { Form } from "semantic-ui-react";


const CustomFileField = React.forwardRef((props, ref) => {

	const fileInputRef = React.useRef();

  return (
    <Form.Field>
      <label>{props.label}</label>
      <input
        ref={fileInputRef}
        hidden
        type={"file"}
        onChange={(e) => props.onChange(e.target.files[0])}/>
      <Form.Input
        action={{
          color: "blue",
          labelPosition: "right",
          icon: "upload",
          content: "เลือกไฟล์",
          onClick: () => fileInputRef.current ? fileInputRef.current.click() : null
        }}
        readOnly
        error={props.error}
        value={props.value}
        placeholder={"กรุณาเลือกไฟล์..."}/>
    </Form.Field>
  )
});

export default React.memo(CustomFileField);

CustomFileField.defaultProps = {
  label: "",
  error: null,
  value: null,
	onChange: () => null,
}

CustomFileField.propTypes = {
  label: propTypes.string,
  error: propTypes.string,
  value: propTypes.string,
	onChange: propTypes.func,
}
