import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Button, Container, Dimmer, Form, Input, Loader, Message } from 'semantic-ui-react';
import ConfirmModal from '../components/common/ConfirmModal';
import TitleBar from '../components/common/TitleBar';
import StockCountProductModal from '../components/store/StockCountProductModal';
import { URL_STOCK } from '../constances/urls';
import useItemDetail from '../frameworks/hooks/useItemDetail';
import edit from '../assets/img_edit.png';
import remove from '../assets/img_delete.png';
import { DELETE, POST } from '../frameworks/HttpClient';
import { Toast } from '../frameworks/Toast';
import TSReactTable from '../frameworks/TSReactTable';
import { dateToString, getColumnsList } from '../utils/Util';
import ImageButton from '../components/common/ImageButton';
import { formatComma } from '../frameworks/Util';

const defaultDataModel = {
  id: null,
  created_name: '',
  created: '',
  stock_count_product_list: []
}

export default function StockCountScreen() {
  const params = useParams();
  const history = useHistory();

  const { 
    response,
    isLoading,
    errorMessages,
    fetchData,
    handleSave,
    handleDelete,
  } = useItemDetail({
    url: URL_STOCK.STOCK_RECORD,
    id: params.id,
    defaultModel: defaultDataModel,
    manualFetch: true,
    onSaveSuccess: () => null,
    onDeleteSuccess: () => history.goBack(),
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'สินค้า', accessor: 'product_name' },
      { 
        Header: 'จำนวนคงคลัง', 
        accessor: 'stock_amount', 
        width: 140,
        Cell: ({value}) => <p>{(value || value === 0) ? formatComma(value, true) : ""}</p>
      }, 
      { 
        Header: 'จำนวนที่นับจริง', 
        accessor: 'counting_amount', 
        width: 140,
        Cell: ({value}) => <p>{(value || value === 0) ? formatComma(value, true) : ""}</p>
      },
      { 
        Header: 'ผลต่าง', 
        accessor: 'difference_amount', 
        width: 100,
        Cell: ({value}) => <p>{(value || value === 0) ? formatComma(value, true) : ""}</p>
      },
      { Header: 'หมายเหตุ', accessor: 'note', style: {textAlign: "left"} },
      { 
        Header: 'Actions', 
        accessor: 'id', 
        width: 100, 
        show: !response.is_finished,
        Cell: ({ original }) => (
          <div>
            <ImageButton
              title='แก้ไข'
              src={edit}
              color='yellow'
              onClick={() => {
                setIsDetailModalVisible(true);
                setTarget(original);
              }}/>
            <ImageButton
              title='ลบ'
              src={remove}
              color='red'
              onClick={() => {
                setIsDeleteStockCountVisible(true);
                setTarget(original);
              }}/>
          </div>
        )
      },
    ])
  ])

  const [target, setTarget] = React.useState(null);
  const [isDetailModalVisible, setIsDetailModalVisible] = React.useState(false);
  const [isDeleteStockCountVisible, setIsDeleteStockCountVisible] = React.useState(false);

  const [isActionLoading, setIsActionLoading] = React.useState(false);
  const [actionErrorMessages, setActionErrorMessages] = React.useState(null);

  const handleClearTarget = () => {
    setTarget(null)
  }

  const handleFinish = async () => {
    setIsActionLoading(true);
		setActionErrorMessages(null);
		try {
			const response = await POST(`${URL_STOCK.STOCK_RECORD}${params.id}/finish_counting/`);
			Toast.success('สำเร็จ');
      history.goBack()
		} catch (error){
			setActionErrorMessages(error.errorMessages);
		} finally {
			setIsActionLoading(false);
		}
  }

  const handleDeleteProductCount = async () => {
    if (!target || !target.id) {
      setActionErrorMessages('เกิดข้อผิดพลาดกรุณาลองอีกครั้ง')
      return;
    }

    setIsActionLoading(true);
		setActionErrorMessages(null);
		try {
			const response = await DELETE(`${URL_STOCK.STOCK_COUNT_PRODUCT}${target.id}`);
      setIsDeleteStockCountVisible(false);
      handleClearTarget();
      fetchData();
    } catch (error){
			setActionErrorMessages(error.errorMessages);
		} finally {
			setIsActionLoading(false);
		}
  }

  React.useEffect(() => {
    if (params.id) {
      fetchData();
    }
  }, []);

  return (
    <Container fluid>
			<TitleBar title='ตรวจนับ Stock'/>
			<Dimmer inverted active={isLoading}>
				<Loader size='large' content='Loading'/>
			</Dimmer>

			<Container style={{width: '95%', marginTop: 24}}>
				{(errorMessages || actionErrorMessages) && (
					<Message 
						negative
						header='เกิดข้อผิดพลาด'
						content={
              <div>{errorMessages} {actionErrorMessages}</div>
          }/>
				)}

        <Form>
          <Form.Group inline>
            <Form.Field 
              label={"วันที่ : "}
              control={Input}
              transparent
              value={`${dateToString(response.created)}`}/>
            <Form.Field 
              label={"ผู้เริ่มการตรวจ : "}
              control={Input}
              transparent
              value={`${response.created_name}`}/>
          </Form.Group>

          <Form.TextArea
            label='หมายเหตุ'
            value={response.note}/>
        </Form>
        
        <div style={{marginTop: 16}}>
          <Button 
            positive 
            icon='add' 
            content='เพิ่มรายการสินค้า' 
            onClick={() => setIsDetailModalVisible(true)}
            disabled={response.is_finished}/>
          
          <Button 
            primary
            icon='check'
            floated={"right"}
            content='เสร็จสิ้นการนับ Stock' 
            loading={isActionLoading}
            onClick={handleFinish}
            disabled={response.is_finished}/>
        </div>
        <TSReactTable
          style={{marginTop: 8}}
          columns={columns}
          data={response.stock_count_product_list}
          pageSize={
            ( response && response.stock_count_product_list && (response.stock_count_product_list.length > 0)) ? 
            response.stock_count_product_list.length : 5
          }
          showPagination={false}/>
      </Container>

      {/* detail modal */}
      <StockCountProductModal
        open={isDetailModalVisible}
        target={target}
        onClose={() => {
          setIsDetailModalVisible(false);
          fetchData();
          if (target) 
            handleClearTarget();
        }}
        recordId={response.id}/>

      <ConfirmModal
				open={isDeleteStockCountVisible}
				title='ยืนยันการลบรายการนับสินค้า'
				detail={
					<Message warning>
						<Message.List>
							<Message.Item>สินค้า: {target ? target.product_name : ""}</Message.Item>
							<Message.Item>จำนวนในสต๊อก: {target ? target.stock_amount : ""}</Message.Item>
							<Message.Item>จำนวนที่นับจริง: {target ? target.counting_amount : ""}</Message.Item>
							<Message.Item>หมายเหตุ: {target ? target.note : ""}</Message.Item>
						</Message.List>
					</Message>
        }
				loading={isActionLoading}
				errorMessages={actionErrorMessages}
				onClose={() => {
					setIsDeleteStockCountVisible(false);
          if (target)
            handleClearTarget();
				}}
				onConfirm={handleDeleteProductCount}
				onCancel={() => {
					setIsDeleteStockCountVisible(false);
          if (target)
            handleClearTarget();
				}}
			/>
    </Container>
  )
}