import React from 'react';
import { Container, Segment, Header, Form, Dimmer, Loader, Message } from 'semantic-ui-react';
import TitleBar from "../components/common/TitleBar";
import SummaryField from "../components/common/SummaryField";
import ProduceSegment from "../components/tank/ProduceSegment";
import CloseSegment from "../components/tank/CloseSegment";
import { useParams } from "react-router-dom";
import { TANK_STATUS, URL_TANK, USER_GROUP } from '../constances';
import { GET } from '../frameworks/HttpClient';
import { dateTimeToString, formatComma } from '../frameworks/Util';
import SaleSegment from '../components/tank/SaleSegment';
import Cookies from 'js-cookie';
import CleanSegment from '../components/tank/CleanSegment';
import BurnSegment from '../components/tank/BurnSegment';

export default function TankSummaryScreen() {
	const params = useParams();
	const user = JSON.parse(Cookies.get("user"));
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [detail, setDetail] = React.useState(null);

	const fetchDetail = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_TANK.TANK_TRANSACTION}${params.id}/`);
			setDetail(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		fetchDetail();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={detail ? detail.lot_no : "STX"}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
      </Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				{(detail 
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.EMPTY)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.REFILL)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.CLOSE)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.SELL)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.CLEAN)) && (
						<BurnSegment
							style={{marginTop: 24}}
							data={detail}/>
					)}
				
				{(detail 
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.EMPTY)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.REFILL)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.CLOSE)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.SELL)) && (
						<CleanSegment 
							style={{marginTop: 24}}
							data={detail}
							onReload={() => fetchDetail()}/>
					)}
				
				{(detail 
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.EMPTY)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.REFILL)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.CLOSE)) && (
						<SaleSegment 
							style={{marginTop: 24}}
							data={detail}/>
				)}
				
				{(detail 
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.EMPTY)
					&& (TANK_STATUS[detail.state] !== TANK_STATUS.REFILL)) && (
						<CloseSegment 
							style={{marginTop: 24}}
							data={detail}
							canEdit={user.groups.find(group => group.name === USER_GROUP.LAB) ? true : false}
							isFinished={(detail 
								&& (TANK_STATUS[detail.state] !== TANK_STATUS.EMPTY)
								&& (TANK_STATUS[detail.state] !== TANK_STATUS.REFILL) 
								&& (TANK_STATUS[detail.state] !== TANK_STATUS.CLOSE))}
							onReload={() => fetchDetail()}/>
				)}
			  

				{(detail && (TANK_STATUS[detail.state] !== TANK_STATUS.EMPTY)) && (
					<ProduceSegment 
						style={{marginTop: 24}}
						data={detail}
						isFinished={(detail && (TANK_STATUS[detail.state] !== TANK_STATUS.REFILL))}
						onReload={() => fetchDetail()}/>
				)}
				

				<Segment style={{width: "55%", margin: "auto", marginTop: 24}}>
					<Header dividing>สร้าง Lot No.</Header>
					<Form style={{textAlign: "center"}}>
						<SummaryField 
							title={"สร้างเมื่อ"}
							value={detail ? dateTimeToString(detail.created) : "-"} />
						<SummaryField 
							title={"เกรดผลิต"}
							value={detail ? detail.grade : "-"} />
						<SummaryField 
							title={"NH3 Solution"}
							value={detail ? formatComma(detail.nh3_solution) : "-"}
							unit={"กิโลกรัม"} />
					</Form>
				</Segment>
			</Container>
		</Container>
	)
}