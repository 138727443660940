import React from 'react';
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Dimmer, Loader, Message, List, Container } from 'semantic-ui-react';
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../common/ImageButton";
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import { PATH } from "../../routes/Routes";
import edit from "../../assets/img_edit.png";
import { GET } from '../../frameworks/HttpClient';
import { PAYMENT_TYPE, URL_PURCHASE } from '../../constances';
import { formatComma } from '../../frameworks/Util';
import PaginationTable from '../common/PaginationTable';

export default function POReceivedSegment(props) {
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ Header: "รหัส PO", accessor: "code", width: 110 },
			{ Header: "ผู้ขาย", accessor: "supplier", minWidth: 220 },
			{ 
				Header: "รายการสินค้า", 
				accessor: "list_purchase_request",
				minWidth: 220,
				Cell: ({ value }) => <List style={{textAlign: "left"}} bulleted items={value.map(e => e.description)}/>
			},
			{ 
				Header: "ประเภท", 
				accessor: "transaction_type", 
				width: 70,
				Cell: ({value}) => (<p>{PAYMENT_TYPE[value]}</p>) 
			},
			{
				Header: "ราคารวม", accessor: "real_price", width: 90, Cell: ({ value }) => formatComma(value) 
			},
			{ 
				Header: "ผู้ขอซื้อ", 
				accessor: "list_purchase_request", 
				width: 100,
				Cell: ({ value }) => {
					let items = []
					for (let pr of value) items.push(pr.created_name)
					const unique = items.filter((item, pos) => items.indexOf(item) == pos)
					return (<p>{unique.toString()}</p>)
				}
			},
			{ 
				Header: "แผนก", 
				accessor: "list_purchase_request", 
				width: 70,
				Cell: ({ value }) => {
					let items = []
					for (let pr of value) items.push(pr.department)
					const unique = items.filter((item, pos) => items.indexOf(item) == pos)
					return (<p>{unique.toString()}</p>)
				}
			},
			{ Header: "สถานะ", accessor: "status", width: 120 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 60,
			Cell: (row) => {
				return (
					<div>
						<ImageButton 
							title={"แก้ไข"}
							src={edit}
							onClick={(e, data) => handleEdit(row.original)}/>
					</div>
				)
			}
		},
	]);

	const handleEdit = (data) => {
		// Go to summary
		history.push({pathname: `${PATH.PO_DETAIL}${data.id}`})
	}

	const fetchData = async (params) => {
		setLoading(true);
		setErrorMessages(null);
		try {
			const response = await GET(URL_PURCHASE.PURCHASE_ORDER, {...props.queryParams, ...params})
			setData(response.data)
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid style={props.style}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<PaginationTable
				columns={columns}
				data={data}
				onPageChange={(page) => fetchData({page})}/>
			{/* <TSReactTable
				columns={columns}
				data={data}
				defaultPageSize={10}/> */}
		</Container>
	)
}

POReceivedSegment.defaultProps = {
	style: {},
	queryParams: {}
}

POReceivedSegment.propTypes = {
	style: propTypes.object,
	queryParams: propTypes.object,
}