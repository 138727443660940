import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import { Form, Message, Checkbox, Dropdown } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { URL_PURCHASE, URL_STOCK } from "../../constances/urls";
import { GET, POST } from "../../frameworks/HttpClient";
import { Toast } from "../../frameworks/Toast";

let validationSchema = {
	product: Yup.string().required("กรุณากรอกข้อมูล"),
	ref_product: Yup.string().required("กรุณากรอกข้อมูล"),
	transaction_type: Yup.string().required("กรุณากรอกข้อมูล"),
}

export default function TransportModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [productOptions, setProductOptions] = React.useState([]);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			product: "",
			ref_product: "",
			transaction_type: "cash",
			is_control: false,
			is_eco_friendly: false,
			amount: "1",
			unit: "",
			due_date: moment().format("YYYY-MM-DD"),
			department: localStorage.getItem('department') || "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: values => handleSubmit(values),
	});

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			await POST(URL_PURCHASE.PURCHASE_REQUEST, values);
			Toast.success("สำเร็จ")
			props.onClose();
		} catch (e) {
			setErrorMessages(e.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	/** Search Product */
	const handleProductSearch = async (_, data) => {
		const search = data.searchQuery;
		if (search)
			getProductOptions(search);
		else 
			setProductOptions([]);
	}

	const getProductOptions = async (search) => {
		const params = {
			type: formik.values.type,
			name: search,
			control_stock: true,
		}
		try {
			const response = await GET(URL_STOCK.PRODUCT, params);
			setProductOptions(
				response.data.map(item => {
					return {
						...item, 
						key: item.id, 
						value: item.id, 
						text: item.name
					}
				})
			);
		} catch (error) {
			setProductOptions([]);
			console.log(error.errorMessages);
		}
	}

	const getTransportProduct = async () => {
		try {
			const response = await GET(URL_STOCK.PRODUCT, {type: "transport"});
			let myData = response.data;
			if (myData.length === 0) 
				setErrorMessages(`ไม่พบ Transport Product!`);
			else {
				formik.setFieldValue("product", myData[0].id);
				formik.setFieldValue("unit", myData[0].unit);
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			formik.resetForm();
			setErrorMessages(null);
			getTransportProduct();
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={"ค่าขนส่ง"}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form textAlign={"center"}>
						<Form.Group inline>
							<Form.Field width={4} label={"ชื่อสินค้า"}/>
							<Form.Field 
								width={12}
								style={{width: "100%"}}
								control={Dropdown}
								selection
								search
								placeholder={"ค้นหา..."}
								options={productOptions}
								icon={"search"}
								name={"ref_product"}
								error={formik.errors.ref_product}
								value={formik.values.ref_product}
								selectOnBlur={false}
								onSearchChange={handleProductSearch}
								onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
						</Form.Group>

						<Form.Group inline>
							<Form.Field width={4} label={"ประเภทการสั่งซื้อ"}/>
							<Form.Field 
								control={Checkbox}
								radio
								label={"เงินสด"}
								name={"cash"}
								checked={formik.values.transaction_type === "cash"}
								onClick={(_, {name}) => formik.setFieldValue("transaction_type", name)}/>
							<Form.Field
								control={Checkbox}
								radio
								label={"เครดิต"}
								name={"credit"}
								checked={formik.values.transaction_type === "credit"}
								onClick={(_, {name}) => formik.setFieldValue("transaction_type", name)}/>
							<Form.Field
								control={Checkbox}
								radio
								label={"memo"}
								name={"memo"}
								checked={formik.values.transaction_type === "memo"}
								onClick={(_, {name}) => formik.setFieldValue("transaction_type", name)}/>
						</Form.Group>

						<Form.Group inline>
							<Form.Field width={4} label={"แผนกขอสั่งซื้อ"}/>
							<Form.Input
								width={12}
								style={{width: "100%"}}
								selection
								placeholder={"กรุณาติดต่อเพื่อเพิ่มแผนกให้ผู้ใช้งาน"}
								readOnly={true}
								name={"department"}
								error={formik.errors.department}
								value={localStorage.getItem('department_name')}/>
						</Form.Group>
					</Form>
				</div>
			}/>
	)
}

TransportModal.defaultProps = {
	initialModel: null,
	open: false,
	onClose: () => {},
}

TransportModal.propTypes = {
	initialModel: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}