import React from "react";
import propTypes from "prop-types";
import { Container, Icon, Dimmer, Loader, Message, Form } from "semantic-ui-react";
import { INVENTORY_TYPE } from "../../constances";
import { dateToString, getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import moment from "moment";
import { GET } from "../../frameworks/HttpClient";
import TSReactTable from "../../frameworks/TSReactTable";
import { DateInput } from "semantic-ui-calendar-react";
import { URL_STOCK } from "../../constances/urls";
import { formatComma } from "../../frameworks/Util";

export default function PickupGoodsView() {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [dateFilter, setDateFilter] = React.useState(moment().format("YYYY-MM-DD"));
	const [dateFilterError, setDateFilterError] = React.useState(false);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "วันที่เบิก", accessor: "withdraw_date", Cell: ({ value }) => dateToString(value), width: 100 },
			{ Header: "วันที่ขอเบิก", accessor: "created", Cell: ({ value }) => dateToString(value), width: 100 },
			{ Header: "สินค้า", accessor: "product.name" },
			{
				Header: "ประเภทสินค้า",
				accessor: "product.type",
				width: 120,
				Cell: ({value}) => (<p>{INVENTORY_TYPE[value]}</p>)
			},
			{ 
				Header: "จำนวน", 
				accessor: "amount", 
				width: 90,
				Cell: ({value}) => <p>{`${value ? formatComma(value) : "-"}`}</p>
			},
			{ Header: "แผนก", accessor: "department", width: 100 },
			{ Header: "ผู้เบิก", accessor: "created_name", width: 100 },
		]),
		{
			Header: "สถานะ",
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: (row) => {
				let isPicked = row.original.state === 'ALREADY_RECEIVE';
				return (
					<Icon 
						name={isPicked ? "check circle" : "minus circle"}
						color={isPicked ? "green" : "red"}/>
				)
			}
		},
	]);

	const fetchData = async (params) => {
		setLoading(true);
		setErrorMessages(null);
		try {
			const response = await GET(`${URL_STOCK.WITHDRAW_TRANSACTION}`, 
				{
					state: "ALREADY_STOCK_PROCESS",
					check_store_group: true,
					...params,
				});
			setData(response.data)
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (dateFilter) {
			if (!moment(dateFilter, "YYYY-MM-DD", true).isValid()) {
				if (!dateFilterError)
					setDateFilterError(true);
				return;
			}
			
			if (dateFilterError)
				setDateFilterError(false);
			fetchData({withdraw_date: dateFilter});
		}
	}, [dateFilter]);

	return (
		<Container fluid style={{marginTop: 24}}>

			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}

			<Form>
				<Form.Group inline>
					<label>{"วันที่เบิกสินค้า"}</label>
					<Form.Field
						control={DateInput}
						closable={true}
						dateFormat="YYYY-MM-DD"
						maxDate={moment()}
						placeholder={"เลือก..."}
						name={"date"}
						error={dateFilterError}
						value={dateFilter}
						onChange={(_, data) => setDateFilter(data.value)}/>
				</Form.Group>
			</Form>

			<TSReactTable
				style={{marginTop: 16}}
				columns={columns}
				data={data}
				defaultPageSize={10}/>
		</Container>
	)
}

PickupGoodsView.defaultProps = {}

PickupGoodsView.propTypes = {}