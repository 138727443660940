import React from 'react';
import { Container, Message } from 'semantic-ui-react';
import { TANK_STATUS, URL_TANK } from "../constances";
import { useHistory } from "react-router-dom";
import { PATH } from "../routes/Routes";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import scale from "../assets/img_scale.png";
import { 
	TABLE_HEADER_STYLE,
	TABLE_BODY_STYLE,
	getColumnsList,
} from "../utils/Util";
import useListItem from '../frameworks/hooks/useItemList';
import { formatComma } from '../frameworks/Util';
import TankWeightLogModal from '../components/tank/TankWeightLogModal';

export default function TankValidateView() {
	const history = useHistory();
	const list = useListItem({url: `${URL_TANK.TANK}?state=ALL`});
	// Weight Detail
	const [targetWeight, setTargetWeight] = React.useState(null);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "เลขที่แท็งก์", accessor: "name" },
			{ 
				Header: "Lot No.", 
				accessor: "lot_no", 
				width: 160,
				Cell: ({value}) => (<p>{value ? value : "-"}</p>)
			},
			{ 
				Header: "เกรด", 
				accessor: "grade", 
				width: 120,
				Cell: ({value}) => (<p>{value ? value : "-"}</p>) 
			},
			{ 
				Header: "นำ้หนัก (Kg)", 
				accessor: "weight", 
				Cell: ({original, value}) => {
					return (
						<p 
							onClick={() => {
								if (original.transaction)
									setTargetWeight(original)
							}}>
							{value ? formatComma(value, true) : "0"}
						</p>
					)
				}
			},
		]),
		{
			Header: "สถานะ",
			headerStyle: TABLE_HEADER_STYLE,
			accessor: "state",
			Cell: ({value}) => {
				const STATUS = TANK_STATUS[value];
				return (<p style={{...TABLE_BODY_STYLE, height: "100%",}}>{STATUS}</p>)
			}
		},
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			accessor: "state",
			width: 80,
			Cell: ({original, value}) => {
				return (
					<div>
						<ImageButton 
							title={"ตรวจสอบน้ำหนัก"}
							src={scale}
							disabled={(TANK_STATUS[value] === TANK_STATUS.EMPTY)}
							onClick={(e, data) => handleVerify(original.transaction)}/>
					</div>
				)
			}
		},
	]);

	const handleVerify = (transaction) => {
		// Go to detail
		history.push({pathname: `${PATH.VERIFY_WEIGHT}${transaction}`})
	}

	React.useEffect(() => {
		// Load list
		list.fetchData();
	}, []);

	return (
		<Container fluid style={{marginTop: 24}}>

			{/* Table */}
			<TSReactTable
				columns={columns}
				data={list.response}
				pageSize={(list.response.length > 0) ? list.response.length : 10}
				showPagination={false}/>
			
			{/* Weight Log */}
			<TankWeightLogModal 
				open={targetWeight ? true : false}
				data={targetWeight}
				onClose={() => setTargetWeight(null)}/>

		</Container>
	);
}