import React from "react";
import propTypes from "prop-types";
import { Header, Form, Input, Dropdown } from "semantic-ui-react";
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const LabAdjustResult = React.forwardRef((props, ref) => {
	const model = { ...(props.initialModel || defaultInitialModal) };
	const gradeOptions = useDropdownItem({url: `/api/common/grade/`})

	let validationSchema = {
		drc: Yup.number().notRequired(),
		tsc: Yup.number().notRequired(),
		nh3: Yup.number().notRequired(),
		mg: Yup.number().notRequired(),
		vfa: Yup.number().notRequired(),
	}

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: model,
    validationSchema: Yup.object(validationSchema),
    onSubmit: props.onSubmit,
  });

	React.useImperativeHandle(ref, () => ({ formik }));

	React.useEffect(() => {
		gradeOptions.fetchData(); 
	}, []);

	return (
		<div style={{...props.style}}>
			<Header as={"h3"} style={{marginLeft: 24}}>ผลตรวจ</Header>
			<Form style={{textAlign: "right"}}>
				<Form.Group widths={"2"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%TSC"}
						control={Input}
						placeholder={"0"} 
						type={"text"}
						name={"tsc"}
						error={formik.errors.tsc}
						value={formik.values.tsc}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%DRC"}
						control={Input}
						placeholder={"0"} 
						type={"text"}
						name={"drc"}
						error={formik.errors.drc}
						value={formik.values.drc}
						onChange={formik.handleChange}
					/>
				</Form.Group>
				<Form.Group widths={"2"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"%NH3"}
						control={Input}
						placeholder={"0"} 
						type={"text"}
						name={"nh3"}
						error={formik.errors.nh3}
						value={formik.values.nh3}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"Mg"}
						control={Input}
						placeholder={"0"} 
						type={"text"}
						name={"mg"}
						error={formik.errors.mg}
						value={formik.values.mg}
						onChange={formik.handleChange}
					/>
				</Form.Group>

				<Form.Group widths={"2"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						label={"VFA"}
						control={Input}
						placeholder={"0"} 
						type={"text"}
						name={"vfa"}
						error={formik.errors.vfa}
						value={formik.values.vfa}
						onChange={formik.handleChange}
					/>
					<Form.Field />
				</Form.Group>
			</Form>
		</div>
	)
});

const defaultInitialModal = {
  drc: "",
	tsc: "",
	nh3: "",
	mg: "",
	vfa: "",
};

LabAdjustResult.defaultProps = {
	style: null,
	initialModel: null,
}

LabAdjustResult.propTypes = {
	style: propTypes.object,
	initialModel: propTypes.shape({
		drc: propTypes.string,
		tsc: propTypes.string,
		nh3: propTypes.string,
		mg: propTypes.string,
		vfa: propTypes.string,
	}),
}

export default LabAdjustResult;