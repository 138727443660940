import React from 'react';
import { Container, Dropdown, Grid, Header, Icon, Image, Label, Menu, Segment, Sidebar } from 'semantic-ui-react';
import logo from '../../assets/mmg_logo_transparent.png';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';
import ChangePasswordModal from './ChangePasswordModal';
import propTypes from 'prop-types';
import { Media } from '../../AppMedia';
import useUserData from '../../hooks/useUserData';
import { formatComma } from '../../frameworks/Util';
import { PATH } from '../../routes/Routes';


function MainHeader(props) {
  const {
    activeMenu,
    menus,
    children,
  } = props;

  const history = useHistory();
  const { user, removeUserData } = useUserData();
  const menusCount = menus.reduce((prev, menu) => prev + (menu.count ? Number(menu.count) : 0), 0);

  const [sideBarActive, setSideBarActive] = React.useState(false);
  const [profileActive, setProfileActive] = React.useState(false);
  const [openLogout, setOpenLogout] = React.useState(false);
  const [openChagePassword, setOpenChangePassword] = React.useState(false);


  const handleLogout = () => {
    setOpenLogout(false);
    Cookies.remove('token');
    removeUserData();
    history.replace(PATH.LOGIN);
  }

  const getMenuHeader = () => {
    const targetMenu = menus.find(menu => menu.key === activeMenu)
    return targetMenu ? targetMenu.text : ''
  }

  return (
    <div>
      <Sidebar
        style={{ backgroundColor: '#3C3C3C' }}
        as={Menu}
        animation='overlay'
        direction='left'
        icon='labeld'
        inverted
        onHide={() => setSideBarActive(false)}
        visible={sideBarActive}
        width='wide'
        vertical
      >
        <Menu.Item onClick={() => setSideBarActive(false)}>
          <div style={{ display: 'flex', fontSize: 16, justifyContent: 'space-between' }}>
            <Icon name='chevron left'/>
            {user.display_name}
            <div/>
          </div>
        </Menu.Item>
        {menus.filter(menu => menu.permission != false).map(menu => (
          <Menu.Item
            style={{ fontSize: 16, padding: 16 }}
            name={menu.key}
            active={activeMenu === menu.key}
            onClick={() => {
              setSideBarActive(false)
              window.scroll(0,0)
              history.replace(menu.path)
            }}
          >
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              {menu.text}
              {menu.count > 0 && <Label circular color='red' content={formatComma(menu.count)} />}
            </span>
          </Menu.Item>
        ))}
      </Sidebar>
      <Sidebar.Pusher>
        <Menu inverted pointing fixed='top' style={{ backgroundColor: '#3C3C3C' }}>
          <Menu.Item onClick={() => { setSideBarActive(true) }} >
            <Icon size='big' name='bars'/>
            {menusCount > 0 && <Label style={{ position: 'absolute', top: '0.3em', right: '1em' }} circular color='red' content={formatComma(menusCount)} />}
          </Menu.Item>
          <Menu.Item><Image centered size='tiny' src={logo} onClick={() => history.replace(PATH.HOME)} /></Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item onClick={() => setProfileActive(state => !state)}>
              <Dropdown
                open={profileActive}
                onClick={() => setProfileActive(state => !state)}
                onClose={() => setProfileActive(false)}
                icon={null}
                direction='left'
                trigger={<Icon name='user circle' size='big' />}
              >
                <Dropdown.Menu>
                  <Dropdown.Header content={user.display_name} />
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => { setOpenChangePassword(true) }}><Icon name='key' />เปลี่ยนรหัสผ่าน</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setOpenLogout(true) }}><Icon name='unlock' />ออกจากระบบ</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Segment basic style={{ marginTop: '6em', paddingTop: 0 }}>
          <Header content={getMenuHeader()} dividing />
          {children}
        </Segment>
      </Sidebar.Pusher>

      <ConfirmModal
        open={openLogout}
        title='ออกจากระบบ'
        detail='คุณต้องการออกจากระบบใช่หรือไม่ ?'
        onClose={() => setOpenLogout(false)}
        onConfirm={() => handleLogout()}
        onCancel={() => setOpenLogout(false)} />

      <ChangePasswordModal
        open={openChagePassword}
        onClose={() => setOpenChangePassword(false)} />
        
    </div>
  )
}

export default React.memo(MainHeader);

MainHeader.defaultProps = {
  activeMenu: '',
  menus: [],
  children: null,
}

MainHeader.propTypes = {
  activeMenu: '',
  menus: propTypes.array,
  children: propTypes.any,
}
