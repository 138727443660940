import moment from "moment";
import React from "react";
import propTypes from "prop-types";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Popup, Dropdown } from "semantic-ui-react";
import PRListModal from "./PRListModal";
import { URL_REPORT } from "../../constances/urls";
import { GET } from "../../frameworks/HttpClient";
import TSReactTable from "../../frameworks/TSReactTable";
import { currencyFormat } from "../../frameworks/Util";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";

export default function ACGroupContainer(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [dateRange, setDateRange] = React.useState(null);
	const [dateRangeError, setDateRangeError] = React.useState(false);
	const [filterType, setFilterType] = React.useState(props.transactionType);
	const [targetCode, setTargetCode] = React.useState(null);
	const [total, setTotal] = React.useState(0);
	const [data, setData] = React.useState([]);

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "เลขที่บัญชี A/C", 
				accessor: "account_code", 
				style: {textAlign: "left"},
				Footer: "รวม",
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ 
				Header: "ชื่อรายการบัญชี", 
				accessor: "account_name", 
				style: {textAlign: "left"},
				width: 360,
			},
			{ 
				Header: "ยอดรวม", 
				accessor: "total_price",
				style: {textAlign: "right"},
				Cell: ({value}) => (<p>{currencyFormat(value)}</p>),
				Footer: currencyFormat(total),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "right"}}),
			},
		]),
		{
			Header: "Action",
			accessor: "account_code", 
			headerStyle: TABLE_HEADER_STYLE,
			width: 80,
			Cell: ({ original, value }) => {
				let params = null
				if (dateRange) {
					params = {
						dateBefore: moment(dateRange[0]).format('YYYY-MM-DD'),
						dateAfter: moment(dateRange[1]).format('YYYY-MM-DD'),
						transactionType: filterType,
					}
				}
				
				return (
				<div>
					<Popup
						content={"รายการสั่งซื้อ"}
						trigger={
							<Button
								icon 
								color={"blue"}
								size={"mini"}
								disabled={(!params) ? true : false}
								onClick={() => props.onActionClick(original, params)}
								>
								<Icon name={"list"}/>
							</Button>}
					/>
				</div>
			)}
		},
	])

	const fetchData = async () => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);

		setLoading(true);
		setErrorMessages(null);
			const params = {
				date_before: moment(dateRange[0]).format('YYYY-MM-DD'),
				date_after: moment(dateRange[1]).format('YYYY-MM-DD'),
				transaction_type: filterType,
			}
			try {
				const response = await GET(URL_REPORT.AC_GROUP_REPORT, params);
				let myData = response.data
				setData(myData)
				setTotal(myData.reduce((total, item) => total += Number(item.total_price), 0))
			} catch (error) {
				setErrorMessages(error.errorMessages)
			} finally {
				setLoading(false)
			}
	}

	const gotoExport = () => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);
			
		props.onExportPDFClick({
			dateBefore: moment(dateRange[0]).format('YYYY-MM-DD'),
			dateAfter: moment(dateRange[1]).format('YYYY-MM-DD'),
			transactionType: filterType,
		})
	}

	return (
		<Container fluid style={{marginTop: 24}}>
			<Header style={{textAlign: "center"}}>{props.title}</Header>

			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			
			<Form>
				<Form.Group inline>
					{props.optionsType && (props.optionsType.length > 0) && (
						<Form.Field 
							label={"ประเภท"}
							control={Dropdown}
							selection
							placeholder={"เลือก..."}
							options={props.optionsType}
							selectOnBlur={false}
							value={filterType}
							onChange={(_, data) => setFilterType(data.value)}/>
					)}

					<label>{"วันที่"}</label>
					<Form.Field
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						error={dateRangeError}
						onChange={(_, data) => setDateRange(data.value)}/>

					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
				
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					onClick={() => gotoExport()}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>

			<PRListModal 
				open={targetCode ? true : false}
				onClose={() => setTargetCode(null)}
				data={targetCode}/>
		</Container>
	)
}

ACGroupContainer.defaultProps = {
	title: "",
	transactionType: "",
	optionsType: [],
	onActionClick: (data, params) => {},
	onExportPDFClick: (params) => {},
}

ACGroupContainer.propTypes = {
	title: propTypes.string,
	transactionType: propTypes.string,
	optionsType: propTypes.array,
	onActionClick: propTypes.func,
	onExportPDFClick: propTypes.func,
}