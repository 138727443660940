import React from 'react';
import { Container, Button, Icon, Message, Dimmer, Loader } from 'semantic-ui-react';
import TitleBar from "../../components/common/TitleBar";
import TSReactTable from "../../frameworks/TSReactTable";
import ImageButton from "../../components/common/ImageButton";
import SaleFieldLatexModal from "../../components/store/sale/SaleFieldLatexModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../../utils/Util";
import edit from "../../assets/img_edit.png";
import { useParams } from "react-router-dom";
import { GET } from "../../frameworks/HttpClient";
import useListItem from "../../frameworks/hooks/useItemList";
import { URL_POND, URL_SALE } from "../../constances";
import { dateTimeToString, formatComma } from "../../frameworks/Util";

export default function SaleFieldLatexScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [openSale, setOpenSale] = React.useState(false);
	const [targetData, setTargetData] = React.useState(null);
	// Table
	const [data, setData] = React.useState();
	const list = useListItem({url: `${URL_SALE.RAW_LATEX_SELL_TRANSACTION}?pond_transaction=${data ? data.transaction : ""}`});
	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลาขาย", 
				accessor: "date", 
				width: 180,
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
			},
			{ Header: "เลขที่ DO", accessor: "do_no" },
			{ 
				Header: "น้ำหนักขาย (kg)", 
				accessor: "weight", 
				width: 180,
				Cell: ({value}) => (<p>{value ? formatComma(parseFloat(value)) : "-"}</p>)
			},
			{ Header: "หมายเหตุ", accessor: "note" },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 120,
			Cell: (row) => (
				<ImageButton 
					title={"แก้ไข"}
					src={edit}
					onClick={(e, data) => handleEdit(row.original)}/>
			),
		},
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_POND.POND}${params.id}/`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleEdit = (data) => {
		setTargetData({
			id: data.id, 
			do_no: data.do_no, 
			weight: parseFloat(data.weight),
			note: data.note,
		})
	}

	const closeSaleModal = () => {
		if (targetData) 
			setTargetData(null);
		else
			setOpenSale(false);
	}

	React.useEffect(() => {
		setOpenSale(targetData ? true : false);
	}, [targetData]);

	React.useEffect(() => {
		if (data && !openSale) {
			/** load list */
			list.fetchData();
		}
	}, [data, openSale]);

	React.useEffect(() => {
		fetchData()
	}, [])

	return (
		<Container fluid>
			<TitleBar title={`ขายน้ำยางสด ${data ? `[ ${data.name} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Button color={"green"} onClick={() => setOpenSale(true)}>
					<Icon name={"add"}/>ขาย
				</Button>

				<TSReactTable 
					style={{marginTop: 16}}
					columns={columns}
					data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 5}
					showPagination={false}/>
			</Container>

			{/* Sale */}
			<SaleFieldLatexModal
				open={openSale}
				id={data ? data.transaction : null}
				initialModel={targetData}
				onClose={() => closeSaleModal()}
			/>
		</Container>
	)
}