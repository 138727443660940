export const POND_STATUS = {
	REFILL: "บ่อว่าง",
	CLOSE: "ปิดบ่อพักน้ำยาง",
	PRODUCE: "เปิดบ่อผลิต",
	CLEAN: "ทำความสะอาด",
	BURN: "เผาฆ่าเชื้อ",
	END: "สิ้นสุดการทำงาน",
}

export const TANK_STATUS = {
	EMPTY: "แท็งก์เปล่า",
	REFILL: "เปิดเพื่อเติมน้ำยาง",
	CLOSE: "ปิดแท็งก์",
	SELL: "เปิดเพื่อขาย",
	CLEAN: "ทำความสะอาด",
	BURN: "เผาฆ่าเชื้อ",
	END: "สิ้นสุดการทำงาน",
}

export const STR_CHEMICAL = {
	water: "น้ำ",
	la: "La",
	dap: "DAP",
	nh3: "NH3",
	tz: "TZ",
	washer: "น้ำล้าง",
}

export const USER_GROUP = {
	FACTORY_POND: "factory_pond",
	FACTORY_TANK: "factory_tank",
	LAB: "lab",
	LAB_REPORT_APPROVER: "lab_report_approver",
	STORE: "store",
	STORE_MANAGER: "store_manager",
	MANAGER: "manager",
	ACCOUNTING: "accounting",
	// STORE FOR GOODS TYPE
	STORE_CHEMICAL: "store_chemical",
	STORE_PACKAGING: "store_packaging",
	STORE_PPE: "store_ppe",
	STORE_SPARE_PART: "store_spare_part",
	STORE_STATIONERY: "store_stationery",
	STORE_OTHERS: "store_others",
}

export const USER_DEPARTMENT = {
	STORE: 'Sto',
}

export const INVENTORY_TYPE = {
	chemical: "สารเคมี",
	packaging: "บรรจุภัณฑ์",
	spare_part: "SPARE PART",
	ppe: "PPE",
	stationery: "เครื่องเขียน",
	others: "อื่นๆ",
	oil: "เชื้อเพลิง",
}

export const PAYMENT_TYPE = {
	cash: "เงินสด",
	memo: "memo",
	credit: "เครดิต",
}

export const PRODUCT_TYPE = {
	chemical: "สารเคมี",
	packaging: "บรรจุภัณฑ์",
	spare_part: "SPARE PART",
	ppe: "PPE",
	stationery: "เครื่องเขียน",
	others: "อื่นๆ",
	oil: "เชื้อเพลิง",
	transport: "บริการขนส่ง",
}

export const TRANSACTION_TYPE = {
	credit: "เครดิต",
	cash: 'เงินสด',
  memo: 'Memo',
}

export const WITHDRAW_STATUS = {
	WAIT_CONFIRM: "รอการยืนยัน",
	NOT_CONFIRM: "ไม่ยืนยัน",
	WAIT_PROGRESS: "รอดำเนินการ",
	REJECT: "ปฏิเสธ",
	ALREADY_WITHDRAW: "เบิกแล้ว",
	ALREADY_RECEIVE: "รับสินค้าแล้ว",
}

export const QUOTATION_TPYE = {
	quotation: "ใบเสนอราคา",
	set_price: "กำหนดราคาซื้อเอง",
}

export const PURCHASE_VAT_TYPE = {
	INCLUDE_VAT: "include_vat",
	EXCLUDE_VAT: "exclude_vat",
	NO_VAT: "no_vat",
}

export const LATEX_ORDER_TYPE = {
	sell: "ขาย",
	dc: "DC"
}

export const MONTH_STRING_TH = {
	"01": "มกราคม", 
	"02": "กุมภาพันธ์", 
	"03": "มีนาคม",
	"04": "เมษายน",
	"05": "พฤษภาคม",
	"06": "มิถุนายน",
	"07": "กรกฎาคม",
	"08": "สิงหาคม",
	"09": "กันยายน",
	"10": "ตุลาคม",
	"11": "พฤศจิกายน",
	"12": "ธันวาคม"
};
