import React from "react";
import propTypes from "prop-types";
import { Form, Message, Dropdown, Checkbox, TextArea, Icon } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import TSReactTable from "../../frameworks/TSReactTable";
import { getColumnsList } from "../../utils/Util";
import { PAYMENT_TYPE, URL_PURCHASE } from "../../constances";
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import { GET, PATCH, POST } from "../../frameworks/HttpClient";
import { formatComma, formatFileName } from "../../frameworks/Util";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "../../frameworks/Toast";

export default function POModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [code, setCode] = React.useState(null)
	const [data, setData] = React.useState([]);
	const supplierOptions = useDropdownItem({url: `${URL_PURCHASE.SUPPLIER}?is_approved=True`});

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			supplier: "",
			vat_type: "",
			remark: "",
			is_factory_order: true,
			quotations_id_list: [],
			transaction_type: null,
		},
		validationSchema: Yup.object().shape({
      supplier: Yup.string().required("กรุณากรอกข้อมูล"),
			vat_type: Yup.string().required("กรุณากรอกข้อมูล").nullable(true),
			is_factory_order: Yup.bool().required("กรุณากรอกข้อมูล"),
			remark: Yup.string().notRequired().nullable(true),
			quotations_id_list: Yup.array().of(Yup.string()).min(1, "กรุณาเลือกใบเสนอราคาอย่างน้อย 1 ใบ"),
			transaction_type: Yup.string().notRequired().nullable(true),
    }),
		onSubmit: (values) => handleSubmit(values),
	});

	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "", 
				width: 50,
				Cell: (row) => {
					let id = row.original.id;
					let currentType = (row.original && row.original.purchase_request) 
						? row.original.purchase_request.transaction_type : null
					let targetType = formik.values.transaction_type
					return (
						<Checkbox 
							style={{margin: "auto"}}
							disabled={(!targetType || (targetType === currentType)) ? false : true}
							checked={formik.values.quotations_id_list.find(item => id === item) ? true : false}
							onChange={(_, data) => {
								let quotationsID = formik.values.quotations_id_list;
								if (data.checked) {
									quotationsID = [...quotationsID, id];
									formik.setFieldValue("transaction_type", currentType);
								} else {
									let index = quotationsID.indexOf(id)
									quotationsID.splice(index, 1)
								}
								formik.setFieldValue("quotations_id_list", quotationsID)
								
								if (quotationsID.length === 0)
									formik.setFieldValue("transaction_type", null);
							}}/>
					)
				}
			},
			{ Header: "รายการสินค้า", accessor: "purchase_request.product.name", width: 220 },
			{ 
				Header: "ประเภท", 
				accessor: "purchase_request.transaction_type", 
				width: 80,
				Cell: ({value}) => (<p>{PAYMENT_TYPE[value]}</p>) 
			},
			{ Header: "จำนวน", accessor: "purchase_request.amount", width: 90, Cell: ({ value }) => formatComma(value) },
			{
				Header: "สินค้าควบคุม", 
				accessor: "purchase_request.is_control", 
				width: 60, 
				Cell: ({ value }) => {
					return (
						<Icon 
							style={{margin: "auto"}} 
							name={value ? "check circle" : "remove circle"} 
							color={value ? "green" : "red"}/>
					)
				}},
			{ Header: "หน่วย", accessor: "purchase_request.unit", width: 110 },
			{ Header: "ราคาต่อหน่วย", accessor: "unit_price", width: 110, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคารวม", accessor: "total_price", width: 110, Cell: ({ value }) => formatComma(value) },
			{ Header: "ราคาจริง", accessor: "real_price", width: 110, Cell: ({ value }) => formatComma(value) },
			{ Header: "ผู้ขอซื้อ", accessor: "purchase_request.created_name", width: 120},
			{ Header: "หมายเหตุ", accessor: "purchase_request.note", width: 280},
			{ 
				Header: "เอกสารแนบ", 
				accessor: "attachment", 
				width: 220,
				Cell: ({ value }) => (<p>{value ? formatFileName(value) : "-"}</p>)  
			},
		]),
	]);

	const handleSubmit = async (values) => {
		setLoading(true);
		setErrorMessages(null);
		try {
			if (props.dataId) {
				// Update PO
				let params = { ...values }
				delete params['quotations_id_list'] //  Don't update quotation list
				if (params["transaction_type"])
					delete params["transaction_type"]
				const response = await PATCH(`${URL_PURCHASE.PURCHASE_ORDER}${props.dataId}/`, params);
			} else {
				// HandleSupplierVat is additional ignore result 
				await handleSupplierVat();
				const response = await POST(URL_PURCHASE.PURCHASE_ORDER, values);
			}
			// Close modal
			Toast.success("สำเร็จ")
			props.onClose();
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	// Update supplier vat_type
	const handleSupplierVat = async () => {
		const supplierId = formik.values.supplier;
		if (!supplierId) {
			setErrorMessages("Supplier ID Not found!");
			return false;
		}

		const supplier = supplierOptions.response.find(x => x.id === supplierId)
		if (supplier.vat_type)
			return true;
			
		try {
			await PATCH(`${URL_PURCHASE.SUPPLIER}${supplierId}/`, {vat_type: formik.values.vat_type})
		} catch (e) {
			setErrorMessages(e.errorMessages);
			return false;
		}
		return true;
	}

	const fetchQuotationFromSupplier = async () => {
		setLoading(true);
		setErrorMessages(null);
		try {
			const response = await GET(URL_PURCHASE.QUOTATION, { supplier: formik.values.supplier, is_available_for_po: true })
			setData(response.data)
		} catch (e) {
			setErrorMessages(e.errorMessages);
		} finally {
			setLoading(false);
		}
	}
	
	// Handle Edit case [props.dataId]
	const prepareDataForEdit = async (id) => {
		setLoading(true);
		try {
			const response = await GET(`${URL_PURCHASE.PURCHASE_ORDER}${id}`);
			// set supplier option
			supplierOptions.initialResponse([response.data.supplier])
			setCode(response.data.code)
			// set quotation
			let quotations = response.data.list_purchase_request.map(item => {
				let selectedQuotation = item.selected_quotation
				return {
					id: selectedQuotation.id,
					unit_price: selectedQuotation.unit_price,
					total_price: selectedQuotation.total_price,
					real_price: selectedQuotation.real_price,
					attachment: selectedQuotation.attachment,
					purchase_request: {
						amount: item.amount,
						unit: item.unit,
						created_name: item.created_name,
						note: item.note,
						transaction_type: item.transaction_type,
						is_control: item.is_control,
						product: {
							name: item.product.name,
						}
					},
				}
			});
			setData(quotations);
			let quotationsId = quotations.map(item => item.id)
			formik.setValues({
				// code: purchaseOrder.code,
				supplier: response.data.supplier.id,
				vat_type: response.data.vat_type,
				is_factory_order: response.data.is_factory_order,
				remark: response.data.remark,
				quotations_id_list: quotationsId
			})

		} catch(error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (props.dataId)
			return;
		
		if (formik.values.supplier) {
			fetchQuotationFromSupplier();
		} else {
			setData([]);
		}
	}, [formik.values.supplier]);

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
			// let purchaseOrder = props.data;
			if (props.dataId) {
				prepareDataForEdit(props.dataId);
			} else {
				supplierOptions.fetchData();
				setData([])
			}
		}
	}, [props.open]);

	return (
		<ConfirmModal 
			open={props.open}
			title={props.dataId ? `รหัส PO : ${code}` : "สร้าง PO"}
			size={"large"}
			loading={loading}
			positiveText={props.dataId ? "แก้ไข" : "ยืนยัน"}
			positiveColor={props.dataId ? "yellow" : "green"}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form>
						<Form.Group >
							<Form.Field 
								width={12}
								label={"ผู้ขาย"}
								control={Dropdown}
								search
								selection
								options={supplierOptions.response}
								placeholder={"เลือก..."}
								selectOnBlur={false}
								onChange={(_, data) => {
									formik.setFieldValue('supplier', data.value)
									const selected = supplierOptions.response.find(x => x.id === data.value)
									if (selected)
										formik.setFieldValue("vat_type", selected.vat_type)
								}}
								error={formik.errors.supplier}
								value={formik.values.supplier}/>
								
							<Form.Field
								width={4}
								label={"วิธีการคำนวณภาษีฯ"}
								control={Dropdown}
								name={"vat_type"}
								placeholder={"เลือก..."}
								selectOnBlur={false}
								selection
								options={[
									{key: "include_vat", value: "include_vat", text: "ราคารวมภาษีฯ"},
									{key: "exclude_vat", value: "exclude_vat", text: "ราคายังไม่รวมภาษีฯ"},
									{key: "no_vat", value: "no_vat", text: "ไม่มีภาษีมูลค่าเพิ่ม"},
								]}
								error={formik.errors.vat_type}
								value={formik.values.vat_type}
								onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>
						</Form.Group>

						<Form.Field
							label={"ข้อมูลเพิ่มเติม"}
							name={"remark"}
							control={TextArea}
							placeholder={"เพิ่มเติม..."}
							onChange={formik.handleChange}
							error={formik.errors.remark}
							value={formik.values.remark}/>
						
						<Form.Group inline>
							<label>ระบุนามที่อยู่ผู้ซื้อ</label>
							<Form.Field 
								label={"โรงงาน"}
								control={Checkbox}
								checked={formik.values.is_factory_order}
								onClick={() => formik.setFieldValue("is_factory_order", true)}/>
							<Form.Field 
								label={"สำนักงานใหญ่"}
								control={Checkbox}
								checked={!formik.values.is_factory_order}
								onClick={() => formik.setFieldValue("is_factory_order", false)}/>
						</Form.Group>
					</Form>
					{formik.errors.quotations_id_list != null && (
						<Message error content={formik.errors.quotations_id_list}/>
					)}
					<TSReactTable
						style={{marginTop: 16}}
						columns={columns}
						data={data}
						pageSize={(data && (data.length > 0)) ? data.length : 5}
						showPagination={false}/>
				</div>
			}/>
	)
}

POModal.defaultProps = {
	open: false,
	dataId: null,
	onClose: () => {},
}

POModal.propTypes = {
	open: propTypes.bool,
	// PO Model
	dataId: propTypes.object,
	onClose: propTypes.func,
}