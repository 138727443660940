import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Label } from 'semantic-ui-react';
import { Media } from '../../AppMedia';
import { PATH } from '../../routes/Routes';


export default function ReportHeader({ headerText, subHeaderText }) {
  const history = useHistory();
  
  return headerText ? (
    <Grid columns='3' stackable verticalAlign='middle'>
      <Grid.Column>
        <Button basic color='black' icon='chevron left' content='กลับ' onClick={() => history.replace(PATH.REPORT)}/>
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Header>{headerText}</Header>
      </Grid.Column>
      <Grid.Column textAlign='right'>
        <Media greaterThan='sm'>
          <Header as='h4' color='grey'>{subHeaderText}</Header>
        </Media>
      </Grid.Column>
    </Grid>
  ) : null
}