import React from "react";
import propTypes from "prop-types";
import { Form, Message, Input, TextArea } from "semantic-ui-react";
import ConfirmModal from "../../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TRANSACTION_TYPE_WORKFLOW } from "../../../constances";
import { POST } from "../../../frameworks/HttpClient";

let validationSchema = {
	// purchase_request: Yup.string().required("กรุณากรอกข้อมูล"),
	amount: Yup.number().required("กรุณากรอกข้อมูล"),
	// quotation: Yup.string().required("กรุณากรอกข้อมูล"),
	unit_price: Yup.number().required("กรุณากรอกข้อมูล"),
	total_price: Yup.number().required("กรุณากรอกข้อมูล"),
	real_price: Yup.number().required("กรุณากรอกข้อมูล"),
	memo: Yup.string().required("กรุณากรอกข้อมูล"),
}

export default function PREditorModal(props) {
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const receivedAmount = props.pr ? props.pr.total_received_amount : 0

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			// purchase_request: props.pr ? props.pr.id : null,
			amount: props.pr ? parseFloat(props.pr.amount) : 0,
			// quotation: (props.pr && props.pr.selected_quotation) ? props.pr.selected_quotation.id : 0,
			unit_price: (props.pr && props.pr.selected_quotation) ? parseFloat(props.pr.selected_quotation.unit_price) : 0,
			total_price: (props.pr && props.pr.selected_quotation) ? parseFloat(props.pr.selected_quotation.total_price) : 0,
			real_price: (props.pr && props.pr.selected_quotation) ? parseFloat(props.pr.selected_quotation.real_price) : 0,
			memo: "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => handleSubmit(values),
	});

	const handleSubmit = async (values) => {
		if (values.amount < receivedAmount) {
			setErrorMessages("ไม่สามารถแก้ไขจำนวนใหม่ได้ เนื่องจากจำนวนใหม่ น้อยกว่า จำนวนที่รับมาแล้ว!")
			return;
		}
		if (!props.pr) {
			setErrorMessages("Purchase Request Not Found!");
			return;
		}

		setLoading(true);
		setErrorMessages(null);
		try {
			const action = "to_state_wait_product_approve_requested"
			await POST(
				`${TRANSACTION_TYPE_WORKFLOW[props.pr.transaction_type]}${props.pr.object_id}/${action}/`, 
				values);
			
				props.onClose();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={`แก้ไขรายการสั่งซื้อ`}
			size={"tiny"}
			loading={loading}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form>
						<Form.Field
							label={"ชื่อสินค้า"}
							control={Input}
							value={props.pr ? props.pr.product.name : ""}
							readOnly/>
						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"จำนวนขอซื้อ (เดิม)"}
								control={Input}
								value={props.pr ? parseFloat(props.pr.amount) : ""}
								readOnly/>
							<Form.Field 
								required
								label={"จำนวนขอซื้อ (ใหม่)"}
								control={Input}
								name={"amount"}
								value={formik.values.amount}
								error={formik.errors.amount}
								onChange={(_, data) => {
									let totalPrice = Number(data.value) * Number(formik.values.unit_price ? formik.values.unit_price : 0);
									totalPrice = totalPrice.toFixed(2);
									formik.setFieldValue(data.name, data.value)
									formik.setFieldValue('total_price', totalPrice)
									if (totalPrice >= 0) {
										formik.setFieldValue('real_price', totalPrice)
									} else {
										formik.setFieldValue('real_price', "0")
									}
								}}/>
						</Form.Group>
						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"ราคาต่อหน่วย (เดิม)"}
								control={Input}
								value={(props.pr && props.pr.selected_quotation) 
									? parseFloat(props.pr.selected_quotation.unit_price) : "0"}
								readOnly/>
							<Form.Field 
								required
								label={"ราคาต่อหน่วย (ใหม่)"}
								control={Input}
								name={"unit_price"}
								value={formik.values.unit_price}
								error={formik.errors.unit_price}
								onChange={(_, data) => {
									let totalPrice = Number(data.value) * Number(formik.values.amount ? formik.values.amount : 0);
									totalPrice = totalPrice.toFixed(2);
									formik.setFieldValue(data.name, data.value)
									formik.setFieldValue('total_price', totalPrice)
									if (totalPrice >= 0) {
										formik.setFieldValue('real_price', totalPrice)
									} else {
										formik.setFieldValue('real_price', "0")
									}
								}}/>
						</Form.Group>
						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"ราคารวม (เดิม)"}
								control={Input}
								value={(props.pr && props.pr.selected_quotation) 
									? parseFloat(props.pr.selected_quotation.total_price) : "0"}
								readOnly/>
							<Form.Field 
								label={"ราคารวม (ใหม่)"}
								control={Input}
								name={"total_price"}
								value={formik.values.total_price}
								error={formik.errors.total_price}
								readOnly/>
						</Form.Group>
						<Form.Group widths={"equal"}>
							<Form.Field 
								label={"ราคาจริง (เดิม)"}
								control={Input}
								value={(props.pr && props.pr.selected_quotation) 
									? parseFloat(props.pr.selected_quotation.real_price) : "0"}
								readOnly/>
							<Form.Field 
								required
								label={"ราคาจริง (ใหม่)"}
								control={Input}
								name={"real_price"}
								value={formik.values.real_price}
								error={formik.errors.real_price}
								onChange={formik.handleChange}/>
						</Form.Group>
						<Form.Field
							required
							label={"เหตุผล"}
							control={TextArea}
							name={"memo"}
							placeholder={"เหตุผล..."}
							value={formik.values.memo}
							error={formik.errors.memo}
							onChange={formik.handleChange}/>
					</Form>
				</div>
			}/>
	)
}

PREditorModal.defaultProps = {
	pr: null,
	open: false,
	onClose: () => {},
}

PREditorModal.propTypes = {
	pr: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}