import React from 'react';
import propTypes from "prop-types";
import { Header, Modal, Button, Dimmer, Loader, Message } from 'semantic-ui-react';

export default function ConfirmModal(props) {

  return (
		<Modal 
			centered={false}
			open={props.open} 
			onClose={props.onClose}
			size={props.size}>
			<Header icon={props.iconTitle} content={props.title}/>
			<Modal.Content>
				<Dimmer.Dimmable dimmed={props.loading}>
					{ props.errorMessages && 
						<Message error>{props.errorMessages}</Message>
					}
					{props.detail}
					<Dimmer inverted active={props.loading}>
						<Loader size={"large"} content={"Loading"}/>
					</Dimmer>
				</Dimmer.Dimmable>
			</Modal.Content>
			<Modal.Actions>
				<Button color={props.negativeColor} onClick={props.onCancel}>{props.negativeText}</Button>
				<Button color={props.positiveColor} onClick={props.onConfirm} disabled={props.loading}>{props.positiveText}</Button>
			</Modal.Actions>
		</Modal>
	)
}

ConfirmModal.defaultProps = {
	open: false,
	title: "",
	iconTitle: null,
	detail: "",
	size: "small",
	loading: false,
	errorMessages: null,
	negativeText: "ยกเลิก",
	negativeColor: "grey",
	positiveText: "ยืนยัน",
	positiveColor: "green",
	onClose: () => {},
	onConfirm: () => {},
	onCancel: () => {},
}

ConfirmModal.propTypes = {
	open: propTypes.bool,
	title: propTypes.string,
	detail: propTypes.oneOfType([
		propTypes.string,
		propTypes.element,
	]),
	size: propTypes.string,
	loading: propTypes.bool,
	errorMessages: propTypes.string,
	negativeText: propTypes.string,
	negativeColor: propTypes.string,
	positiveText: propTypes.string,
	positiveColor: propTypes.string,
	onClose: propTypes.func,
	onConfirm: propTypes.func,
	onCancel: propTypes.func,
}