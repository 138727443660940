import React from "react";
import propTypes from "prop-types";
import { Form, Input, Message, Dropdown } from "semantic-ui-react";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import { URL_PURCHASE } from "../../constances";
import { PATCH, POST } from "../../frameworks/HttpClient";
import CustomFileField from "../common/CustomFileField";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

let validationSchema = {
	purchase_request: Yup.string().required("กรุณากรอกข้อมูล"),
	supplier: Yup.string().required("กรุณากรอกข้อมูล"),
	unit_price: Yup.string().required("กรุณากรอกข้อมูล"),
	total_price: Yup.string().required("กรุณากรอกข้อมูล"),
	real_price: Yup.string().required("กรุณากรอกข้อมูล"),
	quotation_date: Yup.string().required("กรุณากรอกข้อมูล"),
}

export default function QuotationModal(props) {
	const supplierOptions = useDropdownItem({url: URL_PURCHASE.SUPPLIER})

	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			purchase_request: props.prModel ? props.prModel.id : "",
			type: "quotation",
			supplier: "",
			unit_price: "",
			total_price: "",
			real_price: "",
			quotation_date: moment().format("YYYY-MM-DD"),
			attachment: null,
			is_active: true,
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: values => handleSubmit(values),
	});

	const handleSubmit = async (values) => {
		setLoading(true);
		let params = values;
		if (!params['attachment']) {
			// if attachment is null remove attachment key to avoid parsing error on backend
			delete params['attachment']
		}
		try {
			if (props.initialModel) // Update
				await PATCH(`${URL_PURCHASE.QUOTATION}${props.initialModel.id}/`, params, true);
			else // Create
				await POST(URL_PURCHASE.QUOTATION, params, true);
			
			props.onClose();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			formik.resetForm();
			supplierOptions.fetchData();
			
			// Case Edit
			if (props.initialModel) {
				formik.setFieldValue("supplier", props.initialModel.supplier.id);
				formik.setFieldValue("unit_price", props.initialModel.unit_price);
				formik.setFieldValue("total_price", props.initialModel.total_price);
				formik.setFieldValue("real_price", props.initialModel.real_price);
				formik.setFieldValue("quotation_date", props.initialModel.quotation_date);
			}
		}
	}, [props.open])

	return (
		<ConfirmModal 
			open={props.open}
			title={`${props.initialModel ? "" : "เพิ่ม"}ใบเสนอราคา [${(props.prModel && props.prModel.product) ? props.prModel.product.name : ""}]`}
			size={"tiny"}
			loading={loading}
			positiveText={props.initialModel ? "แก้ไข" : "ยืนยัน"}
			positiveColor={props.initialModel ? "yellow" : "green"}
			onClose={props.onClose}
			onCancel={props.onClose}
			onConfirm={formik.handleSubmit}
			detail={
				<div>
					{errorMessages && (
						<Message error content={errorMessages}/>
					)}

					<Form textAlign={"center"}>
						<Form.Field
							label={"ผู้ขาย"}
							control={Dropdown}
							search
							selection
							options={supplierOptions.response}
							placeholder={"เลือก..."}
							name={"supplier"}
							error={formik.errors.supplier}
							value={formik.values.supplier}
							selectOnBlur={false}
							onChange={(_, data) => formik.setFieldValue(data.name, data.value)}/>

						<Form.Group >
							<Form.Field
								width={4} 
								label={"ราคาต่อหน่วย"}
								control={Input}
								placeholder={"0"}
								name={"unit_price"}
								error={formik.errors.unit_price}
								value={formik.values.unit_price}
								onChange={(_, data) => {
									let totalPrice = Number(data.value) * Number(props.prModel ? props.prModel.amount : 0)
									totalPrice = totalPrice.toFixed(2);
									formik.setFieldValue('unit_price', data.value)
									formik.setFieldValue('total_price', totalPrice)
									if (totalPrice > 0) {
										formik.setFieldValue('real_price', totalPrice)
									} else {
										formik.setFieldValue('real_price', "")
									}
								}}/>
							<Form.Field 
								width={6} 
								label={"ราคารวม"}
								control={Input}
								placeholder={"0"}
								name={"total_price"}
								readOnly
								error={formik.errors.total_price}
								value={formik.values.total_price}/>
							<Form.Field 
								width={6} 
								label={"ราคาจริง"}
								control={Input}
								placeholder={"0"}
								name={"real_price"}
								error={formik.errors.real_price}
								value={formik.values.real_price}
								onChange={formik.handleChange}/>
						</Form.Group>

						<Form.Field 
							label={"วันที่ออกใบเสนอราคา"}
							control={DateInput}
							placeholder={"วันที่"}
							dateFormat={"YYYY-MM-DD"}
							maxDate={moment()}
							name={"quotation_date"}
							error={formik.errors.quotation_date}
							value={formik.values.quotation_date}
							onChange={(_, data) => formik.setFieldValue(data.name, data.value)}
							/>
						
						<CustomFileField
							label={"แนบใบเสนอราคา"}
							error={formik.errors.attachment}
							value={formik.values.attachment ? formik.values.attachment.name : ''}
							onChange={(value) => formik.setFieldValue('attachment', value)}/>
					</Form>
				</div>
			}/>
	)
}

QuotationModal.defaultProps = {
	prModel: null,
	initialModel: null,
	open: false,
	onClose: () => {},
}

QuotationModal.propTypes = {
	prModel: propTypes.object,
	initialModel: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
}