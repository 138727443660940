import React from 'react';
import propTypes from "prop-types";
import { URL_REPORT } from "../../constances/urls";
import ListModal from '../common/ListModal';
import { getColumnsList, TABLE_HEADER_STYLE } from '../../utils/Util';
import { formatComma } from '../../frameworks/Util';
import { Button, Icon, List, Popup } from 'semantic-ui-react';

export default function POListModal(props) {

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "รหัส PO", accessor: "code", width: 120 },
			{ Header: "ผู้ขาย", accessor: "supplier" },
			{ 
				Header: "รายการสินค้า", 
				accessor: "list_purchase_request",
				Cell: ({ value }) => <List style={{textAlign: "left"}} bulleted items={value.map(e => e.description)}/>
			},
			{
				Header: "ราคารวม", accessor: "real_price", width: 90, Cell: ({ value }) => formatComma(value) 
			},
			{ Header: "สถานะ", accessor: "status", width: 140 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			accessor: "id",
			width: 80,
			Cell: ({value}) => (
				<div>
					<Popup
						content={"Export PDF"}
						trigger={
							<Button 
								style={{marginLeft: 4, marginRight: 4}}
								icon 
								color={"blue"}
								size={"mini"}
								onClick={() => 
									window.open(`${URL_REPORT.CR_STO_04_REPORT_EXPORT}pdf/?id=${value}`)}>
								<Icon name={"print"}/>
							</Button>}
					/>
				</div>
			)
		},
	]);

	const gotoExport = (item) => {
		window.open(`${URL_REPORT.CR_STO_03_REPORT_EXPORT}?id=${item.id}`)
	}

	return (
		<ListModal 
			size={"large"}
			title={`เลขที่บัญชี : ${props.data ? props.data.account_code : ""} (${props.data ? props.data.account_name : ""})`}
			columns={columns}
			open={props.open}
			onClose={props.onClose}
			url={`${URL_REPORT.PURCHASE_ORDER_BY_ACCOUNTING}${props.data ? "?" + props.data.params : ""}`}/>
	)
}

POListModal.defaultProps = {
	open: false,
	onClose: () => {},
	data: null,
}

POListModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	data: propTypes.object,
}