import React from 'react';
import { Container, Button, Icon, Form, Label, Dimmer, Loader, Message } from 'semantic-ui-react';
import TitleBar from "../components/common/TitleBar";
import ControlInTankModal from "../components/lab/ControlInTankModal";
import ImageButton from "../components/common/ImageButton";
import LabNotify from "../components/lab/LabNotify";
import WeightInputWithCompareModal from "../components/tank/WeightInputWithCompareModal";
import { 
	TABLE_HEADER_STYLE,
	getColumnsList,
} from "../utils/Util";
import edit from "../assets/img_edit.png";
import { dateTimeToString, formatComma } from '../frameworks/Util';
import useListItem from '../frameworks/hooks/useItemList';
import { useParams } from 'react-router-dom';
import { URL_LAB, URL_TANK, USER_GROUP } from '../constances';
import Cookies from 'js-cookie';
import { GET, POST } from '../frameworks/HttpClient';
import TSReactTableFixedColumns from '../frameworks/TSReactTableFixedColumns';

export default function LabControlScreen() {
	const params = useParams();
	const user = JSON.parse(Cookies.get("user"));
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [openLabResult, setOpenLabResult] = React.useState(false);
	const [openWeightInput, setOpenWeightInput] = React.useState(false);
	const [notify, setNotify] = React.useState(null);
	const [targetLabData, setTargetLabData] = React.useState(null);
	const [readOnly,  setReadOnly] = React.useState(false);
	// Table
	const labList = useListItem({url: `${URL_LAB.CLOSED_TANK_LAB_RESULT}?tank_transaction=${params.id}`});
	const [data, setData] = React.useState(null);

	/********************************************** */
	const columnItem = (list, keyName) => {
		let item = null;
		for (let it of list) {
			if (it.chemical === keyName) {
				item = it;
				break;
			}
		}
		if (keyName === "coagulum" || keyName === "micro_coagulum") {
			const amount = item ? parseFloat(item.amount) : 0
			return (<p>{(item && amount !== 0) ? amount.toFixed(4) : 0}</p>)
		}
		else 
			return (<p>{item ? parseFloat(item.amount).toLocaleString("en-US") : "-"}</p>)
	}

	const columnOthers = (list) => {
		const except = ["water", "la", "nh3", "tz"];
		let result = "";
		for (let it of list) {
			let isOther = true;
			for (let ex of except) {
				if (it.chemical === ex) {
					isOther = false;
					break;
				}
			}
			
			if (isOther) {
				if (result) {
					result = `${result} \n${it.chemical} [${it.amount ? parseFloat(it.amount) : "-"}]`;
				} else {
					result = `${it.chemical} [${it.amount ? parseFloat(it.amount) : "-"}]`;
				}
			}
		}
		return (<p style={{whiteSpace: 'pre'}}>{result ? result : "-"}</p>)
	}

	const summaryLabInput = (data, chemicalKey) => {
		return data.reduce((partial, row) => partial + parseInt(row.lab_inputs.find(li => li['chemical'] === chemicalKey)['amount'] || 0), 0)
	}
	/********************************************** */

	const columns = React.useMemo(() => [
		{
			Header: "วันที่และเวลา",
			fixed: "left",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{
					Header: "Action",
					headerStyle: TABLE_HEADER_STYLE,
					show: !readOnly,
					width: 70,
					Cell: ({original}) => (
						<div>
							{(user.groups.find(group => group.name === USER_GROUP.LAB)) && (
								<ImageButton 
									title={"แก้ไข"}
									src={edit}
									onClick={() => setTargetLabData(original)}/>
							)}
						</div>
				)},
				{
					Header: "ตรวจ",
					Footer: "Summation",
					getFooterProps: () => ({ style: { ...TABLE_HEADER_STYLE, textAlign: "center" } }),
					Cell: ({original}) => {
						const date = dateTimeToString(original.created);
						const name = original.inspector_name;
						return (
							<div>
								<p style={{margin: 0}}>{`${date ? date : "-"}`}</p>
								<p style={{fontSize: 12}}>{name ? `(${name})` : ""}</p>
							</div>
						)
					}
				},
				{
					Header: "เติมสาร", 
					Cell: ({original}) => {
						const date = dateTimeToString(original.finish_datetime);
						const name = original.input_user_name;
						return (
							<div>
								<p style={{margin: 0}}>{`${(date && name) ? date : "-"}`}</p>
								<p style={{fontSize: 12}}>{name ? `(${name})` : ""}</p>
							</div>
						)
					}
				},
			], 140),
		},
		{
			Header: "ผลตรวจ",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ Header: "%TSC", accessor: "results", Cell: ({value}) => columnItem(value, "tsc") },
				{ Header: "%DRC", accessor: "results", Cell: ({value}) => columnItem(value, "drc") },
				{ 
					Header: "NON Rubber", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "non_rubber")
				},
				{ Header: "%NH3", accessor: "results", Cell: ({value}) => columnItem(value, "nh3") },
				{ Header: "VFA", accessor: "results", Cell: ({value}) => columnItem(value, "vfa") },
				{ Header: "KOH", accessor: "results", Cell: ({value}) => columnItem(value, "koh") },
				{ Header: "MST", accessor: "results", Cell: ({value}) => columnItem(value, "mst") },
				{ Header: "Mg", accessor: "results", Cell: ({value}) => columnItem(value, "mg") },
				{ 
					Header: "%Coagulum", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "coagulum")  
				},
				{ 
					Header: "Micro Coagulum", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "micro_coagulum") 
				},
				{ 
					Header: "Viscosity", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "viscosity")
				},
				{ 
					Header: "Sludge", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "sludge")
				},
				{ 
					Header: "Filterability", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "filterability") 
				},
				{ 
					Header: "%Lauric", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "la") 
				},
				{ 
					Header: "%WATER DILUTION", 
					accessor: "results", 
					headerStyle: { fontSize: 10 },
					Cell: ({value}) => columnItem(value, "water_dilution")
				},
			], 70),
		},
		{
			Header: "สารเคมีที่ต้องการเติมเพิ่ม",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{
					Header: "น้ำ", 
					accessor: "lab_inputs", 
					Cell: ({ value }) => columnItem(value, "water"), 
					Footer: ({data}) => {
						return summaryLabInput(data, "water")
					},
					getFooterProps: () => ({ style: { ...TABLE_HEADER_STYLE, textAlign: "center" } }),
				},
				{ 
					Header: "La", 
					accessor: "lab_inputs", Cell: 
					({ value }) => columnItem(value, "la"), 
					Footer: (({ data }) => summaryLabInput(data, "la")),
					getFooterProps: () => ({ style: { ...TABLE_HEADER_STYLE, textAlign: "center" } }),
				},
				{ 
					Header: "NH3", 
					accessor: "lab_inputs", 
					Cell: ({value}) => columnItem(value, "nh3"),
					Footer: (({ data }) => summaryLabInput(data, "nh3")),
					getFooterProps: () => ({ style: { ...TABLE_HEADER_STYLE, textAlign: "center" } }),
				},
				{ 
					Header: "TZ", 
					accessor: "lab_inputs", 
					Cell: ({value}) => columnItem(value, "tz"),
					Footer: (({ data }) => summaryLabInput(data, "tz")),
					getFooterProps: () => ({ style: { ...TABLE_HEADER_STYLE, textAlign: "center" } }),
				},
				{ 
					Header: "อื่นๆ", 
					accessor: "lab_inputs", 
					width: 150,
					Cell: ({value}) => columnOthers(value)
				},
			], 70),
		},
		{
			Header: "น้ำหนัก (Kg)",
			headerStyle: TABLE_HEADER_STYLE,
			columns: getColumnsList([
				{ 
					Header: "คำนวณ", 
					accessor: "calculate_weight", 
					Cell: ({value}) => (<p>{value ? formatComma(value) : "-"}</p>) 
				},
				{
					Header: "วัดได้", 
					accessor: "measure_weight",
					Cell: ({value}) => (<p>{value ? formatComma(value) : "-"}</p>) 
				},
				{
					Header: "ส่วนต่าง", 
					accessor: "different_weight",
					Cell: ({value}) => (<p>{value ? formatComma(value, true) : "-"}</p>) 
				},
			], 120),
		},
		...getColumnsList([
			{
				Header: "สถานะ",
				accessor: "is_finished",
				width: 70,
				Cell: ({value}) => {
					return (
						<div>
							<Icon size={"large"}
								name={value ? "check circle" : "minus circle"} 
								color={value ? "green" : "red"} />
						</div>
					)
				}
			}
		]),
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_TANK.TANK_TRANSACTION}${params.id}/`);
			setData(response.data);
			setReadOnly((response.data.state === "END") ? true : false)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	/** Confirm Input */
	const handleInputSubmit = async (args) => {
		if (!notify || !notify.id) {
			return [null, "ไม่มีข้อมูล Lab ID"];
		}

		let response = null;
		let error = null;
		const data = {measure_weight: args.weight}
		try {
			response = await POST(`${URL_LAB.CLOSED_TANK_LAB_RESULT}${notify.id}/confirm/`, data);
		} catch (e) {
			error = e.errorMessages;
		}
		return [response, error]
	}

	React.useEffect(() => {
		setOpenLabResult(targetLabData ? true : false)
	}, [targetLabData]);

	React.useEffect(() => {
		// Find lab that not finished
		const target = labList.response.find(item => item.is_finished === false);
		setNotify(target);
	}, [labList.response]);

	React.useEffect(() => {
		if (!openLabResult && !openWeightInput) {
			/** load list */
			labList.fetchData();
		}
	}, [openLabResult, openWeightInput]);

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={`แลปแท็งก์เก็บน้ำยาง ${data ? `[ ${data.lot_no} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}
				
				{(user.groups.find(group => group.name === USER_GROUP.FACTORY_TANK)) && notify && (
					<LabNotify 
					title={"สารเคมีที่รอการเติมลงแท็งก์"}
					items={notify ? notify.lab_inputs : []}
						onConfirm={() => setOpenWeightInput(true)}
					/>
				)}
				
				{(user.groups.find(group => group.name === USER_GROUP.LAB) && !readOnly) && (
					<Button
						color={"green"} 
						disabled={notify ? true : false}
						onClick={() => setOpenLabResult(true)}>
						<Icon name={"add"}/>เพิ่มผลตรวจ
					</Button>
				)}

				<Form style={{marginTop: 16}}>
					<Form.Group inline>
						<Form.Field width={3}>
							<label>น้ำหนักสะสม</label>
							<Label color={"blue"}>{data ? formatComma(data.refill_weight) : "0"}</Label>
						</Form.Field>

						<Form.Field width={4}>
							<label>น้ำหนักที่วัดได้ก่อนปิดแท็งก์</label>
							<Label color={"blue"}>{data ? formatComma(data.measure_weight) : "0"}</Label>
						</Form.Field>

						<Form.Field>
							<label>น้ำหนักปัจจุบัน</label>
							<Label color={"blue"}>{data ? formatComma(data.current_weight) : "0"}</Label>
						</Form.Field>
					</Form.Group>
				</Form>

				<TSReactTableFixedColumns
					style={{marginTop: 16}}
					columns={columns}
					data={labList.response}
					pageSize={(labList.response.length > 0) ? labList.response.length : 5}
					showPagination={false}
        />
			</Container>

			<ControlInTankModal 
				open={openLabResult}
				data={targetLabData}
				transaction={data ? data.id : null}
				onClose={() => {
					if (targetLabData)
						setTargetLabData(null);
					else 
						setOpenLabResult(false);
				}} />

			<WeightInputWithCompareModal 
				open={openWeightInput}
				title={"ยืนยันการเติมสารเคมี"}
				message1={"น้ำหนักที่ได้จากการคำนวณ"}
				targetWeight={notify ? parseFloat(notify.calculate_weight) : 0}
				message2={"กรุณากรอกน้ำหนักน้ำยางข้นในแท็งก์ (kg)"}
				remarkEnable={false}
				onClose={() => setOpenWeightInput(false)}
				onSubmit={handleInputSubmit} />
		</Container>
	)
}