import React from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Container, Loader, Dimmer, Message, List, Form, Input } from "semantic-ui-react";
import ImageButton from "../../components/common/ImageButton";
import { getColumnsList, TABLE_HEADER_STYLE } from "../../utils/Util";
import preview from "../../assets/img_preview.png";
import receipt from "../../assets/img_receipt.png";
import { GET } from "../../frameworks/HttpClient";
import { URL_PURCHASE } from "../../constances";
import { formatComma } from "../../frameworks/Util";
import { PATH } from "../../routes/Routes";
import PODocumentsModal from "../../components/store/PODocumentsModal";
import PaginationTable from "../../components/common/PaginationTable";
import _ from "lodash";

export default function MemoPaymentView() {
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [targetEdit, setTargetEdit] = React.useState(null);
	const [codeFilter, setCodeFilter] = React.useState("");
	const [debouncedCode, setDebouncedCode] = React.useState("");
	const [data, setData] = React.useState([]);
	const [page, setPage] = React.useState(1);

	const columns = React.useMemo(() => [
		...getColumnsList([ 
			{ Header: "รหัส PO", accessor: "code", width: 110 },
			{ Header: "ผู้ขาย", accessor: "supplier" },
			{ 
				Header: "รายการสินค้า", 
				accessor: "list_purchase_request",
				Cell: ({ value }) => <List style={{textAlign: "left"}} bulleted items={value.map(e => e.description)}/>
			},
			{ 
				Header: "ผู้ขอซื้อ", 
				accessor: "list_purchase_request", 
				width: 90,
				Cell: ({ value }) => {
					let temp = value.map(it => it.created_name)
					let unique = temp.filter((v, i, a) => a.indexOf(v) === i);
					return (<p>{unique.toString()}</p>)
				}
			},
			{
				Header: "ราคารวม", 
				accessor: "real_price", 
				width: 90, 
				Cell: ({ value }) => formatComma(value) 
			},
			{ Header: "สถานะ", accessor: "status", width: 100 },
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 100,
			Cell: (row) => {
				let status = row.original.status
				return (
					<div>
						<ImageButton 
							title={"รายละเอียดการสั่งซื้อ"}
							src={preview}
							color={"teal"}
							onClick={() => handleDetail(row.original)}/>
						{status && (status !== "รอชำระเงิน") && (
							<ImageButton 
								title={"เอกสารการเงิน"}
								src={receipt}
								onClick={() => setTargetEdit(row.original)}/>
						)}
					</div>
				)
			}
		},
	]);

	const fetchData = async () => {
		setErrorMessages(null);
		setLoading(true);
		let params = { code: codeFilter, page: page}
		try {
			const response = await GET(URL_PURCHASE.PURCHASE_ORDER_PAYMENT_MEMO_LIST, params);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleDetail = (data) => {
		// Go to purchase detail
		history.push({pathname: `${PATH.MEMO_PAYMENT}${data.id}`})
	}

	const debounce = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 1000),
    []
  );

	React.useEffect(() => {
		if (!targetEdit)
			fetchData();

	}, [targetEdit, page, debouncedCode])

	return (
		<Container fluid style={{marginTop: 24}}>
			<Dimmer inverted active={loading}>
				<Loader content={"Loading"}/>
			</Dimmer>

			{errorMessages && (
				<Message error content={errorMessages} />
			)}
			
			<Form>
				<Form.Group inline>
					<Form.Field 
						label={"รหัส PO"}
						control={Input}
						value={codeFilter}
						onChange={(_, data) => {
							setCodeFilter(data.value)
							debounce(data.value)
						}}/>
				</Form.Group>
			</Form>
			
			<PaginationTable
				columns={columns}
				data={data}
				onPageChange={(page) => setPage(page)}/>
			
			<PODocumentsModal 
				open={targetEdit ? true : false}
				data={targetEdit}
				onClose={() => setTargetEdit(null)}/>
		</Container>
	)
}

MemoPaymentView.defaultProps = {}

MemoPaymentView.propTypes = {}