import React from "react";
import propTypes from "prop-types";
import { Header, Form, Input, Dropdown, TextArea } from "semantic-ui-react";
import { URL_COMMON } from "../../constances";
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const LabQualityResult = React.forwardRef((props, ref) => {
	const model = { ...(props.initialModel || defaultInitialModal) };
	const gradeOptions = useDropdownItem({url: URL_COMMON.GRADE})

	let validationSchema = {
		nh3: Yup.number().required("กรุณากรอกข้อมูล"),
		vfa: Yup.number().required("กรุณากรอกข้อมูล"),
		grade: Yup.string().required("กรุณาเลือกเกรด"),
		note: Yup.string().notRequired(),
	}

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: model,
    validationSchema: Yup.object(validationSchema),
    onSubmit: props.onSubmit,
  });

	React.useImperativeHandle(ref, () => ({ formik }));

	React.useEffect(() => {
		gradeOptions.fetchData(); 
	}, []);

	return (
		<div style={{...props.style}}>
			<Header as={"h3"} style={{marginLeft: 24}}>ผลตรวจ</Header>
			<Form style={{textAlign: "right"}}>
				<Form.Group widths={"2"}>
					<Form.Field 
						style={{width: "55%"}}
						inline
						required
						label={"%NH3"}
						control={Input}
						placeholder={"0"} 
						type={"text"}
						name={"nh3"}
						error={formik.errors.nh3}
						value={formik.values.nh3}
						onChange={formik.handleChange}
					/>

					<Form.Field 
						style={{width: "55%"}}
						inline
						required
						label={"VFA"}
						control={Input}
						placeholder={"0"} 
						type={"text"}
						name={"vfa"}
						error={formik.errors.vfa}
						value={formik.values.vfa}
						onChange={formik.handleChange}
					/>
				</Form.Group>

				<Form.Field 
					style={{width: "78%"}}
					inline
					required
					label={"เกรด"}
					control={Dropdown}
					selection 
					name={"grade"}
					placeholder={"เลือก..."}
					options={gradeOptions.response}
					selectOnBlur={false}
					onChange={(_, data) => {
						formik.setFieldValue(data.name, data.value)
					}}
					error={formik.errors.grade}
					value={formik.values.grade}
				/>

				<Form.Field 
					style={{width: "78%"}}
					inline
					label={"หมายเหตุ"}
					control={TextArea}
					name={"note"}
					placeholder={"หมายเหตุ..."}
					error={formik.errors.note}
					value={formik.values.note}
					onChange={formik.handleChange}
				/>
			</Form>
		</div>
	)
});

const defaultInitialModal = {
  nh3: "",
  vfa: "",
  grade: "",
	note: "",
};

LabQualityResult.defaultProps = {
	style: null,
	initialModel: null,
}

LabQualityResult.propTypes = {
	style: propTypes.object,
	initialModel: propTypes.shape({
		nh3: propTypes.string,
		vfa: propTypes.string,
		grade: propTypes.string,
	}),
}

export default LabQualityResult;