import React, { useMemo } from 'react';
import moment from "moment";
import { Container, Header, Form, Button, Icon, Segment, Dimmer, Loader, Message, Dropdown } from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import TSReactTable from "../../../frameworks/TSReactTable";
import { getColumnsList } from "../../../utils/Util";
import { URL_REPORT } from '../../../constances/urls';
import { GET } from '../../../frameworks/HttpClient';
import { formatComma } from "../../../frameworks/Util";

export default function STWeeklyReportView() {
	const [grade, setGrade] = React.useState("OTHERS");
	const [dateRange, setDateRange] = React.useState(null);
	const [dateRangeError, setDateRangeError] = React.useState(false);
	// Table
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const columns = useMemo(() => [
		...getColumnsList([
			{ Header: "LOT", accessor: "lot_no", winWidth: 120 },
			{ Header: "GRADE", accessor: "grade", minWidth: 120  },
			{ 
				Header: "QUANTITY (KGS)", 
				accessor: "quantity",
				minWidth: 140,
				Cell: ({value}) => (<p>{formatComma(value)}</p>),
			},
			{ Header: "DATE OF SALE", accessor: "sell_date", width: 100 },
			{ Header: "DATE OF PRODUCT", accessor: "datetime", width: 140 },
			{ 
				Header: "Maturity (DAY)",
				accessor: "maturity",
				width: 80,
			},
		]),
		...getColumnsList([
			{ Header: "TSC", accessor: "tsc" },
			{ Header: "DRC", accessor: "drc" },
			{ Header: "NON", accessor: "non_rubber" },
			{ Header: "NH3", accessor: "nh3" },
			{ 
				Header: "VFA", 
				accessor: "vfa",
				Cell: ({value}) => (<p>{parseFloat(value).toFixed(3)}</p>),
			},
			{ Header: "KOH", accessor: "koh" },
			{ Header: "MST", accessor: "mst" },
			{ Header: "MG", accessor: "mg" },
			{ Header: "%Coagulum", accessor: "coagulum", headerStyle: { fontSize: 10 } },
			{ Header: "Micro Coagulum", accessor: "micro_coagulum", headerStyle: { fontSize: 10 } },
			{ Header: "Viscosity", accessor: "viscosity", headerStyle: { fontSize: 10 } },
			{ Header: "Sludge", accessor: "sludge", headerStyle: { fontSize: 10 } },
			{ Header: "Filterability", accessor: "filterability", headerStyle: { fontSize: 10 } },
			{ Header: "%Lauric", accessor: "la", headerStyle: { fontSize: 10 } },
			{ Header: "%WATER DILUTION", accessor: "water_dilution", headerStyle: { fontSize: 10 } },
		], 70)
	])

	const fetchData = async () => {
		if (!dateRange) {
			setDateRangeError(true)
			return;
		}

		if (dateRangeError)
			setDateRangeError(false);
		
		setLoading(true)
		setErrorMessages(null)
		try {
			const response = await GET(
				URL_REPORT.ST_WEEKLY_REPORT, 
				{
					date_before: moment(dateRange[0]).format('YYYY-MM-DD'),
					date_after: moment(dateRange[1]).format('YYYY-MM-DD'),
					grade: grade,
				}
			)
			setData(response.data)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Container fluid style={{marginTop: 24}}>
			<Form>
				<Form.Group inline>
					<Form.Field
						style={{width: "500px"}}
						inline
						label={"วันที่"}
						control={SemanticDatepicker}
						locale="en-US"
          	type="range"
						showToday={true}
						format="DD/MM/YY"
						placeholder={"เลือกช่วงเวลา..."}
						value={dateRange}
						error={dateRangeError}
						onChange={(_, data) => setDateRange(data.value)}/>

					<Form.Field 
            label={"เกรดผลิต"}
            control={Dropdown}
						selectOnBlur={false}
            selection
            value={grade}
            onChange={(_, data) => setGrade(data.value)}
            options={[
              {key: "OTHERS", value: "OTHERS", text: "ทั่วไป"},
              {key: "FSC", value: "FSC", text: "FSC"},
              {key: "ALL", value: "ALL", text: "ทั้งหมด"},
            ]}/>

					<Button primary onClick={() => fetchData()}>ค้นหา</Button>
				</Form.Group>
			</Form>

			{ errorMessages && 
				<Message error>{errorMessages}</Message>
			}
			<Segment basic style={{padding: 0}}>
				<Dimmer active={loading} inverted><Loader inverted/></Dimmer>
				<TSReactTable
					columns={columns}
					data={data}
					pageSize={(data.length > 0) ? data.length : 10}
					showPagination={false}/>
					
				<Button 
					style={{marginTop: 8}}
					color={"red"}
					floated={"right"}
					size={"mini"}
					disabled={!dateRange}
					onClick={() => window.open(
						`${URL_REPORT.ST_WEEKLY_REPORT_EXPORT}pdf/
						?date_before=${moment(dateRange[0]).format('YYYY-MM-DD')}
						&date_after=${moment(dateRange[1]).format('YYYY-MM-DD')}
						&grade=${grade}`
					)}>
						<Icon name={"file pdf"}/>
						PDF
				</Button>
			</Segment>
		</Container>
	)
}