import React from 'react';
import propTypes from "prop-types";
import { Segment, Header, Form, Button, Icon, Dropdown, TextArea } from 'semantic-ui-react';
import SummaryField from "../common/SummaryField";
import WeightInputModal from "../tank/WeightInputModal";
import TruckTransactionModal from "./TruckTransactionModal";
import { POST } from "../../frameworks/HttpClient";
import { URL_POND, URL_COMMON } from "../../constances";
import { formatComma } from "../../frameworks/Util";
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import * as Yup from 'yup';
import { useFormik } from 'formik';
export default function RefillSegment(props) {
	const gradeOptions = useDropdownItem({url: URL_COMMON.GRADE})
	const [openChangeState, setOpenChangeState] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [openTruck, setOpenTruck] = React.useState(false);

	let validationSchema = {
		closing_grade: Yup.string().required("กรุณากรอกข้อมูล"),
	}

	const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { closing_grade: ""},
    validationSchema: Yup.object(validationSchema),
    onSubmit: props.onSubmit,
  });

	/** CLOSE */
	const handleChangeState = async (args) => {
		// Validation
		const validation = await formik.validateForm();
		const isValid = Object.keys(validation).length === 0;
		if (!isValid)
			return [null, "ข้อมูลยังไม่เพียงพอ"];
		
		let response = null;
		let error = null;
		let data = { 
			...formik.values,
			measure_weight: parseFloat(args.weight),
			closing_note: args.note,
		}
		
		try {
			response = await POST(`${URL_POND.POND_TRANSACTION}${props.data ? props.data.id : ""}/close/`, data)
		} catch (e) {
			error = e.errorMessages
		}
		return [response, error]
	}

	/** EDIT */
	const handleEditWeight = async (args) => {
		let response = null;
		let error = null;
		let data = { 
			measure_weight: parseFloat(args.weight),
			note: args.note,
		}
		try {
			response = await POST(`${URL_POND.POND_TRANSACTION}${props.data ? props.data.id : ""}/update_weight/`, data)
		} catch (e) {
			error = e.errorMessages
		}
		return [response, error]
	}

	const renderGrade = () => {
		return (
			<>
				<Form.Field 
					label={"เกรด"}
					control={Dropdown}
					selection
					placeholder={"เลือก..."}
					options={gradeOptions.response}
					name={"closing_grade"}
					selectOnBlur={false}
					onChange={(_, data) => {
						formik.setFieldValue(data.name, data.value)
					}}
					error={formik.errors.closing_grade}
					value={formik.values.closing_grade}/>
			</>
		)
	}
	
	const closeModal = () => {
		/** Close Modal */
		if (openChangeState)
			setOpenChangeState(false);
		if (openEdit)
			setOpenEdit(false);
		
		/** RELOAD */
		if (props.data) {
			props.onReload();
		
		formik.resetForm();
		}
	}

	React.useEffect(() => {
		gradeOptions.fetchData(); 
	}, []);
	
	return (
		<Segment style={{width: "55%", margin: "auto", ...props.style}}>
			<Header dividing>เติมน้ำยาง</Header>

			<Form style={{textAlign: "center"}}>
				<SummaryField 
					title={"น้ำหนักจากสะพานชั่ง"}
					value={props.data 
						? formatComma(parseFloat(props.data.truck_transaction_weight))
						: "-"}
					unit={"กิโลกรัม"}/>
				
				<SummaryField 
					title={"น้ำหนักที่วัดได้จากบ่อ"}
					value={props.isFinished 
						? formatComma(parseFloat(props.data.measure_weight))
						: "-"}
					unit={"กิโลกรัม"}/>

				<SummaryField 
					title={"น้ำหนักส่วนต่าง"}
					value={props.isFinished 
						? formatComma(parseFloat(props.data.truck_transaction_weight) - parseFloat(props.data.measure_weight))
						: "-"}
					unit={"กิโลกรัม"}/>
				
				<Form.Group 
					style={{width: "100%", textAlign: "left", margin: "auto"}}>
					<Form.Field
						width={16}
						readOnly
						label={"หมายเหตุ"}
						control={TextArea}
						placeholder={"หมายเหตุ..."}
						value={( props.data && props.data.closing_note) ? props.data.closing_note : ""}/>
				</Form.Group>
			</Form>

			<div style={{textAlign: "right", marginTop: 16}}>
				<Button icon 
					basic 
					color={"teal"} 
					onClick={() => setOpenTruck(true)}>
						<Icon name={"truck"}/>
				</Button>
				{props.isFinished && (
					<Button 
						color={"yellow"} 
						onClick={() => setOpenEdit(true)}>
							แก้ไข
					</Button>
				)}
				{!props.isFinished && (
					<Button 
						primary 
						onClick={() => setOpenChangeState(true)}>
							ปิดบ่อ
					</Button>
				)}
			</div>

			{/* Goto CLOSE */}
			<WeightInputModal
				open={openChangeState}
				title={`ปิดบ่อ`}
				message={`กรุณากรอกน้ำหนักน้ำยางในบ่อพัก (kg)`}
				addOnDetail={renderGrade()}
				remarkEnable={true}
				remarkRequired={false}
				onSubmit={handleChangeState}
				onClose={closeModal}
			/>

			{/* For edit */}
			<WeightInputModal
				open={openEdit}
				title={`แก้ไขน้ำหนักหลังปิดบ่อ`}
				message={`กรุณากรอกน้ำหนักน้ำยางในบ่อพัก (kg)`}
				remarkEnable={true}
				remarkRequired={true}
				onSubmit={handleEditWeight}
				onClose={closeModal}
			/>

			{/* Transaction Detail */}
			<TruckTransactionModal
				pondTransaction={props.data ? props.data.id : null} 
				pondName={props.data ? props.data.name : ''}
				open={openTruck}
				onClose={() => setOpenTruck(false)}/>
		</Segment>
	)
}

RefillSegment.defaultProps = {
	style: null,
	data: null,
	isFinished: false,
	onReload: () => {},
	
}

RefillSegment.propTypes = {
	data: propTypes.object,
	isFinished: propTypes.bool,
	onReload: propTypes.func,
}