import React from 'react';
import { GET, PUT, DELETE } from '../HttpClient';


// usage ==> const { response, isLoading, errorMessages, fetchData, handleSave } = useItemDetail({
//   url: '/api/items/',                    **url for item
//   id: '513a-1235-....',                  **item id
//   defaultModel: {id: ..., name: ...}     **item default model 
//   readOnly: true,                        **if readOnly is true then item can't be save
//   manualFetch: true,                     **if manualFetch is true then item is not fetched when mounted (manually call fetchdata instead)
//   onSaveSuccess: () => history.back(),       **if save success it'll return true or call onSaveSuccess
// });
export default function useItemDetail ({
  url,
  id,
  defaultModel=null,
  readOnly=false,
  manualFetch=false,
  onSaveSuccess=() => {}, 
  onDeleteSuccess=() => {}
}) {
  const [response, setResponse] = React.useState(defaultModel);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const fetchData = async () => {
    if (!id) {
      return false;
    }
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${url}${id}/`)
      setResponse(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSave = async (params) => {
    if (readOnly || !id) {
      return false;
    }
    let isSuccess = false;
  
    setIsLoading(true);
    setErrorMessages(null);
    try {
      await PUT(`${url}${id}/`, params);
  
      isSuccess = true;
      onSaveSuccess();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  
    return isSuccess;
  }

  const handleDelete = async () => {
    if (readOnly || !id) {
      return false;
    }
    let isSuccess = false;
  
    setIsLoading(true);
    setErrorMessages(null);
    try {
      await DELETE(`${url}${id}/`);
  
      isSuccess = true;
      onDeleteSuccess();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  
    return isSuccess;
  }
  
  React.useEffect(() => {
    if (!manualFetch) {
      fetchData();
    }
  }, [])

  return { response, isLoading, errorMessages, fetchData, handleSave, handleDelete };
}
