import React from 'react';
import propTypes from "prop-types";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, Dropdown, Message, Input } from 'semantic-ui-react';
import useDropdownItem from "../../frameworks/hooks/useDropdownItem";
import { formatComma } from "../../frameworks/Util";
import { POST } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import ConfirmModal from "../common/ConfirmModal";
import { URL_COMMON, URL_POND } from "../../constances";

export default function SplitPondModal(props) {
  const gradeOptions = useDropdownItem({ url: URL_COMMON.GRADE })
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [toPondOptions, setToPondOptions] = React.useState([]);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      from_grade_new: Yup.string().required("กรุณาเลือกเกรดผลิตบ่อต้นทาง"),
      to_pond: Yup.string().required("กรุณาเลือกบ่อปลายทาง"),
      grade: Yup.string().required("กรุณาเลือกเกรดผลิตบ่อปลายทาง"),
      weight: Yup.number().required("กรุณากรอกน้ำหนัก"),
    }),
    initialValues: {
      from_grade_new: '',
      to_pond: '',
      grade: '',
      weight: '',
    },
    onSubmit: handleSubmit,
  })

  React.useEffect(() => {
    if (props.from_pond != null && props.table_data != null) {
      setToPondOptions(props.table_data
        .filter(item => item.state === 'REFILL' && item.weight === 0 && item.id !== props.from_pond.id)
        .map(item => ({
          key: item.id,
          value: item.id, 
          text: item.name
        }))
      )
      formik.setFieldValue('from_grade_new', props.from_pond.closing_grade)
    }
  }, [props.table_data, props.from_pond])

  React.useEffect(() => {
    formik.resetForm();
    if (props.from_pond != null){
      formik.setFieldValue('from_grade_new', props.from_pond.closing_grade)
    }
  }, [props.open])

  React.useEffect(() => {
    gradeOptions.fetchData();
  }, []);

  async function handleSubmit() {
    const validation = await formik.validateForm();
    const isValid = Object.keys(validation).length === 0;
    if (!isValid) return;
    setLoading(true);
    try {
      await POST(URL_POND.POND_SPLIT, {
        'from_pond_transaction': props.from_pond.transaction,
        ...formik.values
      });
      Toast.success('แบ่งบ่อสำเร็จ')
      props.onSuccess();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  return(
    <ConfirmModal
      open={props.open}
      title={`แบ่งบ่อจาก ${props.from_pond?.name}`}
      size={"tiny"}
      loading={loading}
      negativeText={"ยกเลิก"}
      positiveText={"ยืนยัน"}
      onClose={props.onClose}
      onConfirm={handleSubmit}
      onCancel={props.onClose}
      detail={
        <div>
          {errorMessages && (
            <Message
              negative
              header={"เกิดข้อผิดพลาด"}
              content={errorMessages} />
          )}
          <Form>
            <Form.Group widths={"equal"}>
              <Form.Field 
                label={"บ่อต้นทาง"}
                control={Input}
                value={`${props.from_pond?.name} (นำ้หนัก ${formatComma(props.from_pond?.weight)} Kg)`}/>

              <Form.Field
                required
                label={"เกรดผลิต (บ่อต้นทาง)"}
                control={Dropdown}
                selection
                name={"from_grade_new"}
                placeholder={"เลือก..."}
                options={gradeOptions.response}
                selectOnBlur={false}
                onChange={(_, data) => {
                  formik.setFieldValue('from_grade_new', data.value)
                }}
                error={formik.errors.from_grade_new}
                value={formik.values.from_grade_new}
              />
            </Form.Group>

            <Form.Group widths={"equal"}>
              <Form.Field 
                required
                label={"บ่อปลายทาง"}
                control={Dropdown}
                selection
                name={"to_pond"}
                placeholder={"เลือก..."}
                options={toPondOptions}
                onChange={(_, data) => {
                  formik.setFieldValue('to_pond', data.value)
                }}
                selectOnBlur={false}
                error={formik.errors.to_pond}
                value={formik.values.to_pond}/>
                
              <Form.Field
                required
                label={"เกรดผลิต (บ่อปลายทาง)"}
                control={Dropdown}
                selection
                name={"grade"}
                placeholder={"เลือก..."}
                options={gradeOptions.response}
                onChange={(_, data) => {
                  formik.setFieldValue('grade', data.value)
                }}
                selectOnBlur={false}
                error={formik.errors.grade}
                value={formik.values.grade}
              />
            </Form.Group>

            <Form.Field
              required
              control={Input}
              label={"น้ำหนัก (Kg)"}
              name={"weight"}
              placeholder={"กรอกน้ำหนัก..."}
              onChange={formik.handleChange}
              error={formik.errors.weight}
              value={formik.values.weight}
            />
          </Form>
        </div>
      }
    />
  )
}

SplitPondModal.defaultProps = {
  open: false,
  from_pond: null,
  onClose: () => null,
  onSuccess: () => null,
  table_data: null,
}

SplitPondModal.propTypes = {
  open: propTypes.bool,
  from_pond: propTypes.object,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
  table_data: propTypes.object,
}
