import React from 'react';
import propTypes from "prop-types";
import { Form, Dropdown, Message, Input, TextArea } from 'semantic-ui-react';
import ConfirmModal from "../common/ConfirmModal";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_TRUCK, URL_POND, URL_LAB } from "../../constances";
import { dateTimeToString } from '../../frameworks/Util';

export default function TruckInputModal(props) {
	const [options, setOptions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [truckSelected, setTruckSelected] = React.useState(null);

	const fetchOptions = async () => {
		try {
			const response = await GET(`${URL_TRUCK.TRUCK_TRANSACTION}?status=approved`);
			setOptions(
        response.data.map(item => {
          return {
            ...item,
            key: item.id, 
            value: item.id, 
            text: item.license_plate
          }
        })
      );
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const handleTruckChange = (_, data) => {
		const target = options.find(item => item.id === data.value);
		setTruckSelected(target);
	}

	const handleSubmit = async () => {
		if (!truckSelected) {
			setErrorMessages("ยังไม่ได้เลือกทะเบียนรถ");
			return;
		}
		// Prepare Data
		setErrorMessages(null);
		setLoading(true);
		let response = null;
		let data = {
			pond_transaction: props.id,
			truck_transaction: truckSelected.id,
		}
		try {
			response = await POST(URL_POND.POND_TRUCK_TRANSACTION, data);
		} catch (error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false);
		}

		if (response)
			props.onClose();
	}

	const getLabValue = (key, list) => {
		const target = list.find(item => item.chemical === key);
		return target ? target.amount : "0"
	}

	React.useEffect(() => {
		if (props.open) {
			setTruckSelected(null);
			if (props.id) {
				fetchOptions();
				setErrorMessages(null);
			} else {
				setErrorMessages("pond_transaction not found.")
			}
		}
	}, [props.open]);

	return (
		<ConfirmModal
			open={props.open}
			title={"เพิ่มรถ"}
			size={"tiny"}
			loading={loading}
			negativeText={"ยกเลิก"}
			positiveText={"ยืนยัน"}
			onClose={props.onClose}
			onConfirm={handleSubmit}
			onCancel={props.onClose}
			detail={
				<div>
					{errorMessages && (
						<Message 
							negative
							header={"เกิดข้อผิดพลาด"}
							content={errorMessages}/>
					)}
					<Form style={{textAlign: "right"}}>
						<Form.Field 
							style={{width: "85%"}}
							inline
							label={"ทะเบียนรถ"}
							control={Dropdown}
							selectOnBlur={false}
							selection
							placeholder={"เลือก..."}
							options={options}
							value={truckSelected ? truckSelected.value : null}
							onChange={handleTruckChange}/>
						
						{truckSelected && (
							<div>
							<Form.Field 
								style={{width: "85%"}}
								inline
								label={"เวลาเข้า"}
								control={Input}
								value={(truckSelected) 
									? dateTimeToString(truckSelected.in_datetime) : ""}/>
							<Form.Field 
								style={{width: "85%"}}
								inline
								label={"เกรด"}
								control={Input}
								value={(truckSelected && truckSelected.lab_results) 
									? truckSelected.lab_results.grade : ""}/>
							<Form.Group widths={"2"}>
								<Form.Field 
									style={{width: "70%"}}
									inline
									label={"%NH3"}
									control={Input}
									placeholder={"0"}
									value={(truckSelected && truckSelected.lab_results) 
										? parseFloat(getLabValue("nh3", truckSelected.lab_results.results)).toFixed(2) 
										: ""}/>

								<Form.Field 
									style={{width: "70%"}}
									inline
									label={"VFA"}
									control={Input}
									placeholder={"0"}
									value={(truckSelected && truckSelected.lab_results) 
										? parseFloat(getLabValue("vfa", truckSelected.lab_results.results)).toFixed(3) 
										: ""}/>
							</Form.Group>
							<Form.Field 
									style={{width: "85%"}}
									inline
									label={"หมายเหตุ"}
									control={TextArea}
									placeholder={"หมายเหตุ..."}
									value={(truckSelected && truckSelected.lab_results && truckSelected.lab_results.note) 
										? truckSelected.lab_results.note : ""}/>
							</div>
						)}
						
					</Form>
				</div>
			}
		/>
	)
}

TruckInputModal.defaultProps = {
	open: false,
	id: null,
	onClose: () => {},
}

TruckInputModal.propTypes = {
	open: propTypes.bool,
	id: propTypes.string,
	onClose: propTypes.func,
}