import React from 'react';
import { Container, Button, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import TitleBar from "../components/common/TitleBar";
import TSReactTable from "../frameworks/TSReactTable";
import ImageButton from "../components/common/ImageButton";
import TruckInputModal from "../components/pond/TruckInputModal";
import WeightInputModal from "../components/tank/WeightInputModal";
import { TABLE_HEADER_STYLE, getColumnsList } from "../utils/Util";
import edit from "../assets/img_edit.png";
import { useParams } from "react-router-dom";
import { GET, PATCH } from "../frameworks/HttpClient";
import useListItem from "../frameworks/hooks/useItemList";
import { URL_POND } from "../constances";
import { dateTimeToString, formatComma } from "../frameworks/Util";

export default function PondDetailScreen() {
	const params = useParams();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Modal
	const [openTruck, setOpenTruck] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [targetSelected, setTargetSelected] = React.useState(null);
	// Table
	const [data, setData] = React.useState(null);
	const [total, setTotal] = React.useState(0);
	const list = useListItem({url: `${URL_POND.POND_TRUCK_TRANSACTION}?pond_transaction=${data ? data.transaction : ""}`});
	const columns = React.useMemo(() => [
		...getColumnsList([
			{ 
				Header: "วันที่และเวลา", 
				accessor: "date", 
				width: 160, 
				Cell: ({value}) => (<p>{dateTimeToString(value)}</p>),
				Footer: "รวม", 
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
			{ Header: "ทะเบียนรถ", accessor: "license_plate" },
			{ Header: "จุดรับซื้อ", accessor: "customer_name" },
			{ Header: "เกรดน้ำยาง", accessor: "grade", width: 160 },
			{ 
				Header: "น้ำหนัก (kg)", 
				accessor: "weight" ,
				Cell: ({value}) => (<p>{formatComma(value)}</p>),
				Footer: formatComma(total),
				getFooterProps: () => ({style: {...TABLE_HEADER_STYLE, textAlign: "center"}}),
			},
		]),
		{
			Header: "Action",
			headerStyle: TABLE_HEADER_STYLE,
			width: 120,
			Cell: (row) => (
				<ImageButton 
					title={"แก้ไข"}
					src={edit}
					onClick={(e, data) => handleEdit(row.original)}/>
			),
		},
	]);

	/** load data */
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await GET(`${URL_POND.POND}${params.id}/`);
			setData(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const handleEdit = (data) => {
		setTargetSelected(data)
	}

	/** Update Weight */
	const handleUpdateWeight = async (args) => {
		let response = null;
		let error = null;
		let data = { override_weight: args.weight }
		try {
			response = await PATCH(`${URL_POND.POND_TRUCK_TRANSACTION}${targetSelected ? targetSelected.id : ""}/`, data)
		} catch (e) {
			error = e.errorMessages;
		}
		
		return [response, error]
	}

	React.useEffect(() => {
		setOpenEdit(targetSelected ? true : false);
	}, [targetSelected]);

	React.useEffect(() => {
		/** Calculate total */
		let sum = list.response.reduce((prev, current) => prev + current.weight, 0);
		setTotal(sum);
	}, [list.response])

	React.useEffect(() => {
		if (data && !openTruck && !openEdit) {
			/** load list */
			list.fetchData();
		}
	}, [data, openTruck, openEdit]);

	React.useEffect(() => {
		fetchData();
	}, [])

	return (
		<Container fluid>
			<TitleBar title={`รายละเอียด ${data ? `[ ${data.name} ]` : ""}`}/>

			<Dimmer inverted active={loading}>
				<Loader size={"large"} content={"Loading"}/>
			</Dimmer>

			<Container style={{width: "95%", marginTop: 24}}>
				{errorMessages && (
					<Message 
						negative
						header={"เกิดข้อผิดพลาด"}
						content={errorMessages}/>
				)}

				<Button color={"green"} onClick={() => setOpenTruck(true)}>
					<Icon name={"add"}/>เพิ่มรถ
				</Button>
				<TSReactTable 
					style={{marginTop: 16}}
					columns={columns}
					data={list.response}
					pageSize={(list.response.length > 0) ? list.response.length : 5}
					showPagination={false}/>
			</Container>

			{/* For edit weight */}
			<WeightInputModal
				open={openEdit}
				title={`แก้ไขน้ำหนักน้ำยาง`}
				message={`กรุณากรอกน้ำหนักน้ำยาง (kg) ${targetSelected ? "[ ทะเบียนรถ: " + targetSelected.license_plate + " ]" : ""}`}
				initialWeight={targetSelected ? targetSelected.weight.toString() : ""}
				onSubmit={handleUpdateWeight}
				onClose={() => setTargetSelected(null)}
			/>

			{/* For add truck */}
			<TruckInputModal
				open={openTruck}
				id={data ? data.transaction : null}
				onClose={() => setOpenTruck(false)}
			/>
		</Container>
	)
}