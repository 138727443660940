import React, { useState } from 'react'
import logo from '../assets/mmg_logo.jpg'
import {
  Button, Form, Grid, Header, Message, Segment, Image, Divider
} from 'semantic-ui-react'
import { POST } from '../frameworks/HttpClient';
import { URL_AUTH } from "../constances/urls";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { MOBILE_WIDTH } from "../Config";
import { Toast } from '../frameworks/Toast';
import useUserData from '../hooks/useUserData';
import { PATH } from '../routes/Routes';


export default function LoginScreen() {
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);
  const history = useHistory();
  const [toggleShowPassword, setToggleShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  // forgot password menu
  const [activeMenu, setActiveMenu] = useState('login');
  const [isRequestClicked, setIsRequestClicked] = useState(false);
  const { setUserData } = useUserData();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("จำเป็น"),
    password: Yup.string().required("จำเป็น"),
  });
  const formik = useFormik({
		validateOnChange: false,
    validationSchema: validationSchema,
    initialValues: {
      username: '',
      password: '',
    },
		onSubmit: (values) => onSubmitLogin(values),
  });
  
  const requestResetPasswordForm = useFormik({
		validateOnChange: false,
    validationSchema: Yup.object().shape({
      user: Yup.string().required("จำเป็น")
    }),
    initialValues: {
      user: '',
    },
		onSubmit: (values) => handleSendRequestResetPassword(values),
  });

  const onSubmitLogin = async (values) => {
    setIsLoading(true);
    setErrorMessage(null);
    try{
      const response = await POST(URL_AUTH.API_TOKEN_AUTH, {...values})
      Cookies.set('token', response.data.token, { expires: 30 })
      setUserData(response.data.user)
      history.replace(PATH.HOME);
    } catch(error) { 
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendRequestResetPassword = async (data) => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      await POST(URL_AUTH.REQUEST_RESET_PASSWORD, data);
      Toast.success(`ส่งคำขอเรียบร้อยแล้ว: ${data.user}`)
      requestResetPasswordForm.resetForm();
      setIsRequestClicked(true);
      setTimeout(() => setIsRequestClicked(false), 5000);
      handleChangeMenu('login')
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeMenu = (menu) => {
    formik.resetForm();
    requestResetPasswordForm.resetForm();
    setErrorMessage(null);
    setActiveMenu(menu);
  }

  return (
    <div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column
          style={{ 
            maxWidth: 450, 
            display: 'flex', 
            alignItems: "center" 
          }}>
          <Image src={logo} size='small' centered/>
          { activeMenu === 'login' && 
            <Segment 
              style={{width: (isMobile ? "95%" : "100%")}} >
              <Form onSubmit={formik.handleSubmit} >
                <Message
                  error
                  header={"เข้าสู่ระบบไม่สำเร็จ"}
                  content={errorMessage}
                  visible={errorMessage != null}
                />
                <Form.Input
                  fluid
                  name='username'
                  icon='user'
                  iconPosition='left'
                  placeholder={"ชื่อผู้ใช้"}
                  value={formik.values.username}
                  error={formik.errors.username}
                  onChange={formik.handleChange}
                />
                <Form.Input
                  fluid
                  name='password'
                  icon='lock'
                  iconPosition='left'
                  placeholder={"รหัสผ่าน"}
                  action={
                    <Button
                      basic
                      type='button'
                      icon={toggleShowPassword ? 'eye' : 'eye slash'}
                      onClick={() => setToggleShowPassword(prevState => !prevState)}/>
                  }
                  actionPosition='right'
                  type={toggleShowPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  error={formik.errors.password}
                  onChange={formik.handleChange}
                />
                <Button
                  color='blue'
                  fluid size='large'
                  type='submit'
                  loading={isLoading}
                >
                  {"เข้าสู่ระบบ"}
                </Button>
                <Divider hidden/>
                <a disabled={isLoading} onClick={() => handleChangeMenu('forgot-password')}>{"ลืมรหัสผ่าน"}</a>
              </Form>
            </Segment>
          }
          { activeMenu === 'forgot-password' && 
            <Segment
              style={{width: (isMobile ? "95%" : "100%")}} >
              <Header color='grey'>{"ลืมรหัสผ่าน"}</Header>
              <Form>
                <Message 
                  error
                  header={"ส่งคำขอไม่สำเร็จ"}
                  content={errorMessage}
                  visible={errorMessage != null}
                />
                <Form.Field>
                  <Form.Input
                    name='user'
                    icon='mail'
                    iconPosition='left'
                    placeholder={"อีเมล"}
                    value={requestResetPasswordForm.values.user}
                    error={requestResetPasswordForm.errors.user}
                    onChange={requestResetPasswordForm.handleChange}
                  />
                </Form.Field>
                <Button
                  color='blue'
                  fluid size='large'
                  loading={isLoading}
                  disabled={isRequestClicked}
                  onClick={requestResetPasswordForm.handleSubmit}
                >
                  {"ส่งคำขอ"}
                </Button>
                <Divider hidden/>
                <a disabled={isLoading} onClick={() => handleChangeMenu('login')}>{"กลับ"}</a>
              </Form>
            </Segment>
          }
        </Grid.Column>
      </Grid>
    </div>
  )
}

