import moment from "moment";

export const formatComma = (value, enable_negative=true, decimal_places=2) => {
  if (!value) {
    return 0;
  }
  const isNegative = value.toString().startsWith('-')
  const split_decimal = (+parseFloat(value.toString().replace(',', '').replace('-', ''))).toFixed(decimal_places).split('.');
  const number = split_decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (+split_decimal[1] ? "." + split_decimal[1] : "");
  return `${isNegative && enable_negative ? '-' : ''}${number}`
}

export const currencyFormat = (value) => {
  if (!value) 
    return "0.00";
  
  const currency = parseFloat(value).toFixed(2);
  return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const shortCurrencyFormat = (value, enable_negative=false) => {
  if (!value) {
    return 0;
  }

  const isNegative = value.toString().startsWith('-')
  const numberValue = (+parseFloat(value.toString().replace(',', '').replace('-', '')));
  if (numberValue >= 1000000000) {
    return `${isNegative && enable_negative ? '-' : ''}${(numberValue/1000000000).toFixed(2)}B`
  } else if (numberValue >= 1000000) {
    return `${isNegative && enable_negative ? '-' : ''}${(numberValue/1000000).toFixed(2)}M`
  } else if (numberValue >= 1000) {
    return `${isNegative && enable_negative ? '-' : ''}${(numberValue/1000).toFixed(2)}K`    
  }
  return `${isNegative && enable_negative ? '-' : ''}${formatComma(value)}`
}

export const shortWeightFormat = (value) => {
  if (!value) {
    return 0;
  }

  const numberValue = (+parseInt(value.toString().replace(',', '').replace('-', '')));
  if (numberValue >= 10000000) {
    return `${formatComma((numberValue/1000000).toFixed(2))}K`;
  } else if (numberValue >= 10000) {
    return formatComma((numberValue/1000).toFixed(2));
  }
  return formatComma(value)
}

export const checkWeightAmountUseTonne = (value) => {
  if (!value) {
    return 0;
  }

  const numberValue = (+parseInt(value.toString().replace(',', '').replace('-', '')));
  return numberValue >= 10000;
}

export const ellipsisString = (value, threshold_length=15) => {
  if (!value) {
    return '';
  }
  
  return `${value.toString().substring(0, threshold_length)}${value.toString().length > threshold_length ? '...' : ''}`
}

/** Convert Date-Time */
export const dateTimeToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY HH:mm");
}

export const dateToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY");
}

/**
 * Format number in bytes into KB, MB, GB...
 * @param {*} bytes
 * @param {*} decimals
 */
 export const formatBytes = (bytes, decimals=2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatFileName = (link) => {
  if (!link) {
    return ""
  }
  
  const file_name = link.split('/').pop()
  return <a target="_blank" rel="noopener noreferrer" href={link}>{ellipsisString(file_name, 35)}</a>
}

// ปัดเศษทศนิยม decimals ตำแหน่ง
export const toFixed = (num, decimals=2) => {
  if (parseFloat(num) == NaN)
    return 0
 
  num *= Math.pow(10,decimals);
  num = (Math.round(num,decimals) + (((num - Math.round(num,decimals))>=0.4)?1:0)) / Math.pow(10,decimals);
  return num.toFixed(decimals);
 }